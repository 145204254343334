import React, { memo, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../shared/utils/colors'
import { navLink, langData } from '../../../shared/utils/landingData'
import { ButtonIcon, TopIcon } from '../../../components/svgs/Svgs'

function LandingDrover({ setProp }) {
    const [selectLanguage, setSelectLanguage] = useState(localStorage.getItem('selectedLang') || 'Uzb')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [sideOpen, setSideOpen] = useState(false)

    const handleLanguage = (language) => {
        const { lang } = language
        setSelectLanguage(language.lang)
        localStorage.setItem('selectedLang', lang)
        setIsDropdownOpen(false)
    }

    const handleClose = () => {
        setSideOpen(false)
        setProp(sideOpen)
    }

    const scrollToSection = (id) => {
        const section = document.getElementById(id)
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' })
        } else {
            console.error(`scrollToSection: section with id ${id} not found`)
        }
    }

    useEffect(() => {
        const storedLang = localStorage.getItem('selectedLang')
        if (storedLang) {
            setSelectLanguage(storedLang)
        }
    }, [])

    return (
        <div className={cn(css(s.burgerWrapper), 'flex-col justify-between flex-1')}>
            <div className={cn(css(s.menuNav), 'flex-col items-start justify-center marginAuto')}>
                {navLink.map((item) => (
                    <p key={item.id} onClick={() => { scrollToSection(item.click); handleClose() }}
                        className={cn(css(s.menuTitle), 'text-white font-400 text-l no_underline')}>
                        <span>
                            {item.name}
                        </span>
                    </p>
                ))}
            </div>

            <div className={cn(css(s.dropdownWrapper), 'flex-col')}>
                <div>
                    {isDropdownOpen && (
                        <div className={cn(css(s.dropdown), 'marginAuto h-auto flex-col items-center justify-center')}>
                            {langData.map((item) => {
                                if (item.lang === selectLanguage) {
                                    return null
                                }
                                return (
                                    <div onClick={() => handleLanguage(item)} key={item.id}
                                        className="flex items-center justify-center gap-2">
                                        <span className={cn(css(s.langText), 'text-white pointer')}>
                                            {item.lang}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>

                <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className={cn(css(s.langSelector))}>
                    <span className={css(s.langText)}>
                        {selectLanguage}
                    </span>
                    {isDropdownOpen ? (<ButtonIcon />) : (<TopIcon />)}
                </div>
            </div>
        </div>
    )
}

export default memo(LandingDrover)

const s = StyleSheet.create({
    burgerWrapper: {
        height: '80%',
    },
    // menu css
    menuNav: {
        height: '40%',
        width: '90%',
        paddingLeft: '1.25rem',
        rowGap: '2.125rem',

    },
    menuTitle: {
        textTransform: 'uppercase',
    },
    // lang css
    langSelector: {
        transform: 'translateY(-2.625rem)',
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '0.625rem',
        paddingRight: '0.625rem',
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
        borderWidth: '1px',
        borderColor: '#fff',
        borderStyle: 'solid',
        borderRadius: '0.5rem',
        '@media (min-width: 48rem)': {
            width: '40%',
            marginLeft: '2.25rem',
        },
    },
    dropdown: {
        transform: 'translateY(-3rem)',
        width: '80%',
        gap: '0.625rem',
        paddingLeft: '0.625rem',
        paddingRight: '0.625rem',
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
        borderColor: '#fff',
        borderStyle: 'solid',
        borderRadius: '0.5rem',
        borderWidth: '1px',
        '@media (min-width: 48rem)': {
            width: '40%',
            marginLeft: '2.25rem',
        },
    },
    langText: {
        color: COLORS.white,
        fontSize: '1.125rem',
    },
    dropdownWrapper: {
        '@media (min-width: 48rem)': {
            display: 'none',
        },
    },
})
