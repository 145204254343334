import React from 'react'
import { TableCell } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import Update from '../features/Update'
import Delete from '../features/Delete'

function LoadingSkeleton() {
    return (
        <>
            <TableCell><Skeleton variant="text" animation="wave" /></TableCell>

            <TableCell><Skeleton variant="text" animation="wave" /></TableCell>

            <TableCell><Skeleton variant="text" animation="wave" /></TableCell>

            <TableCell><Skeleton variant="text" animation="wave" /></TableCell>

            <TableCell><Skeleton variant="text" animation="wave" /></TableCell>
        </>
    )
}

function MLModelProfileTableItem({ item, $api, loading }) {
    return !loading ? (
        <>
            <TableCell>{item.name}</TableCell>

            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">
                {item.description}
            </TableCell>

            <TableCell>{item.acceptedValues}</TableCell>

            <TableCell>{item.createdAt}</TableCell>

            <TableCell style={{ paddingRight: 8 }} align="right">
                <div className="h-full flex items-center justify-end">
                    <Update item={item} $api={$api} />

                    <Delete id={item.id} setData={$api.setResponse} />
                </div>
            </TableCell>
        </>
    ) : <LoadingSkeleton />
}

export default MLModelProfileTableItem
