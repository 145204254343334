import React from 'react'
import { TableCell } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Update from '../features/Update'
import Delete from '../features/Delete'

export default function EntityUsersTableItem({ item, setData, type }) {
    return (
        <React.Fragment>
            <TableCell>
                <Avatar alt={item.firstName} src={item.photo} />
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.firstName}
            </TableCell>

            <TableCell>
                {item.lastName}
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.email}
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.role ? item.role.name : 'N/A'}
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.phone}
            </TableCell>

            <TableCell style={{ paddingRight: 8 }} align="right">
                <div className="h-full flex items-center justify-end">
                    <Update type={type} item={item} setData={setData} />

                    <Delete id={item.id} type={type} setData={setData} />
                </div>
            </TableCell>
        </React.Fragment>
    )
}
