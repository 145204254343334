import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import { useLoad } from '../../../../shared/hooks/requests'
import { ROOMS_TENANT } from '../../../../shared/utils/urls'
import RoomsTableItem from './RoomsTableItem'
import RoomDevices from './RoomDevices'
import Table from '../../../../shared/ui/Table'
import Create from '../features/room-features/Create'

const cols = [
    { id: 1, title: '' },
    { id: 2, title: 'Room name' },
    { id: 3, title: 'Description' },
    { id: 4, title: 'Floor' },
    { id: 5, title: 'Created time' },
    { id: 6, title: 'Action' },
]

export default function RoomsTable() {
    const { id } = useParams()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [selected, setSelected] = useState({})
    const loadRooms = useLoad({ url: ROOMS_TENANT, params: { buildingId: id } }, [id])
    const data = loadRooms.response ? loadRooms.response.items : []
    const meta = loadRooms.response ? loadRooms.response : {}

    return (
        <div className="flex items-start gap-5">
            <Paper square className="flex-1">
                <div className="flex items-center justify-between h-[60px] px-4">
                    <Typography className="flex-cent gap-1_5 text-m font-600">
                        Buildings
                    </Typography>

                    <div className="flex-cent gap-2">
                        <Create setData={loadRooms.setResponse} />

                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>

                <Table
                    withCheckbox
                    onSelect={(item) => setSelected({ id: item.id, name: item.name })}
                    disableSelectAll
                    page={page - 1}
                    loading={loadRooms.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <RoomsTableItem
                            item={item}
                            key={item.id}
                            setData={loadRooms.setResponse} />
                    )}
                />
            </Paper>

            <RoomDevices selected={selected} />
        </div>
    )
}
