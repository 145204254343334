import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import MLModelForm from '../components/MLModelForm'
import { usePostRequest } from '../../../shared/hooks/requests'
import { ML_MODELS } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'

export default function Create({ profiles, $api }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createModel = usePostRequest({ url: ML_MODELS })

    const create = async (values) => {
        if (createModel.loading || !values) return

        const { categoricalFeatures, timeSeriesFeatures, version, ...rest } = values

        const { success, error } = await createModel.request({
            data: {
                ...rest,
                version: version.toString(),
                categoricalFeatures: categoricalFeatures.map((c) => ({
                    ...c,
                    acceptedValues: c.acceptedValues.join(';'),
                })),
                timeSeriesFeatures: timeSeriesFeatures.map((t) => ({
                    ...t,
                    acceptedValues: t.acceptedValues.join(';'),
                })),
            },
        })

        if (success) {
            $api.request()
            showMessage('Successfully created', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Ml model</h3>

                    <XIconSizeM onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <MLModelForm profiles={profiles} onSubmit={create} onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
