import React from 'react'
import PieCharts from '../../../components/common/pieChart'

export function PieCard({ sm }) {
    return (
        <div className={`w-[100%] ${sm
            ? 'pt-[6px] pb-[12px] border border-borderColor pl-[6px] h-[232px] gap-[15px] pr-[9px]'
            : 'pr-[19px] gap-[33px] pt-[14px] pb-[41px] h-[100%] pl-[14px]'}  
             flex flex-col items-start   bg-white rounded-[14px]`}>
            <span className={`${sm ? 'text-[12px]' : 'text-[16px]'} font-normal`}>Mijoz vaxt sarfi</span>
            <div>
                <PieCharts sm={sm} />
            </div>
        </div>
    )
}
