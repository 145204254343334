import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { IconButton, CircularProgress } from '@mui/material'
import { Close, Delete, Done, Edit } from '@mui/icons-material'
import { COLORS } from '../../shared/utils/colors'
import { DrawerHeader } from './Sidebar'

export default function DrawerLayout({
    name,
    topic,
    isEdit,
    updateLoading,
    deleteLoading,
    showActionBtns,
    onDeleteIconClick = () => {},
    onClose,
    children,
}) {
    return (
        <React.Fragment>
            <DrawerHeader />
            <div className={cn(css(s.cont))}>
                <div className={cn(css(s.sHead), 'flex items-start justify-between relative')}>
                    <div className="flex-col gap-5">
                        <div className="flex-col gap-1">
                            <h3 className={css(s.topic)}>Name:</h3>
                            <h3 className={css(s.name)}>{name}</h3>
                        </div>
                        <h3 className={css(s.topic)}>{topic}</h3>
                    </div>

                    <IconButton onClick={onClose}>
                        <Close htmlColor="white" />
                    </IconButton>

                    {showActionBtns ? (
                        <div className={cn(css(s.icons), 'flex items-center gap-4_5 absolute')}>
                            <IconButton
                                form="tenantForm"
                                type="submit"
                                sx={{
                                    backgroundColor: isEdit ? COLORS.green : COLORS.orange,
                                    ':hover': { backgroundColor: isEdit ? COLORS.green : COLORS.orange },
                                }}>
                                {isEdit ? (
                                    updateLoading ? (
                                        <CircularProgress size={24} sx={{ color: 'white' }} />
                                    ) : (
                                        <Done htmlColor="white" />
                                    )
                                ) : (
                                    <Edit htmlColor="white" />
                                )}
                            </IconButton>

                            <IconButton
                                onClick={onDeleteIconClick}
                                sx={{
                                    backgroundColor: COLORS.orange,
                                    ':hover': { backgroundColor: COLORS.orange },
                                }}>
                                {!isEdit ? (
                                    deleteLoading ? (
                                        <CircularProgress size={24} sx={{ color: 'white' }} />
                                    ) : (
                                        <Delete htmlColor="white" />
                                    )
                                ) : (
                                    <Close htmlColor="white" />
                                )}
                            </IconButton>
                        </div>
                    ) : null}
                </div>

                {children}
            </div>
        </React.Fragment>
    )
}

const s = StyleSheet.create({
    cont: {
        overflow: 'hidden',
        width: '100%',
        height: 'calc(100% - 64px)',
        boxSizing: 'border-box',
    },
    contentWrap: {
        height: 'auto',
    },
    sHead: {
        backgroundColor: COLORS.mainBlueBg,
        padding: '35px 20px',
    },
    icons: {
        bottom: -20,
        right: 20,
        zIndex: 1,
        ':nth-child(1n) > button': {
            borderRadius: 64,
            height: 40,
            width: 40,
            // eslint-disable-next-line max-len
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.00), 0px 0px 9px 0px rgba(0, 0, 0, 0.01), 0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 0px 3px 0px rgba(0, 0, 0, 0.10)',
        },
    },
    topic: {
        fontWeight: '500',
        fontSize: 16,
        color: COLORS.disabled,
    },
    name: {
        fontWeight: '600',
        fontSize: 20,
        color: COLORS.white,
    },
})
