import React from 'react'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../shared/utils/colors'
import FormInput from '../../components/common/FormInput'
import { password, required } from '../../shared/utils/validators'
import Button from '../../components/common/Button'
import { usePostRequest } from '../../shared/hooks/requests'
import { VERIFY_ADMIN } from '../../shared/utils/urls'
import { useQueryParams } from '../../shared/hooks/queryString'
import { useMessage } from '../../shared/hooks/message'
import useHandleErrors from '../../shared/hooks/handleErrorMessage'

export default function NewPassword() {
    const { code } = useQueryParams()
    const { handleErrorMsg } = useHandleErrors()
    const showMessage = useMessage()
    const navigate = useNavigate()
    const verify = usePostRequest({ url: VERIFY_ADMIN })

    const onSubmit = async (data, helpers) => {
        if (verify.loading) return
        // eslint-disable-next-line no-shadow
        const { password, rePassword } = data
        const { setFieldError } = helpers
        if (verify.loading) return

        if (password !== rePassword) {
            setFieldError('rePassword', 'Passwords do not match')
            return
        }

        const { success, error } = await verify.request({
            params: { code, newPassword: password },
        })

        if (success) {
            navigate('/auth')
            showMessage('Password created successfully', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <div className={cn(css(s.wrap), 'w-full h-full flex-cent')}>
            <div className={cn(css(s.cont), 'flex-col item-center')}>
                <h3 className="text-center">Create a new password</h3>

                <Formik
                    initialValues={{ password: '', rePassword: '' }}
                    // eslint-disable-next-line react/jsx-no-bind
                    onSubmit={onSubmit}
                >
                    {({ errors, handleSubmit }) => (
                        <Form className={cn(css(s.form), 'flex-col')}>
                            <React.Fragment>
                                <FormInput
                                    name="password"
                                    validate={password}
                                    label="New password"
                                    fieldClassName="relative"
                                    errorWithMessage
                                />

                                <FormInput
                                    name="rePassword"
                                    validate={required}
                                    label="Confirm new password"
                                    fieldClassName="relative"
                                    errorWithMessage
                                />

                                <div className={cn(css(s.btnCont), 'flex-col gap-5 items-end')}>
                                    <Button
                                        onClick={handleSubmit}
                                        type="submit"
                                        className={cn(
                                            css(s.btn, isEmpty(errors) && s.enabled),
                                            'w-full rounded-m text-m font-600 text-white',
                                        )}
                                    >
                                        Create Password
                                    </Button>
                                </div>
                            </React.Fragment>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        backgroundColor: COLORS.sepia,
    },
    cont: {
        width: 450,
        padding: 30,
        backgroundColor: COLORS.white,
        ':nth-child(1n) > :first-child': {
            fontSize: 20,
            fontWeight: '600',
        },
    },
    form: {
        marginTop: 40,
        gap: 32,
    },
    inp: {
        borderColor: 'transparent',
        borderRadius: '2px 2px 0px 0px',
        borderBottom: '1px solid #8D8E90',
        backgroundColor: COLORS.defaultInpBg,
        padding: '0 10px',
        color: COLORS.lightBlack,
        '::placeholder': {
            fontSize: 16,
            fontWeight: '400',
            color: COLORS.placeholder,
        },
        ':focus': {
            backgroundColor: COLORS.lightGray,
        },
    },
    btnCont: {
        marginTop: 50,
        ':nth-child(1n) > h3': {
            color: COLORS.gray,
        },
    },
    btn: {
        padding: '14px 0',
        backgroundColor: COLORS.disabled,
    },
    enabled: {
        backgroundColor: COLORS.mainBlueBg,
    },
})
