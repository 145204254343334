import React from 'react'
import { Chip, TableCell } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { COLORS } from '../../../shared/utils/colors'
import Update from '../features/Update'
import Delete from '../features/Delete'

export default function TenantAdminsTableItem({ item, setData }) {
    return (
        <>
            <TableCell>
                <Avatar alt={item.firstName} src={item.photo} />
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.firstName}
            </TableCell>

            <TableCell>
                {item.lastName}
            </TableCell>

            <TableCell className="cursor-pointer">
                <Chip
                    color={item.isActive ? 'success' : 'error'}
                    variant="outlined"
                    sx={{
                        backgroundColor: item.isActive ? '#37B4001F' : COLORS.lightRed,
                        border: `1px solid ${item.isActive ? '#2B8906' : COLORS.red}`,
                        color: item.isActive ? '#2B8906' : COLORS.white,
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '4px 16px',
                        '& .MuiChip-label': { padding: 0 },
                    }}
                    label={item.isActive ? 'Active' : 'Inactive'} />
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.role}
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.phone}
            </TableCell>

            <TableCell style={{ paddingRight: 8 }} align="right">
                <div className="h-full flex items-center justify-end">
                    <Update item={item} setData={setData} />

                    <Delete id={item.id} setData={setData} />
                </div>
            </TableCell>
        </>
    )
}
