import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, Popover, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { COLORS } from '../../../shared/utils/colors'
import Update from '../features/Update'
import Delete from '../features/Delete'
import { JETSON_DEVICE_SNAPSHOTS } from '../../../shared/utils/urls'
import { usePostRequest } from '../../../shared/hooks/requests'
import { PhotoCameraIcon } from '../../../components/svgs/Svgs'

const JETSON_DOMAIN = process.env.REACT_APP_JETSON_API_URL

export default function CardItem({ item, setData }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const getSnapshots = usePostRequest({ otherDomain: JETSON_DOMAIN, url: JETSON_DEVICE_SNAPSHOTS }, [])
    const snapshots = getSnapshots.response ? getSnapshots.response : []

    useEffect(() => {
        getSnapshots.request({ data: { jetsonDeviceId: item.deviceId } })
    }, [])

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const openDrawerHandler = () => {
        setOpenDrawer(true)
        handleClose()
    }

    const closeDrawerHandler = () => setOpenDrawer(false)

    const openDeleteDialogHandler = () => {
        setOpenDeleteDialog(true)
        handleClose()
    }

    const closeDeleteDialogHandler = () => setOpenDeleteDialog(false)

    const isPopoverOpen = Boolean(anchorEl)
    const id = isPopoverOpen ? 'simple-popover' : undefined

    return (
        <Grid boxSizing="border-box" item xs={12} sm={6} md={6} lg={3.5}>
            <div className="bg-white grid grid-cols-2 gap-[2px]
                rounded flex-col border border-sepia-100 overflow-hidden">
                <div className="flex flex-wrap gap-[2px] min-h-56">
                    {!getSnapshots.loading
                        ? snapshots.map((url) => (
                            <div key={url.cameraIp} className="flex-1 w-full bg-gray-100">
                                <img src={`data:image/png;base64,${url.cameraSnapshot}`} alt="SNAPSHOT" />
                            </div>
                        )) : (
                            <div className="flex w-full flex-1 items-center justify-center">
                                <CircularProgress />
                            </div>
                        )}

                    {!snapshots.length && !getSnapshots.loading ? (
                        <div className="flex w-full flex-1 items-center justify-center bg-gray-100">
                            <PhotoCameraIcon />
                        </div>
                    ) : null}
                </div>

                <div className="bg-cardInnerbg px-3 py-2">
                    <div className="flex items-center justify-between">
                        <Typography>{item.deviceName}</Typography>

                        <IconButton onClick={handleClick} aria-describedby={id}>
                            <MoreVertIcon />
                        </IconButton>
                    </div>
                </div>
            </div>

            <Popover
                id={id}
                sx={{ '& .MuiPaper-root': { border: `1px solid ${COLORS.sepia}`,
                    padding: '8px' } }}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ul className="bg-white flex flex-col gap-1">
                    <li onClick={openDrawerHandler}
                        className="list-none flex items-center rounded-lg gap-2
                        cursor-pointer p-1.5 hover:bg-cardInnerbg">
                        <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />

                        <Typography className="text-md text-black font-medium">Edit device</Typography>
                    </li>

                    <li onClick={openDeleteDialogHandler}
                        className="list-none flex items-center rounded-lg gap-2
                        cursor-pointer p-1.5 hover:bg-cardInnerbg">
                        <DeleteIcon
                            htmlColor={COLORS.red}
                            className="cursor-pointer"
                            fontSize="small" />

                        <Typography className="text-md text-red font-medium">Delete device</Typography>
                    </li>
                </ul>
            </Popover>

            <Update
                item={item}
                setData={setData}
                isOpen={openDrawer}
                close={closeDrawerHandler} />

            <Delete
                setData={setData}
                isOpen={openDeleteDialog}
                close={closeDeleteDialogHandler}
                id={item.id} />
        </Grid>
    )
}
