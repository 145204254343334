// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'zustand'

const useTenantProfileStore = create((set) => ({
    profileResponse: {},
    setResponse: (response) => set(() => ({ profileResponse: response })),
    entityFilters: { parent: { hierarchyLevel: 0 } }, // Initialize with default values
    setEntityFilters: (filters) => set(() => ({ entityFilters: filters })),
}))

export default useTenantProfileStore
