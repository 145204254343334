import React, { useState, useEffect, memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { cardData } from '../../../shared/utils/landingData'
import UsersCard from './LandingUserCard'
import { COLORS } from '../../../shared/utils/colors'

function TeamCard() {
    const [users, setUsers] = useState([])
    const [visibleUsers, setVisibleUsers] = useState([])
    const [loadMore, setLoadMore] = useState(false)
    useEffect(() => {
        if (window.innerWidth <= 768) {
            setVisibleUsers(cardData)
        } else {
            setUsers(cardData)
            setVisibleUsers(cardData.slice(0, 6))
        }
    }, [window.innerWidth])
    const handleMore = () => {
        setVisibleUsers(users)
        setLoadMore(true)
    }
    const handleLess = () => {
        setVisibleUsers(cardData.slice(0, 6))
        setLoadMore(false)
    }

    return (
        <div className={cn(css(s.wrapper), 'marginAuto bgBlack')}>
            <div className={cn(css(s.team), 'marginAuto items-start')}>
                <p className={cn(css(s.teamTitle), 'text-white font-700')}>
                    Our Team: Leaders in Artificial Intelligence Development.
                </p>
                <div className={css(s.cardContainer)}>
                    {visibleUsers.map((item) => (
                        <UsersCard key={item.id} {...item} />
                    ))}
                </div>
                {!loadMore ? (
                    <div className={css(s.loadDiv)}>
                        <button onClick={handleMore} className={css(s.loadMore)}>
                            Load More
                        </button>
                    </div>
                )
                    : (
                        <div className={css(s.loadDiv)}>
                            <button onClick={handleLess} className={css(s.loadMore)}>
                                Load Less
                            </button>
                        </div>
                    )}
                <div className={css(s.line)} />
            </div>
        </div>
    )
}

export default memo(TeamCard)

const s = StyleSheet.create({
    wrapper: {
        paddingTop: '2.813rem',
        paddingBottom: '0.625rem',
        maxWidth: '120rem',
    },
    team: {
        width: '80%',
    },
    teamTitle: {
        fontSize: '1.188rem',
        textAlign: 'left',
        marginBottom: '3.625rem',
        '@media (min-width: 48rem)': {
            fontSize: '1.5rem',
            width: '70%',
        },
        '@media (min-width: 62rem)': {
            fontSize: '2.5rem',
            width: '60%',
        },
    },
    line: {
        maxWidth: '100%',
        height: '1px',
        margin: '0 auto',
        backgroundColor: '#F3F5F8',
        '@media (min-width: 48rem)': {
            maxWidth: '100%',
        },
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        gap: '32px',
        marginBottom: '4.5rem',
        '@media (min-width: 48rem)': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            rowGap: '3.625rem',
            overflow: 'unset',
        },
        '@media (min-width: 62rem)': {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        '::-webkit-scrollbar': {
            width: '0',
            height: '0',
        },
    },
    loadDiv: {
        display: 'none',
        '@media (min-width: 48rem)': {
            display: 'block',
            width: '10%',
            margin: '0 auto',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    loadMore: {
        marginBottom: '10px',
        width: '100px',
        height: '40px',
        borderRadius: '8px',
        background: 'transparent',
        outline: 'none',
        color: COLORS.white,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: COLORS.white,
    },
})
