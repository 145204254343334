import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import { PROFILE_FIRMWARE, SMART_CAMERA_PROFILE } from '../../../shared/utils/urls'
import SmartCameraProfileForm from '../components/smartCameraProfileForm'
import { useGetRequest, usePostRequest } from '../../../shared/hooks/requests'

export default function Create({ setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createProfile = usePostRequest({ url: SMART_CAMERA_PROFILE })
    const assignFirmware = useGetRequest()

    const create = async (values) => {
        if (createProfile.loading || !values) return

        const { name, description, firmware } = values

        // First request to create the camera profile with name and description
        const { response, success, error } = await createProfile.request({
            data: { name, description, isActive: true },
        })

        if (success) {
            const cameraProfileId = response.id

            // Second request to fetch firmware data using both profile id and firmware id
            const firmwareResponse = await assignFirmware.request({
                url: PROFILE_FIRMWARE,
                params: {
                    profileId: cameraProfileId,
                    firmwareId: firmware,
                },
            })

            if (firmwareResponse.success) {
                setData((prev) => [...prev, response])
                showMessage('Successfully created', 'success-msg')
                setOpen(false)
            } else {
                handleErrorMsg('Failed to assign firmware')
            }
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>Add smart camera profile</Typography>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <SmartCameraProfileForm
                        loading={createProfile.loading}
                        onSubmit={create}
                        onClose={() => setOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}
