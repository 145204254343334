import { useEffect } from 'react'
import moment from 'moment'
import { useGetRequest } from '../../../shared/hooks/requests'
import { AGE_GENDER_LIST, DAILY_GENDER_LIST, HOURLY_VISITS_LIST, VISITS_LIST } from '../../../shared/utils/urls'

export const GetBranchData = ({ filter, id }) => {
    const getDaily = useGetRequest({ url: VISITS_LIST })
    const getWeekly = useGetRequest({ url: VISITS_LIST })
    const getMonthly = useGetRequest({ url: VISITS_LIST })
    const getMonthlyBreak = useGetRequest({ url: DAILY_GENDER_LIST })
    const getAges = useGetRequest({ url: AGE_GENDER_LIST })
    const getHours = useGetRequest({ url: HOURLY_VISITS_LIST })
    // conndition of data caming from useGetRequest
    const hourlyData = getHours.response ? getHours.response : []
    const agely = getAges.response ? getAges.response : []
    const monthlyBreak = getMonthlyBreak.response ? getMonthlyBreak.response : []
    const daily = getDaily.response ? getDaily.response : null
    const weekly = getWeekly.response ? getWeekly.response : null
    const monthly = getMonthly.response ? getMonthly.response : null

    const fetchData = async () => {
        const addEntityId = filter !== 'all' ? { tenant_entity_id: filter } : id ? { tenant_entity_id: id } : {}

        const commonParams = {
            start_date: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
            end_date: moment(new Date()).format('YYYY-MM-DD'),
        }

        await Promise.all([
            getDaily.request({ params: { ...commonParams, ...addEntityId } }),
            getWeekly.request({ params:
                    { start_date: moment(new Date()).subtract(1, 'weeks').format('YYYY-MM-DD'),
                        end_date: moment(new Date()).format('YYYY-MM-DD'),
                        ...addEntityId } }),
            getMonthly.request({ params:
                    { start_date: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
                        end_date: moment(new Date()).format('YYYY-MM-DD'),
                        ...addEntityId } }),
            getMonthlyBreak.request({ params:
                    { start_date: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
                        end_date: moment(new Date()).format('YYYY-MM-DD'),
                        ...addEntityId } }),
            getAges.request({ params:
                    { date: moment(new Date()).format('YYYY-MM-DD'), ...addEntityId } }),
            getHours.request({ params:
                    { date: moment(new Date()).format('YYYY-MM-DD'), ...addEntityId } }),
        ])
    }

    useEffect(() => {
        fetchData()
    }, [filter, id])

    return { hourlyData, monthly, daily, weekly, agely, monthlyBreak }
}
