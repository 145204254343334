/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'

import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import FormTextField from '../../../shared/ui/FormTextField'
import SelectInput from '../../../shared/ui/SelectInput'
import { useLoad } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { ROOMS_TENANT_UNPAGINATED } from '../../../shared/utils/urls'

export default function JetsonCreateForm() {
    const { handleChange, setFieldValue } = useFormikContext()
    // const [copied, setCopied] = useState(false)
    const { cameraFilter } = useFilter()
    const loadRooms = useLoad({
        url: ROOMS_TENANT_UNPAGINATED,
        params: { buildingId: cameraFilter.bId },
    })
    const roomOptions = loadRooms.response && loadRooms.response.length
        ? loadRooms.response.map((r) => ({ value: r.id, label: r.name })) : []

    return (
        <Grid container sx={{ boxSizing: 'border-box' }} spacing={2}>
            <Grid item width="50%">
                <FormTextField
                    fullWidth
                    required
                    name="username"
                    label="Username"
                    handleChange={handleChange} />
            </Grid>

            <Grid item width="50%">
                <FormTextField
                    fullWidth
                    required
                    name="password"
                    label="Password"
                    handleChange={handleChange} />
            </Grid>

            <Grid item width="50%">
                <FormTextField
                    fullWidth
                    name="deviceName"
                    label="Device name"
                    handleChange={handleChange} />
            </Grid>

            <Grid item width="50%">
                <FormTextField
                    fullWidth
                    name="deviceId"
                    label="Device id"
                    handleChange={handleChange} />
            </Grid>

            <Grid item width="50%">
                <FormTextField
                    fullWidth
                    name="deviceIpVpn"
                    label="device IP Vpn"
                    handleChange={handleChange} />
            </Grid>

            <Grid item width="50%">
                <FormTextField
                    fullWidth
                    name="deviceStreamUrl"
                    label="Device stream url"
                    handleChange={handleChange} />
            </Grid>

            <Grid paddingTop={2} item width="50%">
                <SelectInput
                    required
                    fullWidth
                    name="roomId"
                    label="Room"
                    menuItems={roomOptions}
                    onChange={(e) => setFieldValue('roomId', e.target.value)} />
            </Grid>
        </Grid>

    // <div className="rounded overflow-hidden">
    //     <div className="flex items-center justify-between bg-[#2F2F2F] py-1 px-3">
    //         <h3 className="text-xs font-medium text-[#CDCDCD]">Code</h3>

    //         <IconButton
    //             onClick={() => copyToClipboard(values.setupFile, () => {}, setCopied)}
    //             className="flex items-center gap-1">
    //             {copied ? <DoneAllIcon fontSize="small" htmlColor="#CDCDCD" /> : <CopyCodeIcon />}

    //             <h3 className="text-xs font-medium text-[#CDCDCD]">
    //                 {copied ? 'Copied' : 'Copy code'}
    //             </h3>
    //         </IconButton>
    //     </div>

    //     <SyntaxHighlighter
    //         customStyle={{ overflowY: 'scroll', maxHeight: '60vh' }}
    //         language="bash"
    //         style={a11yDark}>
    //         {values.setupFile}
    //     </SyntaxHighlighter>
    // </div>

    )
}
