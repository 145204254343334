import React, { useState } from 'react'
import { IconButton, Popover, TableCell } from '@mui/material'
import moment from 'moment/moment'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { COLORS } from '../../../../shared/utils/colors'
import Delete from '../features/room-features/Delete'
import Update from '../features/room-features/Update'

export default function RoomsTableItem({ item, setData }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const isPopoverOpen = Boolean(anchorEl)
    const id = isPopoverOpen ? 'simple-popover' : undefined

    const openDrawerHandler = () => {
        setOpenDrawer(true)
        handleClose()
    }

    const closeDrawerHandler = () => setOpenDrawer(false)

    const openDeleteDialogHandler = () => {
        setOpenDeleteDialog(true)
        handleClose()
    }

    const closeDeleteDialogHandler = () => setOpenDeleteDialog(false)

    return (
        <>
            <TableCell className="cursor-pointer">
                {item.name}
            </TableCell>

            <TableCell
                className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.description}
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.floor}
            </TableCell>

            <TableCell>
                {item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }
            </TableCell>

            <TableCell style={{ paddingRight: 0 }} align="right">
                <IconButton onClick={handleClick} aria-describedby={id}>
                    <MoreVertIcon />
                </IconButton>
            </TableCell>

            <Popover
                id={id}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <IconButton onClick={openDrawerHandler}>
                    <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
                </IconButton>

                <IconButton onClick={openDeleteDialogHandler}>
                    <DeleteIcon
                        htmlColor={COLORS.disabled}
                        className="cursor-pointer"
                        fontSize="medium" />
                </IconButton>
            </Popover>

            <Update
                item={item}
                setData={setData}
                isOpen={openDrawer}
                close={closeDrawerHandler} />

            <Delete
                id={item.id}
                setData={setData}
                isOpen={openDeleteDialog}
                close={closeDeleteDialogHandler} />
        </>
    )
}
