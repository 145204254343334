import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { number, object, string } from 'yup'
import axios from 'axios'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import { domain } from '../../../shared/utils/request'
import { SETTIMERESTART } from '../../../shared/utils/urls'
import { auth } from '../../../shared/utils/auth'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import Counter from './Counter'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import SelectInput from '../../../shared/ui/SelectInput'

const modes = [
    {
        label: 'Everyday',
        value: 1,
    },
    {
        label: 'Never Restart',
        value: 0,
    },
    {
        label: 'Every Week',
        value: 2,
    },
]

const days = [
    {
        label: 'Monday',
        value: 1,
    },
    {
        label: 'Tuesday',
        value: 2,
    },
    {
        label: 'Wednesday',
        value: 3,
    },
    {
        label: 'Thursday',
        value: 4,
    },
    {
        label: 'Friday',
        value: 5,
    },
    {
        label: 'Saturday',
        value: 6,
    },
    {
        label: 'Sunday',
        value: 0,
    },
]

export default function TimeRestart({ initialValues, id }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [loading, setLoading] = useState(false)

    const FORM_INITIAL_STATE = {
        Mode: '',
        DayWeek: '',
        Hour: 1,
        Minute: 1,
    }

    const VALIDATION_SCHEMA = object().shape({
        Mode: string().required('Restart Mode is required'),
        DayWeek: string().required('Day is required'),
        Hour: number().required('Hours are required').min(0, 'Hours cannot be less than 0'),
        Minute: number().required('Minutes are required').min(0, 'Minutes cannot be less than 0'),
    })

    const update = async (values) => {
        console.log(values)

        if (loading) return

        setLoading(true)

        const filteredValues = {
            day_week: values.DayWeek,
            hour: values.Hour,
            minute: values.Minute,
            mode: values.Mode,
        }

        try {
            const { data } = await axios.post(
                domain + SETTIMERESTART,
                filteredValues,
                { ...auth(), params: { smart_camera_id: id } },
            )
            if (data.success) {
                showMessage(data.message, 'success-msg')
            }
        } catch (error) {
            handleErrorMsg(error.response)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={update}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <CustomAccordion
                        summary="Time Restart"
                        contClassName="border-[1px] border-sepia-100"
                        expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
                        overrideStyles={{
                            ':before': {
                                display: 'none',
                            },
                            boxShadow: 'none',
                            borderRadius: '3px',
                        }}>
                        <div className="flex flex-col gap-[31px]">
                            <div className="flex gap-[31px] items-end">
                                <div className="w-[50%] flex items-end gap-[20px]">
                                    <span className="text-[14px] font-normal text-black">Restart Mode</span>
                                    <div className="w-[20vh]">
                                        <SelectInput
                                            required
                                            fullWidth
                                            name="Mode"
                                            label="Mode"
                                            menuItems={modes}
                                            onChange={(e) => {
                                                setFieldValue('Mode', e.target.value)
                                            }} />
                                    </div>
                                </div>
                                <div className="flex items-end gap-[20px]">
                                    <span className="text-[14px] font-normal text-black">Day</span>
                                    <div className="w-[20vh]">
                                        <SelectInput
                                            required
                                            fullWidth
                                            name="DayWeek"
                                            label="Day"
                                            menuItems={days}
                                            onChange={(e) => {
                                                setFieldValue('DayWeek', e.target.value)
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-start gap-[21px]">
                                <span className="text-[14px] font-normal text-black">Restart time</span>
                                <div className="flex flex-col items-center gap-[5px]">
                                    <Counter
                                        count={values.Hour}
                                        onIncrement={() => setFieldValue('Hour', values.Hour + 1)}
                                        onDecrement={() => setFieldValue('Hour', Math.max(0, values.Hour - 1))}
                                    />
                                    <span>H</span>
                                </div>
                                <div className="flex flex-col items-center gap-[5px]">
                                    <Counter
                                        count={values.Minute}
                                        onIncrement={() => setFieldValue('Minute', values.Minute + 1)}
                                        onDecrement={() => setFieldValue('Minute', Math.max(0, values.Minute - 1))}
                                    />
                                    <span>M</span>
                                </div>
                            </div>
                            <div className="w-[100%] flex justify-end">
                                <PrimaryBtn
                                    type="submit"
                                    title="Save"
                                    loading={loading}
                                    disabled={loading}
                                    overrideStyles={{
                                        width: '100%',
                                        paddingY: '12px',
                                        fontSize: '14px',
                                    }}
                                />
                            </div>
                        </div>
                    </CustomAccordion>
                </Form>
            )}
        </Formik>
    )
}
