import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { COLORS } from '../utils/colors'

export default function PrimaryBtn({ title, onClick, type, loading, Icon, styles, ...otherProps }) {
    return (
        <Button
            onClick={onClick}
            type={type || 'button'}
            variant="contained"
            sx={{
                backgroundColor: COLORS.mainBlueBg,
                borderRadius: 2,
                boxShadow: 'none',
                fontSize: 14,
                fontWeight: '400',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: COLORS.white,
                ':hover': {
                    backgroundColor: COLORS.darkBlue,
                },
                ...styles,
            }}
            {...otherProps}
        >
            {Icon && <Icon />}

            {title}

            {loading ? (
                <CircularProgress size={18} sx={{ color: COLORS.white }} />
            ) : null}
        </Button>
    )
}
