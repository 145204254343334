import React from 'react'
import PrimaryBtn from '../../../../shared/ui/PrimaryBtn'
import useHandleErrors from '../../../../shared/hooks/handleErrorMessage'
import { usePostRequest } from '../../../../shared/hooks/requests'
import { USER_ROLE_CREATE } from '../../../../shared/utils/urls'
import { useMessage } from '../../../../shared/hooks/message'
import useTenantProfileStore from '../../../../shared/store/tenantProfileStore'

export default function CreateRole({ roleIndex, values }) {
    const showMessage = useMessage()
    const { setResponse, profileResponse } = useTenantProfileStore()
    const { handleErrorMsg } = useHandleErrors()
    const createRole = usePostRequest({ url: USER_ROLE_CREATE })

    const submitRole = async () => {
        if (!values.roles[roleIndex] || createRole.loading) return

        const r = values.roles[roleIndex]

        const newRole = {
            name: r.name,
            description: r.description,
            roleId: r.id,
            tenantProfileId: values.id || values.tenantProfileId,
            permissions: r.permissions,
            modules: values.modules,
        }

        const { response, success, error } = await createRole.request({ data: newRole })

        if (success) {
            setResponse({
                ...profileResponse,
                items: profileResponse.items.map((val) => (
                    val.id === response.tenantProfileId
                        ? {
                            ...val,
                            roles: [...(val.roles || []), response],
                        }
                        : val
                )),
            })
            showMessage('Role created', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }
    return (
        <PrimaryBtn
            loading={createRole.loading}
            title="Create"
            type="button"
            onClick={submitRole} />
    )
}
