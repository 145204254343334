import React, { useEffect, useState } from 'react'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import GeneralConfigForm from './GeneralConfigForm'
import StreamConf from './StreamConf'
import NvdsAnalytics from './NvdsAnalytics'
import { useLoad, usePostRequest } from '../../../shared/hooks/requests'
import { DEEPSTREAM_CONFIGS, JETSON_ATTACHED_CAMERAS } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

const navItems = [
    { id: 'nvds', name: 'NVDS analytics' },
    { id: 'stream', name: 'Stream configuration' },
    { id: 'general', name: 'General configuration' },
]

export default function DeepstreamDetails({ item, close }) {
    const [section, setSection] = useState('general')
    const { jetsonFilter: { jetsonDeviceId } } = useFilter()
    const getConfigs = usePostRequest({ url: DEEPSTREAM_CONFIGS })
    const configs = getConfigs.response ? getConfigs.response : {}
    const loadAttachedCameras = useLoad({ url: JETSON_ATTACHED_CAMERAS.replace('{id}', jetsonDeviceId) }, [])
    const attachedCameras = loadAttachedCameras.response ? loadAttachedCameras.response : []

    useEffect(() => {
        if (item.name && item.version && jetsonDeviceId) {
            getConfigs.request({
                data: {
                    applicationName: item.name,
                    applicationVersion: item.version,
                    jetsonDeviceId,
                },
            })
        }
    }, [])

    return (

        <DrawerLayout
            topic="Deepstream application details"
            onClose={close}
            name={item.name}
            icons={[]}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.id)}
                                className={
                                    `cursor-pointer list-none text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.id ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                {section === 'general' ? (
                    <GeneralConfigForm
                        getConfigs={getConfigs}
                        configs={configs}
                        appName={item.name}
                        version={item.version} />
                ) : section === 'nvds' ? (
                    <NvdsAnalytics
                        appName={item.name}
                        version={item.version}
                        attachedCameras={attachedCameras} />
                ) : (
                    <StreamConf
                        cameras={attachedCameras}
                        appName={item.name}
                        version={item.version}
                        // sources={configs.appGeneralConfig.sources}
                    />
                )}
            </React.Fragment>
        </DrawerLayout>
    )
}
