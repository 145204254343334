import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LazyLoadImage } from 'react-lazy-load-image-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-lazy-load-image-component/src/effects/blur.css'

function UsersCard({ personName, personImg, personPosition }) {
    return (
        <div className={css(s.cardBox)}>
            <div className={css(s.imgContainer)}>
                <LazyLoadImage effect="blur" width="100%" height="100%" src={personImg}
                    alt="person1Img" className={cn(css(s.cardImg), 'w-full h-full')} />
            </div>
            <div className={css(s.personinfo)}>
                <p className={cn(css(s.infoTitle), 'text-white font-600')}>{personName}</p>
                <p className={cn(css(s.infoText), 'text-white font-400')}>{personPosition}</p>
            </div>
        </div>
    )
}

export default UsersCard

const s = StyleSheet.create({
    cardBox: {
        display: 'flex',
        flex: '0 0 auto',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '15.25rem',
        marginLeft: '3rem',
        height: 'auto',
        '@media (min-width: 48rem)': {
            width: 'auto',
            marginLeft: '0',

        },
    },
    imgContainer: {
        height: '22.23rem',
    },
    personinfo: {
        paddingBottom: '1.125rem',
        paddingTop: '0.967rem',
        display: 'flex',
        rowGap: '0.721rem',
        flexDirection: 'column',
        alignItems: 'start',
    },
    infoTitle: {
        fontSize: '1.313rem',
    },
    infoText: {
        fontSize: '1rem',

    },
    cardImg: {
        objectFit: 'cover',
    },
})
