import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'

export default function Breadcrumbs() {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const pathnames = pathname.split('/').filter((x) => x && !/^\d+$/.test(x))

    return (
        <MuiBreadcrumbs sx={{ color: 'white' }} aria-label="breadcrumb">
            {pathnames.map((path, idx) => {
                const routeTo = `/${pathnames.slice(0, idx + 1).join('/')}`
                const name = `${path.charAt(0).toUpperCase()}${path.slice(1, path.length)}`

                return idx !== pathnames.length - 1 ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                        key={path}
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        color="white"
                        onClick={() => navigate(routeTo)}
                    >
                        {name.replace('-', ' ')}
                    </Link>
                ) : <Typography key={path} color="white">{name.replace('-', ' ')}</Typography>
            })}
        </MuiBreadcrumbs>
    )
}
