import React from 'react'
import { useSnackbar } from 'notistack'
import uuid from 'react-uuid'
import Message from '../../components/common/Message'

export function useMessage() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    return (text, className) => {
        const id = uuid()
        enqueueSnackbar(
            <Message
                text={text}
                className={className}
                closeMessage={() => closeSnackbar(id)}
                delay={10}
            />,
            {
                key: id,
                className,
                autoHideDuration: 10 * 1000,
            },
        )
    }
}
