import * as React from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { Box } from '@mui/material'

export default function StackedBarChart({ items, sm }) {
    return (
        <Box sx={{ width: '100%' }}>
            <BarChart
                height={sm ? 200 : 300}
                grid={{ vertical: true, horizontal: true }}
                series={[
                    { data: items.map((item) => item.counts.male), stack: 'total' },
                    { data: items.map((item) => item.counts.female), stack: 'total' },
                    { data: items.map((item) => item.counts.undefined), stack: 'total' },
                ]}
                xAxis={[{ data: items.map((item) => item.date), scaleType: 'band' }]}
            />
        </Box>
    )
}
