import React from 'react'
import { Drawer } from '@mui/material'
import TenantDetails from '../components/TenantDetails'

export default function Update({ isOpen, close, item, setData }) {
    return (
        <Drawer
            sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
            anchor="right"
            open={isOpen}
            onClose={close}>
            <TenantDetails
                setData={setData}
                onClose={close}
                item={item} />
        </Drawer>

    )
}
