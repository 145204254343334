import React from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts'
import { Decrease, Increase } from '../../../components/svgs/Svgs'

// Generate a random color
const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'white', border: '1px solid #ddd', padding: '10px' }}>
                {payload.map((entry) => {
                    const entryName = entry.name.length > 40 ? `${entry.name.slice(0, 35)}...` : entry.name
                    const percentage = entry.payload[`${entry.name}Percentage`]

                    return (
                        <div key={entry.name} style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                style={{
                                    backgroundColor: entry.color, // Use line color
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    marginRight: 10,
                                }}
                            />
                            {/* Show full name on hover */}
                            <strong>
                                <span title={entry.name}>{entryName}</span>:
                            </strong>
                            {entry.value}
                            {percentage !== undefined && (
                                <div className="flex items-center gap-[5px]">
                                    {percentage > 0 ? <Increase /> : <Decrease />}
                                    {percentage !== 0 && <span> ({percentage}%)</span>}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }

    return null
}

export default function ModalChart({ data, newName }) {
    if (!data) {
        return null // or some fallback UI if needed
    }

    // Check if data is an object
    if (typeof data !== 'object') {
        console.error('Invalid data format: Expected an object.')
        return null // or handle error state
    }

    // Create a mapping from IDs to names
    const idToNameMap = newName ? newName.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
    }, {}) : {}

    // Extract the first entry of the data object
    const [, firstValue] = Object.entries(data)[0]

    // Extract xLabels from the keys of the first data object's values
    const xLabels = Object.keys(firstValue)

    // Generate unique colors for each line
    const colors = Object.keys(data).reduce((acc, key) => {
        acc[key] = getRandomColor()
        return acc
    }, {})

    // Extract series data from the data prop
    const seriesData = Object.entries(data).map(([key, value]) => {
        const dataArray = xLabels.map((label) => {
            const labelValue = value[label]
            if (Array.isArray(labelValue)) {
                // Return value and percentage as an object
                return { value: labelValue[0], percentage: labelValue[1] || 0 }
            }
            // Return value with percentage as 0
            return { value: labelValue, percentage: 0 }
        })
        return {
            name: idToNameMap[key] || key,
            data: dataArray.map((item) => item.value),
            percentage: dataArray.map((item) => item.percentage),
            color: colors[key],
        }
    })

    // Convert seriesData to format required by Recharts
    const formattedData = xLabels.map((label, index) => {
        const dataPoint = { label }
        seriesData.forEach(({ data: seriesdatas, name, percentage }) => {
            dataPoint[name] = seriesdatas[index]
            dataPoint[`${name}Percentage`] = percentage[index]
        })
        return dataPoint
    })

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                data={formattedData}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                {seriesData.map(({ name, color }) => (
                    <Line
                        key={name}
                        type="monotone"
                        dataKey={name}
                        stroke={color}
                        strokeWidth={2}
                        dot={false} // Hide dots if you want lines only
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    )
}
