import { css, StyleSheet } from 'aphrodite'
import React, { useRef } from 'react'
import { COLORS } from '../../../shared/utils/colors'
import Modal from '../../../components/common/Modal'

function GiftCard({ gifImg, title, topic }) {
    const modalRef = useRef()
    return (
        <div className={css(s.container)}>
            <img src={gifImg} alt="My Gift" className={css(s.gifBox)} />
            <div className={css(s.infoBox)}>
                <h3 className={css(s.infoTitle)}>{title}</h3>

                <Modal
                    modalRef={modalRef}
                    trigger={<button className={css(s.infoBtn)}>Details</button>}
                >
                    <div className={css(s.contentModal)}>
                        <div className={css(s.gifModalBox)}>
                            <img src={gifImg} alt="My Gif" className={css(s.gifImgModal)} />
                        </div>
                        <div className={css(s.gifInfoBox)}>
                            <h3 className={css(s.infoModalTitle)}>{title}</h3>
                            <div className={css(s.gifModalTexts)}>
                                {topic.map((item) => (
                                    <p key={item.id} className={css(s.gifModalText)}>
                                        <span className={css(s.gifModalText1)}>
                                            {item.text.slice(0, item.text.indexOf(':') + 1)}
                                        </span>
                                        {item.text.slice(item.text.indexOf(':') + 1, item.text.length)}
                                    </p>
                                ))}
                            </div>
                        </div>
                        <div className={css(s.gifBtnBox)}>
                            <button onClick={() => modalRef.current.close()} className={css(s.gifBtn)}>Close</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default GiftCard

const s = StyleSheet.create({
    container: {
        width: '100%',
        height: '272px',
        position: 'relative',
        '@media (min-width: 768px)': {
            width: '100%',
            height: '347px',
        },
    },
    gifBox: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },

    infoBox: {
        position: 'absolute',
        paddingTop: '12.5px',
        paddingBottom: '13.5px',
        transform: 'translateY(-57px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        background: 'rgba(0, 0, 0, 0.60)',
        width: '100%',
    },

    infoTitle: {
        fontSize: '16px',
        lineHeight: 'normal',
        fontStyle: 'normal',
        color: 'white',
    },
    infoBtn: {
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        border: '1px solid #fff',
        borderRadius: '64px',
        color: '#fff',
        background: 'transparent',
    },
    contentModal: {
        maxWidth: '301px',
        width: '95%',
        margin: '0 auto',
        height: 'auto',
        border: '1px solid #404040',
        background: '#17181A',
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        gap: '16px',
        '@media (min-width: 48rem)': {
            paddingLeft: '20px',
            paddingRight: '20px',
            minWidth: '504px',
            width: '100%',
        },
    },
    gifModalBox: {
        minWidth: '261px',
        height: '170px',
        objectFit: 'cover',
        overflow: 'hidden',
        '@media (min-width: 48rem)': {
            width: '100%',
            height: '301px',
        },
    },
    gifImgModal: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    gifInfoBox: {
        minWidth: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '19px',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '27px',
        '@media (min-width: 48rem)': {
            width: '100%',
            alignItems: 'start',
        },
    },
    infoModalTitle: {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: 'normal',
        fontStyle: 'normal',
        color: '#fff',
        '@media (min-width: 48rem)': {
            fontSize: '18px',
        },
    },
    gifModalTexts: {
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '12px',
    },
    gifModalText: {
        fontSize: '12px',
        fontWeight: '300',
        lineHeight: 'normal',
        fontStyle: 'normal',
        color: '#fff',
        width: '92%',
        '@media (min-width: 48rem)': {
            width: '100%',
            fontSize: '14px',
        },
    },
    gifModalText1: {
        fontSize: '12px',
        fontWeight: '600',
        '@media (min-width: 48rem)': {
            fontSize: '14px',
        },
    },
    gifBtnBox: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
    },
    gifBtn: {
        width: '56px',
        height: '27px',
        borderRadius: '5px',
        background: '#fff',
        color: COLORS.black,
        border: 'none',
        outline: 'none',
        '@media (min-width: 48rem)': {
            width: '66px',
            height: '27px',
        },
    },
})

// const s = StyleSheet.create({
//     container: {
//         position: 'relative',
//         minWidth: '15.25rem',
//         // border: '1px solid #fff',
//         height: '400px',
//         display: 'flex',
//         flexDirection: 'column',
//     },
//     gifBox: {
//         width: 'auto',
//         height: '100%',
//         objectFit: 'cover',
//     },
//     infoBox: {
//         position: 'absolute',
//         width: '100%',
//         height: '20%',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         gap: '10px',
//         transform: 'translateY(320px)',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//         background: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
//         backdropFilter: 'blur(3px)', // Background blur effect
//         WebkitBackdropFilter: 'blur(10px)',
//     },
//     infoTitle: {
//         color: 'white',
//         fontSize: '18px',
//         fontWeight: '600',
//     },
//     infoBtn: {
//         color: '#fff',
//         width: '60px',
//         height: '40px',
//         borderRadius: '20px',
//         outline: 'none',
//         borderColor: COLORS.white,
//         background: 'transparent',
//     },
//
//     contentModal: {
//         backgroundColor: 'white',
//         width: '720px',
//         height: 'auto',
//         display: 'flex',
//         flexDirection: 'column',
//         paddingTop: '5px',
//         paddingBottom: '5px',
//         paddingLeft: '5px',
//         paddingRight: '5px',
//         borderRadius: '5px',
//     },
//     gifModal: {
//         minWidth: '100px',
//         height: '50%',
//         border: '1px solid #000',
//     },
//     gifModalBox: {
//         maxWidth: '100px',
//         height: '100%',
//         objectFit: 'contain',
//     },
//     giftInfo: {
//         width: '100%',
//         height: '50%',
//         overflow: 'scroll',
//         paddingTop: '5px',
//         // border: '1px solid #000',
//         display: 'flex',
//         paddingLeft: '5px',
//         flexDirection: 'column',
//     },
//     gifTitle: {
//         color: COLORS.black,
//         fontSize: '18px',
//         fontWeight: '600',
//         marginBottom: '5px',
//     },
//     gifText: {
//         color: COLORS.black,
//         fontSize: '14px',
//         fontWeight: '400',
//         width: '100%',
//         marginBottom: '15px',
//     },
// })
