import React, { useEffect, useState } from 'react'
import { Search } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import Table from '../../../shared/ui/Table'
import MasterUserFilter from '../../master-user/components/MasterUserFilter'
import { useFaceLoad } from '../../../shared/hooks/faceAuthRequest'
import { CLIENTSPAGINATION, MASTER_USERSPAGINATION, USER_PAGINATION } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import UserTableItems from './userTableItems'

const cols = [
    {
        id: 1,
        title: 'Created time',
        align: 'left',
        width: '33%',
    },
    {
        id: 2,
        title: 'Name',
        align: 'center',
        width: '33%',
    },
    {
        id: 3,
        title: 'PersonalNumber',
        align: 'center',
        width: '33%',
    },
]

export function UserTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const { entityFilters, setEntityFilter } = useFilter()
    const getClients = useFaceLoad({ url: CLIENTSPAGINATION }, [])
    const getMasters = useFaceLoad({ url: MASTER_USERSPAGINATION,
        params:
        { client_id: entityFilters.client ? entityFilters.client : 0 } }, [entityFilters.client])
    const loadUsers = useFaceLoad(
        { url: USER_PAGINATION,
            params:
         { page, size: pageSize, master_user_id: entityFilters.user ? entityFilters.user : 0 } },
        [page, pageSize, entityFilters.user],
    )
    const data = loadUsers.response ? loadUsers.response.items : []
    const meta = loadUsers.response ? loadUsers.response : {}
    const clientsList = getClients.response ? getClients.response.items : []
    const masterList = getMasters.response ? getMasters.response.items : []

    useEffect(() => {
        setPage(1)
    }, [entityFilters.user])

    return (
        <div className="flex flex-col items-center gap-[21px] w-[100%]">
            <div className="w-[100%] bg-white flex flex-col items-start
             gap-[10px] h-auto pt-[14px] pb-[15px] px-[14px] shadow-sm">
                <span className="text-[16px] font-normal text-black">Filter</span>
                <div className="flex items-center gap-[10px]">
                    <div className="flex flex-col items-start gap-[10px]">
                        <span className="text-black font-normal tex-[14px]">Client List</span>
                        <MasterUserFilter items={clientsList || []}
                            onChange={(e) => setEntityFilter('client', e.target.value)}
                            placeholder="Choose client" value={entityFilters.client} />
                    </div>
                    <div className="flex flex-col items-start gap-[10px]">
                        <span>Master User List</span>
                        <MasterUserFilter items={masterList || []}
                            onChange={(e) => setEntityFilter('user', e.target.value)} placeholder="Choose master user"
                            value={entityFilters.user} />
                    </div>
                </div>
            </div>
            <div className="bg-white w-[100%]">
                <div className="flex items-center justify-between h-[60px] px-4">
                    <Typography className="text-[20px] font-normal text-black">
                        Users list ({meta.total ? meta.total : 0})
                    </Typography>

                    <div className="flex-cent gap-2">
                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={loadUsers.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <UserTableItems
                            key={item.id}
                            item={item}
                            setData={loadUsers.setResponse}
                        />
                    )}
                />
            </div>
        </div>
    )
}
