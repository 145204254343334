import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import SmartCameraTableItem from './SmartCameraTableItem'
import { BUILDINGS_LIST, ROOMS_LIST, SMARTCAMERAS_LIST } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest } from '../../../shared/hooks/requests'
import CameraFilter from '../../../shared/filter/CameraFilter'
import Table from '../../../shared/ui/Table'

const cols = [
    { id: 1, title: 'Name', padding: '0 0 0 16px' },
    { id: 2, title: 'Users' },
    { id: 3, title: 'Identity' },
    { id: 4, title: 'Status' },
    { id: 5, title: 'Created time' },
]

export default function SmartCamerasTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const { cameraFilter } = useFilter()
    const getSmartCameras = useGetRequest({ url: SMARTCAMERAS_LIST, params: { roomId: cameraFilter.rId } })
    const data = getSmartCameras.response ? getSmartCameras.response.items : []
    const meta = getSmartCameras.response ? getSmartCameras.response : {}

    useEffect(() => {
        if (cameraFilter.rId) {
            getSmartCameras.request()
        }
    }, [cameraFilter.rId])

    return (
        <div className="w-full flex flex-col gap-6">
            <CameraFilter buildingUrl={BUILDINGS_LIST} roomUrl={ROOMS_LIST} />

            <Paper square className="flex flex-col gap-3">
                <Typography className="text-m font-600 px-4 pt-3">
                    Smart Camera
                </Typography>

                <Table
                    page={page - 1}
                    loading={getSmartCameras.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <SmartCameraTableItem item={item} key={item.id} />
                    )}
                />
            </Paper>
        </div>
    )
}
