import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Close } from '@mui/icons-material'

export function LeftItem() {
    const [isOpen, setIsOpen] = useState(false)

    const open = () => setIsOpen(true)

    const close = () => setIsOpen(false)
    return (
        <>
            <div onClick={open} className="w-[100%] h-auto py-[10px] rounded-[14px] flex items-center gap-[10px]">
                <div className="w-[30%] h-[100%]">
                    <img
                        src=""
                        alt="img of the left item"
                        className="w-[100%] h-[100%] rounded-[14px] object-cover" />
                </div>
                <div className="w-[70%] h-[100%] flex flex-col items-start gap-[5px] ">
                    <div className="flex items-center gap-[5px]">
                        <span className="text-[16px] font-medium text-black">Camera name:</span>
                        <span>Exit 1</span>
                    </div>
                    <div className="flex items-center gap-[5px]">
                        <span className="text-[16px] font-medium text-black">Snapshot date:</span>
                        <span>2022-01-01 00:00:00</span>
                    </div>
                    <div className="w-[50%] border border-leftBtnBlue bg-leftBgBlue flex items-center
                justify-center text-leftBtnBlue rounded-[7px] py-[2px]">
                        <span>Backpack</span>
                    </div>
                </div>
            </div>

            <Dialog
                fullWidth
                open={isOpen}
                onClose={close}
                PaperProps={{
                    style: {
                        width: '100%', // Set a percentage-based width or use viewport units like '80vw'
                        maxWidth: '100vh', // Set a maximum width to prevent it from growing too large
                        margin: 'auto', // Center the dialog horizontally
                    },
                }}
            >
                <div className="flex items-center justify-between py-1 pl-3 pr-1
                 bg-main-100 text-white text-lg font-medium">
                    <Typography>Item</Typography>

                    <IconButton onClick={close}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <div className="w-[100%] flex px-[10px] items-start justify-center py-[20px] gap-[40px] h-[500px]">
                        <div className="w-[40%] relative rounded-[14px] h-[80%]">
                            <img
                                src=""
                                alt="img of the left item"
                                className="w-[100%] rounded-[14px] h-[100%] object-cover" />
                            <div className="left-0 bottom-0 border py-[10px] backdrop-blur-lg right-0 flex
                            items-center justify-center backdrop-brightness-50 rounded-[14px] absolute">
                                <span className="text-[17px] font-medium text-white">SNAPSHOT</span>
                            </div>
                        </div>
                        <div className="flex w-[60%]  flex-col items-start gap-[10px]">
                            <span className="text-black font-bold text-[18px]">Information</span>
                            <div className="flex items-start w-[100%]">
                                <div className="flex w-[50%] flex-col">
                                    <div className="w-[100%] border flex items-center justify-center
                                     border-itemGray px-[5px] bg-infoGray py-[10px]">
                                        <span className="text-gray-200 text-[16px] font-medium">Location</span>
                                    </div>
                                    <div className="w-[100%] border border-itemGray flex items-center justify-center
                                     px-[5px] bg-infoGray py-[10px]">
                                        <span className="text-gray-200 text-[16px] font-medium">Snapshot time</span>
                                    </div>
                                    <div className="w-[100%] border border-itemGray flex items-center justify-center
                                     px-[5px] bg-infoGray py-[10px]">
                                        <span className="text-gray-200 text-[16px] font-medium">Cordinate</span>
                                    </div>
                                </div>
                                <div className="flex w-[50%] flex-col">
                                    <div className="w-[100%] border flex items-center justify-center
                                     border-itemGray px-[5px] bg-white py-[10px]">
                                        <span className="text-gray-200 text-[16px] font-medium">Location</span>
                                    </div>
                                    <div className="w-[100%] border border-itemGray flex items-center justify-center
                                     px-[5px] bg-white py-[10px]">
                                        <span className="text-gray-200 text-[16px] font-medium">Snapshot time</span>
                                    </div>
                                    <div className="w-[100%] border border-itemGray flex items-center justify-center
                                     px-[5px] bg-white py-[10px]">
                                        <span className="text-gray-200 text-[16px] font-medium">Cordinate</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
