import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../shared/utils/colors'
import { CloseBtn } from '../svgs/Svgs'

export default function ModalHeader({ title = 'Modal', onClose }) {
    return (
        <div className={`flex justify-between items-center ${css(s.modalHeader)}`}>
            <h3 className={s.title}>{title}</h3>
            <button onClick={onClose}><CloseBtn /></button>
        </div>
    )
}

const s = StyleSheet.create({
    modalHeader: {
        backgroundColor: COLORS.mainBlue,
        color: COLORS.white,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        padding: '12px 16px',
        marginBottom: '20px',
    },
    title: {
        fontSize: '18px',
        fontWeight: '500',
    },
})
