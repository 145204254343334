import React, { useState } from 'react'
import { Drawer } from '@mui/material'
import axios from 'axios'
import { CAMERA_ATTACH_JETSON, JETSON_DEVICES } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../shared/hooks/requests'
import JetsonDeviceDetails from '../components/JetsonDeviceDetails'
import { domain } from '../../../shared/utils/request'
import { auth } from '../../../shared/utils/auth'

export default function Update({ setData, item, isOpen, close }) {
    const showMessage = useMessage()
    const [loading, setLoading] = useState(false)
    const { handleErrorMsg } = useHandleErrors()
    const updateEntity = usePutRequest()

    const detachCameras = async (values) => {
        if (loading) return

        const removedIds = values.attachedIds.filter((id) => !values.cameraIds.includes(id))
        const newAddedIds = values.cameraIds.filter((id) => !values.attachedIds.includes(id))

        const allIds = [...removedIds, ...newAddedIds]

        if (allIds.length) {
            setLoading(true)
            const promises = allIds.map((cameraId) => {
                const isDetached = removedIds.includes(cameraId)
                return axios.post(
                    domain + CAMERA_ATTACH_JETSON,
                    {
                        camera_id: cameraId,
                        device_id: values.id,
                        action_mode: isDetached ? 'detach' : 'attach',
                    },
                    { ...auth() },
                ).then((response) => ({
                    success: true,
                    response,
                })).catch((error) => ({
                    success: false,
                    error,
                }))
            })

            const results = await Promise.all(promises)

            const failedResults = results.filter((result) => !result.success)
            if (failedResults.length > 0) {
                setLoading(false)
                failedResults.forEach(({ error }) => handleErrorMsg(error.response))
            } else {
                setLoading(false)
                showMessage(`Successfully ${removedIds.length ? 'detached' : 'attached'}`, 'success-msg')
            }
        } else {
            showMessage('No changes defined', 'info-msg')
        }
    }

    const update = async (values) => {
        if (!values || updateEntity.loading) return

        const { success, response, error } = await updateEntity.request({
            url: `${JETSON_DEVICES}/${item.id}`,
            data: values,
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((v) => (v.id === item.id ? { ...v, ...response } : v)),
            }))
            close()
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Drawer
            sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
            anchor="right"
            open={isOpen}
            onClose={close}>
            <JetsonDeviceDetails
                onEdit={update}
                onAttachCamera={detachCameras}
                onClose={close}
                item={item}
                loading={updateEntity.loading}
            />
        </Drawer>
    )
}
