import React from 'react'
import moment from 'moment/moment'
import { TableCell } from '@mui/material'

export default function UserTableItems({ item }) {
    return (
        <>
            <TableCell>
                {item.givenDate ? moment(item.givenDate).format('YYYY-MM-DD HH:MM:SS') : '' }
            </TableCell>

            <TableCell className="flex items-center justify-center cursor-pointer">
                <div className="flex w-[100%] items-center justify-center">
                    {item.fullName}
                </div>
            </TableCell>

            <TableCell className="cursor-pointer flex items-center justify-center">
                <div className="flex items-center justify-center w-[100%]">
                    {item.individualPersonalNumber}
                </div>
            </TableCell>
        </>
    )
}
