import React from 'react'
import { Drawer } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useMessage } from '../../../../../shared/hooks/message'
import useHandleErrors from '../../../../../shared/hooks/handleErrorMessage'
import { ROOMS_TENANT } from '../../../../../shared/utils/urls'
import { usePutRequest } from '../../../../../shared/hooks/requests'
import RoomDetails from '../../components/RoomDetails'

export default function Update({ isOpen, close, item, setData }) {
    const showMessage = useMessage()
    const { id } = useParams()
    const { handleErrorMsg } = useHandleErrors()
    const updateRoom = usePutRequest({ url: `${ROOMS_TENANT}/${item.id}` })

    const update = async (values) => {
        if (updateRoom.loading || !values) return

        const { success, response, error } = await updateRoom.request({
            data: { ...values, buildingId: id },
        })

        if (success) {
            showMessage('Successfully updated', 'success-msg')
            setData((prev) => ({
                ...prev,
                items: prev.items.map((v) => (v.id === item.id ? { ...v, ...response } : v)),
            }))
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Drawer
            sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
            anchor="right"
            open={isOpen}
            onClose={close}>
            <RoomDetails
                loading={updateRoom.loading}
                onEdit={update}
                onClose={close}
                item={item} />
        </Drawer>
    )
}
