import { Add, Remove } from '@mui/icons-material'
import { Field, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { COLORS } from '../utils/colors'

export default function NumberInput({ name, min, max, step = 1 }) {
    const { setFieldValue, values } = useFormikContext()

    useEffect(() => {
        if (!values[name] || values[name] === null) {
            setFieldValue(name, min)
        }
    }, [name, min, values[name]])

    const increment = () => {
        const currentValue = parseFloat(values[name])
        if (currentValue < max) {
            setFieldValue(name, (currentValue + step).toFixed(step === 1 ? 0 : 2))
        }
    }

    const decrement = () => {
        const currentValue = parseFloat(values[name])
        if (currentValue > min) {
            setFieldValue(name, (currentValue - step).toFixed(step === 1 ? 0 : 2))
        }
    }

    const handleChange = (e) => {
        const value = parseFloat(e.target.value)
        if (value >= min && value <= max) {
            setFieldValue(name, value)
        }
    }

    return (
        <div className="flex items-center rounded-md h-[30px] border border-[#EDEDED]">
            <button
                type="button"
                onClick={decrement}
                className="bg-[#F3F5F8] flex items-center justify-center
                 h-full w-[30px] p-2 hover:bg-[#EDEDED]"
            >
                <Remove htmlColor={COLORS.disabled} />
            </button>
            <Field
                name={name}
                type="number"
                className="w-12 h-full text-center outline-none"
                min={min}
                max={max}
                step={step}
                value={values[name]}
                onChange={handleChange}
            />
            <button
                type="button"
                onClick={increment}
                className="bg-[#F3F5F8] flex items-center justify-center
                 h-full w-[30px] p-2 hover:bg-[#EDEDED]"
            >
                <Add htmlColor={COLORS.disabled} />
            </button>
        </div>
    )
}
