import React, { useEffect, useState } from 'react'
import { Box, Typography, Checkbox } from '@mui/material'
import { Field } from 'formik'
import Paper from '@mui/material/Paper'
import { useLoad } from '../../../shared/hooks/requests'
import { MODULES } from '../../../shared/utils/urls'
import { LoadingSkeleton } from '../../../components/common/LoadingSkeleton'

export default function ModuleSelectForm({ values, setFieldValue }) {
    const loadModules = useLoad({ url: MODULES }, [])
    const modules = loadModules.response ? loadModules.response.items : []

    const [checkedState, setCheckedState] = useState({})

    useEffect(() => {
        if (modules && modules.length > 0) {
            const initialCheckedState = modules.reduce((acc, module) => {
                acc[module.id] = values.modules.some((m) => m.id === module.id)
                return acc
            }, {})

            setCheckedState(initialCheckedState)
        }
    }, [loadModules.response])

    const handleCheckboxChange = (module) => {
        const isChecked = !checkedState[module.id]
        setCheckedState((prevState) => ({
            ...prevState,
            [module.id]: isChecked,
        }))

        if (isChecked) {
            setFieldValue('modules', [...values.modules, { name: module.name, id: module.id }])
        } else {
            setFieldValue('modules', values.modules.filter((m) => m.id !== module.id))
        }
    }

    return (
        <Paper className="flex flex-col gap-1.5 p-3 bg-white">
            <h3 className="text-s font-500">Module</h3>

            <div className="flex flex-col gap-1.5 overflow-y-auto">
                {!loadModules.loading ? (
                    modules.map((module) => (
                        <Box
                            key={module.id}
                            className="w-full flex items-center justify-between py-2 px-4 bg-[#F3F5F8]"
                        >
                            <Typography>{module.name}</Typography>

                            <Field
                                as={Checkbox}
                                checked={checkedState[module.id] || false}
                                name={`modules[${module.id}]`}
                                onChange={() => handleCheckboxChange(module)}
                            />
                        </Box>
                    ))
                ) : (
                    <LoadingSkeleton height={28} width="100%" />
                )}
            </div>
        </Paper>
    )
}
