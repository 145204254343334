import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import { COLORS } from '../../../shared/utils/colors'
import MLModelForm from './MLModelForm'
import { ML_MODELS } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../shared/hooks/requests'
import ApiIntegrationDocs from './ApiIntegrationDocs'

const navItems = [
    { id: 'detail', name: 'Details' },
    { id: 'api', name: 'API Integration' },
]

export default function MLModelDetails({ item, onClose, profiles, $api }) {
    const [section, setSection] = useState('detail')
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateMLModel = usePutRequest()

    const initialValues = {
        version: item.version || '',
        description: item.description || '',
        s3ModelPipelinePath: item.s3ModelPipelinePath || '',
        s3ModelConfigPath: item.s3ModelConfigPath || '',
        mlModelProfileId: item.mlModelProfileId || '',
        categoricalFeatures: item.categoricalFeatures.map((t) => ({
            id: t.id || 1,
            name: t.name || '',
            description: t.description || '',
            acceptedValues: t.acceptedValues ? t.acceptedValues.split(';') : [],
        })),
        numericFeatures: item.numericFeatures.map((t) => ({
            id: t.id || 1,
            name: t.name || '',
            description: t.description || '',
        })),
        binaryFeatures: item.binaryFeatures.map((t) => ({
            id: t.id || 1,
            name: t.name || '',
            description: t.description || '',
        })),
        timeSeriesFeatures: item.timeSeriesFeatures.map((t) => ({
            id: t.id || 1,
            name: t.name || '',
            description: t.description || '',
        })),
        textFeatures: item.textFeatures.map((t) => ({
            name: t.name || '', description: t.description || '',
        })),
    }

    const update = async (values) => {
        const { success, error } = await updateMLModel.request({
            url: `${ML_MODELS}${item.id}`,
            data: {
                name: values.name,
                description: values.description,
                tags: values.tags.join(';'),
            },
        })

        if (success) {
            $api.request()
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <DrawerLayout
            topic="ML model details"
            onClose={onClose}
            name={item.mlModelProfile.name}
            icons={[]}>
            <React.Fragment>
                <div>
                    <div className={css(s.detailNav)}>
                        <ul className="w-full flex items-center">
                            {navItems.map(((i) => (
                                <li onClick={() => setSection(i.id)}
                                    className={css(s.navItem, section === i.id && s.active)} key={i.id}>
                                    {i.name}
                                </li>
                            )))}
                        </ul>
                    </div>

                    {/* <div className="flex items-center gap-2_5">
                            <Button onClick={showTenantAdmins} className={css(s.actBtn)}>
                                Manage tenant admins
                            </Button>
                        </div> */}
                </div>

                {section === 'detail' ? (
                    <MLModelForm
                        isEdit
                        profiles={profiles}
                        onClose={onClose}
                        onSubmit={update}
                        initialValues={initialValues} />
                ) : (
                    <ApiIntegrationDocs mlName={item.mlModelProfile.name} version={item.version} />
                )}
            </React.Fragment>
        </DrawerLayout>
    )
}

const s = StyleSheet.create({
    sBody: {
        padding: '9px 0',
    },
    detailNav: {
        borderBottom: `1px solid ${COLORS.sepia}`,
        paddingBottom: 1,
    },
    navItem: {
        width: 'fit-content',
        listStyle: 'none',
        padding: '9px 10px',
        fontSize: 16,
        fontWeight: '500',
        position: 'relative',
        color: COLORS.disabled,
        cursor: 'pointer',
        paddingBottom: 8,
        transition: 'color 0.3s ease',
        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -1,
            width: 0,
            borderBottom: `solid 1.5px ${COLORS.mainBlueBg}`,
            transition: 'width 0.3s ease',
        },
        ':hover': {
            color: COLORS.mainBlueBg,
        },
        ':hover::after': {
            width: '100%',
        },
    },
    active: {
        color: COLORS.mainBlueBg,
        ':after': {
            width: '100%',
        },
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '8px 12px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
    },
})
