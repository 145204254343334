import React from 'react'
import { Form, Formik } from 'formik'
import { object, string, array, boolean, number } from 'yup'
import ModuleSelectForm from './ModuleSelectForm'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { TenantRoleForm } from './TenantProfileRoleForm'

const FORM_INITIAL_STATE = {
    name: '',
    description: '',
    modules: [],
    roles: [],
}

const permissionSchema = object().shape({
    moduleId: number(),
    read: boolean(),
    create: boolean(),
    update: boolean(),
    delete: boolean(),
})

const roleSchema = object().shape({
    id: string(),
    name: string().when('description', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required('Role name is required when description is provided'),
        otherwise: (schema) => schema,
    }),
    description: string(),
    permissions: array().of(permissionSchema),
})

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    description: string().required('Description is required'),
    modules: array().optional(),
    roles: array().of(roleSchema),
})

const btnTitle = {
    1: 'Create',
    2: 'Attach module',
    3: 'Ok',
}

export default function TenantProfileForm({
    step,
    onSubmit = () => {},
    onClose = () => {},
    isEdit,
    loading,
    initialValues,
    section = 'details',
    withModules = true,
}) {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, setValues, handleChange }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-4">
                    <div className="flex flex-col gap-4">
                        {(step === 1 || step === 2 || isEdit) && (
                            <div className="flex-col gap-4">
                                {section === 'details' && (
                                    <>
                                        <FormTextField
                                            label="Name"
                                            name="name"
                                            required
                                            handleChange={handleChange}
                                        />

                                        <FormTextField
                                            required
                                            multiline
                                            label="Description"
                                            name="description"
                                            handleChange={handleChange}
                                        />
                                    </>
                                )}

                                {(withModules || section === 'module') && (
                                    <ModuleSelectForm setFieldValue={setFieldValue} values={values} />
                                )}
                            </div>
                        )}

                        {(step === 3 || section === 'roles') && (
                            <TenantRoleForm
                                setValues={setValues}
                                values={values}
                                isEdit={isEdit}
                                initialValues={initialValues}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn onClick={onClose} title="Cancel" type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : btnTitle[step]} type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
