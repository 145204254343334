import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import { usePostFaceRequest } from '../../../shared/hooks/faceAuthRequest'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import ClientForm from '../components/clientForm'
import { CLIENTS } from '../../../shared/utils/urls'

export default function Create({ setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createClient = usePostFaceRequest({ url: CLIENTS })

    const create = async (values) => {
        if (createClient.loading || !values) return

        const { response, success, error } = await createClient.request({
            data: { ...values, isActive: true },
        })
        if (success) {
            setData((prev) => ({
                ...prev,
                items: [...(prev.items || []), response],
            }))
            showMessage('Successfully created', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>Create Client</Typography>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <ClientForm
                        loading={createClient.loading}
                        onSubmit={create}
                        onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
