import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { COLORS } from '../../shared/utils/colors'

export function LoadingSkeleton({ width = 120, height = 15, borderRadius = 4, inline }) {
    return (
        <Skeleton
            inline={inline}
            duration={1}
            baseColor={COLORS.skeletonBg}
            className="flex items-center"
            containerClassName="flex items-center"
            highlightColor="#FFFFFF"
            width={width}
            height={height}
            borderRadius={borderRadius} />
    )
}
