import React, { useState } from 'react'
import { Chip, Dialog, DialogContent, TableCell } from '@mui/material'
import moment from 'moment/moment'
import { COLORS } from '../../../shared/utils/colors'
import LiveCamModal from './LiveCamModal'

export default function SmartCameraTableItem({ item }) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <TableCell className="cursor-pointer" onClick={() => setOpen(true)}>
                {item.name}
            </TableCell>

            <TableCell className="cursor-pointer" onClick={() => setOpen(true)}>
                {item.userCount}
            </TableCell>

            <TableCell className="cursor-pointer" onClick={() => setOpen(true)}>
                {item.identityCount}
            </TableCell>

            <TableCell className="cursor-pointer" onClick={() => setOpen(true)}>
                <Chip
                    color={item.isOnline ? 'success' : 'error'}
                    variant="outlined"
                    sx={{
                        backgroundColor: item.isOnline ? '#37B4001F' : COLORS.lightRed,
                        border: `1px solid ${item.isOnline ? '#2B8906' : COLORS.red}`,
                        color: item.isOnline ? '#2B8906' : COLORS.white,
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '4px 16px',
                        '& .MuiChip-label': { padding: 0 },
                    }}
                    label={item.isOnline ? 'Online' : 'Offline'} />
            </TableCell>

            <TableCell>{item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }</TableCell>

            <Dialog
                fullWidth
                open={open}
                onClose={() => setOpen(false)}
                sx={{ '& .MuiPaper-root': { backgroundColor: 'transparent', maxWidth: '100%' } }}>
                <DialogContent sx={{ padding: 0, borderRadius: '8px', backgroundColor: 'transparent' }}>
                    <LiveCamModal item={item} onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
