import React, { useEffect, useState } from 'react'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import { useGetRequest, usePostRequest } from '../../../shared/hooks/requests'
import { JETSON_ATTACHED_CAMERAS, JETSON_SETUP_CONFIG } from '../../../shared/utils/urls'
import { DEVICE_MANAGER_URL, GITHUB_TOKEN } from '../constants'
import JetsonForm from './JetsonForm'

const navItems = [
    { id: 'detail', name: 'Details' },
    { id: 'attachment', name: 'Camera attachment' },
    { id: 'config', name: 'Config file' },
    { id: 'vpnSetup', name: 'Vpn setup file' },
]

export default function JetsonDeviceDetails({ item, onEdit, onClose, loading, onAttachCamera }) {
    const [section, setSection] = useState('detail')
    const getAttachedCameras = useGetRequest({ url: JETSON_ATTACHED_CAMERAS.replace('{id}', item.deviceId) })
    const attachedCameras = getAttachedCameras.response ? getAttachedCameras.response : []
    const getConfigCode = usePostRequest({ url: JETSON_SETUP_CONFIG })
    const configCode = getConfigCode.response ? getConfigCode.response : ''

    useEffect(() => {
        if (section === 'attachment') getAttachedCameras.request()
        if (section === 'config' || section === 'code') {
            getConfigCode.request({ data: {
                jetsonDeviceId: item.deviceId,
                githubToken: GITHUB_TOKEN,
                jetsonDeviceManagerUrl: DEVICE_MANAGER_URL,
            } })
        }
    }, [section])

    const initialValues = {
        ...item,
        setupFile: configCode,
        cameraIds: attachedCameras ? attachedCameras.map((c) => c.id) : [],
        attachedIds: attachedCameras ? attachedCameras.map((c) => c.id) : [],
    }

    return (
        <DrawerLayout
            topic="Jetson device details"
            onClose={onClose}
            name={item.deviceName}
            icons={[]}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.id)}
                                className={
                                    `cursor-pointer list-none text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.id ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                <JetsonForm
                    isEdit
                    step={section === 'detail' ? 1 : section === 'attachment' ? 2 : section === 'config' ? 3 : 4}
                    loading={loading}
                    onClose={onClose}
                    section={section}
                    onSubmit={section === 'detail' ? onEdit : onAttachCamera}
                    initialValues={initialValues} />
            </React.Fragment>
        </DrawerLayout>
    )
}
