import React from 'react'
import { Select, MenuItem } from '@mui/material'

function Filter({ items, onChange, value }) {
    const options = []

    if (items) {
        options.push({ value: 'all', label: 'All' })

        if (items.children) {
            options.push(...items.children.map((child) => ({ value: child.id.toString(), label: child.name })))
        }
    }

    return (
        <Select
            value={options.some((option) => option.value === value) ? value : ''}
            onChange={onChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            className="w-[175px] h-[46px]"
        >
            {options.map((item) => (
                <MenuItem sx={{ fontStyle: 'normal' }} key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </Select>
    )
}

export default Filter
