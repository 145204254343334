import React from 'react'
import { Form, Formik } from 'formik'
import { Autocomplete, TextField } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import FormTextField from '../../../shared/ui/FormTextField'

const formInitialValues = {
    name: '',
    description: '',
    tags: [],
}

export default function MLModelProfileForm({
    onSubmit = () => {},
    onClose = () => {},
    isEdit = false,
    initialValues,
}) {
    return (
        <Formik enableReinitialize initialValues={{
            ...formInitialValues,
            ...initialValues,
        }} onSubmit={onSubmit} initialTouched={{ remote: true }}>
            {({ setFieldValue, handleChange }) => (
                <Form className="h-layout flex flex-col p-4 justify-between gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            required
                            label="Name"
                            name="name"
                            handleChange={handleChange} />

                        <FormTextField
                            label="Description"
                            name="description"
                            handleChange={handleChange} />

                        <Autocomplete
                            freeSolo
                            multiple
                            options={['Bank', 'Marketing']}
                            onChange={(_, newValue) => setFieldValue('tags', newValue)}
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            paddingBottom: '8px',
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: COLORS.black,
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottomColor: 'rgba(0, 0, 0, 0.54)',
                                        },
                                        // eslint-disable-next-line max-len
                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                            borderBottomColor: '#7C7D7E',
                                            borderBottomWidth: '1px',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: 'black',
                                            borderBottomWidth: '1px',
                                        },
                                    }}
                                    {...params} label="Accepted values" />
                            )} />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
