import React, { memo, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { Button, Checkbox, Typography, FormControlLabel } from '@mui/material'
import { Field } from 'formik'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import FormTextField from '../../../shared/ui/FormTextField'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import UpdateRole from '../features/role-features/UpdateRole'
import CreateRole from '../features/role-features/CreateRole'

function TenantProfileRoleForm({ values, setFieldValue, role, ind, isEdit }) {
    const handleCheck = (moduleIndex, moduleId, permissionType) => {
        const newPermission = values.roles[ind].permissions.map((permission, index) => {
            if (index === moduleIndex) {
                return { ...permission, moduleId, [permissionType]: !permission[permissionType] }
            }
            return permission
        })

        setFieldValue(`roles[${ind}].permissions`, newPermission)
    }
    console.log(isEdit)
    return (
        <CustomAccordion
            summary={role.name || 'Create role'}
            contClassName="border-[1px] border-sepia-100"
            detailClassName="border-[1px] border-sepia-200 mx-2 mb-3
            rounded-[2px] max-h-[25rem] overflow-y-scroll no-scrollbar"
            expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
            overrideStyles={{
                ':before': {
                    display: 'none',
                },
                boxShadow: 'none',
                borderRadius: '3px',
            }}>
            <div className="flex-col gap-4">
                <FormTextField
                    required={isEmpty(values.roles[ind].description)}
                    name={`roles[${ind}].name`}
                    label="Role"
                />

                <FormTextField
                    name={`roles[${ind}].description`}
                    label="Description"
                />
            </div>

            <div className="flex-col gap-4 overflow-y-auto mt-4">
                {values.modules.map((m, moduleIndex) => (
                    <div className="flex-col gap-2" key={m.id}>
                        <Typography className="flex items-center justify-between py-2 px-4 bg-[#F3F5F8]">
                            {m.name}
                        </Typography>

                        <label className="text-s font-400 text-placeholder">Permissions</label>

                        <div className="flex flex-1 items-center justify-start gap-2">
                            <FormControlLabel
                                sx={{ margin: 0 }}
                                className="pl-3 flex-1 flex items-center justify-between pr-1 py-1 bg-defaultInpBg"
                                labelPlacement="start"
                                label="Read"
                                control={(
                                    <Field
                                        as={Checkbox}
                                        checked={values.roles[ind].permissions[moduleIndex].read || false}
                                        onChange={() => handleCheck(moduleIndex, m.id, 'read')}
                                        name={`roles[${ind}].permissions[${moduleIndex}].read`}
                                    />
                                )}
                            />

                            <FormControlLabel
                                sx={{ margin: 0 }}
                                className="pl-3 flex-1 flex items-center justify-between pr-1 py-1 bg-defaultInpBg"
                                labelPlacement="start"
                                label="Create"
                                control={(
                                    <Field
                                        as={Checkbox}
                                        checked={values.roles[ind].permissions[moduleIndex].create || false}
                                        onChange={() => handleCheck(moduleIndex, m.id, 'create')}
                                        name={`roles[${ind}].permissions[${moduleIndex}].create`}
                                    />
                                )}
                            />

                            <FormControlLabel
                                sx={{ margin: 0 }}
                                className="pl-3 flex-1 flex items-center justify-between pr-1 py-1 bg-defaultInpBg"
                                labelPlacement="start"
                                label="Update"
                                control={(
                                    <Field
                                        as={Checkbox}
                                        checked={values.roles[ind].permissions[moduleIndex].update || false}
                                        onChange={() => handleCheck(moduleIndex, m.id, 'update')}
                                        name={`roles[${ind}].permissions[${moduleIndex}].update`}
                                    />
                                )}
                            />

                            <FormControlLabel
                                sx={{ margin: 0 }}
                                className="pl-3 flex-1 flex items-center justify-between pr-1 py-1 bg-defaultInpBg"
                                labelPlacement="start"
                                label="Delete"
                                control={(
                                    <Field
                                        as={Checkbox}
                                        checked={values.roles[ind].permissions[moduleIndex].delete || false}
                                        onChange={() => handleCheck(moduleIndex, m.id, 'delete')}
                                        name={`roles[${ind}].permissions[${moduleIndex}].delete`}
                                    />
                                )}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <div className="w-full flex items-center justify-end mt-4">
                {isEdit
                    ? <UpdateRole roleIndex={ind} values={values} initialValues={isEdit} />
                    : <CreateRole values={values} roleIndex={ind} />}
            </div>
        </CustomAccordion>
    )
}

function MemoizedComponent({
    values,
    setFieldValue,
    setValues,
    initialValues,
}) {
    const [count, setCount] = useState(0)

    const addNewRoleField = () => {
        setCount(count + 1)
        setValues({
            ...values,
            roles: [
                ...values.roles,
                {
                    id: count,
                    name: '',
                    description: '',
                    permissions: values.modules.map((module) => ({
                        moduleId: module.id,
                        read: false,
                        create: false,
                        update: false,
                        delete: false,
                    })),
                },
            ],
        })
    }

    useEffect(() => {
        if (!isEmpty(values) && values.roles.length !== 0) {
            setCount(values.roles.length > count ? values.roles.length : count)
        }
    }, [values.roles])

    return (
        <div className="flex flex-col gap-4">
            {values.roles.map((role, ind) => (
                <TenantProfileRoleForm
                    ind={ind}
                    isEdit={initialValues && initialValues.roles.find((r) => r.id === role.id)}
                    key={role.id}
                    role={role}
                    values={values}
                    setFieldValue={setFieldValue}
                />
            ))}

            <Button
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
                onClick={addNewRoleField}>
                <ControlPointIcon />

                <h3>Create a new role</h3>
            </Button>
        </div>
    )
}

export const TenantRoleForm = memo(MemoizedComponent)
