import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { SMART_CAMERA_PROFILE } from '../../../shared/utils/urls'
import SmartCameraProfileDetails from '../components/smartCameraProfileDetails'
import { usePutRequest } from '../../../shared/hooks/requests'

export default function Update({ setData, item }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [openDrawer, setOpenDrawer] = useState(false)
    const updateProfile = usePutRequest({ url: `${SMART_CAMERA_PROFILE}/${item.id}` })

    const close = () => setOpenDrawer(false)

    const update = async (values) => {
        if (!values || updateProfile.loading) return
        const { success, response, error } = await updateProfile.request({ data: { ...values } })

        if (success) {
            showMessage('Module updated', 'success-msg')

            setData((prev) => prev.map((val) => (val.id === item.id ? { ...val, ...response } : val)))
            close()
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpenDrawer(true)}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '45rem' } }}
                anchor="right"
                open={openDrawer}
                onClose={close}>
                <SmartCameraProfileDetails
                    onEdit={update}
                    onClose={close}
                    loading={updateProfile.loading}
                    item={item} />
            </Drawer>
        </>
    )
}
