import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { ErrorMessage, Field, useField, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { onChangePhoneNumber } from '../utils/number'
import { COLORS } from '../utils/colors'

export default function PhoneInput({
    name,
    help,
    type,
    label,
    loading,
    onChange,
    disabled,
    className,
    placeholder,
    fieldClassName,
    noRequiredValidation,
    labelClassName,
    ...attributes
}) {
    const { setFieldValue, isValid, values, handleBlur } = useFormikContext()
    const isRequired = attributes.required
    const [focused, setFocused] = useState(false)
    const shouldFocus = focused || Boolean(values[name])
    const fieldRef = useRef()
    const [field, meta] = useField(name)

    const configTextField = {
        ...field,
        ...attributes,
    }

    if (meta && meta.touched && meta.error) {
        configTextField.error = true
        configTextField.helperText = meta.error
    }
    // const [code, setCode] = useState('UZ')

    // const codes = [
    //     { value: '+998', label: 'UZ' },
    //     { value: '+7', label: 'RU' },
    //     { value: '+1', label: 'US' },
    // ]

    return (
        <div className="flex flex-col gap-1">
            {/* //     <div className={cn(css(s.codeSelect), 'flex items-center gap-1')}> */}
            {/*     //         <h3 className="text-s font-500">{code}</h3> */}
            {/*     // */}
            {/*     //         <SelectInputCaret /> */}
            {/*     // */}
            {/*        </div> */}

            <div onClick={() => fieldRef.current.focus()}
                className={cn(
                    shouldFocus && willFocus(disabled),
                    'control flex-col items-start gap-1 justify-end',
                    css(s.field, disabled && s.disabled),
                )}>

                {label ? (
                    <label htmlFor={name}
                        className={css(s.label, labelClassName)}>
                        {label} {isRequired && !disabled && (
                            <span style={{ color: !isValid ? COLORS.red : COLORS.gray }}>
                                *
                            </span>
                        )}
                    </label>
                ) : null}

                <div className="flex items-center gap-1">
                    <h3 className={css(s.countryCode)}>+998</h3>

                    <Field
                        id={name}
                        name={name}
                        maxLength={12}
                        innerRef={fieldRef}
                        type={type || 'text'}
                        placeholder={placeholder}
                        onFocus={() => setFocused(true)}
                        onBlur={(e) => {
                            handleBlur(e)
                            setFocused(false)
                        }}
                        onChange={(event) => {
                            const value = onChangePhoneNumber(event.target.value, true)
                            setFieldValue(name, value)
                        }}
                        disabled={disabled}
                        className={cn(css(s.inp), className)}
                        {...configTextField} />
                </div>
            </div>

            <ErrorMessage
                name={name}
                {...attributes}
                render={(msg) => <span className="text-xs font-normal text-red-600">{msg}</span>} />
        </div>
    )
}

const s = StyleSheet.create({
    countryCode: {
        fontSize: 16,
        color: COLORS.lightBlack,
        fontWeight: '400',
    },
    codeSelect: {
        border: 'none',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        textIndent: 1,
        textOverflow: '',
    },
    field: {
        width: '100%',
        height: 50,
        boxSizing: 'border-box',
        padding: '5px 0',
        ':nth-child(1n) > label': {
            letterSpacing: -0.5,
        },
        borderRadius: '2px 2px 0px 0px',
        borderBottom: `1px solid ${COLORS.defInpBc}`,
    },
    inp: {
        width: '100%',
        backgroundColor: 'inherit',
        fontSize: 16,
        color: COLORS.lightBlack,
        fontWeight: '400',
        outline: 'none',
        border: 'none',
        ':disabled': {
            color: '#BEBEBE',
        },
    },
    label: {
        fontSize: 14,
        fontWeight: '400',
        color: 'rgba(0, 0, 0, 0.54)',
    },
})

const willFocus = (isDis) => css(StyleSheet.create({
    focused: {
        borderBottom: `1px solid ${isDis ? '#BEBEBE' : COLORS.black}`,
    },
}).focused)
