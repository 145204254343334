import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import { COLORS } from '../../../shared/utils/colors'
import SmartCameraProfileForm from './smartCameraProfileForm'
import FaceParametres from './FaceParametrs'
import ComparisonParameters from './ComparisonParametrs'

const navItems = [
    { id: 'details', name: 'Details' },
    { id: 'faceparams', name: 'Face Parametres' },
    { id: 'comparisonparams', name: 'Comparison Parameters' },
]

export default function SmartCameraProfileDetails({ item, loading, onClose, onEdit }) {
    const [section, setSection] = useState('details')

    const handleSubmit = (values) => {
        onEdit(values)
    }

    return (
        <DrawerLayout
            topic="Smart camera profile details"
            onClose={onClose}
            name={item.name}
            icons={[]}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.id)}
                                className={
                                    `cursor-pointer list-none text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.name ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={cn(css(s.sBody), 'h-layout overflow-y-auto')}>
                    {section === 'details' ? (
                        <SmartCameraProfileForm
                            isEdit
                            onSubmit={handleSubmit}
                            loading={loading}
                            onClose={onClose}
                            formType={section}
                            initialValues={item} />
                    ) : section === 'faceparams' ? (
                        <FaceParametres id={item.id} />
                    ) : section === 'comparisonparams' ? (
                        <ComparisonParameters id={item.id} />
                    ) : null}
                </div>
            </React.Fragment>
        </DrawerLayout>
    )
}

const s = StyleSheet.create({
    sBody: {
        padding: '9px 0',
    },
    detailNav: {
        borderBottom: `1px solid ${COLORS.sepia}`,
        paddingBottom: 1,
    },
    detailHead: {
        // ':nth-child(1n) > :last-child': {
        //     margin: '12px 0 0 10px',
        // },
    },
    navItem: {
        width: 'fit-content',
        listStyle: 'none',
        padding: '9px 10px',
        fontSize: 16,
        fontWeight: '500',
        position: 'relative',
        color: COLORS.disabled,
        cursor: 'pointer',
        paddingBottom: 8,
        transition: 'color 0.3s ease',
        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -1,
            width: 0,
            borderBottom: `solid 1.5px ${COLORS.mainBlueBg}`,
            transition: 'width 0.3s ease',
        },
        ':hover': {
            color: COLORS.mainBlueBg,
        },
        ':hover::after': {
            width: '100%',
        },
    },
    active: {
        color: COLORS.mainBlueBg,
        ':after': {
            width: '100%',
        },
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '8px 12px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
    },
})
