/* eslint-disable camelcase */
import React from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { ChevronNextSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import Card from './Card'
import { useQueryParams } from '../../../shared/hooks/queryString'
import { TENANT_ENTITIES_CHILDREN } from '../../../shared/utils/urls'
import { useInfiniteScroll } from '../../../shared/hooks/requests'
import Create from '../features/Create'

export default function Branches({ sections }) {
    const { sec_id, sec_name } = useQueryParams()
    const getBranches = useInfiniteScroll({
        url: TENANT_ENTITIES_CHILDREN.replace('{id}', sec_id),
    }, Boolean(sec_id), [sec_id])
    const data = getBranches.response ? getBranches.response.items : []
    const meta = getBranches.response ? getBranches.response : {}
    const options = sections ? sections.map((s) => (
        { value: s.id, label: s.name })) : []

    return sections && sections.length ? (
        <div className="flex-col gap-7_5">
            <div className="flex items-center justify-between">
                <Typography sx={{
                    fontSize: '18px',
                    fontWeight: '600',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}>
                    Branches ( {data.length} )

                    <ChevronNextSizeM color={COLORS.disabled} />

                    <h3>{sec_name}</h3>
                </Typography>

                <div className="flex-cent gap-4_5">
                    <Create hierarchyLevel={3} parentOptions={options} setData={getBranches.setResponse} />

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            {data.length ? (
                <Grid container columnGap={2} rowGap={2}>
                    {data.map((item) => (
                        <Card
                            item={item}
                            key={item.id}
                            setData={getBranches.setResponse}
                            loading={getBranches.loading}
                            parentOptions={options} />
                    ))}
                </Grid>
            ) : null}

            {getBranches.hasMore && meta.total > meta.size && <div ref={getBranches.ref} />}
        </div>
    ) : null
}
