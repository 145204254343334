import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { debounce } from 'lodash'
import { number, object, string } from 'yup'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { COUNTRIES_LIST, DISTRICTS_LIST, REGIONS_LIST, TENANT_PROFILE } from '../../../shared/utils/urls'
import FormTextField from '../../../shared/ui/FormTextField'
import SelectInput from '../../../shared/ui/SelectInput'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const FORM_INITIAL_STATE = {
    name: '',
    tenantProfileId: null,
    regionId: null,
    countryId: null,
    districtId: null,
    description: '',
    phone: '',
    email: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    tenantProfileId: number().required('Tenant Profile is required'),
    regionId: number().required('Region is required'),
    countryId: number().required('Country is required'),
    districtId: number().required('District is required'),
    description: string().optional(),
    phone: string().optional(),
    email: string().test(
        'is-email',
        'Given email address is not valid',
        (value) => (`${value}`).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
    ).required('Email is required'),
})

export default function TenantForm({
    onSubmit = () => {},
    onClose = () => {},
    loading = false,
    initialValues,
    isEdit,
}) {
    const [search, setSearch] = useState('')
    const [inputValue, setInputValue] = useState('')
    const getTenantProfiles = useLoad({ url: `${TENANT_PROFILE}/`,
        params: { size: 10, search: JSON.stringify({ name: search }) } })
    const loadCountries = useLoad({ url: COUNTRIES_LIST }, [])
    const getRegions = useGetRequest({ url: REGIONS_LIST })
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST })
    const tenantProfiles = getTenantProfiles.response ? getTenantProfiles.response.items : []
    const profileOptions = tenantProfiles ? tenantProfiles.map((t) => ({ value: t.id, label: t.name })) : []
    const countryOpts = loadCountries.response
        ? loadCountries.response.map((c) => ({ value: c.id, label: c.name })) : []
    const regionOpts = getRegions.response ? getRegions.response.map((r) => ({ value: r.id, label: r.name })) : []
    const districtOpts = getDistricts.response ? getDistricts.response.map((r) => ({ value: r.id, label: r.name })) : []

    useEffect(() => {
        if (initialValues && initialValues.countryId) {
            getRegions.request({ params: { countryId: initialValues.countryId } })
        }
        if (initialValues && initialValues.regionId) {
            getDistricts.request({ params: { regionId: initialValues.regionId } })
        }
    }, [])

    const debouncedSetSearch = useRef(
        debounce((value) => {
            setSearch(value)
        }, 500),
    ).current

    const sendRequest = useCallback((value) => {
        debouncedSetSearch(value)
    }, [debouncedSetSearch])

    const handleInputChange = (newValue) => {
        setInputValue(newValue)
        sendRequest(newValue)
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ values, setFieldValue, handleChange }) => (
                <Form id="tenantForm" className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            label="Name"
                            name="name"
                            required
                            disabled={!isEdit}
                            handleChange={handleChange} />

                        <SelectInput
                            required
                            disabled={!isEdit}
                            name="tenantProfileId"
                            label="Tenant profile"
                            inputValue={inputValue}
                            loading={getTenantProfiles.loading}
                            menuItems={profileOptions}
                            onInputChange={handleInputChange}
                            onChange={(e) => setFieldValue('tenantProfileId', e.target.value)} />

                        <FormTextField
                            disabled={!isEdit}
                            label="Description"
                            name="description"
                            handleChange={handleChange} />

                        <SelectInput
                            required
                            disabled={!isEdit}
                            loading={loadCountries.loading}
                            label="Country"
                            name="countryId"
                            onChange={(e) => {
                                setFieldValue('countryId', e.target.value)
                                getRegions.request({ params: { countryId: e.target.value } })
                            }}
                            menuItems={countryOpts}
                        />

                        <SelectInput
                            required
                            disabled={!values.countryId || !isEdit}
                            loading={getRegions.loading}
                            label="Region"
                            name="regionId"
                            menuItems={regionOpts}
                            onChange={(e) => {
                                setFieldValue('regionId', e.target.value)
                                setFieldValue('districtId', null)
                                getDistricts.request({ params: { regionId: e.target.value } })
                            }}
                        />

                        <SelectInput
                            disabled={!values.regionId || !isEdit}
                            loading={getDistricts.loading}
                            label="District"
                            name="districtId"
                            onChange={(e) => setFieldValue('districtId', e.target.value)}
                            menuItems={districtOpts}
                        />

                        <FormTextField
                            disabled={!isEdit}
                            label="Phone number"
                            name="phone"
                            handleChange={handleChange} />

                        <FormTextField
                            required
                            disabled={!isEdit}
                            label="Email"
                            name="email" />
                    </div>

                    {/* {!initialValues ? ( */}
                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={initialValues ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                    {/* ) : null} */}

                    <button type="submit" style={{ display: 'none' }} id="hiddenSubmitBtn" />
                </Form>
            )}
        </Formik>
    )
}
