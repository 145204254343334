import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import FormTextField from '../../../shared/ui/FormTextField'
import SelectInput from '../../../shared/ui/SelectInput'
import { useGetRequest } from '../../../shared/hooks/requests'
import { ADMIN_MODULES } from '../../../shared/utils/urls'

const formInitialValues = {
    moduleId: '',
    callbackUrl: '',
    identityCallbackUrl: '',
    authType: '',
    username: '',
    password: '',
    token: '',
    tokenType: '',
}

export default function IntegrationForm({
    onSubmit = () => {},
    onClose = () => {},
    isEdit = false,
    initialValues,
}) {
    const getModules = useGetRequest({ url: ADMIN_MODULES })
    const options = getModules.response ? getModules.response.map((m) => ({ value: m.id, label: m.name })) : []

    useEffect(() => {
        if (isEdit)getModules.request()
    }, [])

    return (
        <Formik enableReinitialize initialValues={{
            ...formInitialValues,
            ...initialValues,
        }} onSubmit={onSubmit} initialTouched={{ remote: true }}>
            {({ setFieldValue, handleChange, values }) => (
                <Form className="h-layout flex flex-col p-4 justify-between gap-4">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <SelectInput
                            required
                            loading={getModules.loading}
                            name="moduleId"
                            label="Module id"
                            menuItems={options}
                            value={values.moduleId}
                            onOpen={() => getModules.request()}
                            onChange={(e) => setFieldValue('moduleId', e.target.value)} />

                        <FormTextField
                            required
                            label="Callback url"
                            name="callbackUrl"
                            handleChange={handleChange} />

                        <FormTextField
                            label="Identity callback url"
                            name="identityCallbackUrl"
                            handleChange={handleChange} />

                        <SelectInput
                            required
                            name="authType"
                            label="Auth type"
                            menuItems={[{ value: 'jwt', label: 'JWT' }, { value: 'other', label: 'Other' }]}
                            onChange={(e) => {
                                setFieldValue('authType', e.target.value)
                            }} />

                        {values.authType === 'other' ? (
                            <div className="flex items-center w-full gap-4">
                                <FormTextField
                                    required
                                    fullWidth
                                    label="Username"
                                    name="username"
                                    handleChange={handleChange} />

                                <FormTextField
                                    required
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    handleChange={handleChange} />
                            </div>
                        ) : (
                            <React.Fragment>
                                <FormTextField
                                    required
                                    label="Token"
                                    name="token"
                                    handleChange={handleChange} />

                                <SelectInput
                                    required
                                    name="tokenType"
                                    label="Token type"
                                    menuItems={[
                                        { value: 'Bearer', label: 'Bearer' },
                                        { value: 'other', label: 'Other' },
                                    ]}
                                    onChange={(e) => {
                                        setFieldValue('tokenType', e.target.value)
                                    }} />
                            </React.Fragment>
                        )}
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
