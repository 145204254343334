import React from 'react'
import { Button, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import FormTextField from '../../../shared/ui/FormTextField'
import { usePostRequest } from '../../../shared/hooks/requests'
import { VPN_SETUP_CONFIG } from '../../../shared/utils/urls'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

export const calculateFileSize = (file) => {
    const byteSize = new Blob([file]).size

    const kbSize = (byteSize / 1024).toFixed(2)

    return kbSize
}

export const handleDownloadFile = (code) => {
    const blob = new Blob([code], { type: 'text/x-sh' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'jetson_config.sh'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
}

export default function VpnSetupFileGenerate() {
    const { handleErrorMsg } = useHandleErrors()
    const { handleChange, values, setFieldValue } = useFormikContext()
    const vpnSetupReq = usePostRequest({ url: VPN_SETUP_CONFIG })

    const fetchVpnSetupFile = async () => {
        if (vpnSetupReq.loading) return

        const { success, response, error } = await vpnSetupReq.request({
            data: {
                vpn_server: values.vpnServer,
                vpn_protocol: values.vpnProtocol,
                vpn_port: values.vpnPort,
                jetson_device_id: values.id,
            },
        })

        if (success) {
            setFieldValue('vpnSetup', response)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <div className="flex flex-col gap-3">
            <FormTextField
                fullWidth
                name="vpnServer"
                label="Vpn server"
                handleChange={handleChange} />

            <FormTextField
                fullWidth
                name="vpnProtocol"
                label="Vpn protocol"
                handleChange={handleChange} />

            <FormTextField
                fullWidth
                name="vpnPort"
                label="Vpn port"
                handleChange={handleChange} />

            <div className="w-full flex flex-col gap-5">
                <Typography fontSize="16px" fontWeight="500" fontFamily="Gilroy">
                    Required OneSystem Jetson Manager setup
                </Typography>

                <Button
                    sx={{ backgroundColor: '#F6F6F6', textTransform: 'none', padding: '14px 0' }}
                    onClick={() => {
                        if (values.vpnSetup) {
                            handleDownloadFile(values.vpnSetup)
                        } else {
                            fetchVpnSetupFile()
                        }
                    }}>
                    <Typography
                        fontSize="16px"
                        fontWeight="500"
                        fontFamily="Gilroy"
                        sx={{ textDecoration: 'underline' }}>
                        {values.vpnSetup ? 'Download' : 'Fetch setup file'}
                    </Typography>

                    {values.vpnSetup ? (
                        <span className="text-md ml-2 font-medium text-[#78818A]">
                            {calculateFileSize(values.vpnSetup)} KB
                        </span>
                    ) : null}
                </Button>
            </div>
        </div>
    )
}
