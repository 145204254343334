import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import { COLORS } from '../../../shared/utils/colors'
import Button from '../../../components/common/Button'
import SmartCameraForm from './SmartCameraForm'
import SCameraClientsTable from '../../infrastructure/components/SCameraClientsTable'
import { useGetRequest } from '../../../shared/hooks/requests'
import { ENABLE_RTMP } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import FaceParametres from './FaceParametres'
import ComparisonParameters from './ComparisonParameters'
import UpdatePassword from './UpdatePassword'
import { OthersForm } from './OthersForm'

const navItems = [
    { id: 'detail', name: 'Details' },
    { id: 'wanted', name: 'Blacklist users', count: 0 },
    { id: 'identity', name: 'Identity', count: 0 },
    { id: 'faceparams', name: 'Face Parametres' },
    { id: 'comparison', name: 'Comparison Parameters' },
    { id: 'security', name: 'Security' },
    { id: 'others', name: 'Others' },
]

export default function SmartCameraDetails({ item, onClose, onEdit, loading, setData }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [section, setSection] = useState('detail')
    const enableStream = useGetRequest({ url: ENABLE_RTMP.replace('{id}', item.id) })

    const handleEnableRtmp = async (enable) => {
        if (enableStream.loading) return

        const { success, response, error } = await enableStream.request({ params: { enable } })

        if (success) {
            showMessage(response.message, 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <DrawerLayout
            topic="Smart camera details"
            onClose={onClose}
            name={item.name}
            icons={[]}>
            <React.Fragment>
                <div className={css(s.detailNav)}>
                    <ul className="w-full flex items-center">
                        {navItems.map(((i) => (
                            <li key={i.id} onClick={() => setSection(i.id)}
                                className={css(s.navItem, section === i.id && s.active)}>
                                {i.name}
                            </li>
                        )))}
                    </ul>
                </div>

                <div className="h-layout overflow-y-auto">
                    {section === 'detail' && (
                        <div className="flex items-center gap-2_5 mt-3 ml-2.5">
                            <Button type="button" onClick={() => handleEnableRtmp(true)} className={css(s.actBtn)}>
                                Enable RTMP stream
                            </Button>

                            <Button type="button" onClick={() => handleEnableRtmp(false)} className={css(s.actBtn)}>
                                Disable RTMP stream
                            </Button>
                        </div>
                    )}
                    {section === 'detail' ? (
                        <SmartCameraForm
                            isEdit
                            loading={loading}
                            onClose={onClose}
                            onSubmit={onEdit}
                            initialValues={item} />
                    ) : null}

                    { section === 'faceparams' ? <FaceParametres id={item.id} /> : null}

                    {section === 'comparison' ? <ComparisonParameters id={item.id} /> : null}

                    {section === 'security' ? (
                        <UpdatePassword
                            id={item.id}
                            setData={setData}
                            oldInfo={{
                                username: item.username,
                                password: item.password,
                            }} />
                    ) : null}

                    {section === 'others' ? <OthersForm id={item.id} /> : null}

                    {['wanted', 'identity'].includes(section) ? (
                        <SCameraClientsTable section={section} id={item.id} />
                    ) : null}
                </div>
            </React.Fragment>
        </DrawerLayout>
    )
}

const s = StyleSheet.create({
    detailNav: {
        borderBottom: `1px solid ${COLORS.sepia}`,
        paddingBottom: 1,
    },
    navItem: {
        width: 'fit-content',
        listStyle: 'none',
        padding: '9px 10px',
        fontSize: 14,
        fontWeight: '500',
        position: 'relative',
        color: COLORS.disabled,
        cursor: 'pointer',
        paddingBottom: 8,
        transition: 'color 0.3s ease',
        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -1,
            width: 0,
            borderBottom: `solid 1.5px ${COLORS.mainBlueBg}`,
            transition: 'width 0.3s ease',
        },
        ':hover': {
            color: COLORS.mainBlueBg,
        },
        ':hover::after': {
            width: '100%',
        },
    },
    active: {
        color: COLORS.mainBlueBg,
        ':after': {
            width: '100%',
        },
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '8px 12px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        transition: 'all 0.3s linear',
    },
    detailCont: {
        paddingTop: 9,
        height: 'calc(100% - 10rem)',
        overflowY: 'scroll',
    },
})
