import { COLORS } from './colors'
import { hexToRGBA } from './string'

export const minMax = (points) => points.reduce(
    (acc, val) => {
        acc[0] = val < acc[0] ? val : acc[0]
        acc[1] = val > acc[1] ? val : acc[1]
        return acc
    },
    [Infinity, -Infinity],
)

export const getMiddlePoint = (points) => {
    const x = points.reduce((acc, val) => acc + val.x, 0) / points.length
    const y = points.reduce((acc, val) => acc + val.y, 0) / points.length
    return { x, y }
}

export const isPolygonClosed = (points) => points.length >= 3
// const first = points[0];
// const last = points[points.length - 1];
// return first[0] === last[0] && first[1] === last[1];

export const defaultColors = {
    vertexRadius: 2.5,
    lineColor: '#0B9E34',
    fillColor: hexToRGBA('#0B9E34', '0.30'),
    vertexColor: COLORS.white,
    vertexStrokeWidth: 1,
}
