import React from 'react'
import { Increase, Decrease } from '../../../components/svgs/Svgs'

export function CompareCard({ icon: IconComponent, cardTitle, data, newName }) {
    // Create a mapping of IDs to names from newName array
    const idToNameMap = newName.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
    }, {}) || {}

    const renderData = () => {
        if (!data || typeof data !== 'object') {
            return null
        }

        // Separate the data into two groups: single-value and two-value arrays
        const singleValueData = []
        const twoValueData = []

        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length === 2) {
                twoValueData.push({ key, value })
            } else {
                singleValueData.push({ key, value })
            }
        })

        // Combine the arrays with single-value data first
        const sortedData = [...singleValueData, ...twoValueData]

        return sortedData.map(({ key, value }) => {
            // Get the name from the idToNameMap based on the key
            const branchName = idToNameMap[key] || key

            if (Array.isArray(value) && value.length === 2) {
                return (
                    <div key={key} className="flex w-[100%] items-center gap-[10px]">
                        <span className="font-bold whitespace-nowrap">{branchName.slice(0, 40)}:</span>
                        <span className="text-branchOrange text-[14px] font-normal">{value[0]}</span>
                        <div className="flex items-center gap-[2px]">
                            <span
                                className={`${value[1] > 0 ? 'text-green-500' : 'text-red-500'}`}>{value[1]}%
                            </span>
                            {value[1] > 0 ? <Increase /> : <Decrease />}
                        </div>
                    </div>
                )
            }
            return (
                <div key={key} className="flex w-[100%] items-center gap-[10px]">
                    <span className="font-bold whitespace-nowrap">{branchName.slice(0, 40)}:</span>
                    <span className="text-branchBlue text-[14px] font-normal">{value}</span>
                </div>
            )
        })
    }

    return (
        <div className="w-[100%] h-auto flex flex-col items-start
         gap-[35px] pt-[12px] pb-[14px] px-[12px] border rounded-[14px] border-borderColor bg-white">
            <div className="flex flex-col items-start gap-[13px]">
                <div className="py-[11px] px-[12px] bg-svgBlue rounded-[10px]">
                    {IconComponent && <IconComponent width={21} height={21} />}
                </div>
                <span className="text-[21px] font-semibold text-black">{cardTitle}</span>
            </div>
            <div className="flex items-center w-[100%] gap-[10px] justify-start">
                <div className="flex items-center w-[100%] flex-nowrap overflow-x-scroll gap-[65px]">
                    {renderData()}
                </div>
            </div>
        </div>
    )
}
