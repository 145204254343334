import React, { useEffect, useRef, useState } from 'react'
import { Line, Circle, Group, Text, Rect, Image as KonvaImage, Arrow } from 'react-konva'
import { useImage } from 'react-konva-utils'
import { minMax, getMiddlePoint } from '../../../shared/utils/polygonUtils'
import trashIcon from '../../../static/images/trash.png'

export default function Polygon({
    points,
    colors,
    flattenedPoints,
    isFinished = false,
    showLabel = false,
    label = 'Polygon',
    polygonInd,
    lineCrossing = false,
    handleDelete = () => {},
    handlePointDragEnd = () => {},
    handleGroupDragEnd = () => {},
    handleMouseOverStartPoint = () => {},
    handleMouseOutStartPoint = () => {},
    handlePointDragMove = () => {},
}) {
    const [stageObject, setStageObject] = useState(null)
    const [minMaxX, setMinMaxX] = useState([0, 0])
    const [minMaxY, setMinMaxY] = useState([0, 0])
    const [textDimensions, setTextDimensions] = useState({ width: 0, height: 0 })

    const textRef = useRef(null)

    const handleGroupMouseOver = (e) => {
        const stage = e.target.getStage()
        if (!isFinished || !stage) return
        stage.container().style.cursor = 'pointer'
        setStageObject(stage)
    }

    const handleGroupMouseOut = (e) => {
        const stage = e.target.getStage()
        if (!stage) return
        stage.container().style.cursor = 'default'
    }

    const handleGroupDragStart = () => {
        const arrX = points.map((p) => p.x)
        const arrY = points.map((p) => p.y)
        setMinMaxX(minMax(arrX))
        setMinMaxY(minMax(arrY))
    }

    const groupDragBoundFunc = (pos) => {
        let { x, y } = pos
        if (!stageObject) return { x, y }
        const sw = stageObject.width()
        const sh = stageObject.height()
        if (minMaxY[0] + y < 0) y = -1 * minMaxY[0]
        if (minMaxX[0] + x < 0) x = -1 * minMaxX[0]
        if (minMaxY[1] + y > sh) y = sh - minMaxY[1]
        if (minMaxX[1] + x > sw) x = sw - minMaxX[1]
        return { x, y }
    }

    const vertexDragBoundFunc = (pos) => {
        let { x, y } = pos
        if (!stageObject) return { x, y }
        const sw = stageObject.width()
        const sh = stageObject.height()
        if (x > sw) x = sw
        if (x < 0) x = 0
        if (y > sh) y = sh
        if (y < 0) y = 0
        return { x, y }
    }

    const [iconImage] = useImage(trashIcon)

    useEffect(() => {
        if (textRef.current) {
            const textNode = textRef.current
            setTextDimensions({
                width: textNode.width(),
                height: textNode.height(),
            })
        }
    }, [label, isFinished])

    const padding = { top: 10, bottom: 10, left: 12, right: 12 }
    const iconSize = 24
    const gap = 24

    const containerWidth = textDimensions.width + iconSize + padding.left + padding.right + gap
    const containerHeight = Math.max(textDimensions.height, iconSize) + padding.top + padding.bottom

    const calculateArrowRotation = () => {
        if (points.length < 2) return 0
        const startX = points[points.length - 2].x
        const startY = points[points.length - 2].y
        const endX = points[points.length - 1].x
        const endY = points[points.length - 1].y
        const angle = Math.atan2(endY - startY, endX - startX) * (180 / Math.PI)
        return angle
    }

    return (
        <Group
            name="polygon"
            draggable={isFinished}
            onDragStart={handleGroupDragStart}
            onDragEnd={handleGroupDragEnd}
            dragBoundFunc={groupDragBoundFunc}
            onMouseOver={handleGroupMouseOver}
            onMouseOut={handleGroupMouseOut}
        >
            <Line
                key={polygonInd}
                name="line"
                points={flattenedPoints}
                stroke={colors.lineColor}
                strokeWidth={1}
                closed={isFinished}
                fill={colors.fillColor}
            />
            {points.map((point, index) => {
                const { x, y } = point
                const startPointAttr = index === 0
                    ? {
                        onMouseOver: handleMouseOverStartPoint,
                        onMouseOut: handleMouseOutStartPoint,
                    }
                    : null
                return (
                    <Circle
                        name="vertex"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        x={x}
                        y={y}
                        radius={colors.vertexRadius}
                        fill={colors.vertexColor}
                        stroke={colors.lineColor}
                        strokeWidth={colors.vertexStrokeWidth}
                        draggable
                        onDragMove={handlePointDragMove}
                        onDragEnd={handlePointDragEnd}
                        dragBoundFunc={vertexDragBoundFunc}
                        {...startPointAttr}
                    />
                )
            })}
            {lineCrossing && polygonInd === 1 && points.length > 1 && (
                <Arrow
                    fill={colors.lineColor}
                    x={points[points.length - 1].x}
                    y={points[points.length - 1].y}
                    rotation={calculateArrowRotation()}
                    offsetX={-7}
                    offsetY={0}
                />
            )}
            {showLabel && (
                <Group
                    x={getMiddlePoint(points).x}
                    y={getMiddlePoint(points).y - 40}
                >
                    <Rect
                        width={containerWidth}
                        height={containerHeight}
                        fill={colors.fillColor}
                        stroke={colors.fillColor}
                        strokeWidth={2}
                        cornerRadius={8}
                        offsetX={containerWidth / 2}
                        offsetY={containerHeight / 2}
                    />
                    <Text
                        name={`Text-${label}`}
                        ref={textRef}
                        text={label}
                        fontSize={16}
                        fill="white"
                        x={-containerWidth / 2 + padding.left}
                        y={-textDimensions.height / 2}
                    />
                    <KonvaImage
                        image={iconImage}
                        x={containerWidth / 2 - padding.right - iconSize / 2}
                        y={0}
                        width={iconSize}
                        height={iconSize}
                        offsetX={iconSize / 2}
                        offsetY={iconSize / 2}
                        onClick={handleDelete}
                    />
                </Group>
            )}
        </Group>
    )
}
