import React from 'react'
import Paper from '@mui/material/Paper'
import { Grid, IconButton, Typography } from '@mui/material'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { Search } from '@mui/icons-material'
import CameraFilter from '../../../shared/filter/CameraFilter'
import { BUILDINGS_TENANT, JETSON_DEVICES, ROOMS_TENANT_UNPAGINATED } from '../../../shared/utils/urls'
import { COLORS } from '../../../shared/utils/colors'
import Create from '../features/Create'
import { useInfiniteScroll } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import CardItem from './CardItem'

export default function JetsonDevices() {
    const { cameraFilter } = useFilter()
    const loadJetsonDevices = useInfiniteScroll({
        url: JETSON_DEVICES,
        params: { roomId: cameraFilter.rId },
    }, Boolean(cameraFilter.rId), [cameraFilter.rId])
    const data = loadJetsonDevices.response ? loadJetsonDevices.response.items : []

    return (
        <div className="flex flex-col gap-5">
            <CameraFilter buildingUrl={BUILDINGS_TENANT} roomUrl={ROOMS_TENANT_UNPAGINATED} />

            <Paper square sx={{ padding: '8px 16px 16px 16px', minHeight: '30vh' }}>
                <div className="flex items-center justify-between pb-2">
                    <Typography className="flex-cent gap-1.5 text-m font-600">
                        Jetson Devices <HelpRoundedIcon htmlColor={COLORS.gray} />
                    </Typography>

                    <div className="flex-cent gap-2">
                        {cameraFilter.rId && <Create setData={loadJetsonDevices.setResponse} />}

                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>

                {data.length ? (
                    <Grid container boxSizing="border-box" spacing={2}>
                        {data.map((item) => (
                            <CardItem key={item.id} item={item} setData={loadJetsonDevices.setResponse} />
                        ))}
                    </Grid>
                ) : (
                    <div className="w-full h-[28vh] flex flex-col items-center justify-center">
                        <Typography>No Jetson devices yet</Typography>
                    </div>
                )}
            </Paper>
        </div>
    )
}
