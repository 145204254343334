import React from 'react'
import { Dialog, DialogContent } from '@mui/material'
import CancelBtn from '../../../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../../../shared/ui/PrimaryBtn'
import useHandleErrors from '../../../../../shared/hooks/handleErrorMessage'
import { useMessage } from '../../../../../shared/hooks/message'
import { useDeleteRequest } from '../../../../../shared/hooks/requests'
import { ROOMS_TENANT } from '../../../../../shared/utils/urls'

export default function Delete({ id, setData, isOpen, close }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const deleteBuilding = useDeleteRequest()

    const remove = async () => {
        if (deleteBuilding.loading || !id) return

        const { success, error } = await deleteBuilding.request({ url: `${ROOMS_TENANT}/${id}` })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.filter((v) => v.id !== id),
            }))
            showMessage('Successfully deleted', 'success-msg')
            close()
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Dialog fullWidth open={isOpen} onClose={close}>
            <DialogContent
                style={{ padding: 20 }}
                sx={{ '& .MuiPaper-root': { width: 'fit-content' } }}>
                <div className="flex min-h-40 flex-col items-center justify-between">
                    <div className="w-full flex-col items-center justify-center gap-6">
                        <h3 className="text-xl font-600">Are you sure you want to delete?</h3>

                        <h3 className="text-l font-400 text-center">
                            Confirm Deletion This Action Is Irreversible
                        </h3>
                    </div>

                    <div className="flex-cent gap-2_5">
                        <CancelBtn type="button" title="Cancel" onClick={close} />

                        <PrimaryBtn type="button" title="Delete" onClick={remove} />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
