import React, { useEffect, useState } from 'react'
// eslint-disable-next-line
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import axios from 'axios'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import ToggleSwitch from '../../../shared/ui/ToggleSwitch'
import FormTextField from '../../../shared/ui/FormTextField'
import { domain } from '../../../shared/utils/request'
import { SETVPN } from '../../../shared/utils/urls'
import { auth } from '../../../shared/utils/auth'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

export default function Vpn({ id, initialValues }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [isVpnEnabled, setIsVpnEnabled] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setIsVpnEnabled(initialValues.Enable === 0)
    }, [initialValues])

    const FORM_INITIAL_STATE = {
        DeviceIp: '',
        UserName: '',
        Password: '',
    }

    const VALIDATION_SCHEMA = object().shape({
        DeviceIp: string().required('Device IP is required'),
        UserName: string().required('User name is required'),
        Password: string().required('Password is required'),
    })

    const update = async (values) => {
        if (loading) return

        setLoading(true)

        const filteredValues = {
            enable: isVpnEnabled,
            ip_address: values.DeviceIp,
            vpn_username: values.UserName,
            vpn_password: values.Password,
        }

        try {
            const { data } = await axios.post(
                domain + SETVPN,
                filteredValues,
                { ...auth(), params: { smart_camera_id: id } },
            )
            if (data.success) {
                showMessage(data.message, 'success-msg')
            }
        } catch (error) {
            handleErrorMsg(error.response)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
                OnlineTime: initialValues.Status && initialValues.Status.OnlineTime
                    ? initialValues.Status.OnlineTime : 'seconds',
                IpAddr: initialValues.Status && initialValues.Status.IpAddr ? initialValues.Status.IpAddr : 'second',
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={update}
        >
            {({ handleChange }) => (
                <Form>
                    <CustomAccordion
                        summary="VPN"
                        contClassName="border-[1px] border-sepia-100"
                        expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
                        overrideStyles={{
                            ':before': {
                                display: 'none',
                            },
                            boxShadow: 'none',
                            borderRadius: '3px',
                        }}>
                        <div className="flex flex-col gap-2">
                            <div className="flex w-[100%] justify-between items-start gap-2">
                                <div className="flex w-[50%] flex-col items-start gap-[20px]">
                                    <div className="flex items-center gap-[21px]">
                                        <span className="text-[14px] font-normal text-black">Enable</span>
                                        <ToggleSwitch
                                            checked={isVpnEnabled}
                                            onClick={() => setIsVpnEnabled((prevState) => !prevState)}
                                        />
                                    </div>
                                    <div className="flex items-center  justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">Server Address:
                                        </span>
                                        <FormTextField
                                            name="DeviceIp"
                                            type="text"
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center  justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">User Name:
                                        </span>
                                        <FormTextField
                                            name="UserName"
                                            type="text"
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center  justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">Password:
                                        </span>
                                        <FormTextField
                                            name="Password"
                                            type="password"
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex w-[50%] flex-col items-start gap-[20px]">
                                    <div className="flex items-center w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">Status:
                                        </span>
                                        <div type="text" className="py-[8px] cursor-pointer bg-[#EDEDED]
                                        text-[#7C7D7E] px-[18px] border
                                     border-[#EDEDED] outline-none rounded-[6px]" >{initialValues.RespType}
                                        </div>
                                    </div>
                                    <div className="flex items-center  justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">Online Time:
                                        </span>
                                        <FormTextField
                                            name="OnlineTime"
                                            type="text"
                                            disabled
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center  justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">IP:
                                        </span>
                                        <FormTextField
                                            name="IpAddr"
                                            type="text"
                                            disabled
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-[100%] flex justify-end">
                                <PrimaryBtn
                                    type="submit"
                                    title="Save"
                                    loading={loading}
                                    disabled={loading}
                                    overrideStyles={{
                                        width: '100%',
                                        paddingY: '12px',
                                        fontSize: '14px',
                                    }}
                                />
                            </div>
                        </div>
                    </CustomAccordion>
                </Form>
            )}
        </Formik>
    )
}
