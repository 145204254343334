import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

function CustomAccordion({
    summary,
    children,
    expandIcon,
    overrideStyles,
    detailClassName,
    contClassName,
    summaryClassName,
    onClickSummary,
    action,
    summaryStyles,
    ...otherProps
}) {
    return (
        <Accordion sx={{
            ...overrideStyles,
        }} className={contClassName} {...otherProps}>
            <AccordionSummary
                expandIcon={expandIcon}
                onClick={onClickSummary}
                className={summaryClassName}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                    '& .MuiAccordionSummary-content': {
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        ...summaryStyles,
                    },
                }}
            >
                {summary}

                <div className="w-full flex items-end justify-end">
                    {action}
                </div>
            </AccordionSummary>
            <AccordionDetails className={detailClassName}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default CustomAccordion
