import React from 'react'

export function AnalyticCard({ component: Component, sm, cardTitle, data, isActive }) {
    return (
        <div className={`w-[100%]  ${sm ? ' border border-borderColor h-[232px] py-[5px] px-[7px]'
            : 'py-[12px] h-[100%] px-[15px]'} flex flex-col items-start gap-[2px]  bg-white rounded-[14px]`}>
            <div className="w-[100%] flex items-center justify-between">
                <span className={`${sm ? 'text-[12px]' : 'text-[18px]'} font-medium text-black`}>{cardTitle}</span>
                {isActive ? (
                    <div className="flex items-center gap-[10px]">
                        <div className="flex items-center gap-[5px]">
                            <div className={`${sm ? 'w-[6px] h-[6px]'
                                : 'w-[12px] h-[12px]'} rounded-[50%] bg-menColor`} />
                            <span className={`${sm ? 'text-[12px]'
                                : 'text-[14px]'} font-normal text-black`}>Erkaklar
                            </span>
                        </div>
                        <div className="flex items-center gap-[5px]">
                            <div className={`${sm ? 'w-[6px] h-[6px]'
                                : 'w-[12px] h-[12px]'} rounded-[50%] bg-womenColor`} />
                            <span className={`${sm ? 'text-[12px]'
                                : 'text-[14px]'} font-normal text-black`}>Ayollar
                            </span>
                        </div>
                        <div className="flex items-center gap-[5px]">
                            <div className={`${sm ? 'w-[6px] h-[6px]'
                                : 'w-[12px] h-[12px]'} rounded-[50%] bg-undefinedColor`} />
                            <span className={`${sm ? 'text-[12px]'
                                : 'text-[14px]'} font-normal text-black`}>noaniq
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center gap-[5px]">
                        <div className={`${sm ? 'w-[6px] h-[6px]'
                            : 'w-[12px] h-[12px]'} rounded-[50%] bg-visitorBlue`} />
                        <span className="text-[14px] font-normal text-black">Tashrif buyuruvchilar</span>
                    </div>
                )}

            </div>
            <div className="w-[100%] h-auto">
                {Component && <Component items={data} sm={sm} />}
            </div>
        </div>
    )
}
