import React, { useState } from 'react'
import DrawerLayout from '../../../../app/layouts/DrawerLayout'
import BuildingForm from './BuildingForm'

const navItems = [
    { id: 'details', name: 'Details' },
]

export default function BuildingDetails({ item, onEdit, onClose, loading }) {
    const [section, setSection] = useState('details')

    const initialValues = {
        name: item.name || '',
        description: item.description || '',
        city: item.city || '',
        state: item.state || '',
        tenantEntityId: item.tenantEntityId || '',
        address: item.address || '',
        latitude: item.latitude || '',
        longitude: item.longitude || '',
    }

    return (
        <DrawerLayout
            topic="Building details"
            onClose={onClose}
            name={item.name}
            icons={[]}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.id)}
                                className={
                                    `cursor-pointer text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.id ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                <BuildingForm
                    isEdit
                    loading={loading}
                    onSubmit={onEdit}
                    onClose={onClose}
                    initialValues={initialValues} />
            </React.Fragment>
        </DrawerLayout>
    )
}
