import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../../shared/utils/colors'
import { useMessage } from '../../../../shared/hooks/message'
import useHandleErrors from '../../../../shared/hooks/handleErrorMessage'
import { FIRMWARE_URL } from '../../../../shared/utils/urls'
import FirmwareDetails from '../components/FirmwareDetails'
import { usePutRequest } from '../../../../shared/hooks/requests'

export default function Update({ setData, item }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [openDrawer, setOpenDrawer] = useState(false)
    const updateFirmware = usePutRequest({ url: `${FIRMWARE_URL}/${item.id}` })

    const close = () => setOpenDrawer(false)

    const update = async (values) => {
        if (!values || updateFirmware.loading) return
        const { success, error } = await updateFirmware.request({ data: { name: values.name,
            description: values.description } })

        if (success) {
            showMessage('Module updated', 'success-msg')
            setData((prev) => prev.map((i) => (i.id === item.id ? { ...i, ...values } : i)))
            close()
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpenDrawer(true)}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '45rem' } }}
                anchor="right"
                open={openDrawer}
                onClose={close}>
                <FirmwareDetails
                    onEdit={update}
                    onClose={close}
                    loading={updateFirmware.loading}
                    item={item} />
            </Drawer>
        </>
    )
}
