import React, { useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import Paper from '@mui/material/Paper'
import { useLoad } from '../../../shared/hooks/requests'
import { COLORS } from '../../../shared/utils/colors'
import Table from '../../../shared/ui/Table'
import { JETSON_PROFILE_LIST } from '../../../shared/utils/urls'
import JetsonProfileTableItem from './JetsonProfileTableItem'
import Create from '../features/Create'

const headerItems = [
    { id: 1, title: 'Name' },
    { id: 2, title: 'Username' },
    { id: 3, title: 'Password' },
    { id: 4, title: 'Cuda version', width: '10%' },
    { id: 5, title: 'Deepstream version', width: '15%' },
    { id: 6, title: 'Jetpack version', width: '10%' },
    { id: 7, title: 'Created time', width: '15%' },
    { id: 8, title: 'Action', width: '7%', align: 'right' },
]

export default function JetsonProfileTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const loadJetsonProfiles = useLoad(
        { url: JETSON_PROFILE_LIST,
            params: { page, size: pageSize } },
        [page, pageSize],
    )
    const data = loadJetsonProfiles.response ? loadJetsonProfiles.response.items : []
    const meta = loadJetsonProfiles.response ? loadJetsonProfiles.response : {}

    return (
        <Paper square>
            <div className="flex items-center justify-between h-[60px] px-4">
                <Typography className="flex-cent gap-1_5 text-m font-600">
                    Jetson Device profiles <HelpRoundedIcon htmlColor={COLORS.gray} />
                </Typography>

                <div className="flex-cent gap-2">
                    <Create setData={loadJetsonProfiles.setResponse} />

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            <Table
                page={page - 1}
                loading={loadJetsonProfiles.loading}
                totalCount={meta.total}
                rowsPerPage={pageSize}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10))
                    setPage(1)
                }}
                cols={headerItems}
                rows={data}
                paginated={meta.total}
                renderItem={(item) => (
                    <JetsonProfileTableItem
                        item={item}
                        key={item.id}
                        setData={loadJetsonProfiles.setResponse}
                    />
                )}
            />
        </Paper>
    )
}
