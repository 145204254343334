import React, { useEffect, useState } from 'react'
import { Button, MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { Form, Formik } from 'formik'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import uuid from 'react-uuid'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import humps from 'humps'
import { usePostRequest } from '../../../shared/hooks/requests'
import { ADD_DEEPSTREAM_CONFIG_GROUP } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { NewConfigForm } from './NewConfigForm'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

const FORM_INITIAL_STATE = {
    configGroup: [{
        id: 1,
        name: 'Group 01',
        properties: [{ id: 1, key: '', value: '' }],
    }],
}

const VALIDATION_SCHEMA = Yup.object().shape({
    configGroup: Yup.array().of(
        Yup.object().shape({
            properties: Yup.array().of(
                Yup.object().shape({
                    key: Yup.string()
                        .required('Key is required')
                        .min(1, 'Key must be at least 1 character long'),
                    value: Yup.string()
                        .required('Value is required')
                        .min(1, 'Value must be at least 1 character long'),
                }),
            ),
        }),
    ),
})

export default function GeneralConfigForm({ appName, version, getConfigs, configs }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [initialValues, setInitialValues] = useState(FORM_INITIAL_STATE)
    const [configType, setConfigType] = useState('')
    const { jetsonFilter: { jetsonDeviceId } } = useFilter()
    const addConfigGroup = usePostRequest({ url: ADD_DEEPSTREAM_CONFIG_GROUP })
    const configOptions = Object.values(configs).length
        ? Object.keys(configs).map((key) => ({ value: key, label: key })) : []

    useEffect(() => {
        if (Object.values(configs).length && configType) {
            const selectedConfig = configs[configType]

            const configGroup = Object.keys(selectedConfig).map((groupName) => ({
                id: uuid(),
                name: groupName,
                isCreated: true,
                properties: Object.keys(selectedConfig[groupName]).map((key) => ({
                    id: uuid(),
                    key,
                    value: selectedConfig[groupName][key],
                })),
            }))

            setInitialValues((prev) => ({
                ...prev,
                configGroup,
            }))
        }
    }, [configType])

    const handleAddNewConfig = (field, setFieldValue, values) => {
        const newGroup = { id: uuid(), name: '', properties: [{ key: '', value: '' }] }
        setFieldValue(field, [...values[field], newGroup])
    }

    const submitNewConfig = async (group) => {
        if (addConfigGroup.loading) return

        const objectedProperties = group.properties.reduce((acc, { key, value }) => {
            acc[key] = value
            return acc
        }, {})

        const { success, response, error } = await addConfigGroup.request({ data: {
            applicationName: appName,
            applicationVersion: version,
            jetsonDeviceId,
            configFileName: humps.decamelize(configType),
            configGroupName: humps.decamelize(group.name, { separator: '-' }),
            groupProperties: humps.decamelizeKeys(objectedProperties, { separator: '-' }),
        } })

        if (success) {
            if (response.status === 'success') {
                getConfigs.request({
                    data: {
                        applicationName: appName,
                        applicationVersion: version,
                        jetsonDeviceId,
                    },
                })
            }
            showMessage(response.description, response.status === 'success' ? 'success-msg' : 'error-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={submitNewConfig}>
            {({ values, setFieldValue, handleChange, errors }) => (
                <Form className="p-5 flex flex-col gap-3.5 overflow-scroll h-layout">
                    <Select
                        variant="outlined"
                        displayEmpty
                        sx={{ height: 37, width: '12rem' }}
                        value={configType}
                        renderValue={(value) => (value ? configOptions.find((v) => v.value === value)
                            && configOptions.find((v) => v.value === value).label : 'Choose config file')}
                        onChange={(e) => setConfigType(e.target.value)}
                    >
                        {!getConfigs.loading && configOptions.length ? configOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem.label}
                            </MenuItem>
                        )) : getConfigs.loading ? (
                            <MenuItem>
                                <Skeleton width="100%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>

                    {configType ? values.configGroup.map((group, index) => (
                        <div className="flex flex-col items-end w-full gap-4">
                            <NewConfigForm
                                idx={index}
                                group={group}
                                errors={errors}
                                appName={appName}
                                values={values}
                                version={version}
                                configType={configType}
                                setFieldValue={setFieldValue}
                                key={group.id}
                                handleChange={handleChange} />

                            {!group.isCreated ? (
                                <PrimaryBtn
                                    onClick={() => submitNewConfig(values.configGroup[index])}
                                    disabled={!isEmpty(errors) || !group.properties.length}
                                    loading={addConfigGroup.loading}
                                    title="Set config"
                                    type="button" />
                            ) : null}
                        </div>
                    )) : null}

                    {isEmpty(errors) && configType ? (
                        <Button
                            sx={{ marginTop: 2, display: 'flex', alignItems: 'center', gap: 1 }}
                            onClick={() => handleAddNewConfig('configGroup', setFieldValue, values)}>
                            <ControlPointIcon />

                            Add new config group
                        </Button>
                    ) : null}
                </Form>
            )}
        </Formik>
    )
}
