import React from 'react'
import { Select, MenuItem } from '@mui/material'

function MasterUserFilter({ items, onChange, value, placeholder, showDefaultOption = false }) {
    const options = showDefaultOption
        ? [{ value: '-', label: '-' }, ...items.map((item) => ({
            value: item.id.toString(),
            label: item.clientName || item.username || item.fullName || item.apiType,
        }))]
        : items.map((item) => ({
            value: item.id.toString(),
            label: item.clientName || item.username || item.fullName || item.apiType,
        }))

    return (
        <Select
            value={value}
            onChange={(e) => onChange(e)}
            displayEmpty
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <em>{placeholder}</em>
                }

                const selectedOption = options.find((option) => option.value === selected)
                return selectedOption ? selectedOption.label : placeholder
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            className="w-[150px] h-[46px]"
        >
            {options.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </Select>
    )
}

export default MasterUserFilter
