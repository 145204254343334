import React, { useState } from 'react'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import { Dialog, DialogContent, Grid, IconButton } from '@mui/material'
import { Close, Fullscreen } from '@mui/icons-material'
import inactiveImg from '../../../static/images/inactive-camera.png'
import Delete from '../features/Delete'
import Update from '../features/Update'
import { COLORS } from '../../../shared/utils/colors'

export default function SnapshotCard({ item, setData }) {
    const [showTools, setShowTools] = useState(false)
    const [open, setOpen] = useState(false)

    return (
        <Grid item sm={12} md={6} lg={4} className="flex-col gap-3 relative min-w-[18rem] h-[250px]">
            <div onMouseEnter={() => setShowTools(true)}
                onMouseLeave={() => setShowTools(false)}
                className={cn(css(s.cont), 'relative w-full')}>
                {item.snapshots.length && item.snapshots[0].snapshotUrl ? (
                    <div className={bgStyle(item.snapshots[0].snapshotUrl)} />
                ) : (
                    <div className={cn(css(s.inactive), 'flex-cent')}>
                        <img src={inactiveImg} alt="#INACTIVE" />
                    </div>
                )}

                <div className={cn(
                    css(s.hoverTools, showTools ? s.activeTools : ''),
                    'flex items-center justify-center gap-2 absolute',
                )}>
                    <Update item={item} setData={setData} />

                    <Delete id={item.id} setData={setData} />
                </div>

                <div className={cn(css(s.btmTools), 'flex items-center justify-between')}>
                    <h3 className="text-l text-white font-600">{item.name}</h3>

                    <IconButton onClick={() => setOpen(true)}>
                        <Fullscreen htmlColor={COLORS.white} />
                    </IconButton>

                    <Dialog
                        fullWidth
                        open={open}
                        onClose={() => setOpen(false)}
                        sx={{ '& .MuiPaper-root': {
                            backgroundColor: 'transparent',
                            maxWidth: 'fit-content',
                            maxHeight: '100%',
                        } }}>
                        <DialogContent sx={{ padding: 0, borderRadius: '8px', backgroundColor: 'transparent' }}>
                            <div className="flex items-center justify-between py-1 pl-3 pr-1
                                bg-main-100 text-white text-lg font-medium">
                                <h3>Create Camera</h3>

                                <IconButton onClick={() => setOpen(false)}>
                                    <Close htmlColor="white" />
                                </IconButton>
                            </div>

                            <div className="w-full h-full flex items-center justify-center p-4 bg-white">
                                <img
                                    alt="SNAPSHOT"
                                    className="object-cover"
                                    src={item.snapshots[0] && item.snapshots[0].snapshotUrl} />
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </Grid>
    )
}

const s = StyleSheet.create({
    card: {
        flex: 1,
        minWidth: '18rem',
        height: 250,
    },
    hoverTools: {
        top: '100%',
        width: '100%',
        height: '100%',
        background: 'rgba(48, 86, 128, 0.80)',
        transition: 'all linear .1s',
    },
    cont: {
        overflow: 'hidden',
        height: '100%',
    },
    activeTools: {
        top: 0,
    },
    inactive: {
        height: 'inherit',
        backgroundColor: '#888',
    },
    btmTools: {
        padding: 14,
        boxSizing: 'border-box',
        position: 'absolute',
        width: 'inherit',
        height: 50,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
    },
})

const bgStyle = (url) => css(StyleSheet.create({
    bg: {
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: 'inherit',
        height: 'inherit',
    },
}).bg)
