import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import axios from 'axios'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import ToggleSwitch from '../../../shared/ui/ToggleSwitch'
import FormTextField from '../../../shared/ui/FormTextField'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { SETIPSETTING } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import { domain } from '../../../shared/utils/request'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { auth } from '../../../shared/utils/auth'

export default function IPSetting({ initialValues, id }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [isDhcpEnabled, setIsDhcpEnabled] = useState(false)
    const [isDnsEnabled, setIsDnsEnabled] = useState(false)
    const [loading, setLoading] = useState(false)

    // Sync toggle state with initial values
    useEffect(() => {
        setIsDhcpEnabled(initialValues.DHCP === 1)
        setIsDnsEnabled(initialValues.ManualDns === 1)
    }, [initialValues])

    const FORM_INITIAL_STATE = {
        IP: '',
        SubnetMask: '',
        Gateway: '',
        DNS: '',
        DNS2: '',
        DeviceMac: '',
        WebPort: '',
    }

    const VALIDATION_SCHEMA = object().shape({
        IP: string().required('IP address is required'),
        SubnetMask: string().required('Subnet mask is required'),
        Gateway: string().required('Gateway is required'),
        DNS: string().required('First DNS is required'),
        DNS2: string().required('Second DNS is required'),
        DeviceMac: string().required('MAC address is required'),
        WebPort: string().required('Web port is required'),
    })

    const update = async (values) => {
        if (loading) return

        setLoading(true)

        const filteredValues = {
            DHCP: isDhcpEnabled ? 1 : 0,
            IP: values.IP,
            subnet_mask: values.SubnetMask,
            gateway: values.Gateway,
            manual_dns: isDnsEnabled ? 1 : 0,
            DNS: values.DNS,
            DNS2: values.DNS2,
            device_mac: values.DeviceMac,
            webPort: values.WebPort,
        }

        try {
            const { data } = await axios.post(
                domain + SETIPSETTING,
                filteredValues,
                { ...auth(), params: { smart_camera_id: id } },
            )
            if (data.success) {
                showMessage(data.message, 'success-msg')
            }
        } catch (error) {
            handleErrorMsg(error.response)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={update}
        >
            {({ handleChange }) => (
                <Form>
                    <CustomAccordion
                        summary="IP settings"
                        contClassName="border-[1px] border-sepia-100"
                        expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
                        overrideStyles={{
                            ':before': {
                                display: 'none',
                            },
                            boxShadow: 'none',
                            borderRadius: '3px',
                        }}
                    >
                        <div className="flex flex-col gap-2">
                            <div className="flex w-[100%] justify-between items-start gap-2">
                                {/* First Toggle Section */}
                                <div className="flex w-[50%] flex-col items-start gap-[20px]">
                                    <div className="flex items-center gap-[21px]">
                                        <span className="text-[14px] font-normal text-black">DHCP</span>
                                        <ToggleSwitch
                                            isChecked={isDhcpEnabled}
                                            onClick={() => setIsDhcpEnabled((prevState) => !prevState)}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap font-normal text-black">
                                            IP Address:
                                        </span>
                                        <FormTextField
                                            name="IP"
                                            type="text"
                                            disabled={isDhcpEnabled}
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap font-normal text-black">
                                            Subnet Mask:
                                        </span>
                                        <FormTextField
                                            name="SubnetMask"
                                            type="text"
                                            disabled={isDhcpEnabled}
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap font-normal text-black">
                                            Gateway:
                                        </span>
                                        <FormTextField
                                            name="Gateway"
                                            type="text"
                                            disabled={isDhcpEnabled}
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* Second Toggle Section */}
                                <div className="flex w-[50%] flex-col items-start gap-[20px]">
                                    {isDhcpEnabled && (
                                        <div className="flex items-center gap-[21px]">
                                            <span className="text-[14px] font-normal text-black">
                                                Automatically Get The DNS
                                            </span>
                                            <ToggleSwitch
                                                isChecked={isDnsEnabled}
                                                onClick={() => setIsDnsEnabled((prevState) => !prevState)}
                                            />
                                        </div>
                                    )}
                                    <div className="flex items-center justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap font-normal text-black">
                                            First DNS:
                                        </span>
                                        <FormTextField
                                            name="DNS"
                                            type="text"
                                            disabled={isDhcpEnabled && isDnsEnabled}
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap font-normal text-black">
                                            Second DNS:
                                        </span>
                                        <FormTextField
                                            name="DNS2"
                                            type="text"
                                            disabled={isDhcpEnabled && isDnsEnabled}
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap font-normal text-black">
                                            MAC:
                                        </span>
                                        <FormTextField
                                            name="DeviceMac"
                                            type="text"
                                            disabled
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap font-normal text-black">
                                            Web Port:
                                        </span>
                                        <FormTextField
                                            name="WebPort"
                                            type="text"
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '100%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-[100%] flex justify-end">
                                <PrimaryBtn
                                    type="submit"
                                    title="Save"
                                    loading={loading}
                                    disabled={loading}
                                    overrideStyles={{
                                        width: '100%',
                                        paddingY: '12px',
                                        fontSize: '14px',
                                    }}
                                />
                            </div>
                        </div>
                    </CustomAccordion>
                </Form>
            )}
        </Formik>
    )
}
