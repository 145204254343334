import React from 'react'
import humps from 'humps'
import IPSetting from './IPSetting'
import ServerConfiguration from './ServerConfiguration'
import Vpn from './Vpn'
import { useLoad } from '../../../shared/hooks/requests'
import { IPSETTING, SERVICECONFIG, TIMERESTART, VPN } from '../../../shared/utils/urls'
import TimeRestart from './TimeRestart'

export function OthersForm({ id }) {
    const getIpSetting = useLoad({ url: IPSETTING, params: { SmartCameraId: id } }, [id])
    const getServiceConfig = useLoad({ url: SERVICECONFIG, params: { smartCameraId: id } }, [id])
    const getVpn = useLoad({ url: VPN, params: { smartCameraId: id } }, [id])
    const getTimeRestart = useLoad({ url: TIMERESTART, params: { smartCameraId: id } }, [id])
    const ipSetting = getIpSetting.response ? humps.pascalizeKeys(getIpSetting.response) : {}
    const serviceConfig = getServiceConfig.response ? humps.pascalizeKeys(getServiceConfig.response) : {}
    const vpn = getVpn.response ? humps.pascalizeKeys(getVpn.response) : {}
    const timerestart = getTimeRestart.response ? humps.pascalizeKeys(getTimeRestart.response) : {}

    return (
        <div className="h-[100%] flex flex-col overflow-y-auto p-4 gap-8">
            <IPSetting
                id={id}
                initialValues={ipSetting}
            />
            <ServerConfiguration
                id={id}
                initialValues={serviceConfig}
            />
            <Vpn
                id={id}
                initialValues={vpn}
            />
            <TimeRestart
                id={id}
                initialValues={timerestart}
            />
        </div>
    )
}
