import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { COLORS } from '../../../shared/utils/colors'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { useMessage } from '../../../shared/hooks/message'
import { useDeleteRequest } from '../../../shared/hooks/requests'
import { ML_MODEL_PROFILES } from '../../../shared/utils/urls'

export default function Delete({ id, setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const deleteMLModel = useDeleteRequest()

    const remove = async () => {
        if (deleteMLModel.loading || !id) return

        const { success, error } = await deleteMLModel.request({ url: `${ML_MODEL_PROFILES}/${id}` })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.filter((v) => v.id !== id),
            }))
            showMessage('Successfully deleted', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <DeleteIcon
                    htmlColor={COLORS.disabled}
                    className="cursor-pointer"
                    fontSize="small" />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogContent sx={{ '& .MuiPaper-root': { width: 'fit-content' } }}
                    style={{ padding: 20 }}>
                    <div className="flex min-h-40 flex-col items-center justify-between">
                        <div className="w-full flex-col items-center justify-center gap-6">
                            <h3 className="text-xl font-600">Are you sure you want to delete?</h3>

                            <h3 className="text-l font-400 text-center">
                                Confirm Deletion This Action Is Irreversible
                            </h3>
                        </div>

                        <div className="flex-cent gap-2_5">
                            <CancelBtn type="button" title="Cancel" onClick={() => setOpen(false)} />

                            <PrimaryBtn type="button" title="Delete" onClick={remove} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
