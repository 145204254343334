import * as React from 'react'
import { LineChart } from '@mui/x-charts/LineChart'
import { Box } from '@mui/material'

export function ChartLine({ items, sm }) {
    return (
        <Box sx={{ width: '100%' }}>
            <LineChart
                xAxis={[
                    {
                        data: items.map((h) => h.hour),
                    },
                ]}
                series={[
                    {
                        data: items.map((h) => h.count),
                    },
                ]}
                height={sm ? 200 : 300}
                grid={{ vertical: true, horizontal: true }}
            />
        </Box>
    )
}
