import React, { useState } from 'react'
import { Button, Chip, CircularProgress } from '@mui/material'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroller'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import FormTextField from '../../../shared/ui/FormTextField'
import CustomAccordion from '../../../shared/ui/CustomAccordion'

export default function BinaryFeatures({ chipStyles, addNew, setValue, handleChange, values }) {
    const [visibleItems, setVisibleItems] = useState(3)
    const userType = localStorage.getItem('userType')

    const loadMoreItems = () => {
        if (visibleItems < values.binaryFeatures.length) {
            setVisibleItems((prev) => Math.min(prev + 3, values.binaryFeatures.length))
        }
    }

    return (
        <CustomAccordion
            summary="Binary features"
            contClassName="border-[1px] border-sepia-100"
            detailClassName={
                `border-[1px] border-sepia-200 mx-2 mb-3
            rounded-[2px] max-h-[30rem] overflow-y-scroll no-scrollbar`
            }
            expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
            overrideStyles={{
                ':before': {
                    display: 'none',
                },
                boxShadow: 'none',
                borderRadius: '3px',
            }}>
            <InfiniteScroll
                useWindow={false}
                pageStart={0}
                threshold={1}
                loadMore={loadMoreItems}
                loader={(
                    <div className="flex justify-center py-4">
                        <CircularProgress />
                    </div>
                )}
                hasMore={visibleItems < values.binaryFeatures.length}>
                {values.binaryFeatures.slice(0, visibleItems).map((item, ind) => (
                // eslint-disable-next-line react/no-array-index-key
                    <div key={item.id} className="flex flex-col gap-2 pt-2">
                        {item.name && (
                            <Chip
                                label={item.name}
                                sx={chipStyles} />
                        )}

                        <div className="flex flex-col gap-2">
                            <FormTextField
                                label="Name"
                                disabled={userType !== 'system_admin'}
                                handleChange={handleChange}
                                name={`binaryFeatures[${ind}].name`}
                            />

                            <FormTextField
                                label="Description"
                                disabled={userType !== 'system_admin'}
                                handleChange={handleChange}
                                name={`binaryFeatures[${ind}].description`}
                            />
                        </div>
                    </div>
                ))}
            </InfiniteScroll>

            {userType === 'system_admin' && (
                <Button
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                    onClick={() => addNew('binaryFeatures', setValue, values)}>
                    <ControlPointIcon />

                    Add Binary features
                </Button>
            )}
        </CustomAccordion>
    )
}
