import React, { useState } from 'react'
import { Chip, TableCell } from '@mui/material'
import moment from 'moment'
import Update from '../features/Update'
import Delete from '../features/Delete'
import { COLORS } from '../../../shared/utils/colors'
import { CameraLiveAnalytics } from './CameraLiveAnalytics'

export default function SmartCameraManageTableItem({ item, setData }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [showAnalytics, setShowAnalytics] = useState(false)

    const open = () => setOpenDrawer(true)
    const close = () => setOpenDrawer(false)

    return (
        <>
            <TableCell className="cursor-pointer" onClick={() => setOpenDrawer(true)}>
                {item.name}
            </TableCell>

            <TableCell onClick={() => setOpenDrawer(true)}
                className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.userCount}
            </TableCell>

            <TableCell className="cursor-pointer" onClick={() => setOpenDrawer(true)}>
                {item.identityCount}
            </TableCell>

            <TableCell onClick={() => setOpenDrawer(true)}>
                <Chip
                    color={item.isOnline ? 'success' : 'error'}
                    variant="outlined"
                    sx={{
                        backgroundColor: item.isOnline ? '#37B4001F' : COLORS.lightRed,
                        border: `1px solid ${item.isOnline ? '#2B8906' : COLORS.red}`,
                        color: item.isOnline ? '#2B8906' : COLORS.white,
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '4px 16px',
                        '& .MuiChip-label': { padding: 0 },
                    }}
                    label={item.isOnline ? 'Online' : 'Offline'} />
            </TableCell>

            <TableCell>{item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''}</TableCell>

            <TableCell>
                <div
                    onMouseEnter={() => setShowAnalytics(true)}
                    onMouseLeave={() => setShowAnalytics(false)}
                >
                    <span className="text-xs">{item.totalActiveTime}</span>
                    {showAnalytics && (
                        <CameraLiveAnalytics
                            items={item.analytics}
                            workTime={item.totalActiveTime}
                            offTime={item.totalOffTime}
                        />
                    )}
                </div>
            </TableCell>

            <TableCell style={{ paddingRight: 8 }} align="right">
                <div className="flex items-center justify-end">
                    <Update isOpen={openDrawer} open={open} close={close} item={item} setData={setData} />
                    <Delete id={item.id} setData={setData} />
                </div>
            </TableCell>
        </>
    )
}
