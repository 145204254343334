import React, { useEffect } from 'react'
import { MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import Paper from '@mui/material/Paper'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useLoad } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { JETSON_DEVICES } from '../../../shared/utils/urls'

export default function Filters({ otherFilters = [] }) {
    const { streamAnalyticsFilter, setStreamAnalyticsFilter } = useFilter()
    const loadJetsons = useLoad({ url: JETSON_DEVICES, params: { is_active: true, page: 1, size: 100 } }, [])
    const jetsons = loadJetsons.response && loadJetsons.response.items ? loadJetsons.response.items : []
    const jetsonOptions = jetsons.map((b) => ({ value: b.id, label: b.deviceName || '-' }))

    // check if workspaceFilter does not have jetsonsDeviceId then set it to the first jetson device
    useEffect(() => {
        if (!streamAnalyticsFilter.jetsonDeviceId && jetsons.length) {
            setStreamAnalyticsFilter((old) => ({ ...old, jetsonDeviceId: jetsons[0].deviceId }))
        }
    }, [jetsons])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3">
                <h3 className="text-m text-black font-500">Filter</h3>

                <div className="flex items-center gap-2">
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? jetsonOptions.find((v) => v.value === value)
                            && jetsonOptions.find((v) => v.value === value).label : 'Choose jetson device')}
                        sx={{ height: 37, width: '10rem' }}
                        value={streamAnalyticsFilter.jetsonDeviceId}
                        onChange={(e) => setStreamAnalyticsFilter((oldState) => ({ ...oldState,
                            jetsonDeviceId: e.target.value }))}
                    >
                        {!loadJetsons.loading && jetsonOptions.length ? jetsonOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem.label}
                            </MenuItem>
                        )) : loadJetsons.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>

                    {otherFilters.map((filterItem) => filterItem)}
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
