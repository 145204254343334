import React from 'react'
import Update from './Update'
import Delete from './Delete'

export default function Actions({ item, setData }) {
    return (
        <div className="h-full flex items-center justify-end">
            <Update setData={setData} item={item} />

            <Delete id={item.id} setData={setData} />
        </div>
    )
}
