import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Button from './Button'
import { COLORS } from '../../shared/utils/colors'
import LoadingSpinner from './LoadingSpinner'

export default function DeleteModal({ onClose, onDelete, loading }) {
    return (
        <div className={cn(css(s.cont), 'flex-col items-center justify-between')}>
            <div className="w-full flex-col items-center justify-center gap-6">
                <h3 className="text-xl font-600">Are you sure you want to delete?</h3>

                <h3 className="text-l font-400 text-center">Confirm Deletion This Action Is Irreversible</h3>
            </div>

            <div className="flex-cent gap-2_5">
                <Button onClick={onClose} className={css(s.btn, s.cancel)}>Cancel</Button>

                <Button onClick={() => onDelete(onClose)} className={css(s.btn, s.delete)}>
                    {loading ? <LoadingSpinner strokeWidth={5} color={COLORS.white} /> : 'Delete'}
                </Button>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        backgroundColor: COLORS.white,
        borderRadius: 5,
        padding: '26px 0 20px 0',
        width: '25rem',
        height: '8rem',
    },
    btn: {
        width: '7rem',
        borderRadius: 8,
        height: 32,
        fontSize: 14,
        fontWeight: '500',
    },
    cancel: {
        backgroundColor: COLORS.sepia,
        color: COLORS.disabled,
    },
    delete: {
        backgroundColor: COLORS.mainBlueBg,
        color: COLORS.white,
    },
})
