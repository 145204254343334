import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../shared/hooks/requests'
import { JETSON_PROFILE } from '../../../shared/utils/urls'
import JetsonProfileDetails from '../components/JetsonProfileDetails'

export default function Update({ item, setData }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateJetsonProfile = usePutRequest()

    const update = async (values) => {
        if (!values || updateJetsonProfile.loading) return

        const { success, error } = await updateJetsonProfile.request({
            url: `${JETSON_PROFILE}/${item.id}`,
            data: values,
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (val.id === item.id ? { ...val, ...values } : val)),
            }))
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpenDrawer(true)}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '35%' } }}
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}>
                <JetsonProfileDetails
                    item={item}
                    onClose={() => setOpenDrawer(false)}
                    onEdit={update}
                    loading={updateJetsonProfile.loading}
                />
            </Drawer>
        </>
    )
}
