import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Typography } from '@mui/material'
import JetsonCreateForm from './JetsonCreateForm'
import AttachCameras from './AttachCameras'
import ConfigFile from './ConfigFile'
import VpnSetupFileGenerate from './VpnSetupFileGenerate'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { COLORS } from '../../../shared/utils/colors'

const FORM_INITIAL_STATE = {
    username: '',
    password: '',
    deviceName: '',
    deviceId: '',
    deviceIpVpn: '',
    deviceStreamUrl: '',
    roomId: '',
    cameraIds: [],
}

const VALIDATION_SCHEMA = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    deviceName: Yup.string().optional(),
    deviceId: Yup.string().optional(),
    deviceIpVpn: Yup.string().optional(),
    deviceStreamUrl: Yup.string().optional(),
    roomId: Yup.number().required('Room is required'),
    cameraIds: Yup.array().of(Yup.number().required()).optional(),
})

export default function JetsonForm({
    isEdit = false,
    loading,
    onClose,
    step,
    section = '',
    onSubmit = () => {},
    initialValues,
}) {
    const renderItems = {
        1: <JetsonCreateForm />,
        2: <AttachCameras initialValues={initialValues} isEdit={isEdit} />,
        3: <ConfigFile />,
        4: <VpnSetupFileGenerate />,
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{ ...FORM_INITIAL_STATE, ...initialValues }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            <Form className="flex flex-col justify-between p-4 gap-10">
                {renderItems[step]}

                {section !== 'config' && section !== 'vpnSetup' ? (
                    <div className="flex items-center justify-between">
                        {!isEdit ? (
                            <Typography fontSize={14} fontWeight="500" color={COLORS.disabled}>
                                Step {step} of 4
                            </Typography>
                        ) : null}

                        <div className="flex items-center justify-end gap-2.5">
                            <CancelBtn
                                onClick={onClose}
                                title="Cancel"
                                type="button"
                            />
                            <PrimaryBtn
                                loading={loading}
                                title={isEdit ? 'Edit' : step !== 4 ? 'Next' : 'Close'}
                                type="submit"
                            />
                        </div>
                    </div>
                ) : null}
            </Form>
        </Formik>
    )
}
