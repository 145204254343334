import React from 'react'
import { Grid } from '@mui/material'
import Filters from './components/Filters'
import Stream from './components/Stream'
import Notifications from './components/Notifications'
import All from './components/All'

export default function StreamAnalytics() {
    return (
        <>
            <Filters />
            <Grid container spacing={2} sx={{ mt: '14px' }}>
                {/* Левая колонка с потоками видеокамер */}
                <Grid item xs={8} sx={{ height: '72vh' }}>
                    <Stream />
                </Grid>

                {/* Правая колонка с уведомлениями */}
                <Grid item xs={4} sx={{ height: '72vh' }}>
                    <Notifications />
                </Grid>
            </Grid>
            <All />
        </>
    )
}
