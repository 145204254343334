import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { CircularProgress } from '@mui/material'
import Filter from '../../dashboard/features/filter'
import { usePostRequest, useLoad } from '../../../shared/hooks/requests'
import { BRANCHS_LIST, ENITITY_LIST } from '../../../shared/utils/urls'
import { BranchCard } from './branchCard'
import BranchModal from './branchModal'
import { CompareCard } from './compareCard'
import { Days, Month, Weeks } from '../../../components/svgs/Svgs'
import { UniversalCard } from './universalCard'
import ModalChart from './modalChart'
import ModalTable from './modalTable'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

export function BranchAnalytics() {
    const { branchEntityFilter, setBranchEntityFilters } = useFilter()
    const [data, setData] = useState({})
    const [open, setOpen] = useState(false)
    const [collect, setCollect] = useState([])
    const [visibleItems, setVisibleItems] = useState(2)
    const observerRef = useRef()

    const getBranches = usePostRequest({ url: BRANCHS_LIST })
    const getEntity = useLoad({ url: ENITITY_LIST })
    const getEntityLower = useLoad({ url: ENITITY_LIST,
        params:
            { tenant_entity_id: branchEntityFilter.filter1 !== 'all'
                ? branchEntityFilter.filter1 : {} } }, [branchEntityFilter.filter1])

    const entityList = getEntity.response ? getEntity.response : {}
    const entityLowerList = getEntityLower.response ? getEntityLower.response : {}

    useEffect(() => {
        setBranchEntityFilters('filter2', 'all')
        setCollect([])
    }, [branchEntityFilter.filter1])

    const extractIdsAndNamess = (entityObject) => {
        const idsAndNames = []

        if (!entityObject || typeof entityObject !== 'object') {
            console.error('Invalid entityObject:', entityObject)
            return idsAndNames
        }

        // Directly access the parent object if it exists
        const { parent } = entityObject
        if (parent && parent.id && parent.name && parent.hierarchyLevel > 2) {
            idsAndNames.push({ id: parent.id, name: parent.name })
        }

        // Directly access the children array if it exists
        const { children } = entityObject
        if (children && Array.isArray(children)) {
            children.forEach((child) => {
                if (child.id && child.name) {
                    idsAndNames.push({ id: child.id, name: child.name })
                }
            })
        }

        return idsAndNames
    }

    const collectedIdsAndNames = extractIdsAndNamess(branchEntityFilter.filter1 !== 'all'
         && entityLowerList.children && entityLowerList.children.length >= 1
        ? entityLowerList : entityList)

    // Create a mapping of ID to name
    const idToNameMap = collectedIdsAndNames ? collectedIdsAndNames.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
    }, {}) : {}

    // Generate the new array based on collect
    const newArray = collect.map((id) => ({
        id,
        name: idToNameMap[id] || 'Unknown', // Default to 'Unknown' if ID is not found
    }))

    const handleCheckBox = (id, isChecked) => {
        if (isChecked) {
            setCollect([...collect, id])
        } else {
            setCollect(collect.filter((selectedId) => selectedId !== id))
        }
    }

    const handleSubmit = async () => {
        setOpen(!open)
        const requestBody = collect.length > 0 ? collect : null
        if (getBranches.loading) return
        const { response, success } = await getBranches.request({
            data: requestBody,
            params: { date: moment(new Date()).format('YYYY-MM-DD') },
        })

        if (success) {
            console.log('Branch successfully added', success)
        }
        if (response) {
            setData(response || {})
        }
    }

    const foundName = branchEntityFilter.filter1 !== 'all' && entityLowerList.children
    && entityLowerList.children.length >= 1
        ? branchEntityFilter.filter2 : branchEntityFilter.filter1

    const foundItem = collectedIdsAndNames
        ? collectedIdsAndNames.find((item) => item.id.toString() === foundName)
        : null

    const loadMoreItems = useCallback(() => {
        if (visibleItems < collectedIdsAndNames.length) {
            setVisibleItems((prev) => Math.min(prev + 2, collectedIdsAndNames.length))
        }
    }, [visibleItems, collectedIdsAndNames.length])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreItems()
            }
        })

        if (observerRef.current) {
            observer.observe(observerRef.current)
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current)
            }
        }
    }, [loadMoreItems])

    return (
        <>
            <div className="flex w-[100%] flex-col items-center gap-[14px]">
                <div className="bg-white w-[100%] flex flex-col gap-[11px]
                 h-auto pt-[12px] pl-[14px] pb-[11px] rounded-[11px]">
                    <span className="text-black font-medium text-[16px]">Filter</span>
                    <div className="flex items-center gap-[10px]">
                        {entityList.parent && entityList.parent.hierarchyLevel === 3 ? (
                            null
                        ) : (
                            <Filter
                                items={entityList || {}}
                                placeholder="ALL"
                                onChange={(e) => setBranchEntityFilters('filter1', e.target.value)}
                                value={branchEntityFilter.filter1}
                            />
                        ) }

                        {branchEntityFilter.filter1 !== 'all' && entityLowerList.children
                        && entityLowerList.children.length > 1
                            ? (
                                <Filter
                                    items={entityLowerList || {}}
                                    placeholder="ALL"
                                    onChange={(e) => setBranchEntityFilters('filter2', e.target.value)}
                                    value={branchEntityFilter.filter2}
                                />
                            ) : null}

                        {branchEntityFilter.filter1 !== 'all'
                        && branchEntityFilter.filter2 === 'all' && entityLowerList.children
                         && entityLowerList.children.length > 1
                            ? (
                                <button
                                    onClick={collect.length > 1 ? handleSubmit : null}
                                    className="px-[27px] bg-btnBlue rounded-[6px] text-white py-[11px]"
                                >
                                    Compare
                                </button>
                            ) : (
                                ''
                            )}
                    </div>
                </div>

                <div className={`${entityLowerList.children && entityLowerList.children.length === 1
                    ? 'flex w-[100%]' : 'grid grid-cols-2 gap-[20px] w-[100%]'} `}>
                    {branchEntityFilter.filter1 !== 'all' ? (
                        entityLowerList.children && entityLowerList.children.length >= 1 ? (
                            branchEntityFilter.filter2 !== 'all' ? (
                                <BranchCard
                                    key={branchEntityFilter.filter2 + 1}
                                    id={branchEntityFilter.filter2}
                                    onCheckboxChange={handleCheckBox}
                                    name={foundItem ? foundItem.name : null}
                                    entityLists={entityLowerList.children ? entityLowerList.children : null}
                                    entityLowerList={entityLowerList.children ? entityLowerList.children : null}
                                />
                            ) : (
                                collectedIdsAndNames.slice(0, visibleItems).map((item) => (
                                    <BranchCard key={item.id + 1} id={item.id} onCheckboxChange={handleCheckBox}
                                        name={item.name} entityLists={entityLowerList.children
                                            ? entityLowerList.children : null}
                                        entityLowerList={entityLowerList.children
                                            ? entityLowerList.children : null} />
                                ))
                            )
                        ) : (
                            <BranchCard
                                key={branchEntityFilter.filter1 + 1}
                                id={branchEntityFilter.filter1}
                                onCheckboxChange={handleCheckBox}
                                name={foundItem ? foundItem.name : null}
                                entityLists={entityList.children ? entityList.children : null}
                            />
                        )
                    ) : collectedIdsAndNames && collectedIdsAndNames.length > 0 ? (
                        collectedIdsAndNames.slice(0, visibleItems).map((item) => (
                            <BranchCard key={item.id + 1} id={item.id} onCheckboxChange={handleCheckBox}
                                name={item.name} entityLists={entityList.children ? entityList.children : null} />
                        ))
                    ) : (
                        null
                    )}
                </div>

                <div ref={observerRef} className="flex justify-center py-4">
                    {visibleItems < collectedIdsAndNames.length && <CircularProgress />}
                </div>
            </div>

            <BranchModal open={open} close={() => setOpen(false)}>
                <div className="flex w-[100%] flex-col items-center gap-[15px]">
                    <div className="w-[100%] grid grid-cols-3 gap-[18px] ">
                        <CompareCard
                            icon={Days}
                            cardTitle="Kunlik tashriflar"
                            branchName="Branchs"
                            data={data.today}
                            newName={newArray || null}
                        />

                        <CompareCard
                            icon={Weeks}
                            cardTitle="Haftalik tashriflar"
                            branchName="Branchs"
                            data={data.week}
                            newName={newArray || null}
                        />

                        <CompareCard
                            icon={Month}
                            cardTitle="Oylik tashriflar"
                            branchName="Branchs"
                            data={data.month}
                            newName={newArray || null}
                        />
                    </div>

                    <div className="flex items-center gap-[18px] w-[100%]">
                        <div className="w-[40%] h-auto">
                            <UniversalCard cardTitle="Yosh bo'yicha kundalik tahlil">
                                <ModalTable data={data.byAge} newName={newArray} />
                            </UniversalCard>
                        </div>

                        <div className="w-[60%] h-auto">
                            <UniversalCard
                                cardTitle="Bugungi tashrif vaqti">
                                <ModalChart data={data.byHour} newName={newArray} />
                            </UniversalCard>
                        </div>
                    </div>

                    <div className="flex items-center gap-[18px] w-[100%]">
                        <div className="w-[60%] h-auto">
                            <UniversalCard
                                cardTitle="Oylik tashrif buyuruvchilar">
                                <ModalChart data={data.byDay} newName={newArray} />
                            </UniversalCard>
                        </div>
                        <div className="w-[40%] h-auto">
                            <UniversalCard cardTitle="Mijoz vaqt sarfi">
                                <ModalTable data={[]} newName={newArray} />
                            </UniversalCard>
                        </div>
                    </div>
                </div>
            </BranchModal>
        </>
    )
}
