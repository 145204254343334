import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import ValidationErrorMessage from './ValidationErrorMessage'
import { COLORS } from '../../shared/utils/colors'

export default function FormInput({
    name,
    help,
    icon,
    type,
    label,
    disabled,
    loading,
    validate,
    className,
    iconStyles,
    fieldStyles,
    labelClassName,
    noRequiredValidation,
    component = 'input',
    errorWithMessage = false,
    ...attributes
}) {
    const { errors, touched, handleBlur, values } = useFormikContext()
    const isRequired = typeof validate === 'function' && !noRequiredValidation
    const isInValid = getIn(errors, name) && getIn(touched, name)
    const [focused, setFocused] = useState(false)
    const shouldFocus = focused || values[name] || attributes.value
    const fieldRef = useRef()

    // const inpRef = useRef()

    // function outsideDetector(event) {
    //     if (inpRef.current && !inpRef.current.contains(event.target)) {
    //         setFocused(false)
    //     }
    // }
    //
    // // eslint-disable-next-line consistent-return
    // useEffect(() => {
    //     if (focused) {
    //         document.addEventListener('mousedown', outsideDetector)
    //         return () => {
    //             document.removeEventListener('mousedown', outsideDetector)
    //         }
    //     }
    // }, [focused])

    return (
        <div style={fieldStyles}>
            <div onClick={() => fieldRef.current.focus()}
                className={cn(
                    shouldFocus && willFocus(disabled),
                    'control relative flex-col items-start justify-end',
                    css(s.field, disabled && s.disabled),
                )}>
                <Field
                    id={name}
                    name={name}
                    innerRef={fieldRef}
                    disabled={disabled}
                    validate={validate}
                    type={type || 'text'}
                    component={component}
                    onFocus={() => setFocused(true)}
                    className={cn(className, css(s.inp), 'relative', { [css(s.icon)]: icon })}
                    onBlur={(e) => { handleBlur(e); setFocused(false) }}
                    {...attributes} />

                {label ? (
                    <label htmlFor={name}
                        className={cn(
                            'absolute',
                            shouldFocus && float(disabled),
                            css(s.label, disabled && s.disLab, labelClassName),
                        )}>
                        {label} {isRequired && !disabled && (
                            <span style={{ color: isInValid ? COLORS.red : COLORS.gray }}>
                                *
                            </span>
                        )}
                    </label>
                ) : null}

                <span style={{ position: 'absolute', right: 17, top: 17, ...iconStyles }}>{icon}</span>

                {help && (!errors[name] || !touched[name]) ? (
                    <p className={css(s.formHint)}>{help}</p>
                ) : null}
            </div>

            {errorWithMessage && (
                <ValidationErrorMessage
                    name={name}
                    errorMsg={help ? <h3 style={{ fontSize: 12 }}>{help}</h3> : ''}
                />
            )}
        </div>
    )
}

const s = StyleSheet.create({
    field: {
        width: '100%',
        height: 50,
        boxSizing: 'border-box',
        padding: '5px 10px',
        ':nth-child(1n) > label': {
            letterSpacing: -0.5,
        },
        borderRadius: '2px 2px 0px 0px',
        borderBottom: `1px solid ${COLORS.defInpBc}`,
        background: COLORS.defaultInpBg,
        ':hover': {
            background: COLORS.inpHoverBg,
        },
    },
    disabled: {
        ':hover': {
            background: COLORS.defaultInpBg,
        },
        backgroundColor: COLORS.defaultInpBg,
        borderBottom: '1px solid #BEBEBE',
        color: '#BEBEBE',
    },
    inp: {
        width: '100%',
        backgroundColor: 'inherit',
        fontSize: 16,
        color: COLORS.lightBlack,
        fontWeight: '400',
        outline: 'none',
        border: 'none',
        ':disabled': {
            color: '#BEBEBE',
        },
    },
    icon: {
        width: '90%',
    },
    dng: {
        borderColor: COLORS.red,
    },
    formHint: {
        letterSpacing: -0.2,
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.gray,
    },
    label: {
        bottom: 15,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.placeholder,
        transition: 'all ease 0.1s',
    },
    disLab: {
        color: '#BEBEBE',
    },
})

const float = (isDis) => css(StyleSheet.create({
    float: {
        transform: 'translateY(-12px)',
        color: isDis ? '#BEBEBE' : COLORS.mainBlueBg,
    },
}).float)

const willFocus = (isDis) => css(StyleSheet.create({
    focused: {
        backgroundColor: isDis ? COLORS.defaultInpBg : COLORS.lightGray,
        borderBottom: `1px solid ${isDis ? '#BEBEBE' : COLORS.mainBlueBg}`,
    },
}).focused)
