function CreateBasicAuthToken(username, password) {
    // Step 1: Combine username and password
    const credentials = `${username}:${password}`

    // Step 2: Base64 encode the credentials
    const base64Credentials = btoa(credentials)

    // Step 3: Create the Authorization header value
    return base64Credentials
}

export default CreateBasicAuthToken
