import React, { memo, useState } from 'react'
import { Add, Delete, Edit, Save } from '@mui/icons-material'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import uuid from 'react-uuid'
import { isEmpty } from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import humps from 'humps'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import FormTextField from '../../../shared/ui/FormTextField'
import { usePostRequest } from '../../../shared/hooks/requests'
import { ADD_DEEPSTREAM_CONFIG_PROPERTY,
    DELETE_DEEPSTREAM_CONFIG_GROUP,
    DELETE_DEEPSTREAM_CONFIG_PROPERTY,
    UPDATE_DEEPSTREAM_CONFIG_PROPERTY } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { useMessage } from '../../../shared/hooks/message'

function MemoizedComponent({ group, idx, handleChange, setFieldValue, errors, appName, version, configType, values }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [editField, setEditField] = useState({})
    const [expanded, setExpanded] = useState(false)
    const [loadingRemove, setLoadingRemove] = useState({})
    const { jetsonFilter: { jetsonDeviceId } } = useFilter()
    const [visibleItemsCount, setVisibleItemsCount] = useState(3)
    const removeProperty = usePostRequest({ url: DELETE_DEEPSTREAM_CONFIG_PROPERTY })
    const updateProperty = usePostRequest({ url: UPDATE_DEEPSTREAM_CONFIG_PROPERTY })
    const addNewProperty = usePostRequest({ url: ADD_DEEPSTREAM_CONFIG_PROPERTY })
    const removeGroup = usePostRequest({ url: DELETE_DEEPSTREAM_CONFIG_GROUP })

    const loadMoreItems = () => {
        if (visibleItemsCount < group.properties.length) {
            setVisibleItemsCount((prev) => Math.min(prev + 3, group.properties.length))
        }
    }

    const handleEditField = (field) => {
        setEditField((prev) => ({
            ...prev,
            [field]: !prev[field],
        }))
    }

    const handleAddNewProperty = () => {
        const newProperty = { isNew: true, id: uuid(), key: '', value: '' }
        setFieldValue(`configGroup[${idx}].properties`, [...group.properties, newProperty])
        handleEditField(`property${newProperty.id}`)
        setExpanded(true)
    }

    const onEditProperty = async (field, configPropertyName, configPropertyValue) => {
        if (updateProperty.loading) return

        const { response, error, success } = await updateProperty.request({ data: {
            applicationName: appName,
            applicationVersion: version,
            jetsonDeviceId,
            configFileName: humps.decamelize(configType),
            configGroupName: humps.decamelize(group.name, { separator: '-' }),
            configPropertyName: humps.decamelize(configPropertyName, { separator: '-' }),
            configPropertyValue,
        } })

        if (success) {
            handleEditField(field)
            showMessage(response.description, response.status === 'success' ? 'success-msg' : 'error-msg')
        } else if (error) {
            handleEditField(field)
            handleErrorMsg(error)
        }
    }

    const onAddNewProperty = async (field, configPropertyName, configPropertyValue) => {
        if (addNewProperty.loading) return

        const { response, error, success } = await addNewProperty.request({ data: {
            applicationName: appName,
            applicationVersion: version,
            jetsonDeviceId,
            configFileName: humps.decamelize(configType),
            configGroupName: humps.decamelize(group.name, { separator: '-' }),
            configPropertyName: humps.decamelize(configPropertyName, { separator: '-' }),
            configPropertyValue,
        } })

        if (success) {
            handleEditField(field)
            showMessage(response.description, response.status === 'success' ? 'success-msg' : 'error-msg')
        } else if (error) {
            handleEditField(field)
            handleErrorMsg(error)
        }
    }

    const handleDeleteProperty = async (id, configPropertyName) => {
        if (loadingRemove[id]) return

        if (group.isCreated) {
            setLoadingRemove({ [id]: true })
            const { response, error, success } = await removeProperty.request({ data: {
                applicationName: appName,
                applicationVersion: version,
                jetsonDeviceId,
                configFileName: humps.decamelize(configType),
                configGroupName: humps.decamelize(group.name, { separator: '-' }),
                configPropertyName: humps.decamelize(configPropertyName, { separator: '-' }),
            } })

            if (success) {
                setLoadingRemove({ [id]: false })
                if (response.status === 'success') {
                    setFieldValue(`configGroup[${idx}].properties`, group.properties.filter((item) => item.id !== id))
                }
                showMessage(response.description, response.status === 'success' ? 'success-msg' : 'error-msg')
            } else if (error) {
                setLoadingRemove({ [id]: false })
                handleErrorMsg(error)
            }
        } else {
            setFieldValue(`configGroup[${idx}].properties`, group.properties.filter((item) => item.id !== id))
            setExpanded(true)
        }
    }

    const handleDeleteGroup = async () => {
        if (removeGroup.loading) return

        if (group.isCreated) {
            const { response, error, success } = await removeGroup.request({ data: {
                applicationName: appName,
                applicationVersion: version,
                jetsonDeviceId,
                configFileName: humps.decamelize(configType),
                configGroupName: humps.decamelize(group.name, { separator: '-' }),
            } })

            if (success) {
                if (response.status === 'success') {
                    setFieldValue('configGroup', values.configGroup.filter((item) => item.id !== group.id))
                }
                showMessage(response.description, response.status === 'success' ? 'success-msg' : 'error-msg')
            } else if (error) {
                handleErrorMsg(error)
            }
        } else {
            setFieldValue('configGroup', values.configGroup.filter((item) => item.id !== group.id))
        }
    }

    return (
        <CustomAccordion
            summary={group.name}
            action={(
                <Tooltip title={`Delete ${group.name}`} placement="top">
                    <IconButton onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleDeleteGroup()
                    }}>
                        {removeGroup.loading ? (
                            <CircularProgress
                                size="18px"
                                sx={{ color: COLORS.mainBlueBg }} />
                        ) : <Delete fontSize="small" htmlColor={COLORS.disabled} />}
                    </IconButton>
                </Tooltip>
            )}
            contClassName="border-[1px] border-sepia-100"
            detailClassName={
                `border-[1px] max-h-[15rem] flex flex-col gap-4 border-sepia-200 mx-2 mb-3
            rounded-[2px] max-h-[30rem] overflow-y-scroll no-scrollbar`
            }
            summaryClassName="flex flex-row-reverse gap-2"
            summaryStyles={{ margin: 0 }}
            expanded={expanded}
            onClickSummary={() => {
                setExpanded(!expanded)
                setVisibleItemsCount(3)
            }}
            expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
            overrideStyles={{
                ':before': {
                    display: 'none',
                },
                width: '100%',
                boxShadow: 'none',
                borderRadius: '3px',
                '& .MuiAccordionSummary-root': {
                    padding: '0 0 0 10px',
                },
            }}>
            <div className="flex w-full items-center justify-center gap-5">
                <FormTextField
                    fullWidth
                    disabled={!editField[`name${idx}`] || group.isCreated}
                    label="Name"
                    handleChange={handleChange}
                    name={`configGroup[${idx}].name`}
                />

                {!group.isCreated ? (
                    <IconButton onClick={() => handleEditField(`name${idx}`)}>
                        <Edit fontSize="small" htmlColor={COLORS.disabled} />
                    </IconButton>
                ) : null}
            </div>

            <InfiniteScroll
                useWindow={false}
                pageStart={0}
                threshold={1}
                className="flex flex-col gap-4"
                loadMore={loadMoreItems}
                loader={(
                    <div className="flex justify-center py-4">
                        <CircularProgress />
                    </div>
                )}
                hasMore={visibleItemsCount < group.properties.length}>
                {group.properties.slice(0, visibleItemsCount).map((property, propIdx) => (
                    <div key={property.id} className="flex w-full max-h-28 items-center justify-center gap-5">
                        <FormTextField
                            fullWidth
                            disabled={!editField[`property${property.id}`]}
                            label="Key"
                            handleChange={handleChange}
                            name={`configGroup[${idx}].properties[${propIdx}].key`}
                        />

                        <FormTextField
                            fullWidth
                            disabled={!editField[`property${property.id}`]}
                            label="Value"
                            handleChange={handleChange}
                            name={`configGroup[${idx}].properties[${propIdx}].value`}
                        />

                        <div className="flex items-center">

                            {editField[`property${property.id}`]
                                ? updateProperty.loading || addNewProperty.loading ? (
                                    <IconButton>
                                        <CircularProgress
                                            size="18px"
                                            sx={{ color: COLORS.mainBlueBg }} />
                                    </IconButton>
                                ) : (
                                    <Tooltip title="Save changes" placement="top">
                                        <IconButton
                                            onClick={() => {
                                                if (property.isNew) {
                                                    onAddNewProperty(
                                                        `property${property.id}`,
                                                        property.key,
                                                        property.value,
                                                    )
                                                } else {
                                                    onEditProperty(
                                                        `property${property.id}`,
                                                        property.key,
                                                        property.value,
                                                    )
                                                }
                                            }}>
                                            <Save fontSize="small" htmlColor={COLORS.disabled} />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Edit property" placement="top">
                                        <IconButton onClick={() => handleEditField(`property${property.id}`)}>
                                            <Edit fontSize="small" htmlColor={COLORS.disabled} />
                                        </IconButton>
                                    </Tooltip>
                                )}

                            <Tooltip title="Delete `key : value` pair" placement="top">
                                <IconButton onClick={() => handleDeleteProperty(property.id, property.key)}>
                                    {loadingRemove[property.id] ? (
                                        <CircularProgress
                                            size="18px"
                                            sx={{ color: COLORS.mainBlueBg }} />
                                    ) : <Delete fontSize="small" htmlColor={COLORS.disabled} />}
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )) }
            </InfiniteScroll>

            {isEmpty(errors) ? (
                <div className="flex w-full items-center justify-center gap-5 pr-9">
                    <FormTextField fullWidth disabled name="disabled" label="Key" />

                    <FormTextField fullWidth disabled name="disabled" label="Value" />

                    <Tooltip title="Add new `key : value` pair" placement="top">
                        <IconButton onClick={handleAddNewProperty}>
                            <Add fontSize="small" htmlColor={COLORS.disabled} />
                        </IconButton>
                    </Tooltip>
                </div>
            ) : null}
        </CustomAccordion>
    )
}

export const NewConfigForm = memo(MemoizedComponent)
