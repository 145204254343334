import { useNavigate } from 'react-router-dom'

export const useAddQueryParams = () => {
    const navigate = useNavigate()

    return (queryParams) => {
        const params = new URLSearchParams(window.location.search)
        Object.entries(queryParams).forEach(([key, value]) => {
            params.set(key, value)
        })
        navigate(`${window.location.pathname}?${params.toString()}`)
    }
}
