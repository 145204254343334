import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import { COLORS } from '../../../shared/utils/colors'
import MLModelProfileForm from './MLModelProfileForm'

const navItems = [
    { id: 1, name: 'Details' },
]

export default function MLModelProfileDetails({ item, onEdit, onClose }) {
    const [section, setSection] = useState('Details')

    const initialValues = {
        name: item.name || '',
        description: item.description || '',
        tags: item.tags ? item.tags.split(';') : [],
    }

    return (
        <DrawerLayout
            topic="ML model profile details"
            onClose={onClose}
            name={item.name}
            icons={[]}>
            <React.Fragment>
                <div className={css(s.detailHead)}>
                    <div className={css(s.detailNav)}>
                        <ul className="w-full">
                            {navItems.map(((i) => (
                                <li onClick={() => setSection(i.name)}
                                    className={css(s.navItem, section === i.name && s.active)} key={i.id}>
                                    {i.name}
                                </li>
                            )))}
                        </ul>
                    </div>

                    {/* <div className="flex items-center gap-2_5">
                            <Button onClick={showTenantAdmins} className={css(s.actBtn)}>
                                Manage tenant admins
                            </Button>
                        </div> */}
                </div>

                <div className={css(s.sBody)}>
                    <MLModelProfileForm
                        isEdit
                        onClose={onClose}
                        onSubmit={onEdit}
                        initialValues={initialValues} />
                </div>
            </React.Fragment>
        </DrawerLayout>
    )
}

const s = StyleSheet.create({
    sBody: {
        padding: '9px 0',
    },
    detailNav: {
        borderBottom: `1px solid ${COLORS.sepia}`,
        paddingBottom: 1,
    },
    navItem: {
        width: 'fit-content',
        listStyle: 'none',
        padding: '9px 10px',
        fontSize: 16,
        fontWeight: '500',
        position: 'relative',
        color: COLORS.disabled,
        cursor: 'pointer',
        paddingBottom: 8,
        transition: 'color 0.3s ease',
        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -1,
            width: 0,
            borderBottom: `solid 1.5px ${COLORS.mainBlueBg}`,
            transition: 'width 0.3s ease',
        },
        ':hover': {
            color: COLORS.mainBlueBg,
        },
        ':hover::after': {
            width: '100%',
        },
    },
    active: {
        color: COLORS.mainBlueBg,
        ':after': {
            width: '100%',
        },
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '8px 12px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
    },
})
