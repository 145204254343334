import axios from 'axios'
import { useMessage } from './message'
import { domain } from '../utils/request'
import { auth } from '../utils/auth'

const MAX_FILE_SIZE = 4194304
const MIN_FILE_SIZE = 50000
const allowedFormats = ['jpg', 'jpeg', 'png']

export default function useImageUpload() {
    const showMessage = useMessage()

    const handleFileUpload = async (file, setValue, name, url) => {
        const formData = new FormData()
        formData.append('file', file)

        try {
            const res = await axios.post(domain + url, formData, auth())
            if (res.status === 200) {
                setValue(name, res.data.file_url)
            }
        } catch (error) {
            console.error('File upload failed', error)
        }
    }

    const onFileUpload = (e, setValue, name, url, withRequest = true, formats = null) => {
        const file = e.target.files[0]

        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase()
            if (![...allowedFormats, ...(formats || [])].includes(fileExtension)) {
                showMessage('File type is not supported', 'error-msg')
                return
            }
            if (file.size < MIN_FILE_SIZE) {
                showMessage('File too small', 'error-msg')
                return
            }
            if (file.size > MAX_FILE_SIZE) {
                showMessage('File too large', 'error-msg')
                return
            }

            setValue(name, URL.createObjectURL(file))
            setValue('fileName', file.name)
            if (withRequest) {
                handleFileUpload(file, setValue, name, url)
            }
        }
    }

    return {
        onFileUpload,
    }
}
