import React from 'react'
import { TableCell } from '@mui/material'
import moment from 'moment'
import Actions from '../features/Actions'

export default function IntegrationApiTableItem({ item, setData }) {
    return (
        <>
            <TableCell>{item.moduleId}</TableCell>

            <TableCell>{item.callbackUrl}</TableCell>

            <TableCell>{item.identityCallbackUrl}</TableCell>

            <TableCell>{item.authType}</TableCell>

            <TableCell>{item.username}</TableCell>

            <TableCell>{item.password}</TableCell>

            <TableCell>{item.token}</TableCell>

            <TableCell>{item.tokenType}</TableCell>

            <TableCell>{item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }</TableCell>

            <TableCell style={{ paddingRight: 8 }} align="right">
                <Actions item={item} setData={setData} />
            </TableCell>
        </>
    )
}
