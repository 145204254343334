import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import axios from 'axios'
import JetsonForm from '../components/JetsonForm'
import { usePostRequest } from '../../../shared/hooks/requests'
import { CAMERA_ATTACH_JETSON, JETSON_DEVICES, JETSON_SETUP_CONFIG } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import { domain } from '../../../shared/utils/request'
import { auth } from '../../../shared/utils/auth'
import { DEVICE_MANAGER_URL, GITHUB_TOKEN } from '../constants'

export default function Create({ setData }) {
    const showMessage = useMessage()
    const [open, setOpen] = useState(false)
    const [step, setStep] = useState(1)
    const { handleErrorMsg } = useHandleErrors()
    const createJetson = usePostRequest({ url: JETSON_DEVICES })
    const attachCameras = usePostRequest({ url: CAMERA_ATTACH_JETSON })
    const jetsonConfigFile = usePostRequest({ url: JETSON_SETUP_CONFIG })

    const close = () => {
        setOpen(false)
        setStep(1)
    }

    const fetchConfigFile = async (values, setFieldValue) => {
        if (jetsonConfigFile.loading) return

        const { success, response, error } = await jetsonConfigFile.request({
            data: {
                jetsonDeviceId: values.deviceId,
                githubToken: GITHUB_TOKEN,
                jetsonDeviceManagerUrl: DEVICE_MANAGER_URL,
            },
        })

        if (success) {
            setFieldValue('setupFile', response)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const attachCamera = async (values, setFieldValue) => {
        if (attachCameras.loading) return

        const promises = values.cameraIds.map((cameraId) => axios.post(
            domain + CAMERA_ATTACH_JETSON,
            { camera_id: cameraId, device_id: values.id, action_mode: 'attach' },
            { ...auth() },
        ).then((response) => ({
            success: true,
            response,
        })).catch((error) => ({
            success: false,
            error,
        })))

        const results = await Promise.all(promises)
        const failedResults = results.filter((result) => !result.success)
        if (failedResults.length > 0) {
            failedResults.forEach(({ error }) => handleErrorMsg(error.response || 'Failed to attach some cameras'))
        } else {
            await fetchConfigFile(values, setFieldValue)
            showMessage('Cameras successfully attached', 'success-msg')
            setStep(3)
        }
    }

    const create = async (values, helpers) => {
        if (createJetson.loading || attachCameras.loading) return

        if (step === 4) close()

        const { setFieldValue } = helpers

        if (step === 1) {
            const { success, error, response } = await createJetson.request({ data: values })

            if (success) {
                setStep(2)
                setData((prev) => ({
                    ...prev,
                    items: [...(prev.items || []), response],
                }))
                setFieldValue('id', response.id)
                showMessage('Successfully added', 'success-msg')
            } else if (error) {
                handleErrorMsg(error)
            }
        } else if (step === 2) {
            attachCamera(values, setFieldValue)
        } else if (step === 3) {
            setStep(4)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between py-1 pl-3 pr-1
                 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Jetson device</h3>

                    <IconButton onClick={() => setOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <JetsonForm
                        onClose={close}
                        loading={createJetson.loading}
                        onSubmit={create}
                        step={step}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}
