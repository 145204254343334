import { CircularProgress, List, ListItem, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import humps from 'humps'
import axios from 'axios'
import { useLoad } from '../../../shared/hooks/requests'
import { SMARTCAMERA_GET_FACE_CONFIG, SMARTCAMERA_SET_FACE_CONFIG } from '../../../shared/utils/urls'
import SelectInput from '../../../shared/ui/SelectInput'
import NumberInput from '../../../shared/ui/NumberInput'
import { COLORS } from '../../../shared/utils/colors'
import ToggleSwitch from '../../../shared/ui/ToggleSwitch'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { domain } from '../../../shared/utils/request'
import { auth } from '../../../shared/utils/auth'

const ioControlOpts = [
    { value: '0', label: 'Whitelist' },
    { value: '1', label: 'Blacklist' },
    { value: '3', label: 'Stranger' },
    { value: '4', label: 'VIP' },
    { value: '5', label: 'Whitelist VIP' },
]

const initialValues = {
    IOOutputTime: '',
    IoEnable: false,
    IoController: '',
    strangerFilt: false,
    CmpThreshold: '',
    CompEnable: false,
}

const titleStyles = {
    fontSize: 14,
    fontWeight: '400',
    flex: 1,
    color: COLORS.placeholder,
    fontFamily: 'Gilroy, sans-serif',
}

export default function ComparisonParameters({ id }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [loading, setLoading] = useState(false)
    const loadConfig = useLoad({ url: SMARTCAMERA_GET_FACE_CONFIG, params: { smartCameraId: id } }, [])
    const configData = loadConfig.response ? humps.pascalizeKeys(loadConfig.response) : {}

    const onSubmit = (values) => {
        if (!values || loading) return

        const { IOOutputTime, IoEnable, IoController, strangerFilt, CmpThreshold } = values
        const settings = { IOOutputTime, IoEnable, IoController, strangerFilt, CmpThreshold }
        setLoading(true)
        axios.post(domain + SMARTCAMERA_SET_FACE_CONFIG, settings, { ...auth(), params: { smart_camera_id: id } })
            .then(({ data }) => {
                if (data.success) {
                    setLoading(false)
                    showMessage(data.message, 'success-msg')
                }
            }).catch(({ response }) => {
                setLoading(false)
                handleErrorMsg(response)
            }).finally(() => setLoading(false))
    }

    const reinitValues = {
        ...configData,
        strangerFilt: configData.StrangerFilt,
    }

    return !loadConfig.loading ? (
        <Formik enableReinitialize
            initialValues={{ ...initialValues, ...reinitValues }}
            onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
                <Form className="w-6/12 h-full">
                    <List className="flex-1">
                        <ListItem sx={{ justifyContent: 'space-between' }}>
                            <Typography sx={titleStyles}>
                                Turn On Contrast Control
                            </Typography>

                            <span className="flex flex-[0.5] items-center justify-start">
                                <ToggleSwitch
                                    name="CompEnable"
                                    isChecked={values.CompEnable}
                                    onClick={() => setFieldValue('CompEnable', !values.CompEnable)}
                                />
                            </span>
                        </ListItem>

                        <ListItem sx={{ justifyContent: 'space-between' }}>
                            <Typography sx={titleStyles}>
                                Compare Thresholds
                            </Typography>

                            <span className="flex flex-[0.5] items-center justify-start">
                                <NumberInput min={50} max={100} name="CmpThreshold" />
                            </span>
                        </ListItem>

                        <ListItem sx={{ justifyContent: 'space-between' }}>
                            <Typography sx={titleStyles}>
                                Turn On IO Output
                            </Typography>

                            <span className="flex flex-[0.5] items-center justify-start">
                                <ToggleSwitch
                                    name="IoEnable"
                                    isChecked={values.IoEnable}
                                    onClick={() => setFieldValue('IoEnable', !values.IoEnable)}
                                />
                            </span>
                        </ListItem>

                        <ListItem sx={{ justifyContent: 'space-between' }}>
                            <Typography sx={titleStyles}>
                                IO Controller
                            </Typography>

                            <span className="flex flex-[0.5] items-center justify-start">
                                <SelectInput
                                    fullWidth
                                    name="IoController"
                                    menuItems={ioControlOpts}
                                    value={values.IoController}
                                    onChange={(e) => setFieldValue('IoController', e.target.value)}
                                />
                            </span>
                        </ListItem>

                        <ListItem sx={{ justifyContent: 'space-between' }}>
                            <Typography sx={titleStyles}>
                                Duration Of IO Output
                            </Typography>

                            <span className="flex flex-[0.5] items-center justify-start">
                                <NumberInput min={100} max={60000} name="IOOutputTime" />
                            </span>
                        </ListItem>

                        <ListItem sx={{ justifyContent: 'space-between' }}>
                            <Typography sx={titleStyles}>
                                Stranger Deduplication
                            </Typography>

                            <span className="flex flex-[0.5] items-center justify-start">
                                <ToggleSwitch
                                    name="strangerFilt"
                                    isChecked={values.strangerFilt}
                                    onClick={() => setFieldValue('strangerFilt', !values.strangerFilt)}
                                />
                            </span>
                        </ListItem>
                    </List>

                    <div className="p-4 flex items-center justify-center">
                        <PrimaryBtn title="Set" type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    ) : (
        <div className="flex items-center justify-center w-full h-full">
            <CircularProgress />
        </div>
    )
}
