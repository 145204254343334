import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { Search } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { FaqIcon } from '../../../components/svgs/Svgs'
import { useLoad } from '../../../shared/hooks/requests'
import { TENANT_PROFILE } from '../../../shared/utils/urls'
import TenantProfilesTableItem from './TenantProfilesTableItem'
import Table from '../../../shared/ui/Table'
import Create from '../features/Create'
import useTenantProfileStore from '../../../shared/store/tenantProfileStore'

const cols = [
    { id: 1, title: 'Created time' },
    { id: 2, title: 'Name' },
    { id: 3, title: 'Description' },
    { id: 4, title: 'Action', width: '10%', align: 'right' },
]

export default function TenantsProfilesTable() {
    const { setResponse, profileResponse } = useTenantProfileStore()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const loadProfiles = useLoad({ url: `${TENANT_PROFILE}/`,
        params: { size: pageSize, page } }, [page, pageSize])
    const data = profileResponse ? profileResponse.items : []
    const meta = profileResponse || {}

    useEffect(() => {
        setResponse(loadProfiles.response)
    }, [loadProfiles.response])

    return (
        <div className="bg-white">
            <div className="flex items-center justify-between h-[60px] px-4">
                <h3 className="flex-cent gap-1_5 text-m font-600">Tenant profiles <FaqIcon /></h3>

                <div className="flex-cent gap-2">
                    <Create data={data} />

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            <Table
                page={page - 1}
                loading={loadProfiles.loading}
                totalCount={meta.total}
                rowsPerPage={pageSize}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10))
                    setPage(1)
                }}
                cols={cols}
                rows={data}
                renderItem={(item) => (
                    <TenantProfilesTableItem
                        key={uuid()}
                        item={item} />
                )}
            />
        </div>
    )
}
