import React from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import FormTextField from '../../../shared/ui/FormTextField'

const FORM_INITIAL_STATE = {
    name: '',
    username: '',
    password: '',
    cudaVersion: '',
    deepstreamVersion: '',
    jetpackVersion: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    username: string().required('Username is required'),
    password: string().required('Password is required'),
    cudaVersion: string().test(
        'is-number',
        'Version must be either a valid decimal or an integer',
        (value) => (`${value}`).match(/^\d+(\.\d+)?$/),
    ).required('Cuda Version is required'),
    deepstreamVersion: string().required('Deepstream Version is required'),
    jetpackVersion: string().required('Jetpack Version is required'),
})

export default function JetsonProfileForm({
    onSubmit = () => {},
    onClose = () => {},
    isEdit = false,
    initialValues,
    loading = false,
}) {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ handleChange }) => (
                <Form className="h-layout flex flex-col p-4 justify-between gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            required
                            label="Name"
                            name="name"
                            handleChange={handleChange} />

                        <FormTextField
                            required
                            label="Username"
                            name="username"
                            handleChange={handleChange} />

                        <FormTextField
                            required
                            label="Password"
                            name="password"
                            handleChange={handleChange} />

                        <FormTextField
                            required
                            label="Cuda version"
                            name="cudaVersion"
                            handleChange={handleChange} />

                        <FormTextField
                            required
                            label="Deepstream version"
                            name="deepstreamVersion"
                            handleChange={handleChange} />

                        <FormTextField
                            required
                            label="Jetpack version"
                            name="jetpackVersion"
                            handleChange={handleChange} />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
