import React from 'react'
import { Dialog, DialogContent } from '@mui/material'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import useTenantActions from './actions'

export default function Delete({ id, setData, isOpen, close }) {
    const { remove, deleteLoading } = useTenantActions(id, setData)

    return (
        <Dialog fullWidth open={isOpen} onClose={close}>
            <DialogContent
                style={{ padding: 20 }}
                sx={{ '& .MuiPaper-root': { width: 'fit-content' } }}>
                <div className="flex min-h-40 flex-col items-center justify-between">
                    <div className="w-full flex-col items-center justify-center gap-6">
                        <h3 className="text-xl font-600">Are you sure you want to delete?</h3>

                        <h3 className="text-l font-400 text-center">
                            Confirm Deletion This Action Is Irreversible
                        </h3>
                    </div>

                    <div className="flex-cent gap-2_5">
                        <CancelBtn type="button" title="Cancel" onClick={close} />

                        <PrimaryBtn loading={deleteLoading} type="button" title="Delete" onClick={remove} />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
