import React, { useState, useEffect } from 'react'
import { Checkbox,
    Chip,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Skeleton,
    Box,
    FormControl,
    InputLabel,
    FormHelperText } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useField } from 'formik'
import { COLORS } from '../utils/colors'

export default function MultiSelect({
    loading = false,
    label,
    options = [],
    overrideStyles,
    name,
    onOpen = () => {},
    required,
    onChange,
    selectedValues: initialSelectedValues,
}) {
    const [selectedValues, setSelectedValues] = useState(initialSelectedValues || [])
    // const [deletedValues, setDeletedValues] = useState([])
    const [, meta] = useField(name)

    useEffect(() => {
        setSelectedValues(initialSelectedValues || [])
    }, [initialSelectedValues])

    const handleChange = (event) => {
        const newValues = event.target.value
        setSelectedValues(newValues)
        if (typeof onChange === 'function') {
            onChange({ selected: newValues, deleted: [] })
        }
    }

    const handleDelete = (valueToDelete) => (event) => {
        event.stopPropagation()
        const newValues = selectedValues.filter((value) => value !== valueToDelete)
        const deletedValues = selectedValues.filter((value) => value === valueToDelete)
        setSelectedValues(newValues)
        if (typeof onChange === 'function') {
            onChange({ selected: newValues, deleted: deletedValues })
        }
    }

    return (
        <FormControl fullWidth variant="standard" sx={{ ...overrideStyles }}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                required={required}
                name={name}
                multiple
                onOpen={onOpen}
                disableUnderline
                sx={{
                    '& .MuiInputBase-input': {
                        paddingBottom: '8px',
                        fontSize: 16,
                        fontWeight: 400,
                        color: COLORS.black,
                        borderBottom: '1px solid #7C7D7E',
                        ':focus': {
                            backgroundColor: 'transparent',
                        },
                        ':hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: 16,
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.54)',
                    },
                }}
                value={selectedValues}
                onChange={handleChange}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip
                                key={value}
                                sx={{ borderRadius: '5px' }}
                                label={options.find((option) => option.value === value)
                                    && options.find((option) => option.value === value).label}
                                onDelete={handleDelete(value)}
                                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                            />
                        ))}
                    </Box>
                )}
            >
                {!loading && options.length ? options.map((menuItem) => (
                    <MenuItem key={menuItem.value} value={menuItem.value}>
                        <ListItemText>{menuItem.label}</ListItemText>
                        <ListItemIcon>
                            <Checkbox checked={selectedValues.includes(menuItem.value)} />
                        </ListItemIcon>
                    </MenuItem>
                )) : loading ? (
                    <MenuItem>
                        <Skeleton width="50%" height={30} />
                    </MenuItem>
                ) : (
                    <MenuItem value={0}>Nothing Found</MenuItem>
                )}
            </Select>

            <FormHelperText sx={{ color: COLORS.red }}>{meta.error}</FormHelperText>
        </FormControl>
    )
}
