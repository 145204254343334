import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { useMessage } from '../../../shared/hooks/message'
import { useDeleteRequest, usePutRequest } from '../../../shared/hooks/requests'
import { TENANTS } from '../../../shared/utils/urls'

export default function useTenantActions(id, setData, close = () => {}) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateTenant = usePutRequest({ url: `${TENANTS}/${id}` })
    const deleteTenant = useDeleteRequest({ url: `${TENANTS}/${id}` })

    const update = async (values, isEdit, setIsEdit) => {
        if (isEdit) {
            if (updateTenant.loading || !values) return

            const { success, response, error } = await updateTenant.request({
                data: { ...values },
            })

            if (success) {
                setData((prev) => ({
                    ...prev,
                    items: prev.items.map((val) => (
                        val.id === response.id ? { ...val, ...response } : val
                    )),
                }))
                setIsEdit(false)
                showMessage('Successfully updated', 'success-msg')
            } else if (error) {
                handleErrorMsg(error)
            }
        } else {
            setIsEdit(true)
        }
    }

    const remove = async () => {
        if (deleteTenant.loading || !id) return

        const { success, error } = await deleteTenant.request()

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.filter((v) => v.id !== id),
            }))
            showMessage('Successfully deleted', 'success-msg')
            close()
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return {
        update,
        updateLoading: updateTenant.loading,
        remove,
        deleteLoading: deleteTenant.loading,
    }
}
