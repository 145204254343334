import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { COLORS } from '../../../shared/utils/colors'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { useMessage } from '../../../shared/hooks/message'
import { SMART_CAMERA_PROFILE } from '../../../shared/utils/urls'
import { useDeleteRequest } from '../../../shared/hooks/requests'

export default function Delete({ id, setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const deleteProfile = useDeleteRequest()

    const remove = async () => {
        if (deleteProfile.loading || !id) return

        const { success, error } = await deleteProfile.request({ url: `${SMART_CAMERA_PROFILE}/${id}` })

        if (success) {
            setData((prev) => prev.filter((v) => v.id !== id))
            showMessage('Successfully deleted', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <DeleteIcon
                    htmlColor={COLORS.disabled}
                    className="cursor-pointer"
                    fontSize="medium" />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogContent
                    style={{ padding: 20 }}
                    sx={{ '& .MuiPaper-root': { width: 'fit-content' } }}>
                    <div className="flex min-h-40 flex-col items-center justify-between">
                        <div className="w-full flex-col items-center justify-center gap-6">
                            <h3 className="text-xl font-600">Are you sure you want to delete?</h3>

                            <h3 className="text-l font-400 text-center">
                                Confirm Deletion This Action Is Irreversible
                            </h3>
                        </div>

                        <div className="flex-cent gap-2_5">
                            <CancelBtn type="button" title="Cancel" onClick={() => setOpen(false)} />

                            <PrimaryBtn
                                loading={deleteProfile.loading}
                                type="button"
                                title="Delete"
                                onClick={remove} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
