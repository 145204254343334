import React, { useState } from 'react'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import TenantForm from './TenantForm'
import useTenantActions from '../features/actions'

const navItems = [
    { id: 1, name: 'Details' },
]

export default function TenantDetails({ item, onClose, setData }) {
    const [section, setSection] = useState('Details')
    const [isEdit, setIsEdit] = useState(false)
    const { update, updateLoading, remove, deleteLoading } = useTenantActions(item.id, setData)

    const initialValues = {
        name: item.name || '',
        tenantProfileId: item.tenantProfileId || null,
        districtId: item.districtId || null,
        countryId: item.countryId || null,
        regionId: item.regionId || null,
        description: item.description || '',
        phone: item.phone || '',
        email: item.email || '',
    }

    return (
        <DrawerLayout
            isEdit={isEdit}
            topic="Tenant details"
            onClose={onClose}
            onDeleteIconClick={() => {
                if (isEdit) {
                    setIsEdit(false)
                } else {
                    remove()
                }
            }}
            deleteLoading={deleteLoading}
            updateLoading={updateLoading}
            name={item.name}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.name)}
                                className={
                                    `cursor-pointer text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.name ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                <TenantForm
                    isEdit={isEdit}
                    loading={updateLoading}
                    onClose={onClose}
                    onSubmit={(values) => update(values, isEdit, setIsEdit)}
                    initialValues={initialValues} />
            </React.Fragment>
        </DrawerLayout>
    )
}
