import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../shared/utils/colors'
import Table from '../../../shared/ui/Table'

function EntityClientScamerasTableItem(item, idx) {
    return (
        <tr className={css(s.row)}>
            <td>{idx + 1}.</td>

            <td>ccsdc</td>

            <td>csdcsd</td>
        </tr>
    )
}

const headerItems = [
    { id: 1, title: '№' },
    { id: 2, title: 'Device name' },
    { id: 3, title: 'Added time' },
]

export default function EntityUserScameras({ data }) {
    return (
        <Table
            rows={data}
            headerClassName={s.tHead}
            loaderItemCount={5}
            columns={headerItems}
            renderItem={(item, idx) => (
                <EntityClientScamerasTableItem
                    idx={idx}
                    item={item} />
            )}
        />
    )
}

const s = StyleSheet.create({
    row: {
        background: COLORS.white,
        color: COLORS.black,
        fontSize: 13,
        fontWeight: '400',
        userSelect: 'none',
        borderBottom: `1px solid ${COLORS.sepia}`,
        ':nth-child(1n) > td:first-child': {
            paddingLeft: 23,
        },
        ':nth-child(1n) > *': {
            textAlign: 'left',
            padding: '16px 0',
        },
        ':nth-child(1n) > td:last-child': {
            ':nth-child(1n) > :only-child': {
                paddingRight: 35,
            },
        },
    },
    userImg: {
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
            objectFit: 'cover',
        },
    },
    tHead: {
        ':nth-child(1n) > tr': {
            ':nth-child(1n) > *': {
                textAlign: 'left',
            },
            ':nth-child(1n) > th:first-child': {
                width: '15%',
            },
        },
    },
})
