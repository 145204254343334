import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { COLORS } from '../../shared/utils/colors'

export default function SidebarItem({ link, isSidebarOpen, padded = false }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [expand, setExpand] = useState(false)

    const handleNavigation = (path, disabled, onClick) => {
        if (!disabled && path) {
            navigate(path)
        } else if (!disabled && !path && onClick) {
            onClick()
        }
    }

    return (
        <ListItem disablePadding sx={{ display: 'block', paddingLeft: padded ? 2 : 0 }}>
            <span>
                <ListItemButton
                    disabled={link.disabled}
                    sx={{
                        minHeight: 48,
                        justifyContent: isSidebarOpen ? 'initial' : 'center',
                        px: 2,
                        backgroundColor: location.pathname === link.path ? COLORS.opacityBlue : 'inherit',
                        ':hover': {
                            backgroundColor: COLORS.opacityBlue,
                        },
                    }}
                    onClick={() => {
                        if (link.links) {
                            setExpand(!expand)
                        }
                        handleNavigation(link.path, link.disabled, link.onClick)
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: isSidebarOpen ? 1 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <link.Icon />
                    </ListItemIcon>

                    <ListItemText
                        primary={link.title}
                        className="text-lg text-white font-medium"
                        sx={{ opacity: isSidebarOpen ? 1 : 0 }}
                    />

                    {link.links && isSidebarOpen && (
                        <ListItemIcon
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setExpand(!expand)
                            }}
                            sx={{
                                minWidth: 0,
                                justifyContent: 'center',
                            }}
                        >
                            {expand ? <ExpandLess htmlColor={COLORS.white} /> : <ExpandMore htmlColor={COLORS.white} />}
                        </ListItemIcon>
                    )}
                </ListItemButton>
            </span>

            <Collapse in={expand} timeout="auto" unmountOnExit>
                {link.links && link.links.map((item) => (
                    <SidebarItem key={item.id} link={item} padded isSidebarOpen={isSidebarOpen} />
                ))}
            </Collapse>
        </ListItem>
    )
}
