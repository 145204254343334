import React, { useState } from 'react'
import { Drawer, IconButton, TableCell } from '@mui/material'
import moment from 'moment/moment'
import { Edit } from '@mui/icons-material'
import Delete from '../features/Delete'
import MLModelDetails from './MLModelDetails'
import { COLORS } from '../../../shared/utils/colors'
import Predict from '../features/Predict'

export default function MLModelTableItem({ item, $api, profiles }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const userType = localStorage.getItem('userType')

    return (
        <>
            <TableCell className="cursor-pointer" onClick={() => setOpenDrawer(true)}>
                {item.mlModelProfile.name}
            </TableCell>

            <TableCell onClick={() => setOpenDrawer(true)}
                className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.description}
            </TableCell>

            <TableCell className="cursor-pointer" onClick={() => setOpenDrawer(true)}>
                {item.version}
            </TableCell>

            <TableCell>{item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }</TableCell>

            <TableCell style={{ paddingRight: 8 }} align="right">
                {userType === 'system_admin' ? (
                    <div className="h-full flex items-center justify-end">
                        <IconButton className="p-0" onClick={() => setOpenDrawer(true)}>
                            <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
                        </IconButton>

                        <Delete id={item.id} setData={$api.setResponse} />
                    </div>
                ) : (
                    <Predict
                        pipeline={item.s3ModelPipelinePath}
                        version={item.version}
                        mlName={item.mlModelProfile.name} />
                )}
            </TableCell>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}>
                <MLModelDetails
                    profiles={profiles}
                    onClose={() => setOpenDrawer(false)}
                    item={item} />
            </Drawer>
        </>
    )
}
