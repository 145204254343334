export const COLORS = {
    mainBlueBg: '#305680',
    mainBlue: '#2A4A6B',
    darkBlue: '#1E3C5E',
    white: '#FFF',
    black: '#000000',
    lightBlack: '#1D1E1F',
    red: '#D90714',
    lightRed: '#E7010F',
    orange: '#FF5722',
    sepia: '#EEE',
    defaultInpBg: '#F3F5F8',
    skeletonBg: '#DBDBDB',
    lightGray: '#DFE4EB',
    green: '#19C215',
    defInpBc: '#8D8E90',
    inpHoverBg: '#EDF0F4',
    disabled: '#AFAFAF',
    gray: '#818181',
    placeholder: '#7C7D7E',
    gainsboro: '#DFDFDF',
    opacityBlue: '#4F6F93',
    blackTxt: '#17181A',
    graySubTxt: '#78818A',
    logoutBtn: '#F5F5F6',
    dashBg: '#ECECEC',
    cardInnerBg: '#F7F7F7',
    btnBlue: '#0085FF',
    diamondWhite: '#F3F5F8',
    pink: '#FBE9E7',
}
