import { Form, Formik } from 'formik'
import React from 'react'
import { object, string } from 'yup'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const FORM_INITIAL_STATE = {
    applicationName: '',
    applicationVersion: '',
    applicationGithubUrl: '',
}

const VALIDATION_SCHEMA = object().shape({
    applicationName: string().required('Application name is required'),
    applicationVersion: string().optional(),
    applicationGithubUrl: string().optional(),
})

export default function DeepstreamForm({ initialValues = {}, onSubmit, onClose, loading, isEdit }) {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...initialValues,
                ...FORM_INITIAL_STATE,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ handleChange }) => (
                <Form className="h-[60%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            required
                            label="Name"
                            name="applicationName"
                            handleChange={handleChange} />

                        <FormTextField
                            label="Version"
                            name="applicationVersion"
                            handleChange={handleChange} />

                        <FormTextField
                            label="Github url"
                            name="applicationGithubUrl"
                            handleChange={handleChange} />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
