import { IconButton, MenuItem, Paper, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Search } from '@mui/icons-material'
import Skeleton from 'react-loading-skeleton'
import uuid from 'react-uuid'
import Table from '../../../shared/ui/Table'
import DeepstreamAppsTableItem from './DeepstreamAppsTableItem'
import { useLoad, usePostRequest } from '../../../shared/hooks/requests'
import { BUILDINGS_TENANT,
    JETSON_ACTIVE_DEVICES_LIST,
    JETSON_DEVICE_DEEPSTREAM_APPS,
    ROOMS_TENANT_UNPAGINATED } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import Create from '../features/Create'
import CameraFilter from '../../../shared/filter/CameraFilter'

const cols = [
    { id: 1, title: 'Name' },
    { id: 2, title: 'Path' },
    { id: 3, title: 'Version' },
    { id: 4, title: 'Status' },
    { id: 5, title: 'Action', width: '10%', align: 'right' },
]

export default function DeepstreamAppsTable() {
    const { jetsonFilter: { jetsonDeviceId }, cameraFilter, setJetsonFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const getDeepstreamApps = usePostRequest({ url: JETSON_DEVICE_DEEPSTREAM_APPS })
    const data = getDeepstreamApps.response ? getDeepstreamApps.response : []
    const loadActiveJetsons = useLoad({ url: JETSON_ACTIVE_DEVICES_LIST })
    const jetsonDevices = loadActiveJetsons.response ? loadActiveJetsons.response.map((device) => ({
        value: device, label: device,
    })) : []

    useEffect(() => {
        if (cameraFilter.rId) {
            loadActiveJetsons.request({ params: { roomId: cameraFilter.rId } })
        }
    }, [cameraFilter.rId])

    useEffect(() => {
        if (jetsonDeviceId) {
            getDeepstreamApps.request({ data: { jetsonDeviceId } }).then(({ response }) => {
                const modifiedData = response ? response.map((item) => ({ id: uuid(), ...item })) : []
                getDeepstreamApps.setResponse(modifiedData)
            })
        }
    }, [jetsonDeviceId])

    return (
        <div className="w-full flex flex-col gap-6">
            <CameraFilter
                buildingUrl={BUILDINGS_TENANT}
                roomUrl={ROOMS_TENANT_UNPAGINATED}
                otherFilters={[
                    <Select
                        key={uuid()}
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? jetsonDevices.find((v) => v.value === value)
                    && jetsonDevices.find((v) => v.value === value).label : 'Choose device')}
                        sx={{ height: 37, width: '10rem' }}
                        value={jetsonDeviceId}
                        onChange={(e) => setJetsonFilter(() => ({ jetsonDeviceId: e.target.value }))}
                    >
                        {!loadActiveJetsons.loading && jetsonDevices.length ? jetsonDevices.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        )) : loadActiveJetsons.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>,
                ]} />

            <Paper square className="bg-white max-w-full">
                <div className="flex items-center justify-between h-[60px] px-4">
                    <Typography fontWeight="500" fontSize={16} className="flex-cent gap-1.5">
                        Deepstream Applications
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <Create jetsonDeviceId={jetsonDeviceId} />

                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>

                <Table
                    page={page - 1}
                    loading={getDeepstreamApps.loading}
                    totalCount={0}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    paginated={0}
                    renderItem={(item) => (
                        <DeepstreamAppsTableItem
                            key={item.name}
                            item={item}
                            setData={getDeepstreamApps.setResponse}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
