import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import { usePostRequest } from '../../../shared/hooks/requests'
import { TENANT_ADMIN_ACTIVATION_CODE, TENANT_ADMIN_INACTIVE } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import TenantAdminForm from '../components/TenantAdminForm'
import ActivationLinkModal from '../../../shared/ui/ActivationLinkModal'

export default function Create({ setData }) {
    const [open, setOpen] = useState(false)
    const [activationLink, setActivationLink] = useState('')
    const showMessage = useMessage()
    const { id } = useParams()
    const { handleErrorMsg } = useHandleErrors()
    const createAdmin = usePostRequest({ url: TENANT_ADMIN_INACTIVE })
    const requestCode = usePostRequest({ url: `${TENANT_ADMIN_ACTIVATION_CODE}/` })

    const create = async (values) => {
        if (createAdmin.loading || !values) return

        const { success, response, error } = await createAdmin.request({ data: { ...values, tenantId: +id } })

        if (success) {
            const res = await requestCode.request({ params: { tenantAdminId: response.id } })
            if (res.success) {
                setActivationLink(`${window.location.origin}/new-password?code=${res.response.code}`)
                setData((prev) => ({
                    ...prev,
                    items: [...(prev.items || []), response],
                }))
                showMessage('Successfully added', 'success-msg')
            } else if (res.error) {
                handleErrorMsg(res.error)
            }
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog sx={{ '& .MuiDialog-paper': { maxWidth: '100%', width: 'fit-content' } }}
                fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Tenant Admin</h3>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    {activationLink
                        ? <ActivationLinkModal url={activationLink} onClose={() => setOpen(false)} />
                        : (
                            <TenantAdminForm
                                onClose={() => setOpen(false)}
                                onSubmit={create}
                                loading={createAdmin.loading} />
                        )}
                </DialogContent>
            </Dialog>
        </>
    )
}
