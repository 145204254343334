import React from 'react'
import moment from 'moment/moment'
import { TableCell } from '@mui/material'
import Update from '../features/Update'
import Delete from '../features/Delete'

export default function ServiceModuleTableItem({ item, setData }) {
    return (
        <>
            <TableCell>
                {item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }
            </TableCell>

            <TableCell>
                {item.name}
            </TableCell>

            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.description}
            </TableCell>

            <TableCell style={{ paddingRight: 0 }} align="right">
                <div className="h-full flex items-center justify-end">
                    <Update item={item} setData={setData} />

                    <Delete id={item.id} setData={setData} />
                </div>
            </TableCell>
        </>
    )
}
