import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import cn from 'classnames'
import { COLORS } from '../../shared/utils/colors'

export const Popup = forwardRef(({
    trigger,
    children,
    items,
    renderItem,
    popupClassName,
    position = 'right',
}, ref) => {
    const [open, setOpen] = useState(false)
    const popupRef = useRef()
    const triggerRef = useRef()

    function outsideDetector(event) {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            if (triggerRef.current && triggerRef.current.contains(event.target)) return
            setOpen(false)
        }
    }

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', outsideDetector)
            return () => {
                document.removeEventListener('mousedown', outsideDetector)
            }
        }
    }, [open])

    useImperativeHandle(ref, () => ({
        onOpen() { setOpen(true) },
        onClose() { setOpen(false) },
    }), [])

    return (
        <div id="mn-popup" className="relative">
            {trigger && (
                <div
                    className="flex items-center justify-end"
                    ref={triggerRef}
                    onClick={(event) => {
                        event.stopPropagation()
                        setOpen(!open)
                    }}
                >
                    {trigger}
                </div>
            )}

            {open && (
                <div
                    ref={popupRef}
                    className={cn(
                        'z-index-1',
                        popupClassName,
                        css(s.popup, position === 'left' ? s.isLeft : s.isRight),
                    )}>
                    {!isEmpty(items) ? (
                        <ul className={css(s.pSet)}>
                            {items.map((item) => renderItem(item, setOpen))}
                        </ul>
                    ) : children }
                </div>
            )}
        </div>
    )
})

const s = StyleSheet.create({
    popup: {
        position: 'absolute',
        backgroundColor: COLORS.white,
    },
    isRight: {
        right: 0,
    },
    isLeft: {
        left: 0,
    },
    pSet: {
        margin: 0,
        ':nth-child(1n) > *': {
            fontSize: 16,
            fontWeight: '400',
            padding: 7,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            ':hover': {
                background: COLORS.cardInnerBg,
            },
        },
        ':nth-child(1n) > :last-child': {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
        },
    },
})
