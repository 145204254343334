// TODO optimize ProtectedRoute logic
import React from 'react'
import { Navigate } from 'react-router-dom'

export function ProtectedRoute({
    children,
    protectedBy,
    redirectPath = '/auth',
}) {
    if (protectedBy) {
        return children
    }

    return <Navigate to={redirectPath} replace />
}
