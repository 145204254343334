import React, { useState } from 'react'
import { Dialog, DialogContent, TableCell, Typography } from '@mui/material'
import Details from '../feature/details'
import { XIconSizeM } from '../../../components/svgs/Svgs'

export default function AuthLogTableItems({ item }) {
    const [personImage, setPersonImage] = useState('')
    const [showFull, setShowFull] = useState(false)

    const toggleUserId = () => {
        setShowFull(!showFull)
    }

    return (
        <>
            <TableCell onClick={toggleUserId}>
                {item.externalUserId ? (showFull ? item.externalUserId
                    : `${item.externalUserId.slice(0, 3)}`) : 'N/A'}
            </TableCell>

            <TableCell>
                {item.client.clientName}
            </TableCell>

            <TableCell>
                {item.individualPersonalNumber}
            </TableCell>

            <TableCell className="whitespace-nowrap">
                {item.createdAt.slice(0, 16).replace('T', ' ')}
            </TableCell>

            <TableCell align="center" onClick={() => setPersonImage(item.referenceImageUrl)}>
                <div className="flex cursor-pointer rounded w-[53px] my-1 border
                    border-sepia-100 h-inherit items-center justify-center overflow-hidden">
                    <img src={item.referenceImageUrl} alt={item.referenceImageObjectName}
                        className="h-full w-full object-cover" />
                </div>
            </TableCell>

            <TableCell align="center" onClick={() => {
                setPersonImage(item.targetImageUrl)
            }}>
                <div className="flex cursor-pointer rounded w-[53px] my-1 border
                    border-sepia-100 h-inherit items-center justify-center overflow-hidden">
                    <img src={item.targetImageUrl} alt={item.targetImageObjectName}
                        className="h-full w-full object-cover" />
                </div>
            </TableCell>

            <TableCell className="flex items-center justify-center" align="center">
                {item.apiType}
            </TableCell>

            <TableCell className="flex items-center justify-center" align="center">
                {item.isAuthenticated === true ? (
                    <span>True</span>
                ) : (
                    <span>False</span>
                )}
            </TableCell>

            <TableCell className="flex items-center justify-center" align="center">
                {item.distance.toFixed(3)}
            </TableCell>

            <TableCell className="flex items-center justify-center" align="center">
                {item.isFaceMatched === true ? (
                    <span>True</span>
                ) : (
                    <span>False</span>
                )}
            </TableCell>

            <TableCell className="flex items-center justify-center" align="center">
                {item.isSpoofed === true ? (
                    <span>True</span>
                ) : (
                    <span>False</span>
                )}
            </TableCell>

            <TableCell className="flex items-center justify-center" align="center">
                {item.spoofingScore.toFixed(3)}
            </TableCell>

            <TableCell style={{ paddingRight: 0 }} align="center">
                <Details item={item} />
            </TableCell>

            <Dialog fullWidth onClose={() => setPersonImage('')} open={Boolean(personImage)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>Image</Typography>

                    <XIconSizeM className="cursor-pointer" onClick={() => setPersonImage('')} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <img className="object-cover w-full h-full" src={personImage} alt="PERSONIMAGE" />
                </DialogContent>
            </Dialog>
        </>
    )
}
