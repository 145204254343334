import React from 'react'
import moment from 'moment/moment'
import { TableCell } from '@mui/material'

export default function MasterUserTableItems({ item }) {
    return (
        <>
            <TableCell>
                {item.clientId ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }
            </TableCell>

            <TableCell className="cursor-pointer flex items-center justify-center">
                <div className="w-[100%] h-auto flex items-center justify-center">
                    {item.username}
                </div>
            </TableCell>

            <TableCell className=" cursor-pointer flex items-center justify-center">
                <div className="w-[100%] h-auto flex items-center justify-center">
                    {item.usersCount}
                </div>
            </TableCell>
        </>
    )
}
