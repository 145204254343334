import React from 'react'
import { Button } from '@mui/material'
import { COLORS } from '../utils/colors'

export default function CancelBtn({ title, onClick, type }) {
    return (
        <Button
            onClick={onClick}
            type={type}
            variant="contained"
            sx={{
                backgroundColor: COLORS.sepia,
                borderRadius: 2,
                boxShadow: 'none',
                fontSize: 14,
                fontWeight: '400',
                textTransform: 'none',
                color: COLORS.disabled,
                ':hover': {
                    backgroundColor: COLORS.sepia,
                },
            }}>
            {title}
        </Button>
    )
}
