import React, { useEffect, useState } from 'react'
import { Decrease, Increase } from '../../../components/svgs/Svgs'

export function StaticCard({ cardIcon: IconComponent, cardTitle, sm, cardValue, manValue, percentage, womanValue }) {
    const [currentDate, setCurrentDate] = useState(new Date())

    useEffect(() => {
        const now = new Date()
        const msUntilMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) - now

        const timer = setTimeout(() => {
            setCurrentDate(new Date())
        }, msUntilMidnight)

        return () => clearTimeout(timer)
    }, [currentDate])

    return (
        <div className={`w-[100%] ${sm
            ? 'border border-borderColor' : ''}
             h-[100%] flex flex-col gap-[9px] rounded-[14px] bg-white px-[12px] pt-[12px] pb-[14px]`}>
            <div className="flex items-start justify-between">
                <div className="flex flex-col items-start gap-[9px]">
                    <div className={` ${sm ? 'py-[5px] px-[5px]' : 'py-[12px] px-[12px]'} rounded-[8px] bg-svgBlue`}>
                        {IconComponent && <IconComponent width={sm ? 16 : 21} height={sm ? 16 : 21} />}
                    </div>
                    <span className={`${sm ? 'text-[12px]' : 'text-[18px]'} font-medium text-black`}>{cardTitle}</span>
                </div>
                <span className={`${sm ? 'text-[12px]' : 'text-[14px]'}`}>{currentDate.toLocaleDateString()}</span>
            </div>
            <div className="flex flex-col items-start gap-[5px]">
                <div className="flex items-center gap-[4px]">
                    <span className={`${sm
                        ? 'text-[21px]' : 'text-[45px]'} font-semibold text-black`}>{cardValue}
                    </span>
                    <div className=" w-auto flex items-center gap-[1px] h-auto">
                        {percentage === 0 ? null : (percentage > 1 ? (<Increase />) : (<Decrease />))}
                        {percentage === 0 ? null : (
                            <span className={`${percentage > 1
                                ? 'text-green-500' : 'text-red-500'}`}>{percentage}%
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex items-center gap-[25px]">
                    <div className="flex items-center gap-[5px]">
                        <span className={`${sm ? 'text-[12px]'
                            : 'text-[14px]'} font-medium text-black`}>Ayol:
                        </span>
                        <span className={`${sm ? 'text-[12px]'
                            : 'text-[14px]'} font-normal text-blue-700`}>{womanValue}
                        </span>
                    </div>
                    <div className="flex items-center gap-[5px]">
                        <span className={`${sm ? 'text-[12px]'
                            : 'text-[14px]'} font-medium text-black`}>Erkak:
                        </span>
                        <span className={`${sm ? 'text-[12px]'
                            : 'text-[14px]'} font-normal text-orange-400`}>{manValue}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
