import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import moment from 'moment/moment'
import { range } from 'lodash'
import { COLORS } from '../../../shared/utils/colors'
import { LoadingSkeleton } from '../../../components/common/LoadingSkeleton'

function Skeleton() {
    return (
        <div key={uuid()} className="flex-col gap-2">
            <div className={cn(css(s.faces), 'flex items-center gap-2_5')}>
                <div className="rounded-s">
                    <LoadingSkeleton width={45} height={45} />
                </div>

                <div className="flex-col gap-1">
                    <LoadingSkeleton height={16} />

                    <LoadingSkeleton height={14} />
                </div>
            </div>
        </div>
    )
}

export default function AttendanceSections({ data, loading }) {
    return (
        <div className={cn(css(s.cont), 'h-full flex-col overflowY-auto gap-2 dis_scrollbar')}>
            {!loading ? data.map((item) => (
                <div key={uuid()} className="flex-col gap-2">
                    <div className={cn(css(s.faces), 'flex items-center gap-2_5')}>
                        <div className="rounded-s">
                            <img src={item.snapshotUrl} alt="#USER" />
                        </div>

                        <div className="flex-col gap-1">
                            <h3 className="text-m font-500 text-blue">
                                {item.userName
                                || (item.wanted && `${item.wanted.firstName} ${item.wanted.lastName}`)
                                   || (item.identity && `${item.identity.firstName} ${item.identity.lastName}`)
                                || 'Not Found'}
                            </h3>

                            <h3 className="text-s font-400 text-black">
                                {moment(item.attendanceDatetime).format('HH:mm')}
                            </h3>
                        </div>
                    </div>
                </div>
            )) : range(10).map(() => <Skeleton key={uuid()} />)}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        overflowX: 'hidden',
    },
    faces: {
        borderBottom: `1px solid ${COLORS.sepia}`,
        paddingBottom: 5,
        ':nth-child(1n) > :first-child': {
            width: 45,
            height: 45,
            overflow: 'hidden',
            ':nth-child(1n) > img': {
                width: 'inherit',
                height: 'inherit',
                objectFit: 'center',
            },
        },
    },
})
