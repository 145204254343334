import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { Grid } from '@mui/material'
import { StaticCard } from '../../dashboard/components/staticCard'
import { Days, Month, Weeks } from '../../../components/svgs/Svgs'
import { AnalyticCard } from '../../dashboard/components/analyticCard'
import { PieCard } from '../../dashboard/components/pieCard'
import StackedBarChart from '../../../components/common/chart'
import { ChartLine } from '../../../components/common/lineChart'
import HorizontalChart from '../../../components/common/horizontalChart'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { GetBranchData } from '../features/getBranchData'

export function BranchCard({ id, onCheckboxChange, name, entityLowerList }) {
    const { branchEntityFilter } = useFilter()
    const filterToUse = branchEntityFilter.filter1 !== 'all' && entityLowerList && entityLowerList.length >= 1
        ? branchEntityFilter.filter2 : branchEntityFilter.filter1

    const { daily, weekly, monthly,
        monthlyBreak, agely, hourlyData } = GetBranchData({ filter: filterToUse, id })

    const handleChange = (event) => {
        onCheckboxChange(id, event.target.checked)
    }

    return (
        <Grid item flex={1} sx={{ flexDirection: 'column' }}
            className="gap-[20px] pt-[11px] bg-white rounded-[14px] px-[12px] pb-[22px]">
            <div className="flex items-center justify-between">
                <span className="text-[18px] font-normal text-black">{name}</span>

                {branchEntityFilter.filter2 === 'all' && entityLowerList && entityLowerList.length > 1
                    ? (
                        <Checkbox onChange={handleChange}
                            sx={{ color: '#FF5722', '&.Mui-checked': { color: '#FF5722' } }} />
                    ) : (
                        null
                    )}
            </div>

            <div className="flex w-[100%] flex-col items-center gap-[6px]">
                <div className="grid w-[100%] grid-cols-3 gap-[9px]">
                    <StaticCard
                        cardIcon={Days} sm
                        cardTitle="Kunlik tashriflar"
                        cardValue={daily && daily.total}
                        manValue={daily && daily.gender.male}
                        percentage={daily && daily.trend}
                        womanValue={daily && daily.gender.female} />

                    <StaticCard
                        cardIcon={Weeks} sm
                        cardTitle="Haftalik tashriflar"
                        cardValue={weekly && weekly.total}
                        manValue={weekly && weekly.gender.male}
                        percentage={weekly && weekly.trend}
                        womanValue={weekly && weekly.gender.female} />

                    <StaticCard
                        cardIcon={Month} sm
                        cardTitle="Oylik tashriflar"
                        cardValue={monthly && monthly.total}
                        manValue={monthly && monthly.gender.male}
                        percentage={monthly && monthly.trend}
                        womanValue={monthly && monthly.gender.female} />
                </div>

                <div className="flex items-center w-[100%] gap-[6px]">
                    <div className="w-[50%]  h-auto">
                        <AnalyticCard
                            cardTitle="Oylik tashrif buyuruvchilar"
                            isActive sm
                            data={monthlyBreak || []}
                            component={StackedBarChart} />
                    </div>

                    <div className="w-[50%]  h-auto">
                        <AnalyticCard
                            cardTitle="Bugungi tashrif vaqti" sm
                            data={hourlyData || []}
                            component={ChartLine} />
                    </div>
                </div>

                <div className="flex items-start w-[100%] gap-[6px]">
                    <div className="w-[60%] h-auto">
                        <AnalyticCard
                            cardTitle="Yosh bo'yicha kundalik tahlil"
                            isActive sm
                            data={agely || []}
                            component={HorizontalChart} />
                    </div>

                    <div className="w-[40%] h-auto">
                        <PieCard sm />
                    </div>
                </div>
            </div>
        </Grid>
    )
}
