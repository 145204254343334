import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { number, object, string } from 'yup'
import axios from 'axios'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import ToggleSwitch from '../../../shared/ui/ToggleSwitch'
import FormTextField from '../../../shared/ui/FormTextField'
import { domain } from '../../../shared/utils/request'
import { SETSERVICECONFIG } from '../../../shared/utils/urls'
import { auth } from '../../../shared/utils/auth'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

export default function ServerConfiguration({ id, initialValues }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [isOffline, setIsOffline] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setIsOffline(initialValues.ResumeTransf === 1)
    }, [initialValues])

    const FORM_INITIAL_STATE = {
        ServerAddr: '',
        WsServerAddr: '',
        PlatformSubCode: '',
    }

    const VALIDATION_SCHEMA = object().shape({
        ServerAddr: string().required('Server Address is required'),
        WsServerAddr: string().required('WebSocket Server Address is required'),
        PlatformSubCode: number().required('Platform Sub Code is required'),
    })

    const update = async (values) => {
        if (loading) return

        setLoading(true)

        const filteredValues = {
            serverAddr: values.ServerAddr,
            wsServerAddr: values.WsServerAddr,
            platformSubCode: values.PlatformSubCode,
            resumeTransf: isOffline,
        }

        try {
            const { data } = await axios.post(
                domain + SETSERVICECONFIG,
                filteredValues,
                { ...auth(), params: { smart_camera_id: id } },
            )
            if (data.success) {
                showMessage(data.message, 'success-msg')
            }
        } catch (error) {
            handleErrorMsg(error.response)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={update}
        >
            {({ handleChange }) => (
                <Form>
                    <CustomAccordion
                        summary="Server Configuration"
                        contClassName="border-[1px] border-sepia-100"
                        expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
                        overrideStyles={{
                            ':before': {
                                display: 'none',
                            },
                            boxShadow: 'none',
                            borderRadius: '3px',
                        }}>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col w-[100%] gap-[20px] items-start">
                                <div className="flex w-[100%] flex-col items-start gap-[20px]">
                                    <div className="flex items-center w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">HTTP Server Address:
                                        </span>
                                        <FormTextField
                                            name="ServerAddr"
                                            type="text"
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '40%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">Websocket Server Address:
                                        </span>
                                        <FormTextField
                                            name="WsServerAddr"
                                            type="text"
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '40%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center  w-[100%] gap-[21px]">
                                        <span className="text-[14px] whitespace-nowrap
                                     font-normal text-black">Protocol Code:
                                        </span>
                                        <FormTextField
                                            name="PlatformSubCode"
                                            type="text"
                                            handleChange={handleChange}
                                            overrideStyles={{
                                                width: '20%',
                                                border: '1px solid #EDEDED',
                                                outline: 'none',
                                                borderRadius: '6px',
                                                paddingY: '6px',
                                                paddingX: '8px',
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center gap-[21px]">
                                        <span className="text-[14px] font-normal text-black">Offline Continually</span>
                                        <ToggleSwitch
                                            isChecked={isOffline}
                                            onClick={() => setIsOffline((prevState) => !prevState)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-[100%] flex justify-end">
                                <PrimaryBtn
                                    type="submit"
                                    title="Save"
                                    loading={loading}
                                    disabled={loading}
                                    overrideStyles={{
                                        width: '100%',
                                        paddingY: '12px',
                                        fontSize: '14px',
                                    }}
                                />
                            </div>
                        </div>
                    </CustomAccordion>
                </Form>
            )}
        </Formik>
    )
}
