import { useFormikContext } from 'formik'
import React from 'react'
import MultiSelect from '../../../shared/ui/MultiSelect'
import { useLoad } from '../../../shared/hooks/requests'
import { CAMERAS_TENANT } from '../../../shared/utils/urls'

export default function AttachCameras() {
    const { values, setFieldValue } = useFormikContext()
    const getCameras = useLoad({ url: CAMERAS_TENANT, params: { roomId: values.roomId } }, [])
    const cameraOptions = getCameras.response && getCameras.response.items
        ? getCameras.response.items.map((item) => ({ value: item.id, label: item.name })) : []

    return values.roomId && (
        <div className="flex items-baseline gap-4 justify-between">
            <MultiSelect
                name="cameraIds"
                onChange={({ selected }) => setFieldValue('cameraIds', selected)}
                label="Camera"
                loading={getCameras.loading}
                selectedValues={values.cameraIds}
                options={cameraOptions} />
        </div>
    )
}
