import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'

export default function Button({
    className,
    children,
    disabled,
    loading,
    loaderColor,
    icon,
    type,
    iconRight = false,
    strokeWidth,
    onClick,
    ...attr
}) {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled || loading}
            className={cn(`btn-smp ${loading || disabled ? ' not-allowed' : ''}`, className)}
            {...attr}
        >
            <div className="flex items-center gap-2">
                <div className={cn(css(s.btn), `flex items-center gap-2${iconRight ? ' row-reverse' : ''}`)}>
                    {!loading && icon}
                    {children}
                </div>
            </div>
        </button>
    )
}

const s = StyleSheet.create({
    btn: {
        fontFamily: 'Gilroy',
    },
})
