import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    outline: 'none',

}

export default function BranchModal({ children, open, close }) {
    return (
        <div>
            <Modal
                sx={{ outline: 'none', border: 'none' }}
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className=""
            >
                <Box sx={style} className="w-[95%] flex flex-col pb-[21px]
                rounded-[10px] items-end bg-white h-screen sm:h-3/4 md:h-[95%] lg:h-1/1 md:overflow-auto">
                    <div className="w-[100%]  flex items-center fixed z-10
                     justify-between rounded-tl-[10px] rounded-tr-[9px] py-[14px] px-[17px] bg-btnBlue">
                        <span className="text-[18px] font-medium text-white">Comparison with the first branch</span>
                        <CloseIcon onClick={close} style={{ color: 'white' }} />
                    </div>
                    <div className="w-[100%] h-[100%] overflow-auto py-[70px] bg-white px-[17px]">
                        {children}
                    </div>
                    <button onClick={close} className="px-[20px] py-[8px] rounded-[8px]
                    bg-btnBlue text-white mt-[10px] mr-[17px]">Ok
                    </button>
                </Box>
            </Modal>
        </div>
    )
}
