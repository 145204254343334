import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { debounce } from 'lodash'
import Table from '../../../shared/ui/Table'
import MasterUserFilter from '../../master-user/components/MasterUserFilter'
import AuthLogTableItems from './AuthLogTableItems'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useFaceLoad } from '../../../shared/hooks/faceAuthRequest'
import { CLIENTS, AUTHLOGPAGINATION } from '../../../shared/utils/urls'
import { useAddQueryParams } from '../../../shared/hooks/addQueryParams'
import { useQueryParams } from '../../../shared/hooks/queryString'

const cols = [
    {
        id: 1,
        title: 'User id',
        align: 'left',
    },
    {
        id: 2,
        title: 'Client Name',
        align: 'left',
    },
    {
        id: 3,
        title: 'Pinfl',
        align: 'center',
    },
    {
        id: 4,
        title: 'CreatedAt',
        align: 'center',
    },
    {
        id: 5,
        title: 'Mani photo',
        align: 'left',
        width: '10%',
    },
    {
        id: 6,
        title: 'Snapshot',
        align: 'left',
        width: '10%',
    },
    {
        id: 7,
        title: 'API Type',
        align: 'left',
    },
    {
        id: 8,
        title: 'Authenticated',
        align: 'left',
    },
    {
        id: 9,
        title: 'Distance',
        align: 'center',
    },
    {
        id: 10,
        title: 'FaceMatched',
        align: 'left',
    },
    {
        id: 11,
        title: 'Spoofed',
        align: 'left',
    },
    {
        id: 12,
        title: 'Score',
        align: 'center',
    },
    { id: 13,
        title: 'Action',
        align: 'right' },
]

const apiType = [
    {
        id: 'face_auth',
        apiType: 'face_auth',
    },
    {
        id: 'face_match',
        apiType: 'face_match',
    },
    {
        id: 'face_search',
        apiType: 'face_search',
    },
    {
        id: 'login',
        apiType: 'login',
    },
]

const Boolean = [
    {
        id: 'true',
        username: 'True',
    },
    {
        id: 'false',
        username: 'False',
    },
]

export function AuthLogTable() {
    const [page, setPage] = useState(1)
    const addQueryParams = useAddQueryParams()
    const { search } = useQueryParams()
    const [pageSize, setPageSize] = useState(25)
    const [searchValue, setSearchValue] = useState('')
    const { filterState, setAuthFilters } = useFilter()
    const getClient = useFaceLoad({ url: CLIENTS }, [])
    const params = {
        page,
        size: pageSize,
        ...(searchValue && { search: searchValue }),
        ...(filterState.authClient && filterState.authClient !== '-' && { client_id: filterState.authClient }),
        ...(filterState.api && filterState.api !== '-' && { api_type: filterState.api }),
        ...(filterState.authenticated && filterState.authenticated !== '-'
            && { is_authenticated: filterState.authenticated }),
        ...(filterState.face_matched && filterState.face_matched !== '-'
            && { is_face_matched: filterState.face_matched }),
        ...(filterState.is_spoofed && filterState.is_spoofed !== '-' && { is_spoofed: filterState.is_spoofed }),
    }

    const clientList = getClient.response ? getClient.response : []

    const loadAuthLog = useFaceLoad({ url: AUTHLOGPAGINATION,
        params }, [page, pageSize, filterState.api, filterState.authClient,
        filterState.authenticated, filterState.face_matched, filterState.is_spoofed, search])
    const data = loadAuthLog.response ? loadAuthLog.response.items : []
    const meta = loadAuthLog.response ? loadAuthLog.response : {}

    const debouncedSearch = useMemo(
        () => debounce((value) => addQueryParams({ search: value }), 500),
        [],
    )

    const onSearch = useCallback(
        (e) => {
            const { value } = e.target
            setSearchValue(value)
            debouncedSearch(value)
        },
        [debouncedSearch],
    )

    useEffect(() => {
        setPage(1)
    }, [filterState.authenticated, filterState.api, filterState.authClient,
        filterState.face_matched, filterState.is_spoofed])

    return (
        <div className="flex flex-col items-center gap-[21px] w-[100%]">
            <div className="w-[100%] bg-white flex flex-col items-start
             gap-[10px] h-auto pt-[14px] pb-[15px] px-[14px] shadow-sm">
                <div className="flex items-center gap-[10px]">
                    <div className="flex items-center gap-[10px]">
                        <div className="flex flex-col items-start gap-[5px]">
                            <span>Api Type</span>
                            <MasterUserFilter items={apiType || []} placeholder="Choose api Type"
                                onChange={(e) => setAuthFilters('api', e.target.value)}
                                showDefaultOption value={filterState.api} />
                        </div>
                        <div className="flex flex-col items-start gap-[5px]">
                            <span>Clients</span>
                            <MasterUserFilter items={clientList || []} placeholder="Choose Client"
                                onChange={(e) => setAuthFilters('authClient', e.target.value)}
                                showDefaultOption
                                value={filterState.authClient} />
                        </div>
                        <div className="flex flex-col items-start gap-[5px]">
                            <span>Authenticated</span>
                            <MasterUserFilter items={Boolean || []} placeholder="Choose authincated"
                                onChange={(e) => setAuthFilters('authenticated', e.target.value)}
                                showDefaultOption
                                value={filterState.authenticated} />
                        </div>
                        <div className="flex flex-col items-start gap-[5px]">
                            <span>Face Match</span>
                            <MasterUserFilter items={Boolean || []} placeholder="Choose face_matched"
                                onChange={(e) => setAuthFilters('face_matched', e.target.value)}
                                showDefaultOption
                                value={filterState.face_matched} />
                        </div>
                        <div className="flex flex-col items-start gap-[5px]">
                            <span>Spoofed</span>
                            <MasterUserFilter items={Boolean || []} placeholder="Choose spoofed"
                                onChange={(e) => setAuthFilters('is_spoofed', e.target.value)}
                                showDefaultOption
                                value={filterState.is_spoofed} />
                        </div>
                    </div>

                    <input type="text" placeholder="Search..."
                        value={searchValue} onChange={onSearch}
                        className="w-[150px] px-[10px] outline-none mt-[29px] py-[10px] border rounded-[14px]" />
                </div>
            </div>
            <div className="bg-white w-[100%]">
                <div className="flex items-center justify-between h-[60px] px-4">
                    <Typography className="text-[20px] font-normal text-black">
                        Latest API Calls ({meta.total ? meta.total : 0})
                    </Typography>

                    <div className="flex-cent gap-2">
                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={loadAuthLog.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <AuthLogTableItems
                            key={item.id}
                            item={item}
                            setData={loadAuthLog.setResponse}
                        />
                    )}
                />
            </div>
        </div>
    )
}
