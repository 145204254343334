import React from 'react'
import { Form, Formik } from 'formik'
import { number, object, string } from 'yup'
import { useLoad } from '../../../../shared/hooks/requests'
import { TENANT_ENTITIES_ALL } from '../../../../shared/utils/urls'
import FormTextField from '../../../../shared/ui/FormTextField'
import CancelBtn from '../../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../../shared/ui/PrimaryBtn'
import SelectInput from '../../../../shared/ui/SelectInput'

const FORM_INITIAL_STATE = {
    name: '',
    description: '',
    city: '',
    state: '',
    tenantEntityId: '',
    address: '',
    latitude: '',
    longitude: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    description: string().optional(),
    tenantEntityId: number().required('Tenant Entity is required'),
    latitude: number().test(
        'is-decimal',
        'Latitude must be a decimal',
        (value) => (`${value}`).match(/^\d*\.{1}\d*$/),
    ).required('Latitude is required'),
    longitude: number().test(
        'is-decimal',
        'Longitude must be a decimal',
        (value) => (`${value}`).match(/^\d*\.{1}\d*$/),
    ).required('Longitude is required'),
})

export default function BuildingForm({
    isEdit,
    loading,
    onSubmit = () => {},
    onClose = () => {},
    initialValues,
}) {
    const getAllEntities = useLoad({ url: TENANT_ENTITIES_ALL }, [])
    const data = getAllEntities.response ? getAllEntities.response.items : []
    const options = data ? data.map((e) => ({ value: e.id, label: e.name })) : []

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ handleChange, setFieldValue }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            required
                            label="Building name"
                            name="name"
                            handleChange={handleChange} />

                        <FormTextField
                            multiline
                            label="Description"
                            name="description"
                            handleChange={handleChange} />

                        <SelectInput
                            required
                            name="tenantEntityId"
                            label="Choose entity"
                            onChange={(e) => setFieldValue('tenantEntityId', e.target.value)}
                            menuItems={options}
                        />

                        <FormTextField
                            required
                            type="number"
                            label="Latitude"
                            name="latitude"
                            handleChange={handleChange} />

                        <FormTextField
                            required
                            type="number"
                            label="Longitude"
                            name="longitude"
                            handleChange={handleChange} />

                        <div className="flex items-center justify-end gap-2.5">
                            <CancelBtn
                                onClick={onClose}
                                title="Cancel"
                                type="button" />

                            <PrimaryBtn
                                loading={loading}
                                title={isEdit ? 'Edit' : 'Create'}
                                type="submit" />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
