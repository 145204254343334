import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { usePostRequest } from '../../../shared/hooks/requests'
import { CAMERAS_TENANT } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import CameraForm from '../components/CameraForm'

export default function Create({ setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createCamera = usePostRequest({ url: CAMERAS_TENANT })

    const create = async (values) => {
        if (createCamera.loading || !values) return

        const { response, success, error } = await createCamera.request({ data: {
            name: values.name,
            description: values.description || undefined,
            rtsp_url: values.rtspUrl,
            vpn_rtsp_url: values.vpnRtspUrl,
            stream_url: values.streamUrl,
            username: values.username,
            password: values.password,
            latitude: values.latitude || undefined,
            longitude: values.longitude || undefined,
            altitude: values.altitude || undefined,
            room_id: values.roomId,
            snapshot_url: values.snapshotUrl || undefined,
        } })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: [...(prev.items || []), response].every((v) => v.roomId === values.roomid)
                    ? [...prev.items, response] : prev.items ? [...prev.items, response] : [response],
            }))
            showMessage('Successfully created', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between py-1 pl-3 pr-1
                 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Camera</h3>

                    <IconButton onClick={() => setOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <CameraForm loading={createCamera.loading} onSubmit={create} onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
