import React, { useEffect, useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { useLoad } from '../../../shared/hooks/requests'
import { ML_MODELS } from '../../../shared/utils/urls'
import Table from '../../../shared/ui/Table'
import MLModelTableItem from './MLModelTableItem'
import { COLORS } from '../../../shared/utils/colors'
import Create from '../features/Create'

const cols = [
    { id: 1, title: 'Profile name', width: '20%', padding: '0 0 0 16px' },
    { id: 2, title: 'Version description', width: '30%' },
    { id: 3, title: 'Version', width: '20%' },
    { id: 4, title: 'Created date', width: '20%' },
    { id: 5, title: 'Action', width: '10%', align: 'right' },
]

export default function MLModelTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(25)
    const [profiles, setProfiles] = useState([])
    const loadMLModels = useLoad({ url: ML_MODELS, param: { size: pageSize, page } }, [page, pageSize])
    const data = loadMLModels.response ? loadMLModels.response.items : []
    const meta = loadMLModels.response ? loadMLModels.response : {}
    const userType = localStorage.getItem('userType')

    useEffect(() => {
        if (data && data.length > 0) {
            const collectedProfiles = data.map((item) => item.mlModelProfile)
            setProfiles(collectedProfiles)
        }

        return () => {
            setProfiles([])
        }
    }, [loadMLModels.response])

    return (
        <div className="bg-white">
            <div className="flex items-center justify-between h-[60px] px-4">
                <Typography className="flex-cent gap-1_5 text-m font-600">
                    ML models <HelpRoundedIcon htmlColor={COLORS.gray} />
                </Typography>

                <div className="flex-cent gap-2">
                    {userType === 'system_admin' ? <Create profiles={profiles} $api={loadMLModels} /> : null}

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            <Table
                page={page - 1}
                loading={loadMLModels.loading}
                totalCount={meta.total}
                rowsPerPage={pageSize}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10))
                    setPage(1)
                }}
                cols={cols}
                rows={data}
                renderItem={(item) => (
                    <MLModelTableItem
                        item={item}
                        key={item.id}
                        profiles={profiles}
                        loading={loadMLModels.loading}
                        $api={loadMLModels}
                    />
                )}
            />
        </div>
    )
}
