import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../shared/hooks/requests'
import { API_INTEGRATIONS } from '../../../shared/utils/urls'
import IntegrationDetails from '../components/IntegrationDetails'

export default function Update({ setData, item }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateMLModelProfile = usePutRequest()

    const update = async (values) => {
        const { success, error, response } = await updateMLModelProfile.request({
            url: `${API_INTEGRATIONS}${item.id}`,
            data: {
                ...values,
            },
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (val.id === item.id ? { ...val, ...response } : val)),
            }))
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpenDrawer(true)}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '35%' } }}
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}>
                <IntegrationDetails
                    onEdit={update}
                    onClose={() => setOpenDrawer(false)}
                    item={item} />
            </Drawer>
        </>
    )
}
