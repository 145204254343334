import { isEmpty } from 'lodash'

export function auth() {
    if (isEmpty(localStorage.getItem('token'))) {
        return {
            headers: {
                'accept-language': 'en',
            },
        }
    }

    return {
        headers: {
            'accept-language': 'en',
            // eslint-disable-next-line max-len
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    }
}
