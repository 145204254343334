import React from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const FORM_INITIAL_STATE = {
    name: '',
    description: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    description: string().required('Description is required'),
})

export default function ModuleForm({
    onSubmit = () => {},
    onClose = () => {},
    initialValues,
    loading,
    isEdit,
}) {
    return (
        <Formik enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ handleChange }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            required
                            label="Name"
                            name="name"
                            handleChange={handleChange}
                        />

                        <FormTextField
                            required
                            label="Description"
                            name="description"
                            handleChange={handleChange}
                        />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
