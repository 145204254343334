import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import { useDispatch, useSelector } from 'react-redux'
import { SketchPicker } from 'react-color'
import * as Yup from 'yup'
import { COLORS } from '../../../shared/utils/colors'
import { useLoad } from '../../../shared/hooks/requests'
import { CAMERA_USERS } from '../../../shared/utils/urls'
import { redo, undo, updatePolygonColors } from '../../../shared/store/polygonStore'
import { RedoIcon, UndoIcon } from '../../../components/svgs/Svgs'
import { ColorPaletteIcon } from '../../../components/svgs/SvgPictures'
import SelectInput from '../../../shared/ui/SelectInput'
import FormTextField from '../../../shared/ui/FormTextField'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const formInitialValues = {
    description: '',
    label: 'Polygon 1',
    type: '',
    userId: null,
}

const VALIDATION_SCHEMA = Yup.object().shape({
    description: Yup.string().optional(),
    label: Yup.string().required('Roi should be named'),
    type: Yup.string().required('Type of service should be defined'),
    userId: Yup.number().required('User should be assigned'),
})

const typeOptions = [
    { value: 'self', label: 'Main workspace' },
    { value: 'customer', label: 'Service workspace' },
]

export default function RoiForm({ onSubmit, cameraId, loading }) {
    const dispatch = useDispatch()
    const polygons = useSelector((state) => state.polygon.polygons)
    const activePolygonIndex = useSelector((state) => state.polygon.activePolygonIndex)
    const shouldCloseForm = useSelector((state) => state.polygon.shouldCloseForm)
    const [color, setColor] = useState('#0B9E34')
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [isFormVisible, setIsFormVisible] = useState(true)

    const loadCameraUsers = useLoad({ url: CAMERA_USERS.replace('{id}', cameraId) }, [])
    const userOptions = loadCameraUsers.response && loadCameraUsers.response.items
        ? loadCameraUsers.response.items
            .map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` })) : []

    const handleColorChange = (c) => {
        setColor(c.hex)
        dispatch(updatePolygonColors({ id: polygons[activePolygonIndex].id, color: c.hex }))
    }

    useEffect(() => {
        if (shouldCloseForm) {
            setIsFormVisible(false)
        }
    }, [shouldCloseForm])

    return isFormVisible ? (
        <Formik
            enableReinitialize
            initialValues={{ ...formInitialValues, ...polygons[0] }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ setFieldValue, handleChange }) => (
                <div className="flex-col gap-2_5">
                    <Form className={cn(css(s.formBody), 'flex-col items-end gap-7_5')}>
                        <div className="w-full flex-col gap-2_5">
                            <FormTextField
                                required
                                label="Name"
                                name="label"
                                handleChange={handleChange} />

                            <FormTextField
                                required
                                label="Description"
                                name="description"
                                handleChange={handleChange} />

                            <SelectInput
                                required
                                name="userId"
                                label="Assigned employee"
                                menuItems={userOptions}
                                onChange={(e) => setFieldValue('userId', e.target.value)} />

                            <SelectInput
                                required
                                name="type"
                                label="Type of Service"
                                menuItems={typeOptions}
                                onChange={(e) => setFieldValue('type', e.target.value)} />
                        </div>

                        <PrimaryBtn
                            loading={loading}
                            title="Save"
                            type="submit" />
                    </Form>

                    <ul className={cn(css(s.toolsCont), 'flex items-center relative')}>
                        <li className="flex-col items-center gap-2" onClick={() => dispatch(undo())}>
                            <UndoIcon />
                            Undo
                        </li>

                        <li className="flex-col items-center gap-2" onClick={() => dispatch(redo())}>
                            <RedoIcon />
                            Redo
                        </li>

                        <li
                            className="flex-col items-center gap-2 relative"
                            onClick={() => setShowColorPicker(!showColorPicker)}
                        >
                            <ColorPaletteIcon />

                            <h3>Color palette</h3>

                            {showColorPicker && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: -300,
                                        left: 150,
                                        zIndex: 1000,
                                    }}
                                >
                                    <SketchPicker
                                        color={color}
                                        onChange={handleColorChange}
                                        onClose={() => setShowColorPicker(false)}
                                    />
                                </div>
                            )}
                        </li>
                    </ul>
                </div>
            )}
        </Formik>
    ) : null
}

const s = StyleSheet.create({
    btn: {
        height: 38,
        backgroundColor: COLORS.disabled,
        padding: '0 25px',
        color: COLORS.white,
    },
    submitBtn: {
        backgroundColor: COLORS.mainBlueBg,
        ':hover': {
            backgroundColor: COLORS.mainBlue,
        },
    },
    formBody: {
        position: 'relative',
        padding: '22px 16px',
        borderRadius: 10,
        background: COLORS.white,
        ':after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '-15px',
            transform: 'translateY(-50%)',
            borderWidth: '20px 20px 20px 0',
            borderStyle: 'solid',
            borderColor: `transparent ${COLORS.white} transparent transparent`,
        },
    },
    toolsCont: {
        padding: '10px 0',
        borderRadius: 10,
        border: '1px solid #F8F8F8',
        background: COLORS.white,
        ':nth-child(1n) > *': {
            borderRight: '1px solid #F8F8F8',
            listStyleType: 'none',
            padding: '0 20px',
            height: 'inherit',
            cursor: 'pointer',
            userSelect: 'none',
        },
        ':nth-child(1n) > :last-child': {
            borderRight: 'none',
        },
        position: 'relative',
    },
})
