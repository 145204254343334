import React from 'react'
import { Grid } from '@mui/material'
import MasterUserFilter from '../../master-user/components/MasterUserFilter'
import { Cameras } from './cameras'
import { LeftItem } from './LeftItem'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

export function LeftItems() {
    const { branchEntityFilter, setBranchEntityFilters } = useFilter()
    return (
        <div className="w-[100%] flex flex-col items-center gap-[12px]">
            <div className="w-[100%] flex flex-col gap-[11px] shadow-sm shadow-gray-200
             bg-white h-auto py-[15px] pl-[14px]">
                <span className="text-[16px] font-medium text-black">Filter</span>
                <MasterUserFilter items={[]} placeholder="Jetson Device"
                    onChange={(e) => setBranchEntityFilters('jetsonFilter', e)}
                    value={branchEntityFilter.jetsonFilter} />
            </div>
            <div className="flex items-start w-[100%] gap-[14px]">
                <Grid container rowGap={1} columnGap={1} columns={3} className="h-[80vh] border overflow-auto">
                    <Cameras />
                    <Cameras />
                    {/* <Cameras />
                    <Cameras />
                    <Cameras />
                    <Cameras />
                    <Cameras />
                    <Cameras />
                    <Cameras />
                    <Cameras /> */}
                </Grid>
                <div className="w-[30%] flex flex-col shadow-sm shadow-gray-200 pt-[20px] pl-[14px] pr-[22px] pb-[20px]
                 items-start gap-[17px] h-[80vh] bg-white">
                    <span className="text-[21px] font-medium text-black">National guard notifications</span>
                    <div className="w-[70px] h-auto">
                        <MasterUserFilter items={[]} placeholder="ALL"
                            onChange={(e) => setBranchEntityFilters('notificationFilter', e)}
                            value={branchEntityFilter.notificationFilter} />
                    </div>
                    <div className="flex flex-col h-[80vh] overflow-auto items-center gap-[10px]">
                        <LeftItem />
                        <LeftItem />
                        <LeftItem />
                        <LeftItem />
                        <LeftItem />
                        <LeftItem />
                        <LeftItem />
                        <LeftItem />
                        <LeftItem />
                    </div>
                </div>
            </div>
        </div>
    )
}
