import React, { useEffect, useState } from 'react'
import { IconButton, Paper, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import moment from 'moment'
import { BUILDINGS_TENANT, ROOMS_TENANT_UNPAGINATED,
    SMART_CAMERA_ANALYTICS, SMARTCAMERAS_TENANT } from '../../../shared/utils/urls'
import SmartCameraManageTableItem from './SmartCameraManageTableItem'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest } from '../../../shared/hooks/requests'
import Create from '../features/Create'
import Table from '../../../shared/ui/Table'
import CameraFilter from '../../../shared/filter/CameraFilter'

const cols = [
    { id: 1, title: 'Name', padding: '0 0 0 16px' },
    { id: 2, title: 'Users' },
    { id: 3, title: 'Identity' },
    { id: 4, title: 'Status' },
    { id: 5, title: 'Created time' },
    { id: 6, title: 'Working time (hh:mm)' },
    { id: 7, title: 'Action', width: '10%', align: 'right' },
]

export default function SmartCameraManageTable() {
    const { cameraFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [combinedData, setCombinedData] = useState([])
    const [loading, setLoading] = useState(false)

    const getSmartCameras = useGetRequest({ url: SMARTCAMERAS_TENANT })
    const getSmartCameraAnalytics = useGetRequest({ url: SMART_CAMERA_ANALYTICS })

    useEffect(() => {
        if (cameraFilter.rId) {
            setLoading(true)
            getSmartCameras.request({
                params: { roomId: cameraFilter.rId, size: pageSize, page },
            })
        }
    }, [page, pageSize, cameraFilter.rId])

    useEffect(() => {
        if (getSmartCameras.response) {
            const fetchAnalytics = async () => {
                const cameras = getSmartCameras.response.items
                const analyticsPromises = cameras.map((camera) => getSmartCameraAnalytics.request({
                    url: `${SMART_CAMERA_ANALYTICS}/${camera.id}`,
                    params: { date: moment().format('YYYY-MM-DD') }, // Fetch analytics for today
                }))

                const analyticsResponses = await Promise.all(analyticsPromises)

                const combined = cameras.map((camera, index) => ({
                    ...camera,
                    analytics: analyticsResponses[index].response || [],
                    totalActiveTime: calculateTotalActiveTime(analyticsResponses[index].response || []),
                    totalOffTime: calculateTotalOffTime(analyticsResponses[index].response || []),
                }))

                setCombinedData(combined)
                setLoading(false) // Data has fully loaded, stop the loading state
            }

            fetchAnalytics()
        }
    }, [getSmartCameras.response])

    const calculateTotalActiveTime = (analytics) => {
        if (!Array.isArray(analytics) || analytics.length === 0) return '00:00'

        // Filter analytics where the count is greater than 30
        const validAnalytics = analytics.filter((analytic) => analytic.count > 30)

        // Sum total active time in minutes (assuming each valid entry represents a 30-minute interval)
        const totalMinutes = validAnalytics.length * 30

        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }

    const calculateTotalOffTime = (analytics) => {
        if (!Array.isArray(analytics) || analytics.length === 0) return '00:00'

        // Filter analytics where the count is less than 30
        const validAnalytics = analytics.filter((analytic) => analytic.count < 30)

        // Sum total off time in minutes (assuming each valid entry represents a 30-minute interval)
        const totalMinutes = validAnalytics.length * 30

        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }

    const data = combinedData.length ? combinedData : []
    const meta = getSmartCameras.response ? getSmartCameras.response : {}

    return (
        <div className="w-full flex flex-col gap-6">
            <CameraFilter buildingUrl={BUILDINGS_TENANT} roomUrl={ROOMS_TENANT_UNPAGINATED} />

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Smart Camera
                    </Typography>

                    {cameraFilter.bId && (
                        <div className="flex-cent gap-4_5">
                            <Create setData={getSmartCameras.setResponse} />

                            <IconButton>
                                <Search fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                </div>

                <Table
                    page={page - 1}
                    loading={loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <SmartCameraManageTableItem
                            item={item}
                            key={item.id}
                            setData={getSmartCameras.setResponse}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
