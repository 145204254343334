function forceCopyToClipboard(text, showMessage, setCopied) {
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'
    document.body.appendChild(textarea)

    textarea.select()

    try {
        document.execCommand('copy')
        showMessage('Copied to clipboard!', 'success-msg')
        setCopied(true)
    } catch (err) {
        showMessage('Please try again', 'error-msg')
    }

    document.body.removeChild(textarea)
}

export const copyToClipboard = (content, showMessage, setCopied) => {
    if (window.isSecureContext && navigator.clipboard) {
        try {
            navigator.clipboard.writeText(content)
            showMessage('Copied to clipboard!', 'success-msg')
            setCopied(true)
        } catch (err) {
            showMessage('Please try again', 'error-msg')
        }
    } else {
        forceCopyToClipboard(content, showMessage, setCopied)
        setCopied(true)
    }
}
