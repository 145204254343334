import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Grid, IconButton } from '@mui/material'
import { Search } from '@mui/icons-material'
import { COLORS } from '../../../shared/utils/colors'
import CameraFilter from '../../../shared/filter/CameraFilter'
import { BUILDINGS_TENANT, CAMERAS_TENANT, ROOMS_TENANT_UNPAGINATED } from '../../../shared/utils/urls'
import SnapshotCard from './SnapshotCard'
import { useGetRequest } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import Create from '../features/Create'

export default function CameraSnapshots() {
    const { cameraFilter } = useFilter()
    const getCameras = useGetRequest({ url: CAMERAS_TENANT })
    const cameras = getCameras.response ? getCameras.response.items : []

    useEffect(() => {
        if (cameraFilter.rId) {
            getCameras.request({ params: { roomId: cameraFilter.rId } })
        }
    }, [cameraFilter])

    return (
        <div className="flex-col gap-5">
            <CameraFilter buildingUrl={BUILDINGS_TENANT} roomUrl={ROOMS_TENANT_UNPAGINATED} />

            <div className={cn(css(s.wrapper), 'flex-col gap-5')}>
                <div className={cn(css(s.header), 'flex items-center justify-between')}>
                    <h3>Camera</h3>

                    {cameraFilter.bId && (
                        <div className="flex-cent gap-2">
                            <Create setData={getCameras.setResponse} />

                            <IconButton>
                                <Search fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                </div>

                <Grid container spacing={2}>
                    {cameras.map((item) => (
                        <SnapshotCard key={item.id} item={item} setData={getCameras.setResponse} />
                    ))}
                </Grid>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        padding: '22px 20px',
        background: COLORS.white,
    },
    header: {
        ':nth-child(1n) > h3': {
            fontSize: 18,
            fontWeight: '600',
            color: COLORS.black,
        },
    },
    gridList: {
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat(auto-fill, minmax(21rem, 1fr))',
    },
})
