import axios from 'axios'
import humps from 'humps'
import { isEmpty } from 'lodash'

export const domain = process.env.REACT_APP_FACE_URL
//  Add Base URL and change snake_case to camelCase
const faceAuthBaseAxios = axios.create({
    baseURL: `${domain}`,
    transformResponse: [...axios.defaults.transformResponse, humps.camelizeKeys],
    transformRequest: [decamelize, ...axios.defaults.transformRequest],
})

faceAuthBaseAxios.interceptors.request.use((config) => ({
    ...config,
    params: humps.decamelizeKeys(config.params),
}))

export default faceAuthBaseAxios

// eslint-disable-next-line consistent-return
function decamelize(object) {
    if (!(object && !(object instanceof File))) return object

    if (object instanceof FormData) {
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of object.entries()) {
            formData.append(humps.decamelize(key), value)
        }
        return formData
    }

    if (typeof object === 'object') {
        return humps.decamelizeKeys(object)
    }
}

export function clearStorage() {
    localStorage.clear()
    // eslint-disable-next-line no-restricted-globals
    location.reload()
}

export function faceAuthHeaders() {
    if (isEmpty(localStorage.faceAuthToken)) {
        return {
            headers: {
                'accept-language': 'en',
            },
        }
    }

    return {
        headers: {
            'accept-language': 'en',
            // eslint-disable-next-line max-len
            Authorization: `Basic ${JSON.parse(localStorage.faceAuthToken)}`,
        },
    }
}
