import React, { useState } from 'react'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import TenantAdminForm from './TenantAdminForm'

const navItems = [
    { id: 1, name: 'Details' },
]

export default function TenantAdminDetails({ item, onClose, onEdit, loading }) {
    const [section, setSection] = useState('Details')

    const initialValues = {
        firstName: item.firstName || '',
        lastName: item.lastName || '',
        description: item.description || '',
        phone: item.phone || '',
        email: item.email || '',
        photo: item.photo,
        tenantId: item.tenantId,
        fileName: item.photo ? item.photo.split('/')[item.photo.split('/').length - 1] : '',
    }

    return (
        <DrawerLayout
            topic="Admin details"
            onClose={onClose}
            name={item.email}
            icons={[]}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.name)}
                                className={
                                    `cursor-pointer list-none text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.name ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                <TenantAdminForm
                    onSubmit={onEdit}
                    loading={loading}
                    isEdit
                    initialValues={initialValues} />
            </React.Fragment>
        </DrawerLayout>
    )
}
