import React, { useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { useLoad } from '../../../shared/hooks/requests'
import { ML_MODEL_PROFILES } from '../../../shared/utils/urls'
import MLModelProfileTableItem from './MLModelProfileTableItem'
import Table from '../../../shared/ui/Table'
import Create from '../features/Create'
import { COLORS } from '../../../shared/utils/colors'

const headerItems = [
    { id: 1, title: 'Name', width: '20%' },
    { id: 2, title: 'Description', width: '30%' },
    { id: 3, title: 'Tags', width: '20%' },
    { id: 4, title: 'Created time', width: '20%' },
    { id: 5, title: 'Action', width: '10%', align: 'right' },
]

export default function MLModelProfileTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(25)
    const loadMLModelProfiles = useLoad(
        { url: ML_MODEL_PROFILES,
            params: { page, size: pageSize } },
        [page, pageSize],
    )
    const data = loadMLModelProfiles.response ? loadMLModelProfiles.response.items : []
    const meta = loadMLModelProfiles.response ? loadMLModelProfiles.response : {}

    return (
        <div className="bg-white">
            <div className="flex items-center justify-between h-[60px] px-4">
                <Typography className="flex-cent gap-1_5 text-m font-600">
                    ML model profile <HelpRoundedIcon htmlColor={COLORS.gray} />
                </Typography>

                <div className="flex-cent gap-4_5">
                    <Create $api={loadMLModelProfiles} />

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            <Table
                page={page - 1}
                loading={loadMLModelProfiles.loading}
                totalCount={meta.total}
                rowsPerPage={pageSize}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10))
                    setPage(1)
                }}
                cols={headerItems}
                rows={data}
                renderItem={(item) => (
                    <MLModelProfileTableItem
                        item={item}
                        key={item.id}
                        loading={loadMLModelProfiles.loading}
                        $api={loadMLModelProfiles}
                    />
                )}
            />
        </div>
    )
}
