import React from 'react'
import { Drawer, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { TENANT_PROFILE } from '../../../shared/utils/urls'
import { usePutRequest } from '../../../shared/hooks/requests'
import TenantProfileDetails from '../components/TenantProfileDetails'
import useTenantProfileStore from '../../../shared/store/tenantProfileStore'

export default function Update({ open, isOpen, close, item }) {
    const showMessage = useMessage()
    const { setResponse, profileResponse } = useTenantProfileStore()
    const { handleErrorMsg } = useHandleErrors()
    const updateProfile = usePutRequest({ url: `${TENANT_PROFILE}/${item.id}` })

    const update = async (values) => {
        const { success, response, error } = await updateProfile.request({
            data: { ...values },
        })

        if (success) {
            setResponse({
                ...profileResponse,
                items: profileResponse.items.map((val) => (
                    val.id === response.id ? { ...val, ...response } : val
                )),
            })
            close()
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={open}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
                anchor="right"
                open={isOpen}
                onClose={close}>
                <TenantProfileDetails
                    loading={updateProfile.loading}
                    onEdit={update}
                    onClose={close}
                    item={item} />
            </Drawer>
        </>

    )
}
