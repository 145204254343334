import React from 'react'
import Popup from 'reactjs-popup'

export default function Modal({
    trigger,
    children,
    modalRef,
    className,
    contentStyle,
    overlayStyle,
    ...args
}) {
    const contStyle = {
        maxHeight: 'calc(100% - 8px)',
        background: 'transparent',
        overflow: 'auto !important',
    }

    return (
        <Popup
            modal
            ref={modalRef}
            closeOnDocumentClick
            closeOnEscape={false}
            className={className}
            contentStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                ...contStyle,
                ...contentStyle,
            }}
            overlayStyle={{
                background: 'rgba(0, 0, 0, 0.30)',
                backdropFilter: 'blur(5px)',
                zIndex: 2,
                ...overlayStyle,
            }}
            trigger={trigger}
            {...args}
        >
            {children}
        </Popup>
    )
}
