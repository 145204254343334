import React, { useState } from 'react'
import { Dialog, DialogContent, Grid, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import ReactPlayer from 'react-player'

export function Cameras() {
    const [isOpen, setIsOpen] = useState(false)

    const open = () => setIsOpen(true)

    const close = () => setIsOpen(false)

    return (
        <>
            <Grid item flex={1} onClick={open} className="min-h-60 w-[100%] border bg-white">
                <ReactPlayer
                    url={[]}
                    playing
                    width="100%"
                    height="100%"
                />
            </Grid>

            <Dialog
                fullWidth
                open={isOpen}
                onClose={close}
                PaperProps={{
                    style: {
                        width: '100%', // Set a percentage-based width or use viewport units like '80vw'
                        maxWidth: '100vh', // Set a maximum width to prevent it from growing too large
                        margin: 'auto', // Center the dialog horizontally
                    },
                }}
            >
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>Camera</Typography>
                    <IconButton onClick={close}>
                        <Close htmlColor="white" className="cursor-pointer" />
                    </IconButton>

                </div>

                <DialogContent style={{ padding: 0 }}>
                    <div className="w-[100%] h-[600px]" /> {/* Use w-full for flexible width */}
                </DialogContent>
            </Dialog>
        </>
    )
}
