import React, { useRef } from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import FormTextField from '../../../../shared/ui/FormTextField'
import CancelBtn from '../../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../../shared/ui/PrimaryBtn'
import useImageUpload from '../../../../shared/hooks/imageUploadHandler'

const FORM_INITIAL_STATE = {
    name: '',
    description: '',
    photo: '',
    fileName: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    description: string().optional(),
    photo: string().optional(),
    fileName: string().optional(),
})

export default function FirmwareForm({
    onSubmit = () => {},
    onClose = () => {},
    initialValues,
    loading,
    isEdit,
    onDetails,
}) {
    const inputRef = useRef(null)
    const { onFileUpload } = useImageUpload()
    return (
        <Formik enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues, // Add formType to initial values
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ handleChange, setFieldValue }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        {onDetails ? (
                            null
                        ) : (
                            <div className="w-full flex items-center gap-4">

                                <FormTextField
                                    fullWidth
                                    type="text"
                                    label="File"
                                    name="fileName"
                                    onClick={() => inputRef.current.click()} />

                                <input
                                    id="file"
                                    type="file"
                                    name="photo"
                                    ref={inputRef}
                                    readOnly="readonly"
                                    className="not-visible"
                                    accept=".png, .jpg, .jpeg, .img"
                                    onChange={(e) => {
                                        setFieldValue('file', e.target.files[0])
                                        onFileUpload(e, setFieldValue, 'photo', '', false, ['img'])
                                    }} />
                            </div>
                        )}
                        <FormTextField
                            required
                            label="Name"
                            name="name"
                            type="text"
                            handleChange={handleChange}
                        />
                        <FormTextField
                            label="Description"
                            name="description"
                            type="text"
                            handleChange={handleChange}
                        />

                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
