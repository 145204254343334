import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import EntityClientsTable from '../entity-users/components/EntityUsersTable'
import BranchesTable from './components/BranchesTable'

export default function BranchManagement() {
    const { pathname } = useLocation()
    const { id } = useParams()

    return pathname.endsWith('users') && id ? <EntityClientsTable /> : <BranchesTable />
}
