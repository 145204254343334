import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { COLORS } from '../../../../../shared/utils/colors'
import { useMessage } from '../../../../../shared/hooks/message'
import useHandleErrors from '../../../../../shared/hooks/handleErrorMessage'
import { usePostRequest } from '../../../../../shared/hooks/requests'
import { CAMERAS_TENANT, SMARTCAMERAS_TENANT } from '../../../../../shared/utils/urls'
import SmartCameraForm from '../../../../smartcamera-manage/components/SmartCameraForm'
import CameraForm from '../../../../camera/components/CameraForm'

const URLS = {
    smart: SMARTCAMERAS_TENANT,
    camera: CAMERAS_TENANT,
}

const DEVICE_TYPES = [
    { id: 'smart', title: 'Smart Camera' },
    { id: 'camera', title: 'Camera' },
]

export default function Create({ setData }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [type, setType] = useState('smart')
    const [open, setOpen] = useState(false)
    const createCamera = usePostRequest({ url: URLS[type] })

    const create = async (values) => {
        if (createCamera.loading || !values) return

        const { response, success, error } = await createCamera.request({ data: values })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: [...(prev.items || []), response],
            }))
            showMessage('Camera added', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between py-1 pl-3 pr-1
                 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Device</h3>

                    <IconButton onClick={() => setOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <div className="border-b border-sepia-200 pb-[1px]">
                        <ul className="w-full flex items-center">
                            {DEVICE_TYPES.map((t) => (
                                <li key={t.id} onClick={() => setType(t.id)}
                                    className={
                                        `cursor-pointer list-none text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${type === t.id ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                    }
                                >
                                    {t.title}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {type === 'smart' ? (
                        <SmartCameraForm
                            loading={createCamera.loading}
                            onSubmit={create}
                            onClose={() => setOpen(false)} />
                    ) : type === 'camera' ? (
                        <CameraForm onSubmit={create} onClose={() => setOpen(false)} />
                    ) : null}
                </DialogContent>
            </Dialog>
        </>
    )
}
