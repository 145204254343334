import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../shared/utils/colors'

export default function RoiList({ data }) {
    const analytics = data.analyticsReport || []

    return (
        <div className={css(s.list)}>
            <div className={css(s.item, s.header)}>
                <div>
                    ROI name
                </div>
                <div>
                    Time Spend
                </div>
            </div>
            {analytics.map((item, index) => (
                <RoiItem key={item.id} item={item} index={index} />
            ))}
        </div>
    )
}

function RoiItem({ item }) {
    const { roi, analytics } = item
    const { name } = roi
    const { minutesWorkTime } = analytics
    const minutesFormat = minutesWorkTime % 1 === 0
        ? minutesWorkTime.toFixed(0)
        : minutesWorkTime.toFixed(2)
    const minute = minutesFormat > 1 ? 'minutes' : 'minute'

    return (
        <div className={css(s.item)}>
            <div>
                {name}
            </div>
            <div>
                {minutesFormat} {minute}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    list: {
        // list shlould fill available space in height and scroll if needed
        height: '100%',
        maxHeight: '400px',
        overflowY: 'auto',
    },
    item: {
        padding: '16px 23px',
        borderBottom: '1px solid #EEE',
        fontSize: '13px',
        display: 'flex',
        justifyContent: 'space-between',
        // last-child
        ':last-child': {
            borderBottom: 'none',
        },
        ':hover': {
            backgroundColor: COLORS.cardInnerBg,
        },
    },
    header: {
        padding: '12px 23px',
        fontSize: '14px',
        fontWeight: '600',
        position: 'sticky',
        top: 0,
        backgroundColor: COLORS.white,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        ':hover': {
            backgroundColor: COLORS.white,
        },
    },
})
