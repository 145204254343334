import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { CLIENTS } from '../../../shared/utils/urls'
import { usePutFaceRequest } from '../../../shared/hooks/faceAuthRequest'
import ClientsDetail from '../components/ClientDetails'

export default function Update({ setData, item }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [openDrawer, setOpenDrawer] = useState(false)
    const updateClient = usePutFaceRequest({ url: `${CLIENTS}${item.id}` })

    const close = () => setOpenDrawer(false)

    const update = async (values) => {
        if (!values || updateClient.loading) return
        const { success, response, error } = await updateClient.request({ data: { ...values, isActive: true } })

        if (success) {
            showMessage('Module updated', 'success-msg')

            setData((prevResponse) => ({
                ...prevResponse,
                items: prevResponse.items.map((r) => (r.id === item.id ? { ...r, ...response } : r)),
            }))
            close()
        } else if (error) {
            handleErrorMsg(error)
        }
    }
    const changePassword = async (values) => {
        if (!values || updateClient.loading) return
        const { success, response, error } = await updateClient.request({ data: { ...values, isActive: true } })

        if (success) {
            showMessage('Module updated', 'success-msg')

            setData((prevResponse) => ({
                ...prevResponse,
                items: prevResponse.items.map((r) => (r.id === item.id ? { ...r, ...response } : r)),
            }))
            close()
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpenDrawer(true)}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '45rem' } }}
                anchor="right"
                open={openDrawer}
                onClose={close}>
                <ClientsDetail
                    onEdit={update}
                    onClose={close}
                    onChangePassword={changePassword}
                    loading={updateClient.loading || changePassword.loading}
                    item={item} />
            </Drawer>
        </>
    )
}
