import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { usePostRequest } from '../../../shared/hooks/requests'
import { JETSON_DEVICE_DEEPSTREAM_APP_CREATE } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import { COLORS } from '../../../shared/utils/colors'
import DeepstreamForm from '../components/DeepstreamForm'

export default function Create({ jetsonDeviceId }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const createDeepstreamApp = usePostRequest({ url: JETSON_DEVICE_DEEPSTREAM_APP_CREATE })

    const create = async (values) => {
        if (createDeepstreamApp.loading || !values) return

        const { success, response } = await createDeepstreamApp.request({
            data: { ...values, jetsonDeviceId },
        })

        if (success) {
            showMessage(response.description, response.status === 'failed' ? 'error-msg' : 'success-msg')
            setOpen(false)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between py-1 pl-3 pr-1
                 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Deepstream Application</h3>

                    <IconButton onClick={() => setOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <DeepstreamForm
                        onSubmit={create}
                        loading={createDeepstreamApp.loading}
                        onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
