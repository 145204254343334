import React, { useState } from 'react'
import { Dialog, DialogContent, Typography } from '@mui/material'
import { XIconSizeM } from '../../../components/svgs/Svgs'

export default function Details({ item }) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <div onClick={() => setOpen(true)}>
                <button className="py-[10px] px-[25px] rounded-[5px]
                 bg-btnBgBlue text-white font-medium">Details
                </button>
            </div>

            <Dialog sx={{ '& .MuiDialog-paper': {
                width: 'auto', // Change width as needed
                height: 'auto', // Change height as needed
                maxWidth: 'none',
                maxHeight: 'none',
            } }} open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>Authentication Logs</Typography>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 1, width: '100%' }}>
                    <div className="w-[100%] flex flex-col gap-[10px] bg-white">
                        <div className="w-[100%] h-[50%] flex items-start">
                            <div className="w-auto h-[50vh]">
                                <img
                                    src={item.referenceImageUrl}
                                    alt={item.referenceImageObjectName}
                                    className="w-auto h-[100%]"
                                />
                            </div>
                            <div className="w-auto h-[50vh]">
                                <img
                                    src={item.targetImageUrl}
                                    alt={item.targetImageObjectName}
                                    className="w-auto h-[100%]"
                                />
                            </div>
                        </div>

                        <div className="flex items-center h-[50%] px-[10px] justify-between">
                            <div className="w-[50%] flex flex-col items-start
                             gap-[10px] h-[100%] border-r border-black">
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">User Id:</span>
                                    <span className="text-[15px] font-semibold text-gray-300">
                                        {item.externalUserId}
                                    </span>
                                </div>
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">Client Name:</span>
                                    <span className="text-[15px] font-semibold text-gray-300">
                                        {item.client.clientName}
                                    </span>
                                </div>
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">Pinfl:</span>
                                    <span className="text-[15px] font-semibold text-gray-300">
                                        {item.individualPersonalNumber}
                                    </span>
                                </div>
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">CreatedAt:</span>
                                    <span className="text-[15px] font-semibold text-gray-300">
                                        {item.createdAt.slice(0, 16).replace('T', ' ')}
                                    </span>
                                </div>
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">API Type:</span>
                                    <span className="text-[15px] font-semibold text-gray-300">
                                        {item.apiType}
                                    </span>
                                </div>
                            </div>
                            <div className="w-[50%] flex flex-col pl-[20px] items-start
                             gap-[10px] h-[100%]">
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">Authenticated:</span>
                                    {item.isAuthenticated === true ? (
                                        <span className="text-[15px] font-semibold text-green-500">True</span>
                                    ) : (
                                        <span className="text-[15px] font-semibold text-red-300">False</span>
                                    )}

                                </div>
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">Distance:</span>
                                    <span className="text-[15px] font-semibold text-gray-300">
                                        {item.distance.toFixed(3)}
                                    </span>
                                </div>
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">FaceMatched:</span>
                                    {item.isFaceMatched === true ? (
                                        <span className="text-[15px] font-semibold text-green-500">True</span>
                                    ) : (
                                        <span className="text-[15px] font-semibold text-red-300">False</span>
                                    )}
                                </div>
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">Spoofed:</span>
                                    {item.isSpoofed === true ? (
                                        <span className="text-[15px] font-semibold text-green-500">True</span>
                                    ) : (
                                        <span className="text-[15px] font-semibold text-red-300">False</span>
                                    )}
                                </div>
                                <div className="w-[100%] h-auto flex items-center gap-[10px]">
                                    <span className="text-[16px] font-medium text-black">Score:</span>
                                    <span className="text-[15px] font-semibold text-gray-300">
                                        {item.spoofingScore.toFixed(3)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
