import React, { useMemo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLoad } from '../../../../shared/hooks/requests'
import { COLORS } from '../../../../shared/utils/colors'
import Table from '../../../../shared/ui/Table'
import FirmwareTableItems from './FirmwareTableItems'
import Create from '../features/create'
import { FIRMWARE_URL } from '../../../../shared/utils/urls'

const initialHeaderItems = [
    { id: 1, title: 'Name' },
    { id: 2, title: 'Description', align: 'center' },
    { id: 3, title: 'Cameras' },
    { id: 4, title: 'Created time', align: 'center' },
    { id: 5, title: 'Action', align: 'right', width: '15%' },
]

export default function FirmwareGrid() {
    const loadOptions = useMemo(
        () => ({
            url: FIRMWARE_URL,
            params: {
                is_active: true,
            },
        }),
        [],
    )
    const firmwareLoad = useLoad(loadOptions, [])
    const data = firmwareLoad.response ? firmwareLoad.response : []
    const meta = firmwareLoad.response ? firmwareLoad.response : {}

    return (
        <div className="flex-col gap-5">
            <div className={cn(css(s.wrapper), 'flex-col gap-5')}>
                <div className={cn(css(s.header), 'flex items-center justify-between')}>
                    <h3>Firmware <span>({data ? data.length : 0})</span> </h3>
                    <div className="flex-cent gap-7">
                        <Create setData={firmwareLoad.setResponse} />
                    </div>
                </div>
                <Table
                    loading={firmwareLoad.loading}
                    totalCount={meta.total}
                    cols={initialHeaderItems}
                    rows={data}
                    renderItem={(item) => (
                        <FirmwareTableItems
                            key={item.id}
                            item={item}
                            setData={firmwareLoad.setResponse} />
                    )}
                />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        padding: '22px 20px',
        background: COLORS.white,
    },
    header: {
        ':nth-child(1n) > h3': {
            fontSize: 18,
            fontWeight: '600',
            color: COLORS.black,
        },
    },
    icon: {
        color: COLORS.black,
    },
})
