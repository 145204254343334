import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import BuildingForm from '../components/BuildingForm'
import { COLORS } from '../../../../shared/utils/colors'
import { useMessage } from '../../../../shared/hooks/message'
import useHandleErrors from '../../../../shared/hooks/handleErrorMessage'
import { usePostRequest } from '../../../../shared/hooks/requests'
import { BUILDINGS_TENANT_PAGINATED } from '../../../../shared/utils/urls'

export default function Create({ setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { id } = useParams()
    const { handleErrorMsg } = useHandleErrors()
    const createBuilding = usePostRequest({ url: BUILDINGS_TENANT_PAGINATED })

    const create = async (data) => {
        if (createBuilding.loading) return

        const { response, success, error } = await createBuilding.request({
            data: { ...data, buildingId: id },
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: [...(prev.items || []), response],
            }))
            showMessage('Room added', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between py-1 pl-3 pr-1
                 bg-main-100 text-white text-lg font-medium">
                    <Typography>
                        Create Building
                    </Typography>

                    <IconButton onClick={() => setOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <BuildingForm
                        loading={createBuilding.loading}
                        onSubmit={create}
                        onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
