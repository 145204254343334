import { Delete, Edit } from '@mui/icons-material'
import { Dialog, DialogContent, IconButton, TableCell } from '@mui/material'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { COLORS } from '../../../shared/utils/colors'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

export default function RoiTableItem({ item, onEdit, onDelete }) {
    const [openDialog, setOpenDialog] = useState(false)

    return (
        <>
            <TableCell>{item.name}</TableCell>

            <TableCell>
                {item.createdAt
                    ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS')
                    : ''}
            </TableCell>

            <TableCell style={{ paddingRight: 0 }} align="right">
                <div className="flex items-center justify-end">
                    <IconButton onClick={() => onEdit(item)}>
                        <Edit
                            htmlColor={COLORS.disabled}
                            className="cursor-pointer"
                            fontSize="medium"
                        />
                    </IconButton>

                    <IconButton onClick={() => setOpenDialog(true)}>
                        <Delete
                            htmlColor={COLORS.disabled}
                            className="cursor-pointer"
                            fontSize="medium"
                        />
                    </IconButton>
                </div>
            </TableCell>

            <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogContent sx={{ '& .MuiPaper-root': { width: 'fit-content' } }}
                    style={{ padding: 20 }}>
                    <div className="flex min-h-40 flex-col items-center justify-between">
                        <div className="w-full flex-col items-center justify-center gap-6">
                            <h3 className="text-xl font-600">Are you sure you want to delete?</h3>

                            <h3 className="text-l font-400 text-center">
                                Confirm Deletion This Action Is Irreversible
                            </h3>
                        </div>

                        <div className="flex-cent gap-2_5">
                            <CancelBtn type="button" title="Cancel" onClick={() => setOpenDialog(false)} />

                            <PrimaryBtn type="button" title="Delete" onClick={onDelete} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
