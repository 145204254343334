import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../shared/utils/colors'

export default function NotFound() {
    const navigate = useNavigate()

    return (
        <div className={cn(css(s.cont), 'h-full flex-cent')}>
            <h1 className="font-600 text-white">404</h1>

            <p className="text-white flex-col">
                The page you were looking for doesn’t exist.
                You may have mistyped the address or the page may have moved.

                <span className="pointer" onClick={() => navigate(-1)}>Go home</span>
            </p>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        gap: 54,
        backgroundColor: COLORS.mainBlueBg,
        ':nth-child(1n) > h1': {
            borderRight: `1px solid ${COLORS.white}`,
            fontSize: '16em',
            paddingRight: 54,
            margin: 0,
        },
        ':nth-child(1n) > p': {
            maxWidth: '28rem',
            lineHeight: 1.5,
            gap: 30,
            ':nth-child(1n) > span': {
                width: 'fit-content',
                lineHeight: 1,
                borderBottom: `1px solid ${COLORS.white}`,
            },
        },
    },
})
