import React from 'react'
import { Chip, TableCell } from '@mui/material'
import moment from 'moment'
import Update from '../features/Update'
import Delete from '../features/Delete'

export default function JetsonProfileTableItem({ item, setData }) {
    return (
        <>
            <TableCell>{item.name}</TableCell>

            <TableCell>{item.username}</TableCell>

            <TableCell
                title={item.password}
                className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px] cursor-pointer">
                {item.password}
            </TableCell>

            <TableCell>
                <Chip
                    color="success"
                    variant="filled"
                    sx={{
                        backgroundColor: '#07A6D94D',
                        border: 'none',
                        color: '#05779B',
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '9px',
                        '& .MuiChip-label': { padding: 0 },
                    }}
                    label={`V${item.cudaVersion}`} />
            </TableCell>

            <TableCell>
                <Chip
                    color="success"
                    variant="filled"
                    sx={{
                        backgroundColor: '#07A6D94D',
                        border: 'none',
                        color: '#05779B',
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '9px',
                        '& .MuiChip-label': { padding: 0 },
                    }}
                    label={`V${item.deepstreamVersion}`} />
            </TableCell>

            <TableCell>
                <Chip
                    color="success"
                    variant="filled"
                    sx={{
                        backgroundColor: '#07A6D94D',
                        border: 'none',
                        color: '#05779B',
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '9px',
                        '& .MuiChip-label': { padding: 0 },
                    }}
                    label={`V${item.jetpackVersion}`} />
            </TableCell>

            <TableCell>{item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }</TableCell>

            <TableCell style={{ paddingRight: 4 }} align="right">
                <div className="flex items-center justify-end">
                    <Update item={item} setData={setData} />

                    <Delete id={item.id} setData={setData} />
                </div>
            </TableCell>
        </>
    )
}
