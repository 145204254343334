import React, { useEffect, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import Paper from '@mui/material/Paper'
import { ButtonGroup, Grid, IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import Button from '../../../../components/common/Button'
import { SelectFingerIcon } from '../../../../components/svgs/SvgPictures'
import { CAMERAS_TENANT, SMARTCAMERAS_TENANT } from '../../../../shared/utils/urls'
import { useInfiniteScroll } from '../../../../shared/hooks/requests'
import LiveStreamCard from './CameraSnapshotCard'
import { COLORS } from '../../../../shared/utils/colors'
import Create from '../features/device-features/Create'

const URLS = {
    smart: SMARTCAMERAS_TENANT,
    camera: CAMERAS_TENANT,
}

const btnStyles = {
    borderColor: '#D8D8D8',
    fontSize: '14px',
    fontWeight: '400',
    height: 'inherit',
    flex: 1,
}

export default function RoomDevices({ selected }) {
    const [camType, setCamType] = useState('smart')
    const loadCameras = useInfiniteScroll({
        url: URLS[camType], params: { roomId: selected.id },
    }, Boolean(selected.id), [selected.id])
    const cameras = loadCameras.response ? loadCameras.response.items : []

    useEffect(() => {
        if (selected.id) loadCameras.request()

        return () => {
            loadCameras.reset()
        }
    }, [camType, selected.id])

    return (
        <Paper sx={{ flex: 1, padding: '12px 16px' }}>
            <div className="flex items-center justify-between">
                <Typography>{selected.name || 'Cameras'}</Typography>

                <div className="flex-cent gap-2">
                    <Create setData={loadCameras.setResponse} />

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            {selected.id ? (
                <div className="flex-col gap-6_5">
                    <ButtonGroup
                        sx={{ width: '50%', overflow: 'hidden', minHeight: 38 }}
                        variant="contained" aria-label="users-filter-button">
                        <Button onClick={() => setCamType('smart')}
                            style={{
                                ...btnStyles,
                                color: camType === 'smart' ? COLORS.white : COLORS.mainBlueBg,
                                backgroundColor: camType === 'smart' ? COLORS.mainBlueBg : COLORS.sepia,
                            }}>
                            Smart camera
                        </Button>

                        <Button onClick={() => setCamType('camera')}
                            style={{
                                ...btnStyles,
                                color: camType === 'camera' ? COLORS.white : COLORS.mainBlueBg,
                                backgroundColor: camType === 'camera' ? COLORS.mainBlueBg : COLORS.sepia,
                            }}>
                            Camera
                        </Button>
                    </ButtonGroup>

                    <Grid container spacing={2}>
                        {cameras.map((cam) => (
                            <LiveStreamCard
                                setData={loadCameras.setResponse}
                                camType={camType}
                                key={cam.id}
                                item={cam} />
                        ))}
                    </Grid>
                </div>
            ) : (
                <div className={cn(css(s.emptyCont), 'w-full flex-col items-center justify-center')}>
                    <SelectFingerIcon />

                    <h3 className="text-xl font-500 text-disabled text-center">
                        Select the rooms you want to view the cameras
                    </h3>
                </div>
            )}
        </Paper>
    )
}

const s = StyleSheet.create({
    cont: {
        backgroundColor: COLORS.white,
        flex: 1,
        padding: '22px 18px',
    },
    header: {
        background: COLORS.white,
        padding: '0 0 18px 0',
        ':nth-child(1n) > h3': {
            fontSize: 18,
            fontWeight: '600',
            color: COLORS.black,
        },
    },
    icon: {
        height: 30,
        width: 30,
        ':hover': {
            backgroundColor: COLORS.sepia,
        },
    },
    emptyCont: {
        height: 'calc(100vh - 12rem)',
        gap: 47,
        ':nth-child(1n) > h3': {
            width: '20rem',
        },
    },
    btns: {
        background: COLORS.sepia,
        padding: '8px 20px',
        fontWeight: '500',
        borderRadius: 5,
        color: COLORS.black,
        fontSize: 14,
        ':hover': {
            background: COLORS.mainBlueBg,
            color: COLORS.white,
        },
    },
    active: {
        background: COLORS.mainBlueBg,
        color: COLORS.white,
    },
    cardsWrap: {
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat(auto-fill, minmax(15rem, 1fr))',
    },
})
