import React, { useState, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Howl } from 'howler'
import Button from '../../components/common/Button'
import { COLORS } from '../../shared/utils/colors'
import { HeaderLogo, HeaderLogoM } from '../../components/svgs/SvgPictures'
import { navLink, videoData, audioData } from '../../shared/utils/landingData'
import { MenuBtn, Mail, Phone, PhoneM, CloseBtn, MailM } from '../../components/svgs/Svgs'
import TeamCard from './components/LandingTeam'
import LandingGifCards from './components/landingGifCards'
import LandingDrover from './components/LandingDroverContent'
import Forms from './components/LandingForm'
import LazyLoad from '../../app/LazyLoad'
import LangSelector from './components/LangSelector'

// importing components and give back this to the lazy load components
const LazyTeamCard = LazyLoad(TeamCard)
const LazyGifCards = LazyLoad(LandingGifCards)
const LazyDrover = LazyLoad(LandingDrover)
const LazyLangSelector = LazyLoad(LangSelector)
const LazyForms = LazyLoad(Forms)

let sound

const getRandomAudio = () => {
    const audioIndex = localStorage.getItem('audio')
    let audioSrc
    if (audioIndex) {
        const newIndex = (parseInt(audioIndex, 10) + 1) % audioData.length
        audioSrc = audioData[newIndex].src
        localStorage.setItem('audio', newIndex.toString())
    } else {
        const index = Math.floor(Math.random() * audioData.length)
        audioSrc = audioData[index].src
        localStorage.setItem('audio', index.toString())
    }
    return audioSrc
}

function HomePage() {
    const [selectedVideo, setSelectedVideo] = useState(() => {
        const videoIndex = localStorage.getItem('video')
        if (videoIndex !== null) {
            return videoData[parseInt(videoIndex, 10)].src
        }
        const randomIndex = Math.floor(Math.random() * videoData.length)
        localStorage.setItem('video', randomIndex.toString())
        return videoData[randomIndex].src
    })
    const [sidebar, setSidebar] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const videoIndex = localStorage.getItem('video')
        let videoSrc
        if (videoIndex) {
            const newIndex = (parseInt(videoIndex, 10) + 1) % videoData.length
            videoSrc = videoData[newIndex].src
            localStorage.setItem('video', newIndex.toString())
        } else {
            const index = Math.floor(Math.random() * videoData.length)
            videoSrc = videoData[index].src
            localStorage.setItem('video', index.toString())
        }

        setSelectedVideo(videoSrc)
    }, [])

    const scrollToSection = (id) => {
        const section = document.getElementById(id)
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' })
        } else {
            console.error(`scrollToSection: section with id ${id} not found`)
        }
    }

    useEffect(() => {
        const requestMicAccessAndPlayAudio = async () => {
            try {
                // Request microphone access as early as possible
                await navigator.mediaDevices.getUserMedia({ audio: true })

                // Check if sound is already playing
                if (!sound) {
                    // Select a random audio file
                    const selectedAudio = getRandomAudio()

                    // Create a Howl instance and play the audio
                    sound = new Howl({
                        src: [selectedAudio],
                        autoplay: true,
                        loop: false,
                        volume: 0.5,
                        preload: true,
                        onend: requestMicAccessAndPlayAudio,
                    })

                    sound.play()
                }
            } catch (error) {
                console.error('Microphone access denied:', error)
            }
        }

        requestMicAccessAndPlayAudio()
    }, [])

    useEffect(() => {
        if (!sidebar) {
            document.body.style.overflow = 'unset'
        } else {
            document.body.style.overflow = 'hidden'
        }
    }, [sidebar])

    return (
        <div className="relative bgBlack w-full">
            <header>
                <div className={cn(css(s.wrapper), 'flex-col relative z-index-1')}>
                    <div className={cn(css(s.gradient), 'absolute w-full h-full')} />
                    <div className={cn(css(s.videoBg), 'absolute w-full h-full')}>
                        <video autoPlay muted loop playsInline className={cn(css(s.video), 'w-full h-full')}>
                            <source src={selectedVideo} />
                        </video>
                    </div>
                    <div className={cn(css(s.headerWrapper), 'w-full marginAuto')}>
                        <div className={cn(css(s.header1), 'marginAuto flex justify-between items-center')}>
                            <div className="flex items-center gap-5">
                                <div className={css(s.logoV)}>
                                    <HeaderLogoM />
                                </div>
                                <div className={css(s.logoB)}>
                                    <HeaderLogo />
                                </div>
                            </div>
                            <div className={css(s.headerNav)}>
                                {navLink.map((item) => (
                                    <p onClick={() => scrollToSection(item.click)} key={item.id}
                                        className="text-white decoration-none font-400 relative  text-l textHover">
                                        {item.name}
                                    </p>
                                ))}
                            </div>
                            <div className={cn(css(s.headerBtn), 'flex items-center')}>
                                <div className={css(s.headerSelector)}>
                                    <LazyLangSelector />
                                </div>

                                <Button onClick={() => navigate('/auth')} className={css(s.loginBtn)}>Login</Button>
                            </div>
                            <div onClick={() => setSidebar(!sidebar)} className={css(s.menuBtn)}>
                                <MenuBtn />
                            </div>
                        </div>
                    </div>
                    <div className={cn(css(s.headerM), 'marginAuto flex-col items-start justify-center')}>

                        <h2 className={cn(css(s.headerMH), 'text-white text-xll')}>
                            Beyond Automation: AI
                            that Understands, Sees, and Innovates.
                        </h2>

                        <p className={cn(css(s.headerMP), 'text-white font-500 text-s')}>
                            Business Optimization,
                            Efficiency Enhancement, and Innovative
                            Solutions: A Guide to Implementing Artificial Intelligence
                        </p>

                        <Button className={cn(css(s.headerMB), 'text-white text-l font-500 rounded-s')}>
                            Learn more
                        </Button>
                    </div>
                </div>
            </header>
            <main>
                {/* <section> */}
                {/*     <Service /> */}
                {/* </section> */}
                <section id="section1">
                    <LazyGifCards />
                </section>
                <section id="section2">
                    <LazyTeamCard />
                </section>
                {/* <section className={cn(css(s.staticBg), 'w-full')}> */}
                {/*     <div className={cn(css(s.statica), 'marginAuto h-auto')}> */}
                {/*         <div className={cn(css(s.staticDiv), 'marginAuto h-auto flex-col')}> */}
                {/*             {StatisticData.map((item) => ( */}
                {/*                 <Statistic key={item.id} {...item} /> */}
                {/*             ))} */}
                {/*         </div> */}
                {/*     </div> */}
                {/* </section> */}
                <section className={cn(css(s.forms), 'w-full marginAuto')}>
                    <LazyForms />
                </section>
            </main>
            <footer>
                <div className={cn(css(s.footer), 'marginAuto w-full h-auto')}>
                    <div className={cn(css(s.footerW), 'marginAuto flex-col items-center justify-center')}>
                        <div className="flex items-center gap-2">
                            <div className={css(s.logoV)}>
                                <HeaderLogoM />
                            </div>

                            <div className={css(s.logoB)}>
                                <HeaderLogo />
                            </div>
                            <div />
                        </div>

                        <div className={cn(css(s.footerLinks), 'flex-col items-center justify-center')}>
                            <div className={cn(css(s.footerNav), 'flex-col items-center justify-center')}>
                                {navLink.map((item) => (
                                    <p onClick={() => scrollToSection(item.click)} key={item.id}
                                        className="text-white decoration-none font-500 text-m relative textHover">
                                        {item.name}
                                    </p>
                                ))}
                            </div>

                            <div className={cn(css(s.footerLink), 'flex-col items-center justify-center')}>
                                <Link to="mailto:kamolsf8@gmail.com"
                                    className="flex items-center gap-5 no_underline">
                                    <div className={css(s.logoV)}>
                                        <MailM />
                                    </div>
                                    <div className={css(s.logoB)}>
                                        <Mail />
                                    </div>
                                    <span className={css(s.infoTitle)}>
                                        kamolsf8@gmail.com
                                    </span>
                                </Link>
                                <Link to="tel:+998996600893" className="flex items-center gap-5 no_underline">
                                    <div className={css(s.logoV)}>
                                        <PhoneM />
                                    </div>
                                    <div className={css(s.logoB)}>
                                        <Phone />
                                    </div>
                                    <span className={css(s.infoTitle)}>
                                        +998 99 660 08 93
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cn(css(s.footerText), 'flex marginAuto items-center justify-center')}>
                    <span className="text-def font-500 items-center justify-center">
                        REALSOFT AI 2024
                    </span>
                </div>
            </footer>
            <div className={cn(
                'absolute flex-col',
                css(s.sidebar, sidebar ? s.navActive : s.navOff),
            )}>
                <div className={cn(css(s.burgerTop), 'flex items-center justify-between')}>
                    <div className="flex items-center gap-5">
                        <HeaderLogoM />
                    </div>

                    <div className={css(s.closeBtn)} onClick={() => setSidebar(!sidebar)}>
                        <CloseBtn />
                    </div>
                </div>
                <LazyDrover setProp={setSidebar} />
            </div>
        </div>
    )
}

export default HomePage

const s = StyleSheet.create({
    closeBtn: {
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    wrapper: {
        height: '62.5rem',
        paddingTop: '2.563rem',
    },
    videoBg: {
        top: '0',
        left: '0',
        zIndex: -2,
    },
    video: {
        objectFit: 'cover',
    },
    gradient: {
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.60)',
        zIndex: -1,
    },
    headerSelector: {
        display: 'none',
        '@media (min-width: 48rem)': {
            display: 'block',
        },
    },
    header1: {
        width: '90%',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
        },
        '@media (min-width: 62rem)': {
            maxWidth: '80%',
        },
    },
    headerWrapper: {
        maxWidth: '120rem',
    },
    headerM: {
        maxWidth: '90%',
        height: '70%',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
        },
        '@media (min-width: 62rem)': {
            maxWidth: '80%',
        },

    },
    headerMH: {
        maxWidth: '97%',
        '@media (min-width: 48rem)': {
            fontSize: '2rem',
        },
        '@media (min-width: 62rem)': {
            fontSize: '3rem',
            width: '70%',
        },
    },
    headerMP: {
        marginBottom: '3rem',
        maxWidth: '85%',
        lineHeight: '1.563rem',
        '@media (min-width: 48rem)': {
            fontSize: '1.125rem',
        },
    },
    headerMB: {
        paddingRight: '1.375rem',
        paddingLeft: '1.5rem',
        paddingTop: '0.688rem',
        paddingBottom: '0.75rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#fff',
        '@media (min-width: 48rem)': {
            paddingTop: '0.688rem',
            paddingBottom: '0.688rem',
            paddingLeft: '2rem',
            paddingRight: '1.938rem',
        },
    },
    headerNav: {
        position: 'relative',
        display: 'none',
        gap: '36px',
        '@media (min-width: 62rem)': {
            display: 'flex',
        },
    },
    headerBtn: {
        gap: '18px',
    },
    loginBtn: {
        display: 'none',
        paddingRight: '1.625rem',
        paddingLeft: '1.625rem',
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
        backgroundColor: COLORS.white,
        borderRadius: '8px',
        textColor: '#000',
        fontSize: '18px',
        fontWeight: '500',
        ':hover': {
            opacity: '0.7',
            transition: 'all 0.3s ease-in-out',
        },
        '@media (min-width: 62rem)': {
            display: 'inline',
        },
    },

    // statistic css style
    staticBg: {
        background: '#000',
    },
    statica: {
        maxWidth: '120rem',
        paddingBottom: '3.813rem',
        paddingTop: '5.25rem',
        backgroundColor: '#000',
    },
    staticDiv: {
        maxWidth: '90%',
        gap: '2.5rem',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },

    // form css styles
    forms: {
        background: COLORS.black,
    },

    // footer css styles
    footerLinks: {
        rowGap: '2.438rem',
        '@media (min-width: 62rem)': {
            flexDirection: 'row',
            gap: '3.563rem',
        },
    },
    footerLink: {
        rowGap: '1.688rem',
        '@media (min-width: 62rem)': {
            flexDirection: 'row',
            gap: '2.875rem',
        },
    },
    footerNav: {
        rowGap: '1.813rem',
        '@media (min-width: 62rem)': {
            flexDirection: 'row',
            gap: '2.875rem',

        },
    },
    infoTitle: {
        color: '#fff',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        textDecoration: 'none',
    },
    footerW: {
        width: '90%',
        paddingTop: '3.188rem',
        gap: '2.313rem',
        '@media (min-width: 76.125rem)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '80%',
            paddingBottom: '1.563rem',
        },
    },
    logoV: {
        display: 'inline-block',
        '@media (min-width: 62rem)': {
            display: 'none',
        },
    },
    logoB: {
        display: 'none',
        '@media (min-width: 62rem)': {
            display: 'inline-block',
        },
    },
    footer: {
        maxWidth: '120rem',
        width: '100%',
        background: COLORS.black,

    },
    footerText: {
        paddingTop: '2.813rem',
        paddingBottom: '1.563rem',
        width: '80%',
    },

    // menu btn css styles
    menuBtn: {
        display: 'flex',
        '@media (min-width: 62rem)': {
            display: 'none',
        },
    },
    sidebar: {
        top: 0,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '100%',
        paddingBottom: '20px',
        borderRadius: '8px',
        borderBottom: '1px solid #000',
        right: 0,
        zIndex: 2,
        backgroundColor: COLORS.black,
        transition: 'all 0.3s linear',
        '@media (min-width: 62rem)': {
            display: 'none',
        },
    },
    navActive: {
        left: 0,
    },
    navOff: {
        left: '100%',
    },
    burgerTop: {
        paddingTop: '2.563rem',
        width: '90%',
        margin: '0 auto',
        top: 0,

    },
})
