export function format(number) {
    return number.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export function integersOnly(value) {
    return String(value).replace(/[^0-9]/gim, '')
}

export function onChangePhoneNumber(value) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length

    if (phoneNumberLength < 3) return phoneNumber
    if (phoneNumberLength < 6) return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)}`
    if (phoneNumberLength < 8) {
        return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5, 7)}`
    }

    return (
        `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)}`
    + ` ${phoneNumber.slice(5, 7)} ${phoneNumber.slice(7, 9)}`
    )
}

export function formatPhoneNumber(value) {
    return value.replace(
        /^(\+\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/,
        '$1 $2 $3 $4 $5',
    )
}

export function normalizeCard(value) {
    if (!value) return null

    const card = value.replace(/[^\d]/g, '')
    if (card.length === 16) {
        return card.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4')
    }

    return null
}

export function ageRuFormat(age) {
    if (!age) return null

    const oneness = age.slice(-1) === '1' && age !== '11'
    const plural = age.slice(-1) >= 2
    && age.slice(-1) <= 4
    && !['12', '13', '14'].includes(age)

    const txt = oneness ? ' год' : plural ? ' года' : ' лет'

    return age + txt
}

export const getMousePos = (stage) => {
    const pos = stage.getPointerPosition()
    return pos ? { x: pos.x, y: pos.y } : { x: 0, y: 0 }
}
