import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import { IconButton, MenuItem, Select, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import BranchesTableItem from './BranchesTableItem'
import { useQueryParams } from '../../../shared/hooks/queryString'
import { useLoad } from '../../../shared/hooks/requests'
import { TENANT_ENTITIES } from '../../../shared/utils/urls'
import Table from '../../../shared/ui/Table'
import Create from '../../company-structure/features/Create'

const cols = [
    { id: 1, title: 'Title' },
    { id: 2, title: 'Description' },
    { id: 3, title: 'Country' },
    { id: 4, title: 'City' },
    { id: 5, title: 'Action', width: '10%', align: 'right' },
]

export default function BranchesTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const { level } = useQueryParams()
    const navigate = useNavigate()
    const loadEntities = useLoad({
        url: TENANT_ENTITIES,
        params: { page, size: pageSize, hierarchyLevel: level },
    }, [level, page, pageSize])
    const data = loadEntities.response ? loadEntities.response.items : []
    const meta = loadEntities.response ? loadEntities.response : {}

    useEffect(() => {
        navigate('/branches?level=1')
    }, [])

    const onSectionClick = (l) => {
        navigate(`/branches?level=${l}`)
    }

    return (
        <div className="flex flex-col gap-6">
            <Paper square className="flex flex-col gap-2.5 p-3">
                <Typography className="text-m text-black font-medium">Filter</Typography>

                <Select
                    variant="outlined"
                    placeholder="Select entity"
                    sx={{ height: 37, width: 123 }}
                    value={level}
                    defaultValue="1"
                    onChange={(e) => onSectionClick(e.target.value)}
                >
                    <MenuItem value="1">
                        Head Quarters
                    </MenuItem>

                    <MenuItem value="2">
                        Sections
                    </MenuItem>

                    <MenuItem value="3">
                        Branches
                    </MenuItem>
                </Select>
            </Paper>

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Branch Management
                    </Typography>

                    <div className="flex-cent gap-2">
                        <Create setData={loadEntities.setResponse} hierarchyLevel={+level} />

                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>

                <Table
                    page={page - 1}
                    loading={loadEntities.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <BranchesTableItem
                            item={item}
                            key={item.id}
                            setData={loadEntities.setResponse} />
                    )}
                />
            </Paper>
        </div>
    )
}
