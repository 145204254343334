import React from 'react'

export function UniversalCard({ cardTitle, children }) {
    return (
        <div className="w-[100%] h-[373px] flex flex-col
         items-start border gap-[15px] border-borderColor rounded-[14px] pt-[12px] pl-[12px] pr-[12px] pb-[20px]">
            <span className="text-[16px] font-semibold text-black">{cardTitle}</span>
            <div className="w-[100%] h-auto overflow-auto">
                {children}
            </div>
        </div>
    )
}
