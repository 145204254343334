import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment/moment'
import humps from 'humps'
import { ButtonGroup, Typography } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import useWebSocket from '../../../shared/hooks/websocket'
import Button from '../../../components/common/Button'
import { useLoad } from '../../../shared/hooks/requests'
import { TEMP_ATTENDANCES } from '../../../shared/utils/urls'
import AttendanceSections from './AttendanceSections'
import Pagination from '../../../components/common/Pagination'

const btnStyles = {
    borderColor: '#D8D8D8',
    fontSize: '14px',
    fontWeight: '400',
    height: 'inherit',
    flex: 1,
}

export default function LiveActions({ id }) {
    const [type, setType] = useState('visitor')
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const { socketData = [] } = useWebSocket(process.env.REACT_APP_SOCKET_URL + id)
    const loadSavedAttendances = useLoad(
        { url: TEMP_ATTENDANCES + type, params: { size: pageSize, page, smartCameraId: id } },
        [page, type],
    )
    const savedAttendances = loadSavedAttendances.response ? loadSavedAttendances.response.items : []
    const mergedData = socketData && savedAttendances ? [
        ...socketData.map((v) => humps.camelizeKeys(v)),
        ...savedAttendances.map((v) => humps.camelizeKeys(v)),
    ] : []
    const sortedData = mergedData.sort((a, b) => moment(a.created_at).diff((moment(b.created_at))))

    const handleBtnClick = (str) => {
        setType(str)
        console.log(str)
    }

    return (
        <div key={id} className={cn(css(s.liveActions), 'flex-col gap-4_5')}>
            <Typography className="text-l font-600 text-black">Real-time Capture</Typography>

            <ButtonGroup
                sx={{ overflow: 'hidden', minHeight: 38 }}
                variant="contained" aria-label="users-filter-button">
                {/* <Button onClick={() => handleBtnClick('user')}
                    style={{
                        ...btnStyles,
                        color: type === 'user' ? COLORS.white : COLORS.mainBlueBg,
                        backgroundColor: type === 'user' ? COLORS.mainBlueBg : COLORS.sepia,
                    }}>
                    Customer users
                </Button> */}

                <Button onClick={() => handleBtnClick('visitor')}
                    style={{
                        ...btnStyles,
                        color: type === 'visitor' ? COLORS.white : COLORS.mainBlueBg,
                        backgroundColor: type === 'visitor' ? COLORS.mainBlueBg : COLORS.sepia,
                    }}>
                    Visitors
                </Button>
                <Button onClick={() => handleBtnClick('wanted')}
                    style={{
                        ...btnStyles,
                        color: type === 'wanted' ? COLORS.white : COLORS.mainBlueBg,
                        backgroundColor: type === 'wanted' ? COLORS.mainBlueBg : COLORS.sepia,
                    }}>
                    Blacklist
                </Button>
                <Button onClick={() => handleBtnClick('identity')}
                    style={{
                        ...btnStyles,
                        color: type === 'identity' ? COLORS.white : COLORS.mainBlueBg,
                        backgroundColor: type === 'identity' ? COLORS.mainBlueBg : COLORS.sepia,
                    }}>
                    Identities
                </Button>
            </ButtonGroup>

            {/* {type === 'user' ? (
                <AttendanceSections
                    data={sortedData.filter((d) => d.captureType === 'user')}
                    loading={loadSavedAttendances.loading} />
            ) : null} */}

            {type === 'visitor' ? (
                <AttendanceSections
                    data={sortedData}
                    loading={loadSavedAttendances.loading} />
            ) : null}

            {type === 'wanted' ? (
                <AttendanceSections
                    data={sortedData}
                    loading={loadSavedAttendances.loading} />
            ) : null}

            {type === 'identity' ? (
                <AttendanceSections
                    data={sortedData}
                    loading={loadSavedAttendances.loading} />
            ) : null}

            <Pagination
                page={page}
                showSelectRows={false}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                loading={loadSavedAttendances.loading}
                pageCount={loadSavedAttendances.response && loadSavedAttendances.response.pages}
                count={loadSavedAttendances.response && loadSavedAttendances.response.total}
            />
        </div>
    )
}

const s = StyleSheet.create({
    liveActions: {
        flex: 0.2,
    },
    btnsCont: {
        ':nth-child(1n) > :first-child': {
            borderRadius: '5px 0 0 5px',
        },
        ':nth-child(1n) > :last-child': {
            borderRadius: '0 5px 5px 0',
            borderRight: 'none',
        },
    },
    btn: {
        borderRight: `1px solid ${COLORS.disabled} !important`,
        background: COLORS.sepia,
        borderRadius: 0,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.mainBlueBg,
        padding: '6px 15px',
    },
    active: {
        color: COLORS.white,
        borderRight: '1px solid #2A4A6B',
        background: COLORS.mainBlueBg,
    },
})
