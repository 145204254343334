import * as React from 'react'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart'

const data = [
    { label: 'More than 1 hour', value: 400, color: '#E79A28' },
    { label: '45-60 minutes', value: 300, color: '#E7298A' },
    { label: '15-20 minutes', value: 300, color: '#7570B3' },
    { label: '10-15 minutes', value: 200, color: '#F7750F' },
    { label: 'Less than 5 minutes', value: 200, color: '#1C9E76' },
]

const sizing = {
    margin: { right: 5 },
    width: 201,
    height: 201,
    legend: { hidden: true },
}
const sizingM = {
    margin: { right: 5 },
    width: 97,
    height: 97,
    legend: { hidden: true },
}

const getArcLabel = (params) => {
    const percent = params.value
    return percent.toString()
}

export default function PieCharts({ sm }) {
    return (
        <div className="flex items-center gap-[17px] justify-center">
            <PieChart
                series={[
                    {
                        data,
                        arcLabel: getArcLabel,
                        innerRadius: sm ? 10 : 60,
                        outerRadius: sm ? 50 : 100,
                        cornerRadius: sm ? 5 : 10,
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontSize: 14,
                    },
                }}
                {...(sm ? sizingM : sizing)}
            />
            <div style={{ marginTop: 20 }}>
                {data.map((item) => (
                    <div
                        key={item.label}
                        style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}
                    >
                        <div style={{ width: 10,
                            height: 10,
                            backgroundColor: item.color,
                            borderRadius: 5,
                            marginRight: 5 }} />
                        <span className={sm ? 'text-[12px]' : 'text-[16px]'}>{item.label}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
