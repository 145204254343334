import React, { useEffect, useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import Table from '../../../shared/ui/Table'
import MasterUserFilter from './MasterUserFilter'
import { useFaceLoad } from '../../../shared/hooks/faceAuthRequest'
import { CLIENTS, MASTER_USERSPAGINATION } from '../../../shared/utils/urls'
import MasterUserTableItems from './MasterUsersTableItem'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

const cols = [
    {
        id: 1,
        title: 'Created time',
        align: 'left',
        width: '33%',
    },
    {
        id: 2,
        title: 'Name',
        align: 'center',
        width: '33%',
    },
    {
        id: 3,
        title: 'Users list',
        align: 'center',
        width: '33%',
    },
]

export function MasterUserTable() {
    const { entityFilters, setEntityFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const loadMasterUser = useFaceLoad(
        { url: MASTER_USERSPAGINATION,
            params:
        { page, size: pageSize, client_id: entityFilters.master ? entityFilters.master : 0 } },
        [page, pageSize, entityFilters.master],
    )
    const data = loadMasterUser.response ? loadMasterUser.response.items : []
    const meta = loadMasterUser.response ? loadMasterUser.response : {}

    useEffect(() => {
        setPage(1)
    }, [entityFilters.master])

    // this is for the filter fetching data
    const getClients = useFaceLoad({ url: CLIENTS }, [])
    const clientList = getClients.response ? getClients.response : []

    return (
        <div className="flex flex-col items-center gap-[21px] w-[100%]">
            <div className="w-[100%] bg-white flex flex-col items-start
             gap-[10px] h-auto pt-[14px] pb-[15px] px-[14px] shadow-sm">
                <span className="text-[16px] font-normal text-black">Filter</span>
                <MasterUserFilter items={clientList}
                    onChange={(e) => setEntityFilter('master', e.target.value)} placeholder="Choose client"
                    value={entityFilters.master} />
            </div>
            <div className="bg-white w-[100%]">
                <div className="flex items-center justify-between h-[60px] px-4">
                    <Typography className="text-[20px] font-normal text-black">
                        Master users list ({meta.total ? meta.total : 0})
                    </Typography>

                    <div className="flex-cent gap-2">
                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={loadMasterUser.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <MasterUserTableItems
                            key={item.id}
                            item={item}
                            setData={loadMasterUser.setResponse} />
                    )}
                />
            </div>
        </div>
    )
}
