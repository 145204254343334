import React from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'
import { COLORS } from '../utils/colors'

export default function FormTextField({
    name,
    label,
    type,
    disabled = false,
    required = false,
    handleChange = {},
    overrideStyles,
    inputProps,
    ...otherProps
}) {
    const [field, meta] = useField(name)

    const configTextField = {
        ...field,
        ...otherProps,
    }

    if (meta && meta.touched && meta.error) {
        configTextField.error = true
        configTextField.helperText = meta.error
    }

    return (
        <TextField
            type={type}
            id={name}
            name={name}
            label={label}
            disabled={disabled}
            variant="standard"
            required={required}
            onChange={handleChange}
            InputProps={inputProps}
            sx={{
                '& .MuiInputBase-input': {
                    paddingBottom: '8px',
                    fontSize: 16,
                    fontWeight: 400,
                    color: COLORS.black,
                },
                '& .MuiInputLabel-root': {
                    fontSize: 16,
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.54)',
                },
                '& .MuiInput-underline:before': {
                    borderBottomColor: 'rgba(0, 0, 0, 0.54)',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottomColor: '#7C7D7E',
                    borderBottomWidth: '1px',
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: 'black',
                    borderBottomWidth: '1px',
                },
                ...overrideStyles,
            }}
            {...configTextField}
        />
    )
}
