class WebSocketService {
    constructor(url, maxReconnectAttempts = 999) {
        this.url = url
        this.listeners = []
        this.maxReconnectAttempts = maxReconnectAttempts
        this.reconnectAttempts = 0
        this.reconnectExponentialDelay = 1000

        this.initializeWebSocket()
    }

    initializeWebSocket() {
        this.ws = new WebSocket(this.url)
        this.ws.onmessage = (message) => {
            const data = JSON.parse(message.data)
            this.listeners.forEach((listener) => listener(data))
        }

        this.ws.onclose = () => {
            console.log('WebSocket closed. Attempting to reconnect...')
            this.attemptReconnect()
        }

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }
    }

    attemptReconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            setTimeout(() => {
                this.reconnectAttempts++
                console.log(`Reconnect attempt #${this.reconnectAttempts}`)
                this.initializeWebSocket()
            }, this.reconnectExponentialDelay)
        } else {
            console.error('Max WebSocket reconnection attempts reached.')
        }
    }

    addListener(listener) {
        this.listeners.push(listener)
    }

    removeListener(listener) {
        this.listeners = this.listeners.filter((l) => l !== listener)
    }
}

export default WebSocketService
