/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import SelectInput from '../../../shared/ui/SelectInput'
import { useGetRequest } from '../../../shared/hooks/requests'
import { ML_MODELS } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import { SelectFingerIcon } from '../../../components/svgs/SvgPictures'

export default function ApiIntegrationDocs({ mlName, version }) {
    const getByVersion = useGetRequest()
    const showMessage = useMessage()
    const [markdown, setMarkDown] = useState(null)
    const data = getByVersion.response ? getByVersion.response : {}

    async function fetchMarkdown() {
        if (!Object.values(data).length) return
        try {
            const response = await fetch(data.s3ModelConfigPath)
            if (!response.ok) {
                showMessage('Network response was not ok', 'error-msg')
            }
            const blob = await response.blob()
            const reader = new FileReader()

            reader.onload = (e) => {
                setMarkDown(e.target.result)
            }

            reader.readAsText(blob)
        } catch (error) {
            showMessage(error, 'error-msg')
        }
    }

    useEffect(() => {
        fetchMarkdown()
    }, [getByVersion.response])

    const versions = Array.from(
        { length: Math.max(version) },
        (_, index) => ({ value: index + 1, label: `V${index + 1}` }),
    )

    return (
        <Formik initialValues={{ version: '' }} onSubmit={() => {}}>
            {({ setFieldValue }) => (
                <Form className="p-5 w-full flex flex-col gap-5">
                    <SelectInput
                        fullWidth
                        name="version"
                        label="Select version"
                        menuItems={versions}
                        onChange={(e) => {
                            getByVersion.request({
                                url: `${ML_MODELS + mlName}/${e.target.value}`,
                            })
                            setFieldValue('version', e.target.value)
                        }} />

                    <Box className="border border-sepia-100 rounded ">
                        <header className="py-2 px-4 bg-[#F8F8F8]">
                            <Typography className="text-sm font-medium text-[#6A7386]">Documentation</Typography>
                        </header>

                        <section className="h-full overflow-y-auto">
                            {markdown ? (
                                <SyntaxHighlighter
                                    customStyle={{ overflowY: 'scroll', maxHeight: '50vh' }}
                                    language="markdown"
                                    style={atomOneDark}>
                                    {markdown}
                                </SyntaxHighlighter>
                            ) : !getByVersion.loading ? (
                                <div className="w-full h-96 flex flex-col items-center justify-center gap-8">
                                    <SelectFingerIcon />

                                    <Typography className="text-xl font-medium text-gray-100">
                                        Select one of the versions
                                    </Typography>
                                </div>
                            ) : (
                                <div className="flex w-full h-96 items-center justify-center ">
                                    <CircularProgress />
                                </div>
                            )}
                        </section>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}
