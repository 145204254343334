import React, { useState } from 'react'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import EntityForm from './EntityForm'

const navItems = [
    { id: 1, name: 'Details' },
]

export default function Details({ item, loading, parentOptions, onEdit, onClose }) {
    const [section, setSection] = useState('Details')

    const initialValues = {
        name: item.name || '',
        description: item.description || '',
        districtId: item.districtId || '',
        countryId: item.countryId || '',
        regionId: item.regionId || '',
        photo: item.photo,
        hierarchyLevel: item.hierarchyLevel,
        parentId: item.parentId,
        fileName: item.photo ? item.photo.split('/')[item.photo.split('/').length - 1] : '',
    }

    return (
        <DrawerLayout
            topic="Entity details"
            onClose={onClose}
            name={item.name}
            icons={[]}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.name)}
                                className={
                                    `cursor-pointer text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.name ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* <div className="flex items-center gap-2_5"> */}
                {/*     <Button className={css(s.actBtn)}>Open details page</Button> */}
                {/* </div> */}

                <EntityForm
                    isEdit
                    onClose={onClose}
                    loading={loading}
                    onSubmit={onEdit}
                    hierarchyLevel={item.hierarchyLevel}
                    parentOptions={parentOptions}
                    initialValues={initialValues} />
            </React.Fragment>
        </DrawerLayout>
    )
}
