import React, { memo, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import uuid from 'react-uuid'
import { email, required, validator } from '../../../shared/utils/validators'
import { COLORS } from '../../../shared/utils/colors'
import Button from '../../../components/common/Button'
import { LinkIn, LinkInM, Checked, Telegram, TelegramM, JoinIcon } from '../../../components/svgs/Svgs'
import FormInput from '../../../components/common/FormInput'

function Forms() {
    const [isOpenModal, setIsOpenModal] = useState(false)
    const closeModal = () => {
        setIsOpenModal(false)
    }
    const onSubmit = (values, { resetForm }) => {
        console.log(values)
        setIsOpenModal(!isOpenModal)
        setTimeout(() => {
            closeModal()
        }, 1000)
        resetForm()
    }

    return (
        <>
            <div className={cn(css(s.formBox), 'marginAuto h-auto')}>
                <Formik initialValues={{ userName: '', mail: '', message: '', id: uuid() }}
                    onSubmit={onSubmit}>
                    <div className={cn(css(s.innerBox), 'flex-col marginAuto')}>
                        <div className={cn(css(s.linksBox), 'w-full h-full flex items-center')}>
                            <div className={cn(css(s.linkBox), 'flex-col')}>
                                <Link className={css(s.linkedBox)} to="https://www.linkedin.com/company/realsoftai/">
                                    <div className={cn(css(s.box1), 'flex items-center justify-center')}>
                                        <div className={css(s.logoL)}>
                                            <LinkInM />
                                        </div>
                                        <div className={css(s.logoK)}>
                                            <LinkIn />
                                        </div>
                                        <p className="text-white">
                                            LinkedIn
                                        </p>
                                    </div>
                                    <div className={cn(css(s.box2), 'items-center')}>
                                        <div className={css(s.joinBox)}>
                                            <JoinIcon />
                                        </div>
                                        <p className={cn(css(s.chatTitle), 'font-500')}>
                                            Join chat
                                        </p>
                                    </div>
                                </Link>
                            </div>

                            <div className={css(s.linkBox)}>
                                <Link className={css(s.linkedBox)} to="https://t.me/realsoftai">
                                    <div className={cn(css(s.box1), 'flex items-center justify-center')}>
                                        <div className={css(s.logoL)}>
                                            <TelegramM />
                                        </div>
                                        <div className={css(s.logoK)}>
                                            <Telegram />
                                        </div>
                                        <p className="text-white">
                                            Telegram
                                        </p>
                                    </div>
                                    <div className={cn(css(s.box2), 'items-center')}>
                                        <div className={css(s.joinBox)}>
                                            <JoinIcon />
                                        </div>
                                        <h3 className={cn(css(s.chatTitle), 'font-500')}>
                                            Join chat
                                        </h3>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <Form className={cn(css(s.form), 'flex-col')}>
                            <p className={css(s.formTitle)}>
                                Ready to answer any questions:
                                your reliable partner in finding solutions
                            </p>

                            <div className={cn(css(s.formInputs), 'flex-col')}>
                                <div className={cn(css(s.InputsBox1), 'flex-col')}>
                                    <div className="w-full">
                                        <FormInput
                                            name="userName"
                                            label="Your Name"
                                            validate={required}
                                            errorWithMessage />
                                    </div>

                                    <div className="w-full">
                                        <FormInput
                                            type="email"
                                            name="mail"
                                            label="Your Mail"
                                            validate={validator(required, email)}
                                            errorWithMessage />
                                    </div>
                                </div>
                                <div>
                                    <FormInput type="text" name="message" validate={required}
                                        label="Your Message" errorWithMessage />
                                </div>
                            </div>

                            <div className="flex items-center gap-2">
                                <Button type="submit"
                                    className={css(s.formBtn)}>Send
                                </Button>
                                <span className={css(s.privacyText)}>By clicking submit, you agree
                                    to our Privacy Policy
                                </span>
                            </div>
                        </Form>
                    </div>
                </Formik>
                <div className={cn(css(s.line), 'marginAuto')} />
            </div>
            {isOpenModal && (
                <div className={css(s.modal)}>
                    <div className={cn(css(s.modalContent), 'absolute')}>
                        <div className={cn(css(s.modalContext), 'flex-col items-center justify-center')}>
                            <Checked />
                            <span className={css(s.modalText)}>Successfully sent</span>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default memo(Forms)

const s = StyleSheet.create({
    formBox: {
        paddingTop: '12px',
        maxWidth: '120rem',

    },
    innerBox: {
        maxWidth: '90%',
        height: '566px',
        rowGap: '1.25rem',
        marginBottom: '4rem',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
            flexDirection: 'row-reverse',
            alignItems: 'start',
            justifyContent: 'space-between',
            gap: '1.875rem',
        },
    },
    linksBox: {
        gap: '0.625rem',
        '@media (min-width: 48rem)': {
            flexDirection: 'column',
            width: '25%',
            rowGap: '1.875rem',
        },
    },
    line: {
        maxWidth: '90%',
        height: '1px',
        background: '#fff',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
        },
    },
    linkBox: {
        paddingLeft: '0.5em',
        width: '50%',
        backgroundColor: '#1E1E1E',
        height: '9.375rem',
        '@media (min-width: 48rem)': {
            width: '100%',
            height: '16.688rem',
        },
    },
    linkedBox: {
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
        height: '100%',
        '@media (min-width: 48rem)': {
            width: '100%',
            height: '16.688rem',
        },
    },
    box1: {
        height: '90%',
        gap: '0.625rem',
        '@media (min-width: 62rem)': {
            height: '85%',
        },
    },
    box2: {
        display: 'none',
        gap: '0.688rem',
        '@media (min-width: 62rem)': {
            display: 'flex',
        },
    },
    chatTitle: {
        color: COLORS.diamondWhite,
        fontSize: '1rem',
    },

    // form page for submit css
    form: {
        maxWidth: '100%',
        height: '100%',
        backgroundColor: '#1E1E1E',
        paddingTop: '1.125rem',
        paddingBottom: '1rem',
        paddingLeft: '1.125rem',
        paddingRight: '1.125rem',
        '@media (min-width: 48rem)': {
            width: '65%',
            paddingTop: '2.438rem',
            paddingBottom: '2.4rem',
            paddingLeft: '2.563rem',
            paddingRight: '2.563rem',
        },

    },
    formTitle: {
        color: '#fff',
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '1.5rem',
        maxWidth: '84%',
        textAlign: 'left',
        marginBottom: '1.375rem',
        '@media (min-width: 48rem)': {
            fontSize: '2rem',
            maxWidth: '100%',
            lineHeight: 'normal',
            marginBottom: '1.938rem',
        },
        '@media (min-width: 78.938rem)': {
            marginBottom: '3.938rem',
            maxWidth: '100%',
        },
    },
    formInputs: {
        rowGap: '1.875rem',
        marginBottom: '2.125rem',
        '@media (min-width: 48rem)': {
            rowGap: '3.313rem',
            marginBottom: '1.125rem',
        },
        '@media (min-width: 62rem)': {
            marginBottom: '3.250rem',
        },
    },
    InputsBox1: {
        rowGap: '1.875rem',
        '@media (min-width: 48rem)': {
            flexDirection: 'row',
            columnGap: '1.875rem',
        },
    },
    inputName: {
        width: '100%',
        paddingTop: '0.625rem',
        paddingBottom: '0.75rem',
        outline: 'none',
        border: 'none',
        borderBottom: '1px solid #000',
        fontSize: '0.875rem',
        color: '#fff',
        touchAction: 'manipulation',
        '@media (min-width: 48rem)': {
            paddingTop: '1.125rem',
            paddingBottom: '1.125rem',
            fontSize: '1.125rem',
        },
    },
    formBtn: {
        paddingTop: '0.688rem',
        paddingBottom: '0.563rem',
        paddingLeft: '2.75rem',
        paddingRight: '2.688rem',
        background: COLORS.btnBlue,
        color: '#fff',
        fontSize: '1rem',
        fontWeight: '500',
        borderRadius: '0.5rem',
        maxWidth: '40%',
        ':hover': {
            opacity: '0.7',
        },
        '@media (min-width: 48rem)': {
            paddingTop: '0.875rem',
            paddingBottom: '0.875rem',
            maxWidth: '20%',
        },
    },
    logoL: {
        display: 'inline-block',
        '@media (min-width: 48rem)': {
            display: 'none',
        },
    },
    logoK: {
        display: 'none',
        '@media (min-width: 48rem)': {
            display: 'inline-block',
        },
    },
    privacyText: {
        display: 'none',
        width: '30%',
        color: COLORS.lightBlack,
        '@media (min-width: 62rem)': {
            display: 'block',
        },
    },
    errorText: {
        color: COLORS.red,
        fontSize: '500',
    },
    joinBox: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        backgroundColor: '#D9D9D9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':hover': {
            transform: 'rotate(90deg)',
            transition: 'all 0.3s ease-in-out',
        },
    },

    // modal css
    modal: {
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        background: 'rgba(49, 49, 49, 0.8)',
        transition: 'all 0.3s ease',
    },
    modalContext: {
        height: '90%',
        rowGap: '1.563rem',
    },
    modalContent: {
        top: '50%',
        left: '50%',
        width: '80%',
        height: '300px',
        borderRadius: '8px',
        transform: 'translate(-50%, -50%)',
        background: COLORS.diamondWhite,
        '@media (min-width: 48rem)': {
            width: '50%',
            height: '573px',
        },
    },
    modalText: {
        fontSize: '1rem',
        fontWeight: '500',
        lineHeight: 'normal',
        '@media (min-width: 48rem)': {
            fontSize: '2rem',
            fontWeight: '600',
        },
    },
})
