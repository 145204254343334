import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import { usePostRequest } from '../../../shared/hooks/requests'
import { SMARTCAMERAS_TENANT } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import SmartCameraForm from '../components/SmartCameraForm'

export default function Create({ setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createSmartCamera = usePostRequest({ url: SMARTCAMERAS_TENANT })

    const create = async (values) => {
        if (createSmartCamera.loading) return

        const { response, success, error } = await createSmartCamera.request({ data: values })

        if (success) {
            showMessage('Successfully created', 'success-msg')
            setData((prev) => ({
                ...prev,
                items: [...(prev.items || []), response].every((v) => v.roomId === values.roomid)
                    ? [...prev.items, response] : prev.items ? [...prev.items, response] : [response],
            }))
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Smart Camera</h3>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <SmartCameraForm
                        loading={createSmartCamera.loading}
                        onClose={() => setOpen(false)}
                        onSubmit={create} />
                </DialogContent>
            </Dialog>
        </>
    )
}
