import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { useMessage } from '../../../../shared/hooks/message'
import useHandleErrors from '../../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../../shared/utils/colors'
import { FIRMWARE_POST_WITH_FILE } from '../../../../shared/utils/urls'
import FirmwareForm from '../components/FirmwareForm'
import { usePostRequest } from '../../../../shared/hooks/requests'

export default function Create({ setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createFirmware = usePostRequest({ url: FIRMWARE_POST_WITH_FILE })

    const create = async (values) => {
        if (createFirmware.loading || !values) return

        const formData = new FormData()
        formData.append('file', values.file)

        const { response, success, error } = await createFirmware.request({
            data: formData,
            params: { name: values.name, description: values.description },
        })

        if (success) {
            setData((prev) => [...(prev || []), response])
            showMessage('Successfully created', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between py-1 pl-3 pr-1
                 bg-main-100 text-white text-lg font-medium">
                    <Typography>Create Firmware</Typography>

                    <IconButton onClick={() => setOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <FirmwareForm
                        loading={createFirmware.loading}
                        onSubmit={create}
                        onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
