import React, { useState } from 'react'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import JetsonProfileForm from './JetsonProfileForm'

const navItems = [
    { id: 'detail', name: 'Details' },
]

export default function JetsonProfileDetails({ item, onEdit, onClose, loading }) {
    const [section, setSection] = useState('detail')

    return (
        <DrawerLayout
            topic="Jetson profile details"
            onClose={onClose}
            name={item.name}
            icons={[]}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.id)}
                                className={
                                    `cursor-pointer text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.id ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* <div className="flex items-center gap-2_5">
                            <Button onClick={showTenantAdmins} className={css(s.actBtn)}>
                                Manage tenant admins
                            </Button>
                        </div> */}

                <JetsonProfileForm
                    isEdit
                    loading={loading}
                    onClose={onClose}
                    onSubmit={onEdit}
                    initialValues={item} />
            </React.Fragment>
        </DrawerLayout>
    )
}
