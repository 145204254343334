import React, { useRef } from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import PhoneInput from '../../../shared/ui/PhoneInput'
import { FILE_UPLOAD } from '../../../shared/utils/urls'
import { isImageReal } from '../../../shared/utils/string'
import useImageUpload from '../../../shared/hooks/imageUploadHandler'
import { PhotoCameraIcon } from '../../../components/svgs/Svgs'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const FORM_INITIAL_STATE = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    photo: '',
    fileName: '',
    description: '',
}

const VALIDATION_SCHEMA = object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    email: string().test(
        'is-email',
        'Given email address is not valid',
        (value) => (`${value}`).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
    ).required('Email is required'),
    phone: string().required('Phone is required'),
    photo: string().optional(),
    fileName: string().optional(),
    description: string().optional(),
})

export default function TenantAdminForm({
    onSubmit = () => {},
    onClose = () => {},
    isEdit,
    loading = false,
    initialValues,
}) {
    const inputRef = useRef(null)
    const { onFileUpload } = useImageUpload()

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ values, setFieldValue, handleChange }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <div className="w-full flex items-center gap-3 justify-between">
                            {isImageReal(values.photo) ? (
                                <img src={values.photo} alt="#Admin" className=" w-[60px] h-[60px] rounded" />
                            ) : (
                                <div className="flex items-center justify-center bg-gray-100 w-[60px] h-[60px] rounded">
                                    <PhotoCameraIcon />
                                </div>
                            )}

                            <FormTextField
                                fullWidth
                                type="text"
                                label="Photo"
                                name="fileName"
                                onClick={() => inputRef.current.click()} />

                            <input
                                id="file"
                                type="file"
                                name="photo"
                                ref={inputRef}
                                readOnly="readonly"
                                className="not-visible"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(e) => onFileUpload(e, setFieldValue, 'photo', FILE_UPLOAD)} />
                        </div>

                        <FormTextField
                            label="First name"
                            name="firstName"
                            required
                            handleChange={handleChange} />

                        <FormTextField
                            required
                            label="Last name"
                            name="lastName"
                            handleChange={handleChange} />

                        <FormTextField
                            label="Email"
                            name="email"
                            required
                            handleChange={handleChange} />

                        <PhoneInput
                            required
                            label="Phone number"
                            name="phone"
                            handleChange={handleChange} />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
