import { StyleSheet, css } from 'aphrodite'
import React from 'react'
import { COLORS } from '../../../shared/utils/colors'
import { separateCamCase } from '../../../shared/utils/string'

export default function EntityUserErrors({ errors }) {
    return (
        <div className={css(s.cont)}>
            {errors.map((err) => (
                <ul className="flex-col gap-2" key={err.identityId}>
                    {Object.entries(err).map(([key, value]) => (value !== null ? (
                        <li className="flex item-center gap-2" key={key}>
                            <h3 className="text-s text-lightRed font-500">
                                {separateCamCase(key)}:
                            </h3>

                            <span className="text-s text-lightRed font-400">
                                {typeof value === 'boolean' ? (value ? 'yes' : 'no') : value}
                            </span>
                        </li>
                    ) : null))}
                </ul>
            ))}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        height: 'inherit',
        overflowY: 'scroll',
        borderRadius: 2,
        padding: '0 16px 13px 16px',
        ':nth-child(1n) > ul': {
            listStyleType: 'none',
            marginBottom: 20,
            background: COLORS.pink,
            padding: '13px 16px',
        },
    },
})
