import { Grid, CircularProgress, List, ListItem, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import humps from 'humps'
import axios from 'axios'
import { useLoad } from '../../../shared/hooks/requests'
import { SMARTCAMERA_GET_FACE_CONFIG, SMARTCAMERA_SET_FACE_CONFIG } from '../../../shared/utils/urls'
import SelectInput from '../../../shared/ui/SelectInput'
import NumberInput from '../../../shared/ui/NumberInput'
import { COLORS } from '../../../shared/utils/colors'
import ToggleSwitch from '../../../shared/ui/ToggleSwitch'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { auth } from '../../../shared/utils/auth'
import { domain } from '../../../shared/utils/request'

const uploadModeOpts = [
    { value: '0', label: 'Face Image' },
    { value: '1', label: 'Background Image' },
    { value: '2', label: 'Face & Background Image' },
    { value: '3', label: 'Face & Humanoid' },
    { value: '4', label: 'Face & Humanoid & Background Image' },
    { value: '5', label: 'Humanoid Image' },
]

const initialValues = {
    FaceQuality: 0, // umber, max:10, min:1  Face Sensitivity
    // MaskDetectEnable: '',
    FaceMiniPixel: 0, // number 30 300 Face Min Pixel
    FaceMaxPixel: 0, // number 300 700  Face Max Pixel
    DetectAreaX: 0, // number 0 1920
    DetectAreaY: 0, // number 0 1920
    DetectAreaW: 0, // number 0 1920
    DetectAreaH: 0, // number 0 1920
    LivenessEnable: '', // select close color detection 0 and 1 Liveness Detection Mode
    LivenessThreshold: 0, // number 0 0.99  Living Threshold
    SnapMode: '', // select number,0: capture after leaving, 1: quick + capture after leaving  Capture Mode
    UploadMode: '', // select uploadModeOpts Local Image Save Mode
    // ChooseMode: '',
    // Yaw: '',
    // Pitch: '',
    // Roll: '',
    // FacePicQuality: '',
    PicQuality: 0, // number, max:90, min:0  Scene quality
    // SnapFaceArea: '',
    MultiFace: false, // bool number, open:1, close:0  Face Property Detection
    BodyQuality: 0, // number, max:90, min:0 Humanoid Coding Quality
    BodyAreaEx: 0, // umber, max:10, min:0 Humanoid Expansion Factor
    ExposureMode: '', // select Ordinary Scene  Exposure Mode
    // PicUploadMode: '',
    // WedIrMinFace: '',
    // TempEnable: '',
    // CompEnable: '',
    // IoType: '',
    // IOOutputTime: '',
    // AlarmTempValue: '',
    // TempDetectAreaX: '',
    // TempDetectAreaY: '',
    // TempDetectAreaW: '',
    // TempDetectAreaH: '',
    // TempMinPixel: '',
    // TempMaxPixel: '',
    // IoEnable: '',
    // ShowFaceNameN: '',
    // IoController: '',
    // TempType: '',
    // strangerFilt: '',
    // strangerDay: '',
}

const titleStyles = {
    fontSize: 14,
    fontWeight: '400',
    flex: 1,
    color: COLORS.placeholder,
    fontFamily: 'Gilroy, sans-serif',
}

export default function FaceParametres({ id }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [loading, setLoading] = useState(false)
    const loadConfig = useLoad({ url: SMARTCAMERA_GET_FACE_CONFIG, params: { smartCameraId: id } }, [])
    const configData = loadConfig.response ? humps.pascalizeKeys(loadConfig.response) : {}

    const onSubmit = async (values) => {
        if (!values || loading) return

        setLoading(true)
        axios.post(domain + SMARTCAMERA_SET_FACE_CONFIG, values, { ...auth(), params: { smart_camera_id: id } })
            .then(({ data }) => {
                if (data.success) {
                    setLoading(false)
                    showMessage(data.message, 'success-msg')
                }
            }).catch(({ response }) => {
                setLoading(false)
                handleErrorMsg(response)
            }).finally(() => setLoading(false))
    }

    return !loadConfig.loading ? (
        <Formik enableReinitialize
            initialValues={{ ...initialValues, ...configData }}
            onSubmit={onSubmit}>
            {({ handleChange, values, setFieldValue }) => (
                <Form className="w-full h-full">
                    <Grid padding={2} container spacing={2}>
                        <Grid item width="50%">
                            <SelectInput
                                fullWidth
                                name="LivenessEnable"
                                label="Liveness Detection Mode"
                                value={values.LivenessEnable}
                                menuItems={[{ value: '0', label: 'Close' }, { value: '1', label: 'Color detection' }]}
                                onChange={(e) => {
                                    setFieldValue('LivenessEnable', e.target.value)
                                    handleChange(e)
                                }}
                            />
                        </Grid>

                        <Grid item width="50%">
                            <SelectInput
                                fullWidth
                                name="SnapMode"
                                label="Capture Mode"
                                value={values.SnapMode}
                                menuItems={[
                                    { value: '0', label: 'capture after leaving' },
                                    { value: '1', label: 'quick + capture after leaving' },
                                ]}
                                onChange={(e) => {
                                    setFieldValue('SnapMode', e.target.value)
                                    handleChange(e)
                                }}
                            />
                        </Grid>

                        <Grid item width="50%">
                            <SelectInput
                                fullWidth
                                name="ExposureMode"
                                label="Exposure Mode"
                                menuItems={[{ value: 0, label: 'Ordinary Scene' }]}
                                value={values.ExposureMode}
                                onChange={(e) => setFieldValue('ExposureMode', e.target.value)}
                            />
                        </Grid>

                        <Grid item width="50%">
                            <SelectInput
                                fullWidth
                                name="UploadMode"
                                label="Image Upload Mode"
                                menuItems={uploadModeOpts}
                                value={values.UploadMode}
                                onChange={(e) => setFieldValue('UploadMode', e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <div className="flex w-full">
                        <List className="flex-1">
                            <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={titleStyles}>
                                    Living Threshold
                                </Typography>

                                <span className="flex flex-[0.5] items-center justify-start">
                                    <NumberInput min={0} max={0.99} name="LivenessThreshold" step={0.01} />
                                </span>
                            </ListItem>

                            <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={titleStyles}>
                                    Humanoid Coding Quality
                                </Typography>

                                <span className="flex flex-[0.5] items-center justify-start">
                                    <NumberInput min={0} max={90} name="BodyQuality" />
                                </span>
                            </ListItem>

                            <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={titleStyles}>
                                    Humanoid Expansion Factor
                                </Typography>

                                <span className="flex flex-[0.5] items-center justify-start">
                                    <NumberInput min={0} max={10} name="BodyAreaEx" />
                                </span>
                            </ListItem>

                            <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={titleStyles}>
                                    Face Sensitivity
                                </Typography>

                                <span className="flex flex-[0.5] items-center justify-start">
                                    <NumberInput min={1} max={10} name="FaceQuality" />
                                </span>
                            </ListItem>
                        </List>

                        <List className="flex-1">
                            <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={titleStyles}>
                                    Scene Quality
                                </Typography>

                                <span className="flex flex-[0.5] items-center justify-start">
                                    <NumberInput min={0} max={90} name="PicQuality" />
                                </span>
                            </ListItem>

                            <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={titleStyles}>
                                    Minimum Pixel Of Face
                                </Typography>

                                <span className="flex flex-[0.5] items-center justify-start">
                                    <NumberInput min={30} max={300} name="FaceMiniPixel" />
                                </span>
                            </ListItem>

                            <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={titleStyles}>
                                    Max Pixel Of Face
                                </Typography>

                                <span className="flex flex-[0.5] items-center justify-start">
                                    <NumberInput min={300} max={700} name="FaceMaxPixel" />
                                </span>
                            </ListItem>

                            <ListItem sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={titleStyles}>
                                    Face Property Detection
                                </Typography>

                                <span className="flex flex-[0.5] items-center justify-start">
                                    <ToggleSwitch
                                        name="MultiFace"
                                        isChecked={values.MultiFace}
                                        onClick={() => setFieldValue('MultiFace', !values.MultiFace)}
                                    />
                                </span>
                            </ListItem>
                        </List>
                    </div>

                    <div className="flex items-center justify-end p-4">
                        <PrimaryBtn title="Set" type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    ) : (
        <div className="flex items-center justify-center w-full h-full">
            <CircularProgress />
        </div>
    )
}
