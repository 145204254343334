import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { Search } from '@mui/icons-material'
import { FaqIcon } from '../../../components/svgs/Svgs'
import TenantAdminsTableItem from './TenantAdminsTableItem'
import Table from '../../../shared/ui/Table'
import { useLoad } from '../../../shared/hooks/requests'
import { TENANT_ADMINS_LIST } from '../../../shared/utils/urls'
import Create from '../features/Create'

const cols = [
    { id: 1, title: 'Photo' },
    { id: 2, title: 'First name' },
    { id: 3, title: 'Last name' },
    { id: 5, title: 'Status' },
    { id: 6, title: 'Role' },
    { id: 7, title: 'Phone number' },
    { id: 8, title: 'Action', width: '10%', align: 'right' },
]

export default function TenantAdminsTable() {
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(10)
    const { id } = useParams()
    const loadAdmins = useLoad({
        url: TENANT_ADMINS_LIST.replace('{id}', id),
        params: { size, page },
    }, [id, page, size])
    const data = loadAdmins.response ? loadAdmins.response.items : []
    const meta = loadAdmins.response ? loadAdmins.response : {}

    return (
        <div className="bg-white">
            <div className="flex items-center justify-between h-[60px] px-4">
                <h3 className="flex-cent gap-1_5 text-m font-600">Tenant Admins <FaqIcon /></h3>

                <div className="flex-cent gap-2">
                    <Create setData={loadAdmins.setResponse} />

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            <Table
                page={page - 1}
                loading={loadAdmins.loading}
                totalCount={meta.total}
                rowsPerPage={size}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {
                    setSize(parseInt(e.target.value, 10))
                    setPage(1)
                }}
                cols={cols}
                rows={data}
                renderItem={(item) => (
                    <TenantAdminsTableItem
                        item={item}
                        key={item.id}
                        setData={loadAdmins.setResponse}
                    />
                )}
            />
        </div>
    )
}
