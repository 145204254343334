import axios from 'axios'
import { useMessage } from '../../../shared/hooks/message'
import { domain } from '../../../shared/utils/request'
import { auth } from '../../../shared/utils/auth'

const allowedFormats = {
    pipeline: ['pkl', 'joblib'],
    config: ['md'],
}

export function useMlModelFileUpload() {
    const showMessage = useMessage()

    async function handleUpload(file, setValue, name, url, modelName, v) {
        const formData = new FormData()
        formData.append('file', file)

        try {
            const res = await axios.post(domain + url, formData, {
                ...auth(),
                params: { model_name: modelName, version: v.toString() },
            })
            if (res.status === 200) {
                setValue(name, res.data.file_url)
            }
        } catch (error) {
            console.error('File upload failed', error)
        }
    }

    function UploadFile(e, type, setValue, name, url, modelName, v) {
        const file = e.target.files[0]

        if (file) {
            const fileExtension = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
            if (!allowedFormats[type].includes(fileExtension)) {
                showMessage('File type is not supported', 'error-msg')
                return
            }

            setValue(name, file.name)
            handleUpload(file, setValue, name, url, modelName.label, v)
        }
    }

    return {
        UploadFile,
    }
}
