import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { CircularProgress } from '@mui/material'
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroller'
import SelectInput from '../../../shared/ui/SelectInput'
import FormTextField from '../../../shared/ui/FormTextField'
import { useGetRequest } from '../../../shared/hooks/requests'
import { ML_MODELS } from '../../../shared/utils/urls'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import { separateCamCase } from '../../../shared/utils/string'

const formInitialValues = {
    mode: '',
    description: '',
    categoricalFeatures: [],
    numericFeatures: [],
    binaryFeatures: [],
    timeSeriesFeatures: [],
    textFeatures: [],
}

function DynamicFields({ feature, values, handleChange }) {
    const [visibleItems, setVisibleItems] = useState(3)

    const loadMoreItems = () => {
        if (visibleItems < values[feature].length) {
            setVisibleItems((prev) => Math.min(prev + 3, values[feature].length))
        }
    }

    return (
        <CustomAccordion
            key={feature}
            summary={`${feature.at(0).toLocaleUpperCase()}${separateCamCase(feature).slice(1, feature.length)}`}
            contClassName="border-[1px] border-sepia-100"
            detailClassName="border-[1px] border-sepia-200 mx-2 mb-3
            rounded-[2px] max-h-[20rem] overflow-y-scroll no-scrollbar"
            expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
            overrideStyles={{
                ':before': {
                    display: 'none',
                },
                boxShadow: 'none',
                borderRadius: '3px',
            }}
        >
            <InfiniteScroll
                useWindow={false}
                pageStart={0}
                threshold={1}
                loadMore={loadMoreItems}
                loader={(
                    <div className="flex justify-center py-4">
                        <CircularProgress />
                    </div>
                )}
                hasMore={visibleItems < values[feature].length}
            >
                <div className="flex flex-col gap-4">
                    {values[feature].slice(0, visibleItems).map((item, index) => Object.keys(item).map((key) => {
                        if (key === 'accepted_values') {
                            const options = item[key].split(';').map((value) => ({
                                value,
                                label: value,
                            }))
                            return (
                                <SelectInput
                                    required
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={`${item.name}-${key}-${index}`}
                                    name={item.name}
                                    label={separateCamCase(item.name)}
                                    menuItems={options}
                                    onChange={handleChange}
                                />
                            )
                        }
                        if (key === 'name') {
                            return (
                                <FormTextField
                                    required
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={`${item.name}-${key}-${index}`}
                                    type={feature === 'numericFeatures' ? 'number' : 'text'}
                                    label={separateCamCase(item.name)}
                                    name={item.name}
                                    handleChange={handleChange}
                                />
                            )
                        }
                        return null
                    }))}
                </div>
            </InfiniteScroll>
        </CustomAccordion>
    )
}

export default function TryModelForm({
    version,
    mlName,
    loading,
    onSubmit = () => {},
    onClose = () => {},
}) {
    const [initialValues, setInitialValues] = useState(formInitialValues)
    const getByVersion = useGetRequest()
    const data = getByVersion.response ? getByVersion.response : {}

    useEffect(() => {
        if (data) {
            setInitialValues((prevValues) => ({
                ...prevValues,
                ...Object.keys(formInitialValues).reduce((acc, key) => {
                    if (data[key] !== undefined) {
                        acc[key] = data[key]
                    }
                    return acc
                }, {}),
            }))
        }
    }, [getByVersion.response])

    const versions = Array.from(
        { length: Math.max(version) },
        (_, index) => ({ value: index + 1, label: `V${index + 1}` }),
    )

    const features = [
        'categoricalFeatures',
        'numericFeatures',
        'binaryFeatures',
        'timeSeriesFeatures',
        'textFeatures',
    ]

    return (
        <Formik enableReinitialize initialValues={{ ...initialValues }} onSubmit={onSubmit}>
            {({ handleChange, setFieldValue, values }) => (
                <Form className="w-full min-h-60 flex flex-col justify-between gap-10">
                    <div className="w-full flex flex-col gap-4">
                        <SelectInput
                            name="mode"
                            label="Mode"
                            menuItems={versions}
                            onChange={(e) => {
                                getByVersion.request({
                                    url: `${ML_MODELS + mlName}/${e.target.value}`,
                                })
                                setFieldValue('mode', e.target.value)
                            }}
                        />

                        <FormTextField
                            multiline
                            fullWidth
                            label="Description"
                            name="description"
                            handleChange={handleChange}
                        />

                        {!getByVersion.loading ? (
                            features.map(
                                (f) => (data[f] && data[f].length ? (
                                    <DynamicFields
                                        key={f}
                                        feature={f}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                ) : null),
                            )
                        ) : (
                            <div className="w-full h-full flex items-center justify-center">
                                <CircularProgress />
                            </div>
                        )}
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn title="Cancel" onClick={onClose} type="button" />

                        <PrimaryBtn loading={loading} title="Predict" type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
