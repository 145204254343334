/* eslint-disable camelcase */
import React from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { COLORS } from '../../../shared/utils/colors'
import { ChevronNextSizeM } from '../../../components/svgs/Svgs'
import Card from './Card'
import { useQueryParams } from '../../../shared/hooks/queryString'
import { useAddQueryParams } from '../../../shared/hooks/addQueryParams'
import { TENANT_ENTITIES_CHILDREN } from '../../../shared/utils/urls'
import { useInfiniteScroll } from '../../../shared/hooks/requests'
import Branches from './Branches'
import Create from '../features/Create'

export default function Sections({ headQuarters }) {
    const addQueryParams = useAddQueryParams()
    const { p_name, sec_id, id } = useQueryParams()
    const getSections = useInfiniteScroll({ url: TENANT_ENTITIES_CHILDREN.replace('{id}', id) }, Boolean(id), [id])
    const data = getSections.response ? getSections.response.items : []
    const meta = getSections.response ? getSections.response : {}

    const options = headQuarters ? headQuarters.map((entity) => (
        { value: entity.id, label: entity.name })) : []

    return (
        <div className="flex flex-col gap-10">
            <div className="flex-col gap-7_5">
                <div className="flex items-center justify-between">
                    <Typography sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                        Sections ( {data.length} )

                        <ChevronNextSizeM color={COLORS.disabled} />

                        {data.length ? p_name : null}
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <Create parentOptions={options} hierarchyLevel={2} setData={getSections.setResponse} />

                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>

                {data.length ? (
                    <Grid container spacing={2}>
                        {data.map((item) => (
                            <Card
                                item={item}
                                key={item.id}
                                loading={getSections.loading}
                                setData={getSections.setResponse}
                                parentOptions={options}
                                isActive={item.id === +sec_id}
                                onClick={() => addQueryParams({
                                    sec_id: item.id,
                                    sec_name: item.name,
                                })} />
                        ))}
                    </Grid>
                ) : null}

                {getSections.hasMore && meta.total > meta.size && <div ref={getSections.ref} />}
            </div>

            <Branches sections={data} />
        </div>
    )
}
