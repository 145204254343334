import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import FormTextField from '../../../shared/ui/FormTextField'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { useGetRequest } from '../../../shared/hooks/requests'
import { SCAMERA_UPDATE_PASS } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import CancelBtn from '../../../shared/ui/CancelBtn'

const FORM_INITIAL_STATE = {
    username: '',
    password: '',
    newPassword: '',
    confirmPass: '',
}

const getValidationSchema = (isChecked) => Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string()
        .required('Password is required')
        .min(5, 'Password must be at least 5 characters'),
    ...(isChecked && {
        newPassword: Yup.string()
            .required('New Password is required')
            .min(5, 'New Password must be at least 5 characters'),
        confirmPass: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
    }),
})

export default function UpdatePassword({ id, oldInfo, setData }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [isChecked, setIsChecked] = useState(false)
    const [show, setShow] = useState({})
    const changePass = useGetRequest()

    const handleCheck = (values, setFieldError) => {
        const { username, password } = values
        if (oldInfo.username !== username) {
            setIsChecked(false)
            setFieldError('username', 'Username is incorrect')
        } else if (oldInfo.password !== password) {
            setIsChecked(false)
            setFieldError('password', 'Password is incorrect')
        } else {
            setIsChecked(true)
        }
    }

    const handleShowPassword = (field) => {
        setShow((prev) => ({
            ...prev,
            [field]: !prev[field],
        }))
    }

    const updatePassword = async ({ newPassword }) => {
        if (!newPassword || changePass.loading) return

        const { response, success, error } = await changePass.request({
            url: SCAMERA_UPDATE_PASS.replace('{id}', id),
            params: { newPassword },
        })

        if (success) {
            showMessage('Password successfully changed', 'success-msg')
            setData((prev) => ({
                ...prev,
                items: prev.items.map((item) => (item.id === id ? response : item)),
            }))
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Formik
            initialValues={FORM_INITIAL_STATE}
            validationSchema={getValidationSchema(isChecked)}
            onSubmit={updatePassword}
        >
            {({ handleChange, values, setFieldError }) => (
                <Form className="w-full flex flex-col items-end gap-3.5 p-5">
                    {!isChecked ? (
                        <>
                            <FormTextField
                                required
                                fullWidth
                                name="username"
                                label="Login"
                                onChange={handleChange}
                            />

                            <FormTextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={show.password ? 'text' : 'password'}
                                onChange={handleChange}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ margin: 0, padding: '0 10px 5px 0' }}>
                                            <IconButton
                                                onClick={() => handleShowPassword('password')}
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                {show.password ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </>
                    ) : null}

                    {isChecked && (
                        <>
                            <FormTextField
                                required
                                fullWidth
                                name="newPassword"
                                label="New Password"
                                type={show.newPassword ? 'text' : 'password'}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ margin: 0, padding: '0 10px 5px 0' }} position="end">
                                            <IconButton
                                                onClick={() => handleShowPassword('newPassword')}
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                {show.newPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <FormTextField
                                required
                                fullWidth
                                name="confirmPass"
                                label="Confirm the new password"
                                type={show.confirmPass ? 'text' : 'password'}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ margin: 0, padding: '0 10px 5px 0' }} position="end">
                                            <IconButton
                                                onClick={() => handleShowPassword('confirmPass')}
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                {show.confirmPass ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </>
                    )}

                    <div className="flex items-center justify-end gap-3.5">
                        {isChecked ? (
                            <CancelBtn
                                title="Cancel"
                                onClick={() => setIsChecked(false)}
                                styles={{ width: 'fit-content' }}
                            />
                        ) : null}

                        <PrimaryBtn
                            title="Change"
                            onClick={() => (isChecked ? {} : handleCheck(values, setFieldError))}
                            type={isChecked ? 'submit' : 'button'}
                            styles={{ width: 'fit-content' }}
                            loading={changePass.loading}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
