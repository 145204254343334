import * as React from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { Box } from '@mui/material'

export default function HorizontalChart({ items, sm }) {
    const transformedData = items.map((item) => ({
        age: item.ageGroup,
        men: item.counts.male,
        women: item.counts.female,
        undefined: item.counts.undefined,
    }))

    return (
        <Box sx={{ width: '100%' }}>
            <BarChart
                dataset={transformedData}
                yAxis={[{ scaleType: 'band', dataKey: 'age' }]}
                series={[
                    { dataKey: 'men' },
                    { dataKey: 'women' },
                    { dataKey: 'undefined' },
                ]}
                layout="horizontal"
                grid={{ vertical: true, horizontal: true }}
                height={sm ? 200 : 260}
            />
        </Box>
    )
}
