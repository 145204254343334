import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, List, ListItem, Typography } from '@mui/material'
import axios from 'axios'
import Paper from '@mui/material/Paper'
import { isEmpty } from 'lodash'
import { Close } from '@mui/icons-material'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import TryModelForm from '../components/TryModelForm'
import { ML_MODEL_PREDICT } from '../../../shared/utils/urls'
import { auth } from '../../../shared/utils/auth'
import { useMessage } from '../../../shared/hooks/message'

export default function Predict({ version, mlName, pipeline }) {
    const [open, setOpen] = useState(false)
    const [result, setResult] = useState({})
    const showMessage = useMessage()
    const [predicting, setPredicting] = useState(false)
    const modelDomain = process.env.REACT_APP_ML_MODEL_URL

    const onClose = () => setOpen(false)

    function onPredict(values) {
        if (predicting) return

        const { binaryFeatures,
            categoricalFeatures,
            description,
            mode,
            numericFeatures,
            textFeatures,
            timeSeriesFeatures,
            ...rest } = values
        const ext = pipeline.split('.')[pipeline.split('.').length - 1]
        setPredicting(true)
        axios.post(`${modelDomain + ML_MODEL_PREDICT + mlName}/${version}`, rest, {
            ...auth(),
            params: { model_extension: ext },
        }).then(({ data }) => {
            setResult(data)
            setPredicting(false)
        }).catch(({ response }) => {
            showMessage(response.data.detail, 'error-msg')
            setPredicting(false)
        }).finally(() => setPredicting(false))
    }

    return (
        <div className="flex items-center justify-end">
            <PrimaryBtn onClick={() => setOpen(true)} title="Try out" />

            <Dialog fullWidth open={open} onClose={onClose}>
                <div className="flex items-center justify-between pl-3 py-1 pr-1
                bg-main-100 text-white text-lg font-medium">
                    <Typography>
                        {!isEmpty(result) ? `Prediction results for ${mlName}` : 'Try Ml model'}
                    </Typography>

                    <IconButton onClick={onClose}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent>
                    {!isEmpty(result) ? (
                        <div className="flex flex-col items-end gap-10">
                            <Paper sx={{ width: '100%' }}>
                                <List>
                                    {Object.keys(result).map((k) => (
                                        <ListItem sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Typography>{k}: </Typography>

                                            <Typography>
                                                {JSON.stringify(result[k])}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>

                            <PrimaryBtn onClick={() => {
                                setResult('')
                                setOpen(false)
                            }} title="Close" />
                        </div>
                    ) : (
                        <TryModelForm
                            loading={predicting}
                            /* eslint-disable-next-line react/jsx-no-bind */
                            onSubmit={onPredict}
                            version={version}
                            mlName={mlName}
                            onClose={onClose} />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}
