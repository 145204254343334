import React, { useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { COLORS } from '../../../shared/utils/colors'
import { useLoad } from '../../../shared/hooks/requests'
import { MODULES } from '../../../shared/utils/urls'
import ServiceModuleTableItem from './ServiceModuleTableItem'
import Table from '../../../shared/ui/Table'
import Create from '../features/Create'

const cols = [
    { id: 1, title: 'Created time', width: '15%' },
    { id: 2, title: 'Name', width: '25%' },
    { id: 3, title: 'Description' },
    { id: 4, title: 'Action', width: '10%', align: 'right' },
]

export default function ServiceModulesTable() {
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const loadModules = useLoad({ url: MODULES, params: { size: pageSize, page: page || 1 } }, [page, pageSize])
    const data = loadModules.response ? loadModules.response.items : []
    const meta = loadModules.response ? loadModules.response : {}

    return (
        <div className="bg-white">
            <div className="flex items-center justify-between h-[60px] px-4">
                <Typography className="flex-cent gap-1.5 text-m font-600">
                    Service Modules <HelpRoundedIcon htmlColor={COLORS.gray} />
                </Typography>

                <div className="flex-cent gap-2">
                    <Create setData={loadModules.setResponse} />

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            <Table
                page={page}
                loading={loadModules.loading}
                totalCount={meta.total}
                rowsPerPage={pageSize}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10))
                    setPage(0)
                }}
                cols={cols}
                rows={data}
                renderItem={(item) => (
                    <ServiceModuleTableItem
                        key={item.id}
                        item={item}
                        setData={loadModules.setResponse} />
                )}
            />
        </div>
    )
}
