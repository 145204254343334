import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import { usePostRequest } from '../../../shared/hooks/requests'
import { TENANTS } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import TenantForm from '../components/TenantForm'

export default function Create({ setData }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createTenant = usePostRequest({ url: `${TENANTS}/` })

    const create = async (values) => {
        if (createTenant.loading || !values) return

        const { response, success, error } = await createTenant.request({
            data: { ...values },
        })
        if (success) {
            setData((prev) => ({
                ...prev,
                items: [...(prev.items || []), response],
            }))
            showMessage('Successfully created', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>
                        Create Tenant
                    </Typography>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <TenantForm
                        isEdit
                        loading={createTenant.loading}
                        onSubmit={create}
                        onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
