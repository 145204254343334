import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import React, { memo } from 'react'
import GiftCard from './giftCard'
import { gifData } from '../../../shared/utils/landingData'

function LandingGifCards() {
    return (
        <div className={cn(css(s.wrapper), 'marginAuto')}>
            <div className={css(s.innovations)}>
                <h3 className={css(s.innovationTitle)}>Innovative Solutions: Your Path to Success</h3>
                <div className={cn(css(s.innovation), 'marginAuto')}>
                    {gifData.map((item) => (
                        <div className={css(s.card)} key={item.id}>
                            <GiftCard {...item} />
                        </div>
                    ))}
                </div>
            </div>
            <div className={css(s.line)} />
        </div>
    )
}
export default memo(LandingGifCards)

const s = StyleSheet.create({
    wrapper: {
        paddingTop: '3rem',
        maxWidth: '120rem',
        paddingBottom: '0.625rem',
    },
    innovations: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
        },
        '@media (max-width: 62rem)': {
            maxWidth: '80%',
            height: 'auto',
        },
    },
    innovation: {
        minWidth: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        marginLeft: 'auto',
        marginRight: 'auto',
        gap: '1rem',
        height: '100%',
        marginBottom: '50px',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '@media (min-width: 62rem)': {
            maxWidth: '80%',
            gridTemplateColumns: 'repeat(3, 1fr)',
            height: 'auto',
        },
    },

    card: {
        width: '100%',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    innovationTitle: {
        fontSize: '1.188rem',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '2.688rem',
        color: 'white',
        '@media (min-width: 48rem)': {
            fontSize: '1.875rem',
            maxWidth: '60%',
        },
        '@media (min-width: 62rem)': {
            fontSize: '2.5rem',
            maxWidth: '60%',
        },
    },
    innovationText: {
        fontSize: '0.875rem',
        lineHeight: '1.563rem',
        marginBottom: '6rem',
        '@media (min-width: 48rem)': {
            fontSize: '1.125rem',
            maxWidth: '100%',
        },
    },
    line: {
        width: '90%',
        margin: '0 auto',
        height: '1px',
        background: '#F3F5F8',
        '@media (min-width: 48rem)': {
            width: '80%',
        },
    },
})
