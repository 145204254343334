import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import ReactPlayer from 'react-player'
import { useGetRequest } from '../../../shared/hooks/requests'
import { useMessage } from '../../../shared/hooks/message'
import { JETSON_DEVICE_STREAM } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { isValidURL } from '../../../shared/utils/string'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import Button from '../../../components/common/Button'
import { COLORS } from '../../../shared/utils/colors'
import { WarningIcon } from '../../../components/svgs/Svgs'

export default function Stream() {
    const { streamAnalyticsFilter } = useFilter()
    const [streamUrl, setStreamUrl] = useState('')
    const [videoError, setVideoError] = useState('')
    const [loading, setLoading] = useState(true)
    const [canRenderPlayer, setCanRenderPlayer] = useState(false) // контролируем рендеринг плеера

    const { handleErrorMsg } = useHandleErrors()
    const showMessage = useMessage()
    // eslint-disable-next-line max-len
    const enableStream = useGetRequest({ url: JETSON_DEVICE_STREAM.replace('{id}', streamAnalyticsFilter.jetsonDeviceId) })

    useEffect(() => {
        enableStream.request()
        setVideoError('')
    }, [streamAnalyticsFilter.jetsonDeviceId])

    useEffect(() => {
        if (enableStream.response) {
            // Устанавливаем URL стрима
            setStreamUrl(enableStream.response)
            console.log(enableStream.response)

            // Запускаем таймер на 3 секунды перед рендерингом ReactPlayer
            setTimeout(() => {
                setCanRenderPlayer(true) // Разрешаем рендеринг плеера после 3 секунд
                setLoading(false)
            }, 3000)
        }
    }, [enableStream.response])

    const handleVideoError = () => {
        setVideoError('Stream cannot be shown due to internet bandwidth restriction')
        setLoading(false)
    }

    const handleReconnect = async () => {
        if (enableStream.loading) return
        const { success, response, error } = await enableStream.request({ params: { enable: true } })
        if (success) {
            setVideoError('')
            showMessage(response.message, 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Box sx={{ backgroundColor: '#e2e2e2', height: '100%' }}>
            <div className="flex-1 flex-cent justify-center rounded-m overflow-hidden"
                style={{ position: 'relative' }}>
                {/* Проверяем, можно ли рендерить плеер, и нет ли ошибок */}
                {isValidURL(streamUrl) && canRenderPlayer && (
                    <ReactPlayer
                        url={streamUrl}
                        playing
                        autoplay
                        muted
                        loop
                        onError={handleVideoError}
                        width="100%"
                        height="100%"
                    />
                )}

                {loading && (
                    <span className="loader" style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'black',
                    }}>
                        <LoadingSpinner strokeWidth={5} width={48} />
                    </span>
                )}

                {videoError && (
                    <div className={cn(css(s.bgContainer), 'flex-cent')}>
                        <div className={css(s.bg)} />
                        <div className={cn(css(s.errorMessage), 'flex-col items-center justify-center gap-2_5')}>
                            <div className="flex-col items-center gap-1_5">
                                <WarningIcon />

                                <h3 className="text-m font-500">Warning</h3>
                            </div>

                            <p className="text-s font-400">{videoError}</p>

                            <Button className={css(s.reconBtn)} onClick={handleReconnect}>
                                Reconnect {enableStream.loading
                                    ? <LoadingSpinner width={14} strokeWidth={4} />
                                    : null}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Box>
    )
}

const s = StyleSheet.create({
    bgContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'blur(5px)',
        zIndex: 1,
    },
    errorMessage: {
        borderRadius: 10,
        padding: '20px 24px',
        backgroundColor: 'rgba(255, 255, 255, 0.30)',
        backdropFilter: 'blur(10px)',
        border: '1px solid #C3C3C3',
        width: '20rem',
        position: 'relative',
        zIndex: 2,
        color: COLORS.white,
        textAlign: 'center',
        marginTop: '10px',
    },
    reconBtn: {
        padding: '5px 10px',
        borderRadius: 4,
        background: COLORS.white,
        marginTop: 22,
        fontSize: 10,
        fontWeight: '400',
        color: COLORS.black,
    },
})
