import React from 'react'
import { BarChart } from '@mui/x-charts/BarChart'

const serviceTime = [8, 8, 5, 4, 6, 9, 10]
const workPlace = [5, 5, 2, 3, 3, 1, 1]
const daysOfWeek = ['Mon 01', 'Tue 02', 'Wed 03', 'Thu 04', 'Fri 05', 'Sat 06', 'Sun 07']

export default function WeeklyChart() {
    return (
        <BarChart
            series={[
                { data: serviceTime, label: 'Service time', id: 'serviceTimeId', stack: 'total', color: '#F5C7B8' },
                { data: workPlace, label: 'At workplace', id: 'workPlaceId', stack: 'total', color: '#E05A33' },
            ]}
            xAxis={[{ data: daysOfWeek, scaleType: 'band', disableTicks: true, disableLine: true, labelFontSize: 40 }]}
            yAxis={[{ disableTicks: true, disableLine: true, labelFontSize: 40 }]}
            componentsProps={{
                bar: {
                    label: {
                        position: 'inside',
                        display: true,
                        color: '#000',
                    },
                },
            }}
            slotProps={{
                legend: {
                    direction: 'column',
                    position: { vertical: 'top', horizontal: 'right' },
                    padding: 0,
                    itemMarkWidth: 13,
                    itemMarkHeight: 8,
                    labelStyle: {
                        fontSize: 10,
                    },
                },
            }}
        />
    )
}
