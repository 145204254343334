import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import { usePostRequest } from '../../../shared/hooks/requests'
import { ML_MODEL_PROFILES } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import MLModelProfileForm from '../components/MLModelProfileForm'

export default function Create({ $api }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createMlModelProfile = usePostRequest({ url: ML_MODEL_PROFILES })

    const create = async (values) => {
        if (createMlModelProfile.loading || !values) return

        const { success, error } = await createMlModelProfile.request({ data: {
            name: values.name,
            description: values.description,
            tags: values.tags.join(';'),
        } })

        if (success) {
            $api.request()
            showMessage('Successfully added', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Ml model profile</h3>

                    <XIconSizeM onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <MLModelProfileForm onSubmit={create} onClose={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}
