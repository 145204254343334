import { Chip, CircularProgress, Drawer, IconButton, TableCell } from '@mui/material'
import React, { useState } from 'react'
import { capitalizeFirstLetter } from '../../../shared/utils/string'
import { PlayIcon, ReloadIconSizeM, StopIcon, UpgradeIcon } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import { usePostRequest } from '../../../shared/hooks/requests'
import { RUN_DEEPSTREAM_APP, STOP_DEEPSTREAM_APP } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import DeepstreamDetails from './DeepstreamDetails'

const iconBtnStyles = {
    borderRadius: '8px',
    padding: '7px',
    backgroundColor: COLORS.mainBlueBg,
    ':hover': {
        backgroundColor: COLORS.mainBlue,
    },
}

const isRunning = (st) => st === 'running'

export default function DeepstreamAppsTableItem({ item, setData }) {
    const showMessage = useMessage()
    const [openDrawer, setOpenDrawer] = useState(false)
    const { handleErrorMsg } = useHandleErrors()
    const { jetsonFilter: { jetsonDeviceId } } = useFilter()
    const deepstreamToggle = usePostRequest({ url: isRunning(item.status) ? STOP_DEEPSTREAM_APP : RUN_DEEPSTREAM_APP })

    const handleRunDeepstream = async () => {
        if (deepstreamToggle.loading) return

        const { success, response, error } = await deepstreamToggle.request({
            data: {
                applicationName: item.name,
                applicationVersion: item.version,
                jetsonDeviceId,
            },
        })

        if (success) {
            if (response.status === 'success') {
                setData((prev) => prev.map((v) => (v.name === item.name ? {
                    ...v,
                    status: isRunning(v.status) ? 'available' : 'running',
                } : v)))
            }
            showMessage(response.description, response.status === 'success' ? 'success-msg' : 'error-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const comingSoon = () => showMessage('Feature not available for now', 'info-msg')

    const open = () => setOpenDrawer(true)

    const close = () => setOpenDrawer(false)

    return (
        <>
            <TableCell onClick={open}>{item.name}</TableCell>

            <TableCell onClick={open}>{item.path}</TableCell>

            <TableCell onClick={open}>{item.version}</TableCell>

            <TableCell>
                <Chip
                    variant="outlined"
                    sx={{
                        width: '95px',
                        backgroundColor: item.status === 'available' ? '#F4F1B5' : '#07D9424D',
                        border: 'none',
                        color: item.status === 'available' ? '#E6A600' : '#0B9E34',
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '4px 16px',
                        '& .MuiChip-label': { padding: 0 },
                    }}
                    label={capitalizeFirstLetter(item.status)} />
            </TableCell>

            <TableCell>
                <div className="flex items-center justify-end gap-3">
                    <IconButton onClick={handleRunDeepstream} sx={iconBtnStyles}>
                        {deepstreamToggle.loading
                            ? (
                                <CircularProgress
                                    size="18px"
                                    sx={{ color: 'white' }} />
                            ) : isRunning(item.status) ? <StopIcon /> : <PlayIcon />}
                    </IconButton>

                    <IconButton onClick={comingSoon} sx={iconBtnStyles}>
                        <ReloadIconSizeM />
                    </IconButton>

                    <IconButton onClick={comingSoon} sx={iconBtnStyles}>
                        <UpgradeIcon />
                    </IconButton>
                </div>
            </TableCell>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
                anchor="right"
                open={openDrawer}
                onClose={close}>
                <DeepstreamDetails item={item} close={close} />
            </Drawer>
        </>
    )
}
