import React, { useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { CopyToClipboardIcon } from '../../components/svgs/Svgs'
import { useMessage } from '../hooks/message'
import PrimaryBtn from './PrimaryBtn'
import { copyToClipboard } from '../utils/copyToClipboard'

export default function ActivationLinkModal({ url, onClose }) {
    const [, setCopied] = useState(false)
    const showMessage = useMessage()

    return (
        <div className="flex flex-col  gap-7 p-4 bg-white">
            <Typography>In order to activate user use the following activation link:</Typography>

            <Typography paragraph sx={{ margin: 0 }}
                className="flex-1 flex items-center gap-2 bg-[#F3F5F8] p-3 text-[14px] font-normal">
                {url}

                <IconButton onClick={() => copyToClipboard(url, showMessage, setCopied)}>
                    <CopyToClipboardIcon className="pointer" />
                </IconButton>
            </Typography>

            <div className="flex items-end justify-end">
                <PrimaryBtn onClick={onClose} title="Ok" type="button" />
            </div>
        </div>
    )
}
