import { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import WebSocketService from '../utils/socket'

export default function useWebSocket(url) {
    const [allMessages, setAllMessages] = useState([])
    const [filteredData, setFilteredData] = useState([])

    const processMessages = useCallback(
        (incomingMessages) => incomingMessages.sort((a, b) => moment(b.created_at).diff(moment(a.created_at))),
        [],
    )

    const handleMessage = useCallback((newMessage) => {
        setAllMessages((prevMessages) => [newMessage, ...prevMessages])
    }, [])

    useEffect(() => {
        const wsService = new WebSocketService(url)
        wsService.addListener(handleMessage)

        return () => wsService.removeListener(handleMessage)
    }, [url, handleMessage])

    useEffect(() => {
        setFilteredData(processMessages(allMessages))
    }, [allMessages, processMessages])

    return { filteredData }
}
