import React, { useState } from 'react'
import { Autocomplete, Button, Chip, CircularProgress, TextField } from '@mui/material'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroller'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import FormTextField from '../../../shared/ui/FormTextField'
import CustomAccordion from '../../../shared/ui/CustomAccordion'

export const customFieldStyles = {
    '& .MuiInputBase-input': {
        paddingBottom: '8px',
        fontSize: 16,
        fontWeight: 400,
        color: COLORS.black,
    },
    '& .MuiInputLabel-root': {
        fontSize: 16,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: 'rgba(0, 0, 0, 0.54)',
    },
    // eslint-disable-next-line max-len
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#7C7D7E',
        borderBottomWidth: '1px',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
        borderBottomWidth: '1px',
    },
}

export default function CategoricalFeatures({ chipStyles, addNew, setValue, handleChange, values }) {
    const [visibleItems, setVisibleItems] = useState(3)
    const userType = localStorage.getItem('userType')

    const loadMoreItems = () => {
        if (visibleItems < values.categoricalFeatures.length) {
            setVisibleItems((prev) => Math.min(prev + 3, values.categoricalFeatures.length))
        }
    }

    return (
        <CustomAccordion
            summary="Categorical features"
            contClassName="border-[1px] border-sepia-100"
            detailClassName={
                `border-[1px] border-sepia-200 mx-2 mb-3
            rounded-[2px] max-h-[30rem] overflow-y-scroll no-scrollbar`
            }
            expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
            overrideStyles={{
                ':before': {
                    display: 'none',
                },
                boxShadow: 'none',
                borderRadius: '3px',
            }}>
            <InfiniteScroll
                useWindow={false}
                pageStart={0}
                threshold={1}
                loadMore={loadMoreItems}
                loader={(
                    <div className="flex justify-center py-4">
                        <CircularProgress />
                    </div>
                )}
                hasMore={visibleItems < values.categoricalFeatures.length}>
                {values.categoricalFeatures.slice(0, visibleItems).map((item, ind) => {
                    const tagName = `categoricalFeatures[${ind}].acceptedValues`

                    return (
                    // eslint-disable-next-line react/no-array-index-key
                        <div key={`key-${ind}`} className="flex flex-col gap-2 pt-2">
                            {item.name && (
                                <Chip
                                    label={item.name}
                                    sx={chipStyles} />
                            )}

                            <div className="flex flex-col gap-2">
                                <FormTextField
                                    label="Name"
                                    disabled={userType !== 'system_admin'}
                                    handleChange={handleChange}
                                    name={`categoricalFeatures[${ind}].name`}
                                />

                                <FormTextField
                                    label="Description"
                                    disabled={userType !== 'system_admin'}
                                    handleChange={handleChange}
                                    name={`categoricalFeatures[${ind}].description`}
                                />

                                <Autocomplete
                                    freeSolo
                                    multiple
                                    disabled={userType !== 'system_admin'}
                                    options={['Bank', 'Marketing']}
                                    onChange={(_, newValue) => setValue(tagName, newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            label="Accepted values"
                                            name={tagName}
                                            disabled={userType !== 'system_admin'}
                                            variant="standard"
                                            sx={customFieldStyles}
                                            {...params} />
                                    )} />
                            </div>
                        </div>
                    )
                })}
            </InfiniteScroll>

            {userType === 'system_admin' && (
                <Button
                    sx={{ marginTop: 2, display: 'flex', alignItems: 'center', gap: 1 }}
                    onClick={() => addNew('categoricalFeatures', setValue, values)}>
                    <ControlPointIcon />

                    Add Categorical features
                </Button>
            )}
        </CustomAccordion>
    )
}
