import React, { useEffect, useRef } from 'react'
import { Form, Formik } from 'formik'
import { number, object, string } from 'yup'
import PersonIcon from '@mui/icons-material/Person'
import { COLORS } from '../../../shared/utils/colors'
import { COUNTRIES_LIST, DISTRICTS_LIST, FILE_UPLOAD, REGIONS_LIST, TENANT_ENTITIES } from '../../../shared/utils/urls'
import { isImageReal } from '../../../shared/utils/string'
import { useGetRequest, useInfiniteScroll } from '../../../shared/hooks/requests'
import useImageUpload from '../../../shared/hooks/imageUploadHandler'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import FormTextField from '../../../shared/ui/FormTextField'
import SelectInput from '../../../shared/ui/SelectInput'

const FORM_INITIAL_VALUES = {
    name: '',
    description: '',
    photo: '',
    countryId: '',
    districtId: '',
    regionId: '',
    parentId: '',
    fileName: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    description: string().optional(),
    photo: string().nullable(),
    countryId: string().required('Country is required'),
    districtId: number().required('District is required'),
    regionId: number().required('Region is required'),
    parentId: number().nullable(),
    fileName: string().optional().nullable(),
})

// const levelOptions = [
//     { value: 1, label: '1' },
//     { value: 2, label: '2' },
//     { value: 3, label: '3' },
// ]

export default function EntityForm({
    onSubmit = () => {},
    onClose = () => {},
    loading,
    hierarchyLevel,
    isEdit = false,
    parentOptions = [],
    initialValues,
}) {
    const inputRef = useRef(null)
    const { onFileUpload } = useImageUpload()
    const getCountries = useGetRequest({ url: COUNTRIES_LIST })
    const getRegions = useGetRequest({ url: REGIONS_LIST })
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST })
    const countries = getCountries.response ? getCountries.response.map((c) => ({ value: c.id, label: c.name })) : []
    const regions = getRegions.response ? getRegions.response.map((c) => ({ value: c.id, label: c.name })) : []
    const districts = getDistricts.response ? getDistricts.response.map((c) => ({ value: c.id, label: c.name })) : []

    const loadParent = useInfiniteScroll({
        url: TENANT_ENTITIES,
        params: { hierarchyLevel: hierarchyLevel - 1 },
    }, !parentOptions.length && hierarchyLevel !== 1, [hierarchyLevel])

    const parents = parentOptions.length ? parentOptions : (loadParent.response ? loadParent.response.items
        .map((p) => ({ value: p.id, label: p.name })) : [])

    useEffect(() => {
        if (isEdit) getCountries.request()

        if (initialValues && initialValues.countryId) {
            getRegions.request({ params: { countryId: initialValues.countryId } })
        }
        if (initialValues && initialValues.regionId) {
            getDistricts.request({ params: { regionId: initialValues.regionId } })
        }
    }, [])

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_VALUES,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ setFieldValue, values, handleChange }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <div className="w-full flex items-center gap-4">
                            {isImageReal(values.photo) ? (
                                <img src={values.photo} alt="#OFFICE" className=" w-[60px] h-[60px] rounded" />
                            ) : (
                                <span className="bg-gray-100 w-[60px] h-[55px]
                                rounded-full flex items-center justify-center">
                                    <PersonIcon htmlColor={COLORS.white} fontSize="large" />
                                </span>
                            )}

                            <FormTextField
                                fullWidth
                                type="text"
                                label="Photo"
                                name="fileName"
                                onClick={() => inputRef.current.click()} />

                            <input
                                id="file"
                                type="file"
                                name="photo"
                                ref={inputRef}
                                readOnly="readonly"
                                className="not-visible"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(e) => onFileUpload(e, setFieldValue, 'photo', FILE_UPLOAD)} />
                        </div>

                        <FormTextField
                            required
                            label="Name"
                            name="name"
                            handleChange={handleChange} />

                        <FormTextField
                            multiline
                            label="Description"
                            name="description"
                            handleChange={handleChange} />

                        {parents && parents.length && hierarchyLevel !== 1 ? (
                            <SelectInput
                                required
                                name="parentId"
                                label="Select Head Quarter"
                                menuItems={parents}
                                onChange={(e) => setFieldValue('parentId', e.target.value)} />
                        ) : null}

                        {/* {!isEdit ? ( */}
                        {/*     <SelectInput */}
                        {/*         required */}
                        {/*         label="Select hiearchy level" */}
                        {/*         name="hierarchyLevel" */}
                        {/*         menuItems={levelOptions} */}
                        {/*         onChange={({ value }) => setFieldValue('hierarchyLevel', value)} */}
                        {/*     /> */}
                        {/* ) : null} */}

                        <SelectInput
                            loading={getCountries.loading}
                            label="Country"
                            name="countryId"
                            onOpen={() => getCountries.request()}
                            onChange={(e) => {
                                setFieldValue('countryId', e.target.value)
                                getRegions.request({ params: { countryId: e.target.value } })
                            }}
                            menuItems={countries}
                        />

                        <SelectInput
                            disabled={!values.countryId}
                            loading={getRegions.loading}
                            label="Region"
                            name="regionId"
                            menuItems={regions}
                            onChange={(e) => {
                                setFieldValue('regionId', e.target.value)
                                setFieldValue('districtId', null)
                                getDistricts.request({ params: { regionId: e.target.value } })
                            }}
                        />

                        <SelectInput
                            disabled={!values.regionId}
                            loading={getDistricts.loading}
                            label="District"
                            name="districtId"
                            onChange={(e) => setFieldValue('districtId', e.target.value)}
                            menuItems={districts}
                        />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
