import { Button, Typography } from '@mui/material'
import React from 'react'
import { useFormikContext } from 'formik'
import { calculateFileSize, handleDownloadFile } from './VpnSetupFileGenerate'

export default function ConfigFile() {
    const { values } = useFormikContext()
    return (
        <div className="w-full flex flex-col gap-5">
            <Typography fontSize="16px" fontWeight="500" fontFamily="Gilroy">
                Required OneSystem Jetson Manager setup
            </Typography>

            <Button
                sx={{ backgroundColor: '#F6F6F6', textTransform: 'none', padding: '14px 0' }}
                onClick={() => handleDownloadFile(values.setupFile)}>
                <Typography
                    fontSize="16px"
                    fontWeight="500"
                    fontFamily="Gilroy"
                    sx={{ textDecoration: 'underline' }}>
                    Download
                </Typography>

                <span className="text-md ml-2 font-medium text-[#78818A]">
                    {calculateFileSize(values.setupFile)} KB
                </span>
            </Button>
        </div>
    )
}
