import React from 'react'
import { isEqual } from 'lodash'
import PrimaryBtn from '../../../../shared/ui/PrimaryBtn'
import { USER_ROLE_CREATE } from '../../../../shared/utils/urls'
import { useMessage } from '../../../../shared/hooks/message'
import useTenantProfileStore from '../../../../shared/store/tenantProfileStore'
import useHandleErrors from '../../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../../shared/hooks/requests'

export default function UpdateRole({ values, roleIndex, initialValues }) {
    const showMessage = useMessage()
    const { setResponse, profileResponse } = useTenantProfileStore()
    const { handleErrorMsg } = useHandleErrors()
    const updateRole = usePutRequest()

    const onUpdateRole = async () => {
        if (!values.roles[roleIndex] || updateRole.loading) return

        const r = values.roles[roleIndex]

        if (!isEqual(initialValues, r)) {
            const newRole = {
                name: r.name,
                description: r.description,
                roleId: r.id,
                tenantProfileId: values.id || values.tenantProfileId,
                permissions: r.permissions,
                modules: values.modules,
            }

            const { response, success, error } = await updateRole.request({
                url: `${USER_ROLE_CREATE}/${r.id}`,
                data: newRole,
            })

            if (success) {
                setResponse({
                    ...profileResponse,
                    items: profileResponse.items.map((val) => (
                        val.id === response.tenantProfileId ? { ...val,
                            roles: val.roles.map((role) => (role.id === response.id
                                ? response : role)) } : val
                    )),
                })
                showMessage('Role updated', 'success-msg')
            } else if (error) {
                handleErrorMsg(error)
            }
        } else {
            showMessage('No changes defined', 'success-msg')
        }
    }

    return (
        <PrimaryBtn
            loading={updateRole.loading}
            title="Update"
            type="button"
            onClick={onUpdateRole} />
    )
}
