import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SnackbarProvider } from 'notistack'
import { ProtectedRoute } from '../components/ProtectedRoute'
import NotFound from '../errors/NotFound'
import GlobalContext from './contexts/GlobalContext'
import { routes } from './routes'
import FilterContextProvider from './contexts/FilterContextProvider'
import Layout from './layouts/Layout'

export default function App() {
    return (
        <BrowserRouter>
            <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} preventDuplicate maxSnack={2}>
                <GlobalContext>
                    <FilterContextProvider>
                        <Routes>
                            <Route element={<Layout />}>
                                {routes.filter((r) => !r.noLayer).map(
                                    ({
                                        path,
                                        component: Component,
                                        protectedBy,
                                        redirectPath,
                                    }) => (
                                        <Route
                                            key={path}
                                            path={path}
                                            element={(
                                                <ProtectedRoute
                                                    protectedBy={protectedBy}
                                                    redirectPath={redirectPath}
                                                >
                                                    <Component />
                                                </ProtectedRoute>
                                            )}
                                        />
                                    ),
                                )}
                            </Route>

                            {routes.filter((r) => r.noLayer).map(
                                ({
                                    path,
                                    component: Component,
                                    protectedBy,
                                    redirectPath,
                                }) => (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={(
                                            <ProtectedRoute
                                                protectedBy={protectedBy}
                                                redirectPath={redirectPath}
                                            >
                                                <Component />
                                            </ProtectedRoute>
                                        )}
                                    />
                                ),
                            )}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </FilterContextProvider>
                </GlobalContext>
            </SnackbarProvider>
        </BrowserRouter>
    )
}
