import React, { memo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, CssBaseline } from '@mui/material'
import { Header } from '../../components/Header'
import { DrawerHeader, Sidebar } from './Sidebar'

const Layout = memo(() => {
    const [open, setOpen] = useState(false)

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <CssBaseline />

            <Sidebar open={open} close={() => setOpen(false)} />

            <Header isOpen={open} open={() => setOpen(true)} />

            <Box className="mn-cont"
                component="main" sx={{ height: '100%', flexGrow: 1, p: 3, backgroundColor: '#ECECEC' }}>
                <DrawerHeader />

                <Outlet />
            </Box>
        </Box>
    )
})

export default Layout
