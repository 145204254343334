import React, { useState } from 'react'
import { Paper, Tabs, Tab } from '@mui/material'
import { css, StyleSheet } from 'aphrodite'
import Table from './Table'
import { COLORS } from '../../../shared/utils/colors'

export default function All() {
    return (
        <Paper square className="flex flex-col gap-2.5 p-3 mt-[14px]">
            <h3 className="text-m text-black font-600">All</h3>
            <TabFilter />
            <Table data={[]} />
            {/* firstly here will be the tab filters */}
            {/* then the table of data */}
        </Paper>
    )
}

const tabItems = [
    { id: 'working', label: 'ROI employing working hours' },
    { id: 'service', label: 'ROI employee service time' },
    { id: 'intruder', label: 'Intruder zone' },
    { id: 'queue', label: 'Queue analitics' },
]

function TabFilter() {
    const [selectedTab, setSelectedTab] = useState('working')

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    return (
        <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="tab filters"
            className={css(s.tabs)}
            indicatorColor="none"
        >
            {tabItems.map((tab) => (
                <Tab
                    key={tab.id}
                    label={tab.label}
                    value={tab.id}
                    className={css(s.tab)}
                    classes={{
                        selected: css(s.tabSelected),
                    }}
                />
            ))}
        </Tabs>
    )
}

const s = StyleSheet.create({
    tabs: {
        backgroundColor: COLORS.sepia,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        minHeight: '38px',
        width: '100%',
    },
    tab: {
        minHeight: '38px',
        whiteSpace: 'nowrap',
        fontSize: '12px',
        fontWeight: 400,
        padding: '6px 10px',
        textTransform: 'capitalize',
        flexGrow: 1,
        textAlign: 'center',
        maxWidth: '100%',
        ':nth-child(2n)': {
            borderRight: `1px solid ${COLORS.gainsboro}`,
            borderLeft: `1px solid ${COLORS.gainsboro}`,
        },
        ':last-child': {
            borderRight: 'none',
        },
        ':hover': {
            opacity: 0.8,
        },
    },
    tabSelected: {
        backgroundColor: COLORS.mainBlue,
        color: COLORS.white,
    },
})
