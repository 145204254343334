import React, { useState } from 'react'
import { Chip, Grid, Typography, Card as MuiCard, CardContent, IconButton, Popover } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { OfficesIconSizeL } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import { isImageReal } from '../../../shared/utils/string'
import Update from '../features/Update'
import Delete from '../features/Delete'

export default function Card({ item, isActive, parentOptions, setData, onClick }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const country = item.country ? item.country.name : ''
    const region = item.region ? item.region.name : ''
    const district = item.district ? item.district.name : ''

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const isPopoverOpen = Boolean(anchorEl)
    const id = isPopoverOpen ? 'simple-popover' : undefined

    const openDrawerHandler = () => {
        setOpenDrawer(true)
        handleClose()
    }

    const closeDrawerHandler = () => setOpenDrawer(false)

    const openDeleteDialogHandler = () => {
        setOpenDeleteDialog(true)
        handleClose()
    }

    const closeDeleteDialogHandler = () => setOpenDeleteDialog(false)

    return (
        <>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <MuiCard
                    onClick={onClick}
                    sx={{ backgroundColor: isActive ? COLORS.mainBlueBg : COLORS.cardInnerBg }}>
                    <CardContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        cursor: 'pointer',
                        backgroundColor: isActive ? COLORS.mainBlueBg : COLORS.cardInnerBg,
                        borderRadius: '15px',
                    }}>
                        <div className="flex justify-between max-w-full">
                            <div className="flex items-center gap-2 w-11/12">
                                {isImageReal(item.photo)
                                    ? <img className="w-12 h-12 rounded-m" src={item.photo} alt="#LOGO" />
                                    : null}

                                <div className="flex flex-col w-11/12">
                                    <span className="text-m font-medium text-disabled">Name:</span>

                                    <Typography sx={{ fontSize: '18px',
                                        fontWeight: '600',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        color: isActive ? COLORS.white : COLORS.black }}>
                                        {item.name}
                                    </Typography>
                                </div>
                            </div>

                            <IconButton onClick={handleClick} aria-describedby={id}>
                                <MoreVertIcon htmlColor={isActive ? 'white' : ''} />
                            </IconButton>
                        </div>

                        <Typography sx={{ fontSize: '16px',
                            fontWeight: '500',
                            marginTop: '10px',
                            color: isActive ? COLORS.white : COLORS.mainBlueBg }}>
                            Employees {item.identityCount}
                        </Typography>

                        <div className="flex items-center gap-2.5">
                            <Chip
                                label={`${country} ${region} ${district}`}
                                variant="outlined"
                                size="medium"
                                sx={{ borderRadius: '5px',
                                    color: isActive ? COLORS.white : COLORS.mainBlueBg,
                                    fontSize: '16px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    fontWeight: '500' }}
                                icon={<OfficesIconSizeL color={isActive ? COLORS.white : COLORS.mainBlueBg} />} />

                            <Chip
                                label="Camera"
                                variant="outlined"
                                size="medium"
                                sx={{ borderRadius: '5px',
                                    color: isActive ? COLORS.white : COLORS.mainBlueBg,
                                    fontSize: '16px',
                                    fontWeight: '500' }}
                                icon={(
                                    <Typography color={COLORS.white}
                                        className="flex items-center justify-center bg-[#E79A27] rounded px-2.5">
                                        {item.smartCameraCount || '0'}
                                    </Typography>
                                )} />
                        </div>
                    </CardContent>
                </MuiCard>
            </Grid>

            <Popover
                id={id}
                sx={{ '& .MuiPaper-root': { border: `1px solid ${COLORS.sepia}`,
                    padding: '8px' } }}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ul className="bg-white flex flex-col gap-1">
                    <li onClick={openDrawerHandler}
                        className="list-none flex items-center rounded-lg gap-2
                        cursor-pointer p-1.5 hover:bg-cardInnerbg">
                        <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />

                        <Typography className="text-md text-black font-medium">Edit</Typography>
                    </li>

                    <li onClick={openDeleteDialogHandler}
                        className="list-none flex items-center rounded-lg gap-2
                        cursor-pointer p-1.5 hover:bg-cardInnerbg">
                        <DeleteIcon
                            htmlColor={COLORS.red}
                            className="cursor-pointer"
                            fontSize="small" />

                        <Typography className="text-md text-red font-medium">Delete</Typography>
                    </li>
                </ul>
            </Popover>

            <Update
                item={item}
                setData={setData}
                parentOptions={parentOptions}
                isOpen={openDrawer}
                close={closeDrawerHandler} />

            <Delete
                setData={setData}
                isOpen={openDeleteDialog}
                close={closeDeleteDialogHandler}
                id={item.id} />
        </>
    )
}
