import React from 'react'
import { TableCell } from '@mui/material'
import Update from '../features/update'
import Delete from '../features/delete'

export default function SmartCameraProfileTableItems({ item, setData }) {
    return (
        <>
            <TableCell>
                {item.name}
            </TableCell>

            <TableCell className="cursor-pointer flex items-center justify-center">
                <div className="w-[100%] h-auto flex items-center justify-center">
                    {item.description}
                </div>
            </TableCell>

            <TableCell className=" cursor-pointer flex items-center justify-center">
                <div className="w-[100%] h-auto flex items-center justify-center">
                    {item.createdAt.slice(0, 16).replace('T', ' ')}
                </div>
            </TableCell>
            <TableCell style={{ paddingRight: 0 }} align="right">
                <div className="h-full flex items-center justify-end">
                    <Update item={item} setData={setData} />

                    <Delete id={item.id} setData={setData} />
                </div>
            </TableCell>
        </>
    )
}
