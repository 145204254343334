import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton, Popover, TableCell } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import Update from '../../company-structure/features/Update'
import { COLORS } from '../../../shared/utils/colors'
import Delete from '../../company-structure/features/Delete'

export default function BranchesTableItem({ item, setData }) {
    const navigate = useNavigate()
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const isPopoverOpen = Boolean(anchorEl)
    const id = isPopoverOpen ? 'simple-popover' : undefined

    const openDrawerHandler = () => {
        setOpenDrawer(true)
        handleClose()
    }

    const closeDrawerHandler = () => setOpenDrawer(false)

    const openDeleteDialogHandler = () => {
        setOpenDeleteDialog(true)
        handleClose()
    }

    const closeDeleteDialogHandler = () => setOpenDeleteDialog(false)

    return (
        <>
            <TableCell>{item.name}</TableCell>

            <TableCell className="cursor-pointer">{item.description}</TableCell>

            <TableCell className="cursor-pointer">{item.country ? item.country.name : 'N/A'}</TableCell>

            <TableCell className="cursor-pointer">{item.region ? item.region.name : 'N/A'}</TableCell>

            <TableCell style={{ paddingRight: 0 }} align="right">
                <div className="flex items-center justify-end">
                    <IconButton onClick={() => navigate(`/branches/${item.id}/users`)}>
                        <AccountCircleIcon />
                    </IconButton>

                    <IconButton onClick={handleClick} aria-describedby={id}>
                        <MoreVertIcon />
                    </IconButton>
                </div>
            </TableCell>

            <Popover
                id={id}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div>
                    <IconButton onClick={openDrawerHandler}>
                        <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
                    </IconButton>

                    <IconButton onClick={openDeleteDialogHandler}>
                        <DeleteIcon
                            htmlColor={COLORS.disabled}
                            className="cursor-pointer"
                            fontSize="medium" />
                    </IconButton>
                </div>
            </Popover>

            <Update
                item={item}
                setData={setData}
                isOpen={openDrawer}
                close={closeDrawerHandler} />

            <Delete
                setData={setData}
                isOpen={openDeleteDialog}
                close={closeDeleteDialogHandler}
                id={item.id} />
        </>
    )
}
