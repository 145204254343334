import React, { useState } from 'react'
import { Drawer, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { TENANT_ADMIN } from '../../../shared/utils/urls'
import { usePutRequest } from '../../../shared/hooks/requests'
import TenantAdminDetails from '../components/TenantAdminDetails'

export default function Update({ item, setData }) {
    const showMessage = useMessage()
    const [open, setOpen] = useState(false)
    const { handleErrorMsg } = useHandleErrors()
    const updateAdmin = usePutRequest({ url: TENANT_ADMIN.replace('{id}', item.id) })

    const update = async (values) => {
        const { success, response, error } = await updateAdmin.request({
            data: { ...values },
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (
                    val.id === response.id ? { ...val, ...response } : val
                )),
            }))
            setOpen(false)
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpen(true)}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}>
                <TenantAdminDetails
                    loading={updateAdmin.loading}
                    onEdit={update}
                    onClose={() => setOpen(false)}
                    item={item} />
            </Drawer>
        </>

    )
}
