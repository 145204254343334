import React, { useState } from 'react'
import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import { useLoad, usePostRequest } from '../../../shared/hooks/requests'
import { CAMERA_ROI, UPDATE_DEEPSTREAM_ROI } from '../../../shared/utils/urls'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

function List({ cameraName, cameraId, selectAll, checkedCamera, select, checked }) {
    const getRois = useLoad({ url: CAMERA_ROI.replace('{id}', cameraId) }, [])
    const roilist = getRois.response ? getRois.response : []

    return (
        <CustomAccordion
            summary={cameraName}
            action={(
                <Tooltip title="Select all" placement="top">
                    <Checkbox checked={checkedCamera[cameraId] || false} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        selectAll(cameraId, roilist)
                    }} />
                </Tooltip>
            )}
            contClassName="border-[1px] border-sepia-100"
            detailClassName={
                `border-[1px] max-h-[15rem] flex flex-col gap-4 border-sepia-200 mx-2 mb-3
rounded-[2px] max-h-[30rem] overflow-y-scroll no-scrollbar`
            }
            summaryClassName="flex flex-row-reverse gap-2 m-0"
            summaryStyles={{ margin: 0 }}
            expandIcon={<CaretDownIconSizeM color={COLORS.disabled} />}
            overrideStyles={{
                ':before': {
                    display: 'none',
                },
                width: '100%',
                boxShadow: 'none',
                borderRadius: '3px',
                '& .MuiAccordionSummary-root': {
                    padding: '0 0 0 10px',
                },
            }}>
            <div className="flex flex-col gap-2">
                <Typography>ROI</Typography>

                {roilist.map((roi) => (
                    <FormControlLabel
                        key={roi.id}
                        sx={{ margin: 0 }}
                        className="pl-3 flex-1 flex items-center justify-between pr-1 py-1 bg-defaultInpBg"
                        labelPlacement="start"
                        label={roi.name}
                        control={(
                            <Checkbox
                                checked={checked[roi.id] || false} // Ensure it defaults to false if undefined
                                onClick={() => select(roi.id, roi)}
                            />
                        )}
                    />
                ))}
            </div>
        </CustomAccordion>
    )
}

export default function NvdsAnalytics({ attachedCameras, appName, version }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const { jetsonFilter: { jetsonDeviceId } } = useFilter()
    const [checked, setChecked] = useState({})
    const [checkedCamera, setCheckedCamera] = useState({})
    const [selected, setSelected] = useState([])
    const updateROI = usePostRequest({ url: UPDATE_DEEPSTREAM_ROI })

    const handleRoiSelect = (id, data) => {
        const isChecked = !checked[id]
        setChecked((prevState) => ({
            ...prevState,
            [id]: isChecked,
        }))

        if (isChecked) {
            setSelected((prev) => [...prev, data])
        } else {
            setSelected((prev) => prev.filter((v) => v.id !== data.id))
        }
    }

    const handleSelectAll = (cameraId, data) => {
        const isAllSelected = !!checkedCamera[cameraId]

        const updatedChecked = { ...checked }
        const updatedCheckedCamera = { ...checkedCamera }
        let updatedSelected = [...selected]

        updatedCheckedCamera[cameraId] = !isAllSelected

        data.forEach((roi) => {
            updatedChecked[roi.id] = !isAllSelected
            if (!isAllSelected) {
                if (!updatedSelected.some((selectedRoi) => selectedRoi.id === roi.id)) {
                    updatedSelected.push(roi)
                }
            } else {
                updatedSelected = updatedSelected.filter((selectedRoi) => selectedRoi.id !== roi.id)
            }
        })

        setChecked(updatedChecked)
        setCheckedCamera(updatedCheckedCamera)
        setSelected(updatedSelected)
    }

    const submitChanges = async () => {
        if (!selected.length || updateROI.loading) return
        const rois = []

        for (const roi of selected) {
            const points = roi.points
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .flatMap((sorted) => [sorted.x, sorted.y])

            rois.push(points)
        }

        const { response, success, error } = await updateROI.request({ data: {
            applicationName: appName,
            applicationVersion: version,
            jetsonDeviceId,
            streams: [{ rois }],
        } })

        if (success) {
            showMessage(response.description, response.status === 'success' ? 'success-msg' : 'error-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <div className="py-3.5 px-5 flex flex-col items-end gap-2.5">
            {
                attachedCameras.map((camera) => (
                    <List
                        key={camera.id}
                        selected={selected}
                        checked={checked}
                        checkedCamera={checkedCamera}
                        cameraId={camera.id}
                        selectAll={handleSelectAll}
                        select={handleRoiSelect}
                        cameraName={camera.name} />
                ))
            }

            <PrimaryBtn
                onClick={submitChanges}
                title="Apply changes"
                loading={updateROI.loading}
                styles={{ width: 'fit-content', marginTop: '10px' }} />
        </div>
    )
}
