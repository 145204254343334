import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import { Button, ButtonGroup, IconButton, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { COLORS } from '../../../shared/utils/colors'
import { IDENTITY_BY_ENTITY, USERS_BY_ENTITY } from '../../../shared/utils/urls'
import { useLoad } from '../../../shared/hooks/requests'
import EntityUsersTableItem from './EntityUsersTableItem'
import Table from '../../../shared/ui/Table'
import Create from '../features/Create'

const cols = [
    { id: 1, title: 'Photo' },
    { id: 2, title: 'First name' },
    { id: 3, title: 'Last name' },
    { id: 4, title: 'Email' },
    { id: 5, title: 'Role' },
    { id: 6, title: 'Phone number' },
    { id: 7, title: 'Action', width: '10%', align: 'right' },
]

const GETURL = {
    users: USERS_BY_ENTITY,
    identity: IDENTITY_BY_ENTITY,
    blacklist: '',
}

const btnStyles = {
    borderColor: '#D8D8D8',
    fontSize: '14px',
    fontWeight: '400',
}

export default function EntityUsersTable() {
    const { id } = useParams()
    const [page, setPage] = useState(1)
    const [type, setType] = useState('users')
    const [pageSize, setPageSize] = useState(10)
    const loadUsers = useLoad({ url: GETURL[type] + id, params: { page, size: pageSize } }, [page, page, type])
    const data = loadUsers.response ? loadUsers.response.items : []
    const meta = loadUsers.response ? loadUsers.response : {}

    const handleBtnClick = (str) => {
        setType(str)
    }

    return (
        <Paper square className="flex flex-col gap-3">
            <div className="flex items-center justify-between px-4 pt-3">
                <div className="flex flex-col gap-2.5">
                    <Typography className="text-m text-black font-medium">Users</Typography>

                    <ButtonGroup variant="contained" aria-label="users-filter-button">
                        <Button onClick={() => handleBtnClick('users')}
                            style={{
                                ...btnStyles,
                                color: type === 'users' ? COLORS.white : COLORS.mainBlueBg,
                                backgroundColor: type === 'users' ? COLORS.mainBlueBg : COLORS.sepia,
                            }}>
                            Customer users
                        </Button>

                        <Button onClick={() => handleBtnClick('identity')}
                            style={{
                                ...btnStyles,
                                color: type === 'identity' ? COLORS.white : COLORS.mainBlueBg,
                                backgroundColor: type === 'identity' ? COLORS.mainBlueBg : COLORS.sepia,
                            }}>
                            Identity
                        </Button>

                        {/* <Button onClick={() => handleBtnClick('blacklist')} */}
                        {/*     style={{ */}
                        {/*         ...btnStyles, */}
                        {/*         color: type === 'blacklist' ? COLORS.white : COLORS.mainBlueBg, */}
                        {/*         backgroundColor: type === 'blacklist' ? COLORS.mainBlueBg : COLORS.sepia, */}
                        {/*     }}> */}
                        {/*     Blacklist */}
                        {/* </Button> */}
                    </ButtonGroup>
                </div>

                <div className="flex-cent gap-2">
                    <Create setData={loadUsers.setResponse} type={type} />

                    <IconButton>
                        <Search fontSize="small" />
                    </IconButton>
                </div>
            </div>

            <Table
                page={page - 1}
                loading={loadUsers.loading}
                totalCount={meta.total}
                rowsPerPage={pageSize}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10))
                    setPage(1)
                }}
                cols={cols}
                rows={data}
                renderItem={(item) => (
                    <EntityUsersTableItem
                        type={type}
                        item={item}
                        setData={loadUsers.setResponse}
                    />
                )}
            />
        </Paper>
    )
}
