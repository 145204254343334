import en from '../locales/en.json'
import ru from '../locales/ru.json'
import uz from '../locales/uz.json'

const translations = {
    en,
    ru,
    uz,
}

export function translationsMessage(lang) {
    return translations[lang]
}
