import React from 'react'
import { Dialog, DialogContent } from '@mui/material'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { TENANT_ENTITIES } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { useDeleteRequest } from '../../../shared/hooks/requests'

export default function Delete({ id, setData, isOpen, close }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const deleteEntity = useDeleteRequest()

    const remove = async () => {
        if (deleteEntity.loading) return

        const { success, error } = await deleteEntity.request({ url: `${TENANT_ENTITIES}${id}` })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.filter((v) => v.id !== id),
            }))
            showMessage('Successfully deleted', 'success-msg')
            close()
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Dialog fullWidth open={isOpen} onClose={close}>
            <DialogContent sx={{ '& .MuiPaper-root': { width: 'fit-content' } }}
                style={{ padding: 20 }}>
                <div className="flex min-h-40 flex-col items-center justify-between">
                    <div className="w-full flex-col items-center justify-center gap-6">
                        <h3 className="text-xl font-600">Are you sure you want to delete?</h3>

                        <h3 className="text-l font-400 text-center">
                            Confirm Deletion This Action Is Irreversible
                        </h3>
                    </div>

                    <div className="flex-cent gap-2_5">
                        <CancelBtn type="button" title="Cancel" onClick={close} />

                        <PrimaryBtn loading={deleteEntity.loading} type="button" title="Delete" onClick={remove} />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
