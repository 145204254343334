import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
// import { StyleSheet } from 'aphrodite'
import moment from 'moment'

const cols = [
    { id: 1, title: '№' },
    { id: 2, title: 'Camera name' },
    { id: 3, title: 'Photo' },
    { id: 4, title: 'Full name' },
    { id: 5, title: 'At workplace' },
    { id: 6, title: 'At workplace min' },
]

export default function CustomTable({ data }) {
    const [transformedData, setTransformedData] = useState([])

    useEffect(() => {
        const dublicatedData = data.reduce((acc, curr) => {
            const { identity, analyticsReport } = curr
            const dublicated = analyticsReport.map((item) => ({ ...item, identity }))
            return [...acc, ...dublicated]
        }, [])
        setTransformedData(dublicatedData)
    }, [data])

    return (
        <Table aria-label="custom table">
            <TableHead>
                <TableRow>
                    {cols.map((col) => (
                        <TableCell key={col.id}>{col.title}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {transformedData.map((item, index) => (
                    <TableRow key={item.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                            <img src={item.identity.photo} alt={item.identity.firstName} width="40" />
                        </TableCell>
                        <TableCell>{`${item.identity.firstName} ${item.identity.lastName}`}</TableCell>
                        <TableCell>
                            {moment(item.analytics.roiFirstEntrance).format('HH:mm')} -
                            {moment(item.analytics.roiLastExit).format('HH:mm')}
                        </TableCell>
                        <TableCell>{item.analytics.minutesWorkTime}</TableCell>
                    </TableRow>
                ))}
                {/* add no data message */}

            </TableBody>
        </Table>
    )
}

// const s = StyleSheet.create({
//     actBtn: {
//         borderRadius: 5,
//         background: '#1976d2',
//         padding: '8px 12px',
//         color: '#fff',
//         fontSize: 14,
//         fontWeight: '500',
//         ':hover': {
//             background: '#1565c0',
//         },
//     },
// })
