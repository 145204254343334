import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Layer, Image as KonvaImage, Stage } from 'react-konva'
import uuid from 'react-uuid'
import { setActivePolygonIndex, setPolygons } from '../../../shared/store/polygonStore'
import Polygon from './Polygon'
import { getMiddlePoint } from '../../../shared/utils/polygonUtils'
import RoiForm from './RoiForm'
import '../../../app/styles/canvas.css'
import { hexToRGBA } from '../../../shared/utils/string'
import { COLORS } from '../../../shared/utils/colors'

const defaultColors = {
    vertexRadius: 2.5,
    lineColor: '#0B9E34',
    fillColor: hexToRGBA('#0B9E34', '0.30'),
    vertexColor: COLORS.white,
    vertexStrokeWidth: 1,
}

export default function Canvas({
    cameraId,
    imageSrc,
    maxPoints,
    onSave,
    loading,
    onDelete,
    formVisible,
    setCurrentPolygon,
    setFormVisible,
    maxPolygons,
}) {
    const dispatch = useDispatch()
    const [image, setImage] = useState(null)
    const [isMouseOverPoint, setIsMouseOverPoint] = useState(false)
    const [formPosition, setFormPosition] = useState({ x: 0, y: 0 })
    const [isDrawing, setIsDrawing] = useState(true)
    const { polygons, activePolygonIndex, shouldCloseForm } = useSelector((state) => state.polygon)

    const imageElement = useMemo(() => {
        const element = new Image()
        element.src = imageSrc
        return element
    }, [imageSrc])

    useEffect(() => {
        if (imageElement.complete) {
            setImage(imageElement)
        }
    }, [imageElement])

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'd') {
                const activePolygon = polygons[activePolygonIndex]
                if (isDrawing && activePolygon && activePolygon.points.length >= 2) {
                    setIsDrawing(false)
                } else {
                    setIsDrawing((prev) => !prev)
                }
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [polygons, activePolygonIndex, isDrawing])

    useEffect(() => {
        if (shouldCloseForm) {
            setFormVisible(false)
        }
    }, [shouldCloseForm])

    const handlePolygonCompletion = (polygon) => {
        const middlePoint = getMiddlePoint(polygon.points)
        setFormPosition({ x: middlePoint.x, y: middlePoint.y })
        setFormVisible(true)
        setCurrentPolygon(polygon)
        console.log(polygon)
    }

    const getMousePos = (stage) => {
        const pos = stage.getPointerPosition()
        return pos ? { x: pos.x, y: pos.y } : { x: 0, y: 0 }
    }

    const handleMouseClick = useCallback(
        (e) => {
            if (!isDrawing) return

            let activeKey = activePolygonIndex
            const copy = [...polygons]

            if (copy.filter((p) => p.isFinished).length >= maxPolygons) return

            let polygon = copy[activeKey]
            const { isFinished } = polygon
            if (e.target.name() === 'vertex' && !isMouseOverPoint) {
                return
            }
            if (isFinished) {
                polygon = {
                    id: uuid(),
                    points: [],
                    isFinished: false,
                    label: `Polygon ${copy.length + 1}`,
                    colors: defaultColors,
                }
                setIsMouseOverPoint(false)
                activeKey += 1
                dispatch(setActivePolygonIndex(activeKey))
            }
            const { points } = polygon
            const stage = e.target.getStage()
            const mousePos = getMousePos(stage)

            if ((points || []).length + 1 >= maxPoints) {
                polygon = {
                    ...polygon,
                    points: [...(points || []), { ...mousePos, orderNumber: (points || []).length + 1 }],
                    isFinished: true,
                }
                setIsDrawing(false)
                handlePolygonCompletion(polygon)
            } else if (!points.find((point) => point.x === mousePos.x && point.y === mousePos.y)) {
                polygon = {
                    ...polygon,
                    points: [...(points || []), { ...mousePos, orderNumber: (points || []).length + 1 }],
                }
            }

            copy[activeKey] = polygon
            dispatch(setPolygons({ polygons: copy, shouldUpdateHistory: true }))
        },
        [activePolygonIndex, dispatch, isMouseOverPoint, isDrawing, maxPolygons, polygons],
    )

    const handleMouseMove = useCallback(
        (e) => {
            if (!isDrawing) return

            const stage = e.target.getStage()
            if (!stage) {
                return
            }
            const mousePos = getMousePos(stage)
            const copy = [...polygons]
            let polygon = copy[activePolygonIndex]
            const points = polygon ? polygon.points : []
            if (polygon && polygon.isFinished) {
                return
            }
            const sortedPoints = [...(points || []), { ...mousePos, orderNumber: (points || []).length + 1 }].sort(
                (a, b) => a.orderNumber - b.orderNumber,
            )
            const flattenedPoints = sortedPoints.reduce((a, b) => [...a, ...[b.x, b.y]], [])
            polygon = {
                ...polygon,
                flattenedPoints,
            }
            copy[activePolygonIndex] = polygon
            dispatch(setPolygons({ polygons: copy, shouldUpdateHistory: false }))
        },
        [activePolygonIndex, dispatch, isDrawing, polygons],
    )

    const handleMouseOverStartPoint = useCallback(
        (e, polygonKey) => {
            const polygon = polygons[polygonKey]
            const { points, isFinished } = polygon
            if (isFinished || (points || []).length < 3) {
                return
            }
            e.target.scale({ x: 3, y: 3 })
            setIsMouseOverPoint(true)
        },
        [polygons],
    )

    const handleMouseOutStartPoint = useCallback((e) => {
        e.target.scale({ x: 1, y: 1 })
        setIsMouseOverPoint(false)
    }, [])

    const handlePointDragMove = useCallback(
        (e, polygonKey) => {
            const copy = [...polygons]
            let polygon = copy[polygonKey]
            const { isFinished } = polygon
            if (!isFinished) {
                e.target.stopDrag()
                return
            }
            const stage = e.target.getStage()
            const index = e.target.index - 1
            const pos = { x: e.target.x(), y: e.target.y(), orderNumber: index + 1 }

            if (stage) {
                if (pos.x < 0) pos.x = 0
                if (pos.y < 0) pos.y = 0
                if (pos.x > stage.width()) pos.x = stage.width()
                if (pos.y > stage.height()) pos.y = stage.height()
            }

            const { points } = polygon
            const updatedPoint = { ...points[index], ...pos }
            const newPoints = [...points.slice(0, index), updatedPoint, ...points.slice(index + 1)]
            const sortedPoints = newPoints.sort((a, b) => a.orderNumber - b.orderNumber)
            const flattenedPoints = sortedPoints.reduce((a, b) => [...a, ...[b.x, b.y]], [])
            polygon = {
                ...polygon,
                points: newPoints,
                flattenedPoints,
            }
            copy[polygonKey] = polygon
            dispatch(setPolygons({ polygons: copy, shouldUpdateHistory: false }))
        },
        [dispatch, polygons],
    )

    const handlePointDragEnd = useCallback(
        (e, polygonKey) => {
            const index = e.target.index - 1
            const pos = { x: e.target.x(), y: e.target.y(), orderNumber: index + 1 }
            const copy = [...polygons]
            let polygon = copy[polygonKey]
            const { points } = polygon

            const updatedPoint = { ...points[index], ...pos }
            const newPoints = [...points.slice(0, index), updatedPoint, ...points.slice(index + 1)]
            const sortedPoints = newPoints.sort((a, b) => a.orderNumber - b.orderNumber)
            const flattenedPoints = sortedPoints.reduce((a, b) => [...a, ...[b.x, b.y]], [])

            polygon = {
                ...polygon,
                points: newPoints,
                flattenedPoints,
            }
            copy[polygonKey] = polygon
            dispatch(setPolygons({ polygons: copy, shouldUpdateHistory: true }))
            handlePolygonCompletion(polygon)
        },
        [dispatch, polygons],
    )

    const handleGroupDragEnd = useCallback(
        (e, polygonKey) => {
            const copy = [...polygons]
            let polygon = copy[polygonKey]
            const { points } = polygon
            if (e.target.name() === 'polygon') {
                const result = points.map((point) => ({
                    ...point,
                    x: point.x + e.target.x(),
                    y: point.y + e.target.y(),
                }))
                e.target.position({ x: 0, y: 0 })
                polygon = {
                    ...polygon,
                    points: result,
                    flattenedPoints: result.reduce((a, b) => [...a, ...[b.x, b.y]], []),
                }
                copy[polygonKey] = polygon
                dispatch(setPolygons({ polygons: copy, shouldUpdateHistory: true }))
                handlePolygonCompletion(polygon)
            }
        },
        [dispatch, polygons],
    )

    return (
        <React.Fragment>
            <Stage
                width={imageElement.width}
                height={imageElement.height}
                className={isDrawing ? 'drawing-cursor' : 'default-cursor'}
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseClick}
            >
                <Layer>
                    {image && (
                        <KonvaImage
                            image={image}
                            width={image.width}
                            height={image.height}
                            fill="black"
                        />
                    )}

                    {polygons.map((polygon, index) => (
                        <Polygon
                            key={polygon.id}
                            isFinished={polygon.isFinished}
                            points={polygon.points}
                            colors={polygon.colors}
                            flattenedPoints={polygon.flattenedPoints}
                            handlePointDragMove={(e) => handlePointDragMove(e, index)}
                            handlePointDragEnd={(e) => handlePointDragEnd(e, index)}
                            handleMouseOverStartPoint={(e) => handleMouseOverStartPoint(e, index)}
                            handleMouseOutStartPoint={handleMouseOutStartPoint}
                            handleGroupDragEnd={(e) => handleGroupDragEnd(e, index)}
                            handleDelete={() => onDelete(polygon.id)}
                            showLabel={polygon.isFinished}
                            label={polygon && polygon.label}
                        />
                    ))}
                </Layer>
            </Stage>

            {formVisible && (
                <div style={{ position: 'absolute', left: formPosition.x + 150, top: formPosition.y }}>
                    <RoiForm loading={loading} cameraId={cameraId} onSubmit={onSave} />
                </div>
            )}
        </React.Fragment>
    )
}
