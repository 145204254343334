import React from 'react'
import { Form, Formik } from 'formik'
import { number, object, string } from 'yup'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import SelectInput from '../../../shared/ui/SelectInput'
import { useLoad } from '../../../shared/hooks/requests'
import { FIRMWARE_URL } from '../../../shared/utils/urls'

const FORM_INITIAL_STATE = {
    name: '',
    description: '',
    firmware: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    description: string().required('Description is required'),
    firmware: number().required('Firmware is required'),
})

export default function SmartCameraProfileForm({
    onSubmit = () => {},
    onClose = () => {},
    initialValues,
    loading,
    isEdit,
    formType,
}) {
    const loadFirmware = useLoad({ url: FIRMWARE_URL, params: { isActive: true } })

    const firmwareOptions = loadFirmware.response && loadFirmware.response.length
        ? loadFirmware.response.map((f) => ({ value: f.id, label: f.name })) : []

    return (
        <Formik enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
                formType, // Add formType to initial values
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ handleChange, setFieldValue }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            required
                            label="Name"
                            name="name"
                            handleChange={handleChange}
                        />
                        <FormTextField
                            required
                            label="Description"
                            name="description"
                            type="text"
                            handleChange={handleChange}
                            overrideStyles={{ height: 'auto' }}
                        />
                        <SelectInput
                            required
                            name="firmware"
                            label="Assign firmware"
                            handleChange={handleChange}
                            menuItems={firmwareOptions}
                            onChange={(e) => {
                                setFieldValue('firmware', e.target.value)
                            }}
                        />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
