import React from 'react'
import { TableCell } from '@mui/material'
import Table from '../../../shared/ui/Table'
import { Decrease, Increase } from '../../../components/svgs/Svgs'

const renderValue = (value) => {
    if (Array.isArray(value)) {
        return (
            <div className="flex items-center gap-[10px]">
                <div>{`(${value[0]})`}</div>
                <div className="flex items-center gap-[1px]">
                    {value[1] > 0 ? (<Increase />) : (<Decrease />)}
                    <div className={`${value[1] > 0 ? 'text-green-600' : 'text-red-600'}`}>{`${value[1]}%`}</div>
                </div>
            </div>
        )
    }
    return <div>{`(${value})`}</div>
}

const insertHyphen = (text) => {
    const middleIndex = Math.floor(text.length / 2)
    return `${text.slice(0, middleIndex)}-${text.slice(middleIndex)}`
}

function ModalTable({ data, newName }) {
    const idToNameMap = newName.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
    }, {}) || {}

    if (!data) {
        return null // or some fallback UI if needed
    }

    if (typeof data !== 'object') {
        console.error('Invalid data format: Expected an object.')
        return null // or handle error state
    }

    const headers = Object.keys(data)
    const maxRows = Math.max(...headers.map((header) => Object.keys(data[header]).length))
    const rows = Array.from({ length: maxRows }, () => ({}))

    headers.forEach((header) => {
        const headerData = Object.entries(data[header])

        // Sort the data by length: shorter arrays first
        const sortedData = headerData.sort((a, b) => a[1].length - b[1].length)

        sortedData.forEach(([key, value], index) => {
            rows[index][header] = { key, value }
        })
    })

    const cols = headers.map((header) => ({
        id: header,
        title: idToNameMap[header] || header,
    }))

    return (
        <Table
            cols={cols || []}
            rows={rows || []}
            paginated={false}
            renderItem={(item, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    {headers.map((header) => (
                        <TableCell key={`${rowIndex}-${header}`}>
                            {item[header] ? (
                                <div key={`${rowIndex}-${header}-${item[header].key}`}
                                    className="flex items-center gap-[4px]">
                                    <div>
                                        <span className="whitespace-nowrap">
                                            {insertHyphen(item[header].key)}
                                        </span>
                                    </div>
                                    <div>{renderValue(item[header].value)}</div>
                                </div>
                            ) : ''}
                        </TableCell>
                    ))}
                </React.Fragment>
            )}
        />
    )
}

export default ModalTable
