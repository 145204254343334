import React from 'react'
import { TableCell } from '@mui/material'
import Update from '../features/update'
import Delete from '../features/delete'

export default function FirmwareTableItems({ item, setData }) {
    return (
        <>
            <TableCell className="cursor-pointer">
                {item.name}
            </TableCell>

            <TableCell className="whitespace-nowrap flex items-center justify-center" align="center">
                {item.description}
            </TableCell>

            <TableCell className="whitespace-nowrap justify-center
             overflow-hidden text-ellipsis  cursor-pointer">
                {item.cameras}
            </TableCell>

            <TableCell className="flex items-center justify-center" align="center">
                {item.createdAt.slice(0, 16).replace('T', ' ')}
            </TableCell>

            <TableCell style={{ paddingRight: 0 }} align="right">
                <div className="h-full flex items-center justify-end">
                    <Update item={item} setData={setData} />

                    <Delete id={item.id} setData={setData} />
                </div>
            </TableCell>
        </>
    )
}
