import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { ChevronDownSizeS, ChevronNextSizeL, ChevronUpSizeS } from '../svgs/Svgs'
import { COLORS } from '../../shared/utils/colors'

const sizes = [10, 20, 30, 40, 50]

export default function Pagination({
    count,
    pageSize,
    page,
    setPageSize,
    loading,
    setPage,
    pageCount,
    showSelectRows = true,
}) {
    function onChangeSize(mode) {
        if (mode === 'increase') return setPageSize(pageSize !== 50 ? sizes[sizes.indexOf(pageSize) + 1] : pageSize)
        return setPageSize(pageSize !== 10 ? sizes[sizes.indexOf(pageSize) - 1] : pageSize)
    }

    const p = page - 1

    return (
        <div className={cn('flex items-center justify-end', css(s.pg))}>
            <h3 className={css(s.count)}>
                {count
                    ? `${(p * pageSize) + 1} - 
                    ${((p + 1) * pageSize) < count ? ((p + 1) * pageSize) : count} of ${count}`
                    : null}
            </h3>

            {showSelectRows ? (
                <span className={cn('flex-cent dis_sl', css(s.showMore))}>
                    <h5 className={css(s.show)}>Show rows: {pageSize}</h5>

                    <span className={css(s.upDown)}>
                        <span className="flex-cent">
                            <ChevronUpSizeS onClick={() => (!loading ? onChangeSize('increase') : {})} />
                        </span>

                        <span className="flex-cent">
                            <ChevronDownSizeS onClick={() => (!loading ? onChangeSize('decrease') : {})} />
                        </span>
                    </span>
                </span>
            ) : null}

            <span
                onClick={() => (!loading && page > 1 ? setPage(page - 1) : {})}
                className={cn('flex-cent pointer', css(s.contBtn), css(s.backBtn), { [css(s.disBtn)]: page <= 1 })}>
                <ChevronNextSizeL deg={90} />
            </span>

            <span
                onClick={() => (!loading && page < pageCount ? setPage(page + 1) : {})}
                className={cn('flex-cent pointer', css(s.contBtn), { [css(s.disBtn)]: page >= pageCount })}>
                <ChevronNextSizeL deg={-90} />
            </span>
        </div>
    )
}

const s = StyleSheet.create({
    pg: {
        padding: 20,
    },
    showMore: {
        height: 32,
        paddingLeft: 8,
        marginLeft: 28,
        gap: 10,
        border: `1px solid ${COLORS.placeholder}`,
        borderRadius: 4,
    },
    backBtn: {
        marginLeft: 24,
        marginRight: 12,
    },
    contBtn: {
        background: `linear-gradient(180deg, ${COLORS.gray} 0%, ${COLORS.gray} 100%)`,
        width: 32,
        height: 32,
        borderRadius: 4,
        ':hover': {
            background: `linear-gradient(180deg, ${COLORS.mainBlueBg} 0%, ${COLORS.mainBlue} 100%)`,
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                strokeOpacity: 1,
            },
        },
    },
    disBtn: {
        backgroundColor: COLORS.mainBlueBg,
    },
    count: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.gray,
    },
    upDown: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 32,
        height: 32,
        borderLeft: '1px solid #ccc',
        ':nth-child(1n) > *': {
            height: '40%',
            ':nth-child(1n) > :first-child': {
                cursor: 'pointer',
                ':nth-child(1n) > :first-child': {
                    ':hover': {
                        fill: '#000',
                    },
                },
            },
        },
    },
    show: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.gray,
    },
})
