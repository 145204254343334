import React from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import { useNavigate } from 'react-router-dom'
import FormTextField from '../../shared/ui/FormTextField'
import CancelBtn from '../../shared/ui/CancelBtn'
import PrimaryBtn from '../../shared/ui/PrimaryBtn'
import CreateBasicAuthToken from './features/credentialBasicAuth'

const FORM_INITIAL_STATE = {
    username: '',
    password: '',
}

const VALIDATION_SCHEMA = object().shape({
    username: string().required('Name is required'),
    password: string().required('Description is required'),
})

export default function AuthForm({ onClose }) {
    const navigate = useNavigate()
    const onSubmit = async (values) => {
        const { username, password } = values
        const authToken = CreateBasicAuthToken(username, password)
        const faceAuthTokens = JSON.stringify(authToken)
        localStorage.setItem('faceAuthToken', faceAuthTokens)
        onClose()
        navigate('/client')
        window.location.reload()
    }

    return (
        <Formik
            validationSchema={VALIDATION_SCHEMA}
            initialValues={{
                ...FORM_INITIAL_STATE,
            }}
            onSubmit={onSubmit}>
            {({ handleChange }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            required
                            label="username"
                            name="username"
                            handleChange={handleChange}
                        />

                        <FormTextField
                            required
                            label="password"
                            name="password"
                            handleChange={handleChange}
                        />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            title="Enter"
                            type="Enter" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
