export function joinedToSnakeCase(str) {
    return (
        str.charAt(0).toUpperCase()
    + str
        .replace(/([a-z])([A-Z])/g, '$1_$2')
        .toLowerCase()
        .replace(/_/g, '_')
        .slice(1)
    )
}

export function isImageReal(url) {
    if (typeof url !== 'string') return false
    const regex = /\.(jpeg|jpg|gif|png|svg|webp)$/i
    return regex.test(url)
}

export function separateCamCase(str) {
    return str
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .trim()
}

export function hexToRGBA(hex, alpha = 1) {
    let color = hex
    color = hex.replace(/^#/, '')

    const r = parseInt(color.substring(0, 2), 16)
    const g = parseInt(color.substring(2, 4), 16)
    const b = parseInt(color.substring(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export function isValidURL(string) {
    try {
        URL(string)
        return true
    } catch (e) {
        const regex = /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/.*)?$/
        return regex.test(string)
    }
}

export function strCutter(str, maxLength) {
    if (!str || typeof str !== 'string') return null

    return str.length > maxLength ? `${str.slice(0, maxLength - 3)}...` : str
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)
