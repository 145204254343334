import React from 'react'
import { useLocation } from 'react-router-dom'
import RoomsTable from './rooms/components/RoomsTable'
import BuildingsTable from './buildings/components/BuildingsTable'

export default function InfrastructurePage() {
    const { pathname } = useLocation()

    return pathname.endsWith('rooms') ? <RoomsTable /> : <BuildingsTable />
}
