import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import MLModelProfileDetails from '../components/MLModelProfileDetails'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../shared/hooks/requests'
import { ML_MODEL_PROFILES } from '../../../shared/utils/urls'

export default function Update({ $api, item }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateMLModelProfile = usePutRequest()

    const update = async (values) => {
        const { success, error } = await updateMLModelProfile.request({
            url: `${ML_MODEL_PROFILES}/${item.id}`,
            data: {
                name: values.name,
                description: values.description,
                tags: values.tags.join(';'),
            },
        })

        if (success) {
            $api.request()
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }
    return (
        <>
            <IconButton className="p-0" onClick={() => setOpenDrawer(true)}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '35%' } }}
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}>
                <MLModelProfileDetails
                    onEdit={update}
                    onClose={() => setOpenDrawer(false)}
                    item={item} />
            </Drawer>
        </>
    )
}
