import React, { useEffect } from 'react'
import { MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import Paper from '@mui/material/Paper'
import { useGetRequest, useLoad } from '../hooks/requests'
import { useFilter } from '../../app/contexts/FilterContextProvider'

export default function CameraFilter({ buildingUrl, roomUrl, otherFilters = [] }) {
    const { cameraFilter, setCameraFilter } = useFilter()
    const loadBuildings = useLoad({ url: buildingUrl }, [])
    const buildings = loadBuildings.response ? loadBuildings.response : []
    const getRooms = useGetRequest({ url: roomUrl, params: { buildingId: cameraFilter.bId } })
    const rooms = getRooms.response ? getRooms.response : []
    const buildingOptions = buildings.map((b) => ({ value: b.id, label: b.name }))
    const roomOpts = rooms.length ? rooms.map((r) => ({ value: r.id, label: r.name })) : []

    useEffect(() => {
        if (cameraFilter.bId) {
            getRooms.request()
        }
    }, [cameraFilter.bId])

    return (
        <Paper square className="flex flex-col gap-2.5 p-3">
            <h3 className="text-m text-black font-500">Filter</h3>

            <div className="flex items-center gap-2">
                <Select
                    variant="outlined"
                    displayEmpty
                    renderValue={(value) => (value ? buildingOptions.find((v) => v.value === value)
                        && buildingOptions.find((v) => v.value === value).label : 'Choose building')}
                    sx={{ height: 37, width: '10rem' }}
                    value={cameraFilter.bId}
                    onChange={(e) => setCameraFilter(() => ({ bId: e.target.value }))}
                >
                    {!loadBuildings.loading && buildingOptions.length ? buildingOptions.map((menuItem) => (
                        <MenuItem key={menuItem.value} value={menuItem.value}>
                            {menuItem.label}
                        </MenuItem>
                    )) : loadBuildings.loading ? (
                        <MenuItem>
                            <Skeleton width="50%" height={30} />
                        </MenuItem>
                    ) : <MenuItem value="">Nothing Found</MenuItem>}
                </Select>

                {roomOpts.length ? (
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? roomOpts.find((v) => v.value === value)
                            && roomOpts.find((v) => v.value === value).label : 'Choose room')}
                        sx={{ height: 37, width: '10rem' }}
                        value={cameraFilter.rId}
                        onChange={(e) => setCameraFilter((prev) => ({ ...prev, rId: e.target.value }))}
                    >
                        {!getRooms.loading && roomOpts.length ? roomOpts.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem.label}
                            </MenuItem>
                        )) : roomOpts.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value={0}>Nothing Found</MenuItem>}
                    </Select>
                ) : null}

                {otherFilters.map((filterItem) => filterItem)}
            </div>
        </Paper>
    )
}
