import React, { useState } from 'react'
import Table from '../../../shared/ui/Table'
import { useLoad } from '../../../shared/hooks/requests'
import { SMART_CAMERA_PROFILE } from '../../../shared/utils/urls'
import SmartCameraProfileTableItem from './smartCameraProfileTableItem'
import Create from '../features/create'

const cols = [
    {
        id: 1,
        title: 'Name',
        align: 'left',
    },
    {
        id: 2,
        title: 'Description',
        align: 'center',
    },
    {
        id: 3,
        title: 'Created time',
        align: 'center',
    },
    {
        id: 4,
        title: 'action',
        align: 'right',
    },
]

export function SmartCameraProfileTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const loadProfile = useLoad({ url: SMART_CAMERA_PROFILE, params: { isActive: true } }, [page, pageSize])
    const data = loadProfile.response ? loadProfile.response : []

    return (
        <div className="w-[100%] h-auto flex flex-col items-center gap-[21px] py-[20px] px-[24px] bg-white">
            <div className="w-[100%] h-auto flex items-center justify-between">
                <span className="text-[20px] font-normal text-black">Smart camera profile
                    ({data ? data.length : 0})
                </span>
                <Create setData={loadProfile.setResponse} />
            </div>
            <Table
                page={page}
                loading={loadProfile.loading}
                totalCount={[]}
                rowsPerPage={pageSize}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10))
                    setPage(1)
                }}
                cols={cols}
                rows={data}
                renderItem={(item) => (
                    <SmartCameraProfileTableItem
                        key={item.id}
                        item={item}
                        setData={loadProfile.setResponse} />
                )}
            />
        </div>
    )
}
