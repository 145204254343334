import React, { useState } from 'react'
import { Drawer, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { IDENTITIES, TENANT_USER } from '../../../shared/utils/urls'
import { usePutRequest } from '../../../shared/hooks/requests'
import EntityUserDetails from '../components/EntityUserDetails'

const URL = {
    users: TENANT_USER,
    identity: IDENTITIES,
}

export default function Update({ item, setData, type }) {
    const showMessage = useMessage()
    const { id } = useParams()
    const [open, setOpen] = useState(false)
    const { handleErrorMsg } = useHandleErrors()
    const updateUser = usePutRequest({ url: URL[type].replace('{id}', item.id) })

    const update = async (values) => {
        if (updateUser.loading) return

        const { success, response, error } = await updateUser.request({
            data: { ...values, tenantEntityId: +id },
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (
                    val.id === item.id ? { ...val, ...response } : val
                )),
            }))
            setOpen(false)
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpen(true)}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}>
                <EntityUserDetails
                    loading={updateUser.loading}
                    onEdit={update}
                    onClose={() => setOpen(false)}
                    item={item} />
            </Drawer>
        </>

    )
}
