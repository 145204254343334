import React, { useState } from 'react'
import { TableCell } from '@mui/material'
import moment from 'moment/moment'
import Update from '../features/Update'
import Delete from '../features/Delete'
// import Delete.jsx.jsx from '../features/Delete.jsx.jsx'

export default function TenantProfilesTableItem({ item }) {
    const [openDrawer, setOpenDrawer] = useState(false)

    const open = () => setOpenDrawer(true)
    const close = () => setOpenDrawer(false)

    return (
        <>
            <TableCell>
                {item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.name}
            </TableCell>

            <TableCell
                className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.description}
            </TableCell>

            <TableCell style={{ paddingRight: 8 }} align="right">
                <div className="h-full flex items-center justify-end">
                    <Update item={item} open={open} isOpen={openDrawer} close={close} />

                    <Delete id={item.id} />
                </div>
            </TableCell>
        </>
    )
}
