import React, { memo, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { IconButton, AppBar as MuiAppBar, styled } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { BellIcon, CaretDownIconSizeM, LogoutIcon } from './svgs/Svgs'
import { COLORS } from '../shared/utils/colors'
import { Popup } from './common/Popup'
import { NoUserPhoto } from './svgs/SvgPictures'
import Button from './common/Button'
import Breadcrumbs from './Breadcrumbs'
import { useGetRequest } from '../shared/hooks/requests'
import { ADMIN_INFO, CUSTOMER_INFO } from '../shared/utils/urls'
import { clearStorage } from '../shared/utils/session'

function ProfilePopup({ user }) {
    return (
        <div className={cn('flex-col gap-4', css(s.profile))}>
            <div className="flex items-center gap-3">
                {user.image ? (
                    <img className={css(s.innerAvatar)} src={user.image} alt="#logo" />
                ) : (
                    <div>
                        <NoUserPhoto />
                    </div>
                )}

                <div className={cn('flex-col gap-1', css(s.name))}>
                    <h3>{user.firstName} {user.lastName}</h3>

                    <h3>{user.role ? user.role.name : null}</h3>
                </div>
            </div>

            <Button onClick={clearStorage} className={cn(css(s.exitBtn), 'w-full flex rounded-m')}>
                <LogoutIcon />

                <h3 className="text-s font-500">Log out</h3>
            </Button>
        </div>
    )
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
    }),
    justifyContent: 'space-between',
    backgroundColor: COLORS.mainBlueBg,
    height: '64px',
    ...(open && {
        marginLeft: 250,
        width: 'calc(100% - 250px)',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
        }),
    }),
}))

const apis = {
    tenant_admin: ADMIN_INFO,
    customer_user: CUSTOMER_INFO,
}

function MemoizedComponent({ isOpen, open }) {
    const navigate = useNavigate()
    const popupRef = useRef()
    const exitRef = useRef()
    const [, setOpenList] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(false)

    const userType = localStorage.getItem('userType')
    const loadUserInfo = useGetRequest({ url: apis[userType] })
    const user = loadUserInfo.response ? loadUserInfo.response : {}

    useEffect(() => {
        if (userType !== 'system_admin') {
            loadUserInfo.request()
        }
    }, [])

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen()
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen()
            }
        } else if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
        }
        setIsFullscreen(!isFullscreen)
    }

    return (
        <AppBar position="fixed" open={isOpen}>
            <div className={cn(css(s.cont), 'flex flex-1 items-center justify-between')}>
                <span className="flex items-center justify-center gap-2_5">
                    {!isOpen && (
                        <IconButton onClick={open}>
                            <MenuIcon htmlColor={COLORS.white} />
                        </IconButton>
                    )}

                    <Breadcrumbs />
                </span>

                <div className="flex items-center justify-center gap-7">
                    <IconButton onClick={toggleFullscreen}>
                        {isFullscreen ? <FullscreenExitIcon htmlColor="white" /> : <FullscreenIcon htmlColor="white" />}
                    </IconButton>

                    <IconButton>
                        <BellIcon />
                    </IconButton>

                    <Popup
                        ref={popupRef}
                        popupClassName={css(s.popup)}
                        position="right"
                        trigger={(
                            <div className="pointer flex items-center gap-4_5">
                                <div className="flex items-center gap-2_5">
                                    <div>
                                        <NoUserPhoto />
                                    </div>

                                    <div className={cn(css(s.userTitles), 'flex-col')}>
                                        <h3>{user.firstName}</h3>

                                        <h3>{user.lastName}</h3>
                                    </div>
                                </div>

                                <CaretDownIconSizeM />
                            </div>
                        )}>
                        <ProfilePopup
                            exitRef={exitRef}
                            navigate={navigate}
                            user={user}
                            loading={loadUserInfo.loading}
                            onClose={() => setOpenList(false)} />
                    </Popup>
                </div>
            </div>
        </AppBar>
    )
}

export const Header = memo(MemoizedComponent)

const s = StyleSheet.create({
    head: {
        height: '4rem',
        background: COLORS.mainBlueBg,
    },
    cont: {
        paddingLeft: 12,
        paddingRight: 50,
    },
    logo: {
        width: '18rem',
        minWidth: '17rem',
        borderRight: `1px solid ${COLORS.mainBlue}`,
    },
    navTitle: {
        color: COLORS.white,
        fontSize: 21,
        fontWeight: '500',
    },
    userTitles: {
        ':nth-child(1n) > :first-child': {
            color: COLORS.white,
            fontSize: 16,
            fontWeight: '500',
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.white,
            fontSize: 14,
            fontWeight: '500',
        },
    },
    profileBar: {
        padding: '0 16px',
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'transparent',
        ':hover': {
            background: 'rgba(255, 255, 255, 0.1)',
            borderColor: 'rgba(255, 255, 255, 0.2)',
        },
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'end',
            ':nth-child(1n) > :first-child': {
                fontWeight: '500',
                fontSize: 11,
                color: '#FFFFFFBF',
            },
            ':nth-child(1n) > :last-child': {
                fontWeight: '600',
                fontSize: 12,
                color: '#FFF',
            },
        },
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: 50,
        background: COLORS.white,
    },
    innerAvatar: {
        width: 34,
        height: 34,
        borderRadius: 50,
        border: '1px solid rgba(0, 0, 0, 0.3)',
    },
    profile: {
        width: '13rem',
        position: 'relative',
        background: COLORS.white,
        backgroundBlendMode: 'color-dodge',
        backdropFilter: 'blur(65px)',
        borderRadius: 10,
        padding: '12px 10px',
        '::before': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            top: -12,
            left: '42%',
            borderLeft: '14px solid transparent',
            borderRight: '14px solid transparent',
            borderBottom: '14px solid white',
        },
    },
    name: {
        ':nth-child(1n) > :first-child': {
            color: COLORS.blackTxt,
            fontWeight: '600',
            fontSize: 14,
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.graySubTxt,
            fontWeight: '500',
            fontSize: 12,
        },
    },
    lst: {
        fontWeight: '400',
        fontSize: 12,
        color: COLORS.mainBlue,
        listStyle: 'none',
        ':nth-child(1n) > *': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
        },
    },
    defaultAvatar: {
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
    popup: {
        top: 60,
        borderRadius: 10,
        boxShadow: '0px 10px 100px 0px rgba(0, 0, 0, 0.10)',
    },
    exitBtn: {
        padding: 7,
        color: COLORS.red,
        background: COLORS.logoutBtn,
    },
})
