import React, { Suspense } from 'react'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import LoadingSpinner from '../components/common/LoadingSpinner'

export default function LazyLoad(Component) {
    const LazyComponent = React.lazy(() => Promise.resolve({ default: Component }))

    return function (props) {
        return (
            <Suspense
                fallback={(
                    <div className={cn(css(s.loader), 'flex-cent h-full')}>
                        <LoadingSpinner width="30%" strokeWidth={3} />
                    </div>
                )}
            >
                <LazyComponent {...props} />
            </Suspense>
        )
    }
}
const s = StyleSheet.create({
    loader: {
        width: '100%',
    },
})
