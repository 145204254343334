import { useMessage } from './message'

export default function useHandleErrors() {
    const showMessage = useMessage()

    const handleErrorMsg = (error) => {
        const errorMessage = Array.isArray(error.data.detail)
            ? `${error.data.detail[0].loc[1]} ${error.data.detail[0].msg}`
            : error.data.detail

        showMessage(errorMessage, 'error-msg')
    }

    return { handleErrorMsg }
}
