import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import { usePostRequest } from '../../../shared/hooks/requests'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import TenantProfileForm from '../components/TenantProfileForm'
import { ATTACH_MODULES_TO_TENANT_PROFILE, TENANT_PROFILE } from '../../../shared/utils/urls'
import useTenantProfileStore from '../../../shared/store/tenantProfileStore'
import { useMessage } from '../../../shared/hooks/message'

export default function Create() {
    const { handleErrorMsg } = useHandleErrors()
    const showMessage = useMessage()
    const { setResponse, profileResponse } = useTenantProfileStore()
    const [step, setStep] = useState(1)
    const [open, setOpen] = useState(false)
    const createProfile = usePostRequest({ url: `${TENANT_PROFILE}/` })
    const attachModules = usePostRequest({ url: ATTACH_MODULES_TO_TENANT_PROFILE })

    async function handleCreateProfile(values, setFieldValue) {
        if (!values || createProfile.loading) return

        const { name, description } = values
        const { response, success, error } = await createProfile.request({
            data: {
                name,
                description,
            },
        })

        if (success) {
            showMessage('Profile created now click again to attach modules', 'success-msg')
            setFieldValue('tenantProfileId', response.id)
            setResponse({
                ...profileResponse,
                items: [...(profileResponse.items || []), response],
            })
            setStep(2)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    async function handleAttachModules(modules, tenantProfileId) {
        if (attachModules.loading) return

        if (!modules || !modules.length) {
            showMessage('Please select at least one module', 'error-msg')
            return
        }

        const { success, error } = await attachModules.request({
            data: {
                tenantProfileId,
                modules: modules.map((m) => m.id),
            },
        })

        if (success) {
            setResponse({
                ...profileResponse,
                items: profileResponse.items
                    .map((item) => (item.id === tenantProfileId ? { ...item, modules } : item)),
            })
            setStep(3)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const create = async (values, helpers) => {
        if (!values) return

        const { setFieldValue } = helpers

        if (step === 1) {
            await handleCreateProfile(values, setFieldValue)
        }

        if (step === 2) {
            const tenantProfileId = values.tenantProfileId || values.id
            await handleAttachModules(values.modules, tenantProfileId)
        }

        if (step === 3) {
            setOpen(false)
            setStep(1)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Tenant Profile</h3>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <TenantProfileForm
                        onClose={() => setOpen(false)}
                        onSubmit={create}
                        step={step}
                        loading={createProfile.loading || attachModules.loading}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}
