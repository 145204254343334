import React, { useState } from 'react'
import { Paper, Tabs, Tab, Box, Typography } from '@mui/material'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../shared/utils/colors'
import Button from '../../../components/common/Button'
import notificationImage1 from '../../../static/images/notification1.png'
import notificationImage2 from '../../../static/images/notification2.png'
import notificationImage3 from '../../../static/images/notification3.png'
import notificationImage4 from '../../../static/images/notification4.png'

// Локальная переменная для табов
const initialTabItems = [
    { id: 'suspicious', label: 'Suspicious Item' },
    { id: 'gun', label: 'Gun Detection' },
    { id: 'intruder', label: 'IntruderZone' },
    { id: 'line', label: 'LineCross' },
    { id: 'overcrowd', label: 'OvercrowdDetection' },
]

export default function Notifications() {
    const [selectedTab, setSelectedTab] = useState('gun')

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    return (
        <Paper square className="flex flex-col gap-2.5 h-full">
            <Typography variant="h6" className={cn(css(s.title))}>
                Notifications
            </Typography>
            {/* Табы для переключения между типами уведомлений */}
            <Box className={cn(css(s.tabsWrapper))}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="notification tabs"
                    variant="scrollable" // Добавляем прокрутку
                    scrollButtons="auto" // Кнопки прокрутки
                    className={cn(css(s.tabs))}
                    indicatorColor="none"
                >
                    {initialTabItems.map((tab) => (
                        <Tab
                            key={tab.id}
                            label={tab.label}
                            value={tab.id}
                            className={cn(css(s.tab))}
                            classes={{
                                selected: css(s.tabSelected), // Стиль для активного таба
                            }}
                        />
                    ))}
                </Tabs>
            </Box>

            {/* Содержимое табов */}
            <Box className={cn(css(s.notificationsList))}>

                {/* Здесь будет рендериться список уведомлений для выбранного таба */}

                <NotificationItem imageOne={notificationImage1} imageTwo={notificationImage1} />
                <NotificationItem imageOne={notificationImage2} imageTwo={notificationImage2} />
                <NotificationItem imageOne={notificationImage3} imageTwo={notificationImage3} />
                <NotificationItem imageOne={notificationImage4} imageTwo={notificationImage4} />

                {/* Другие уведомления */}
            </Box>
        </Paper>
    )
}

function NotificationItem({ imageOne, imageTwo }) {
    return (
        <div className={cn(css(s.notificationItem))}>
            <div className={cn(css(s.notificationImageWrapper))}>
                {/* images */}
                <img src={imageOne} alt="camera" className={cn(css(s.notificationImage))} />
                <img src={imageTwo} alt="camera" className={cn(css(s.notificationImage))} />
            </div>
            <div className={cn(css(s.notificationBodyWrapper))}>
                <div>
                    <span className={cn(css(s.titleText))}>Camera: </span>
                    <span className={cn(css(s.opacityText))}>Exit</span>
                </div>
                <div>
                    <span className={cn(css(s.titleText))}>Date: </span>
                    <span className={cn(css(s.opacityText))}>17.09.2024</span>
                </div>
                <div>
                    <span className={cn(css(s.titleText))}>Time: </span>
                    <span className={cn(css(s.opacityText))}>23:59</span>
                </div>
            </div>
            <div>
                {/* details button */}
                <Button className={css(s.actBtn)}>Details</Button>

            </div>
        </div>
    )
}

// Стили Aphrodite
const s = StyleSheet.create({
    tabsWrapper: {
        overflowX: 'auto',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
    },
    tabs: {
        backgroundColor: COLORS.sepia,
        display: 'flex',
        minHeight: '30px',
        justifyContent: 'center',
        borderRadius: '6px',
    },
    tab: {
        minHeight: '30px',
        whiteSpace: 'nowrap',
        fontSize: '12px',
        fontWeight: 400,
        padding: '6px 10px',
        textTransform: 'capitalize',
        ':nth-child(2n)': {
            borderRight: `1px solid ${COLORS.gainsboro}`,
            borderLeft: `1px solid ${COLORS.gainsboro}`,
        },
        ':last-child': {
            borderRight: 'none',
        },
        ':hover': {
            opacity: 0.8,
        },
    },
    tabSelected: {
        backgroundColor: COLORS.mainBlue,
        color: COLORS.white,
    },
    notificationsList: {
        marginTop: '16px',
    },
    title: {
        margin: '16px 0 0 12px',
        fontSize: 18,
        fontWeight: 600,
    },
    notificationItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 16px',
        borderBottom: `1px solid ${COLORS.sepia}`,
        gap: '40px',
        ':last-child': {
            borderBottom: 'none',
        },
    },
    notificationImageWrapper: {
        display: 'flex',
        gap: '5px',
    },
    notificationImage: {
        width: '53px',
        height: '59px',
        borderRadius: '10px',
        marginRight: '10px',
    },
    notificationBodyWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
    },
    titleText: {
        color: COLORS.black,
        fontWeight: 500,
    },
    opacityText: {
        color: '#878787',
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '4px 9px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        ':hover': {
            background: COLORS.mainBlue,
        },
    },
})
