import React, { useEffect, useState, useCallback } from 'react'
import { LinearProgress, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { COLORS } from '../../shared/utils/colors'

const colors = {
    'success-msg': COLORS.green,
    'error-msg': COLORS.red,
    'info-msg': '#ffb703',
}

export default function Message({ text, className, closeMessage, delay }) {
    const [progress, setProgress] = useState(100)

    const handleProgress = useCallback(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 0) {
                    clearInterval(interval)
                    closeMessage()
                    return 0
                }
                return Math.max(oldProgress - 1, 0)
            })
        }, delay * 10)

        return () => clearInterval(interval)
    }, [closeMessage, delay])

    useEffect(() => {
        const clearInterval = handleProgress()
        return clearInterval
    }, [handleProgress])

    return (
        <article className="w-full">
            <div className="flex w-full items-center justify-between p-2.5">
                <Typography className={`${className === 'info-msg' ? 'text-black' : 'text-white'} text-xs`}>
                    {text}
                </Typography>

                <CloseIcon className="cursor-pointer text-white" onClick={closeMessage} />
            </div>

            <LinearProgress
                sx={{
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: COLORS.white,
                        opacity: 0.5,
                    },
                    backgroundColor: colors[className],
                    borderRadius: '0 0 4px 4px',
                }}
                variant="determinate"
                value={progress}
            />
        </article>
    )
}
