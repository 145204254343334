import React, { useEffect } from 'react'
import { Days, Month, Weeks } from '../../components/svgs/Svgs'
import { AnalyticCard } from './components/analyticCard'
import StackedBarChart from '../../components/common/chart'
import { ChartLine } from '../../components/common/lineChart'
import { PieCard } from './components/pieCard'
import HorizontalChart from '../../components/common/horizontalChart'
import { useLoad } from '../../shared/hooks/requests'
import { ENITITY_LIST } from '../../shared/utils/urls'
import { useFilter } from '../../app/contexts/FilterContextProvider'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { GetBranchData } from '../branch-analytics/features/getBranchData'
import Filter from './features/filter'
import { StaticCard } from './components/staticCard'
import useTenantProfileStore from '../../shared/store/tenantProfileStore'

export default function Dashboard() {
    const { entityFilters, setEntityFilter } = useFilter()
    const { daily, weekly, monthly, monthlyBreak, agely, hourlyData } = GetBranchData({ filter:
        entityFilters.dashboard })
    const getEntity = useLoad({ url: ENITITY_LIST })
    const entityList = getEntity.response ? getEntity.response : []

    const ParentName = entityList.parent && entityList.parent.hierarchyLevel === 3 ? entityList.parent.name : null

    const { setEntityFilters } = useTenantProfileStore()
    useEffect(() => {
        if (entityList.parent) {
            setEntityFilters({ parent: entityList.parent })
        }
    }, [entityList, setEntityFilters])
    return (
        <div className="flex flex-col h-full  items-center gap-[14px]">
            {entityList.parent && entityList.parent.hierarchyLevel === 3 ? (
                <div className="w-[100%] h-auto flex flex-col items-start justify-start
             gap-[11px] pt-[12px] pb-[11px] pl-[14px] bg-white rounded-[10px]">
                    <span className="text-black text-[20px] font-normal">{ParentName}</span>
                </div>
            ) : (
                <div className="w-[100%] h-auto flex flex-col items-start justify-start
             gap-[11px] pt-[12px] pb-[11px] pl-[14px] bg-white rounded-[10px]">
                    <span className="text-black text-[16px] font-medium">Filter</span>
                    <Filter items={entityList || {}}
                        placeholder="ALL"
                        value={entityFilters.dashboard}
                        onChange={(e) => setEntityFilter('dashboard', e.target.value)} />
                </div>
            )}
            <div className=" w-[100%] flex flex-col items-center gap-[14px]">
                <div className=" w-[100%] h-[100%] flex items-center gap-[19px]">
                    {daily
                        ? (
                            <StaticCard cardIcon={Days} cardTitle="Bugungi tashrif" cardValue={daily && daily.total}
                                manValue={daily && daily.gender.male} percentage={daily && daily.trend}
                                womanValue={daily && daily.gender.female} />
                        )
                        : (
                            <div className="w-[100%] bg-white flex items-center
                            justify-center rounded-[14px] h-[166px]">
                                <LoadingSpinner />
                            </div>
                        ) }
                    {weekly
                        ? (
                            <StaticCard cardIcon={Weeks} cardTitle="Haftalik tashriflar"
                                cardValue={weekly && weekly.total} manValue={weekly && weekly.gender.male}
                                percentage={weekly && weekly.trend} womanValue={weekly && weekly.gender.female} />
                        )
                        : (
                            <div className="w-[100%] bg-white flex items-center
                            justify-center rounded-[14px] h-[166px]">
                                <LoadingSpinner />
                            </div>
                        )}
                    {monthly
                        ? (
                            <StaticCard cardIcon={Month} cardTitle="Oylik tashriflar"
                                cardValue={monthly && monthly.total} manValue={monthly && monthly.gender.male}
                                percentage={monthly && monthly.trend} womanValue={monthly && monthly.gender.female} />
                        )
                        : (
                            <div className="w-[100%] bg-white flex items-center
                             justify-center rounded-[14px] h-[166px]">
                                <LoadingSpinner />
                            </div>
                        )}
                </div>

                <div className=" w-[100%] h-[100%] flex items-center gap-[14px]">
                    {monthly
                        ? (
                            <AnalyticCard cardTitle="Oylik tashrif buyuruvchilar"
                                data={monthlyBreak || []} isActive component={StackedBarChart} />
                        )
                        : (
                            <div className="w-[100%] bg-white flex items-center
                             justify-center rounded-[14px] h-[353px]">
                                <LoadingSpinner />
                            </div>
                        )}
                    {hourlyData
                        ? (
                            <AnalyticCard cardTitle="Bugungi tashrif vaqti"
                                data={hourlyData || []} component={ChartLine} />
                        )
                        : (
                            <div className="w-[100%] bg-white flex items-center
                            justify-center rounded-[14px] h-[353px]">
                                <LoadingSpinner />
                            </div>
                        )}
                </div>

                <div className="w-[100%] h-[100%] pb-[10px] flex items-start gap-[14px]">
                    <div className="w-[60%] h-[100%]">
                        {agely
                            ? (
                                <AnalyticCard cardTitle="Yosh bo'yicha kundalik tahlil"
                                    data={agely || []} isActive component={HorizontalChart} />
                            )
                            : (
                                <div className="w-[100%] bg-white flex items-center
                                justify-center rounded-[14px] h-[313px]">
                                    <LoadingSpinner />
                                </div>
                            )}
                    </div>
                    <div className="w-[40%] h-[100%]">
                        <PieCard />
                    </div>
                </div>
            </div>
        </div>
    )
}
