import moment from 'moment/moment'
import React from 'react'

export function CameraLiveAnalytics({ items = [], workTime, offTime }) {
    const displayedTimes = new Set()

    // Generate time intervals from 00:00 to 23:59
    let startTime = moment('00:00', 'HH:mm')
    const endTime = moment('23:59', 'HH:mm')
    const allTimes = []

    while (startTime <= endTime) {
        allTimes.push(startTime.format('HH:mm'))
        startTime = startTime.add(30, 'minutes')
    }

    const firstDataTime = items.length > 0 ? moment(items[0].id, 'HH:mm') : null
    const lastDataTime = items.length > 0 ? moment(items[items.length - 1].id, 'HH:mm') : null

    return (
        <div className="absolute z-10 translate-x-[-170px] p-2 flex flex-col gap-[15px]
         bg-white shadow-lg w-[404px] border border-gray-100 rounded-lg">
            <div className="w-[100%] flex flex-col gap-[10px]">
                <span className="font-normal text-[16px] text-black">Detailed statistics</span>
                <div className="flex w-[100%] items-center justify-between gap-[20px]">
                    <div className="flex w-[100%] flex-col items-center gap-[5px]">
                        <span className="text-[21px] font-semibold text-black">{workTime}</span>
                        <span className="text-[14px] font-normal text-gray-200">Working time</span>
                    </div>
                    <div className="flex flex-col w-[100%] items-center gap-[5px]">
                        <span className="text-[21px] font-semibold text-black">{offTime}</span>
                        <span className="text-[14px] font-normal text-gray-200">Off time</span>
                    </div>
                </div>
            </div>

            {/* Conditionally render time intervals only if data is available */}
            {items.length > 0 && (
                <div className="flex w-full overflow-x-auto text-xs">
                    {allTimes.map((time) => {
                        const entry = items.find((item) => item.id === time)
                        const isBeforeFirstEntry = !entry && firstDataTime
                        && moment(time, 'HH:mm').isBefore(firstDataTime)
                        const isAfterLastEntry = !entry && lastDataTime && moment(time, 'HH:mm').isAfter(lastDataTime)
                        const isLastTimeInData = lastDataTime && time === lastDataTime.format('HH:mm')
                        const entryTime = time.slice(0, 2)
                        const hour = parseInt(entryTime, 10)

                        // Display times at 30-minute intervals (00, 02, 04, ..., 10)
                        const shouldDisplayTime = hour % 1 === 0 && !displayedTimes.has(entryTime)

                        // Add the entryTime to the displayedTimes set if it will be shown
                        if (shouldDisplayTime) {
                            displayedTimes.add(entryTime)
                        }

                        return (
                            <div key={time} className="flex flex-col items-center" style={{ minWidth: '40px' }}>
                                <div
                                    style={{
                                        width: '100%',
                                        backgroundColor: isBeforeFirstEntry
                                            ? 'red'
                                            : isAfterLastEntry || (isLastTimeInData && time !== '23:59')
                                                ? 'gray'
                                                : (entry ? (entry.count > 30 ? 'green' : 'red') : 'gray'),
                                        height: '8px',
                                        margin: 0,
                                        borderRadius: '4px',
                                    }}
                                />
                                {shouldDisplayTime && (
                                    <span className="text-[10px] w-[100%] text-center text-gray-500 mt-1">
                                        {entryTime}
                                    </span>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
