import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../../../shared/utils/colors'
import { useMessage } from '../../../../../shared/hooks/message'
import useHandleErrors from '../../../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../../../shared/hooks/requests'
import { CAMERAS_TENANT, SMARTCAMERAS_TENANT } from '../../../../../shared/utils/urls'
import DeviceDetails from '../../components/DeviceDetails'

const URLS = {
    smart: SMARTCAMERAS_TENANT,
    camera: CAMERAS_TENANT,
}

export default function Update({ setData, item, camType }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateCamera = usePutRequest({ url: `${URLS[camType]}/${item.id}` })

    const update = async (values) => {
        const { success, error, response } = await updateCamera.request({ data: values })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (val.id === item.id ? { ...val, ...response } : val)),
            }))
            showMessage('Successfully updated', 'success-msg')
            setOpenDrawer(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpenDrawer(true)}>
                <Edit htmlColor={COLORS.white} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}>
                <DeviceDetails
                    camType={camType}
                    onEdit={update}
                    onClose={() => setOpenDrawer(false)}
                    item={item} />
            </Drawer>
        </>
    )
}
