import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { ML_MODEL_UPLOAD_PIPELINE, ML_MODEL_UPLOAD_CONFIG } from '../../../shared/utils/urls'
import SelectInput from '../../../shared/ui/SelectInput'
import { useMlModelFileUpload } from '../services/fileUpload'
import NumericFeatures from './NumericFeatures'
import CategoricalFeatures from './CategoricalFeatures'
import BinaryFeatures from './BinaryFeatures'
import TimeSeriesFeatures from './TimeSeriesFeatures'
import TextFeatures from './TextFeatures'

const formInitialValues = {
    version: '',
    description: '',
    s3ModelPipelinePath: '',
    s3ModelConfigPath: '',
    mlModelProfileId: '',
    categoricalFeatures: [{ id: 1, name: '', description: '', acceptedValues: [] }],
    numericFeatures: [{ id: 1, name: '', description: '' }],
    binaryFeatures: [{ id: 1, name: '', description: '' }],
    timeSeriesFeatures: [{ id: 1, name: '', description: '' }],
    textFeatures: [{ name: '', description: '' }],
}

const chipStyles = {
    width: 'fit-content',
    border: '1px solid #3C8DE6',
    backgroundColor: '#3C8DE633',
    borderRadius: 2,
    padding: '4px 0',
    color: '#147FF9',
    fontSize: 14,
    fontWeight: '400',
}

export default function MLModelForm({ initialValues, onClose, onSubmit, isEdit, profiles }) {
    const { UploadFile } = useMlModelFileUpload()
    const options = profiles ? profiles.map((v) => ({ value: v.id, label: v.name })) : []
    const pipelineInputRef = useRef()
    const readmeInputRef = useRef()
    const userType = localStorage.getItem('userType')

    const handleAddNewFeature = (type, setFieldValue, values) => {
        const newFeature = { name: '', description: '' }
        if (type === 'categoricalFeatures' || type === 'timeSeriesFeatures') {
            newFeature.acceptedValues = []
        }
        setFieldValue(type, [...values[type], newFeature])
    }

    const handleNumberChange = (e, handleChange) => {
        const { value } = e.target
        const numericValue = parseFloat(value)
        if (!Number.isNaN(numericValue) && numericValue > 0) {
            handleChange(e)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...formInitialValues,
                ...initialValues,
            }}
            onSubmit={onSubmit}
            initialTouched={{ remote: true }}
        >
            {({ handleChange, values, setFieldValue }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            required
                            disabled={userType !== 'system_admin'}
                            type="number"
                            name="version"
                            label="Version"
                            handleChange={(e) => handleNumberChange(e, handleChange)} />

                        <FormTextField
                            multiline
                            fullWidth
                            disabled={userType !== 'system_admin'}
                            name="description"
                            label="Version description"
                            handleChange={handleChange} />

                        <SelectInput
                            required
                            disabled={userType !== 'system_admin'}
                            name="mlModelProfileId"
                            label="Ml Model Profile"
                            menuItems={options}
                            onChange={(e) => {
                                setFieldValue('mlModelProfileId', e.target.value)
                            }} />

                        {values.mlModelProfileId && values.version ? (
                            <div className="w-full flex items-center justify-center gap-3">
                                <FormTextField
                                    readOnly
                                    required
                                    disabled={userType !== 'system_admin'}
                                    className="w-full"
                                    handleChange={handleChange}
                                    onClick={() => pipelineInputRef.current.click()}
                                    name="s3ModelPipelinePath"
                                    label="Add machine learning pipeline" />

                                <FormTextField
                                    readOnly
                                    required
                                    disabled={userType !== 'system_admin'}
                                    className="w-full"
                                    handleChange={handleChange}
                                    onClick={() => readmeInputRef.current.click()}
                                    name="s3ModelConfigPath"
                                    label="Add Documentation markdown file" />
                            </div>
                        ) : null}

                        <NumericFeatures
                            setValue={setFieldValue}
                            handleChange={handleChange}
                            addNew={handleAddNewFeature}
                            values={values}
                            chipStyles={chipStyles} />

                        <CategoricalFeatures
                            setValue={setFieldValue}
                            handleChange={handleChange}
                            addNew={handleAddNewFeature}
                            values={values}
                            chipStyles={chipStyles} />

                        <BinaryFeatures
                            setValue={setFieldValue}
                            handleChange={handleChange}
                            addNew={handleAddNewFeature}
                            values={values}
                            chipStyles={chipStyles} />

                        <TimeSeriesFeatures
                            setValue={setFieldValue}
                            handleChange={handleChange}
                            addNew={handleAddNewFeature}
                            values={values}
                            chipStyles={chipStyles} />

                        <TextFeatures
                            setValue={setFieldValue}
                            handleChange={handleChange}
                            addNew={handleAddNewFeature}
                            values={values}
                            chipStyles={chipStyles} />
                    </div>

                    {userType === 'system_admin' && (
                        <div className="flex items-center justify-end gap-2.5">
                            <CancelBtn
                                onClick={onClose}
                                title="Cancel"
                                type="button" />

                            <PrimaryBtn
                                title={isEdit ? 'Edit' : 'Create'}
                                type="submit" />
                        </div>
                    )}

                    <input
                        id="file"
                        type="file"
                        name="s3ModelPipelinePath"
                        ref={pipelineInputRef}
                        readOnly="readonly"
                        className="not-visible"
                        accept=".joblib, .pkl"
                        onChange={(e) => UploadFile(
                            e,
                            'pipeline',
                            setFieldValue,
                            's3ModelPipelinePath',
                            ML_MODEL_UPLOAD_PIPELINE,
                            options.find((opt) => opt.value.toString() === values.mlModelProfileId.toString()),
                            values.version,
                        )}
                    />

                    <input
                        id="file"
                        type="file"
                        name="s3ModelConfigPath"
                        ref={readmeInputRef}
                        readOnly="readonly"
                        className="not-visible"
                        accept=".md"
                        onChange={(e) => UploadFile(
                            e,
                            'config',
                            setFieldValue,
                            's3ModelConfigPath',
                            ML_MODEL_UPLOAD_CONFIG,
                            options.find((opt) => opt.value.toString() === values.mlModelProfileId.toString()),
                            values.version,
                        )}
                    />
                </Form>
            )}
        </Formik>
    )
}
